import React from "react";
import cn from 'classnames';


function ToggleSwitch(props) {
    const { isToggled, setIsToggled, size = '' } = props;
    const onToggle = () => setIsToggled(!isToggled);
    return (
        <label className={cn('toggle-switch', size)}>
            <input type="checkbox" checked={isToggled} onChange={onToggle} />
            <span className="switch" />
        </label>
    );
}
export default ToggleSwitch;