import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Container, Button, Input
} from "reactstrap"
import { GeneralContext } from '../../../context'

import { withNamespaces } from "react-i18next"
import { useParams } from 'react-router-dom'
import image_source from '../../../assets/images/Frame58.png'
import cn from 'classnames';


const Header = (props) => {
    const { active } = props;

    return (
        <React.Fragment>
            <div className='twt-header'>
                {/* <div className='twt-header-img'>
                    <img src={image_source}></img>
                </div> */}
                <div className='twt-header-row'>
                    <div className={cn('twt-header-row-cell', active === 1 ? 'selected' : '')}>
                        <span className={cn('twt-header-row-title', active === 1 ? 'selected' : '')}>
                            1. Create an account
                        </span>
                        <span className='twt-header-row-subtitle'>
                            Use magic link to create an account
                        </span>
                    </div>
                    <div className={cn('twt-header-row-cell', active === 2 ? 'selected' : '')}>
                        <span className={cn('twt-header-row-title', active === 2 ? 'selected' : '')}>
                            2. Verify your twitter account
                        </span>
                        <span className='twt-header-row-subtitle'>
                            Share a tweet to verify your account
                        </span>
                    </div>
                    <div className={cn('twt-header-row-cell', active === 3 ? 'selected' : '')}>
                        <span className={cn('twt-header-row-title', active === 3 ? 'selected' : '')}>
                            3. Mint <br /> your PFP
                        </span>
                        <span className='twt-header-row-subtitle'>
                            Confirm your free mint
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )

}
export default withRouter(withNamespaces()(Header))
