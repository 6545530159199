import React, { useEffect, useState } from 'react';
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Button,

} from "reactstrap"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import SortableCard from "./sortable-card"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from "../../../../components/Global/modals"
import ToggleSwitch from "../../../../components/Global/toggleSwitch"

import { faBan } from '@fortawesome/free-solid-svg-icons'
import DataService from '../../../../helpers/DataService';
import { toastrInfo } from '../../../../helpers/Toastr';
const config = {
    lockToContainerEdges: true
}

const SortableItem = (props) => {
    let { collection_id, property, swapPositions, array_properties, setPropertyAfterChange, parent_key } = props;
    useEffect(() => {
        console.log('property', property);
        let total_traits = property.variations.reduce((acc, variation) => acc + parseFloat(variation.rarity), 0);
        const updateData = {
            ...property,
            variations: property.variations.map(vari => {
                const percentage = Math.ceil((vari.rarity * (100 / total_traits)))
                return {
                    ...vari,
                    percentage: percentage
                }
            })
        }
        setUpdateProperty(
            updateData
        )
    }, [property])

    const [showModal, setShowModal] = useState(false)
    const [updateProperty, setUpdateProperty] = useState(false)
    const [includeTokenMetadata, setIncludeTokenMetadata] = useState(false)
    const [rarityWeight, setRarityWeight] = useState(false)
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxHeight: '70%',
            overflow: 'auto',
        }
    }

    const setWeight = (trait, weight) => {
        let find = property.variations.find(_trait => _trait.name === trait.name);
        let findIndex = property.variations.findIndex(_trait => _trait.name === trait.name);
        if (!find) return;
        find.weight = weight;

        property.variations[findIndex] = find;

        // setPropertyAfterChange(property)
    }

    const editProperties = () => {
        setShowModal(true)

    }



    const _closeModal = () => {
        setShowModal(false)

    }
    const __renderPOView = () => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={bodyAttribute}
            footer={footer}
            styles={modalStyles}
        />
    )

    const footer = <div className='modalcard-footer row'>
        <Button
            type="button"
            color="primary"
            className="theme-button-white-primary"
            onClick={(e) => {
                e.preventDefault()
                _closeModal();
            }}
        >
            {props.t(`Cancel`)}
        </Button>
        <Button
            type="button"
            color="primary"
            className="theme-button-secundary"
            onClick={(e) => {
                e.preventDefault()
                _handlePropertyAfter();
            }}
        >
            {props.t(`Save "${property.name}" trait`)}
        </Button>
    </div>

    const bodyAttribute = <div className='collections-layers-dialog'>
        <p className='collections-layers-dialog-title'>
            Edit {property.name} Attribute
        </p>
        <div className='collections-layers-dialog-token'>
            <p>Include in token metadata?</p>
            <ToggleSwitch isToggled={includeTokenMetadata} setIsToggled={setIncludeTokenMetadata} size={'small'} />
        </div>
        <p className='collections-layers-dialog-strong'>
            Set the rarity settings for each of the traits below, so Neefter knows how often they should appear.
        </p>
        <p className='collections-layers-dialog-subtitle'>
            Updating the rarity for each trait will adjust the estimated quantity and percentage of such trait.
        </p>
        <div className='collections-layers-dialog-table'>
            <table>
                <thead>
                    <tr>
                        <th>Trait name</th>
                        <th className='end'>Rarity Weight</th>
                        <th className='end'>
                            <div className='collections-layers-dialog-table-header'>
                                Est. Quantity
                                {/* <span>#</span>
                                <ToggleSwitch isToggled={rarityWeight} setIsToggled={setRarityWeight} size={'small'} />
                                <span>%</span> */}
                            </div>

                        </th>
                        <th className='end'>Est. Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        updateProperty && updateProperty.variations.map((variation, childKey) => (
                            (
                                <tr key={'tr' + childKey}>
                                    <td>
                                        {
                                            variation.image &&
                                            <div className='collections-layers-dialog-table-card'>
                                                <img style={{ width: '100%' }} alt={variation.name} src={variation.image} key={childKey} />
                                                <p>{variation.name}</p>
                                            </div>
                                        }{
                                            !variation.image &&
                                            <div className='collections-layers-dialog-table-card ico'>
                                                <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                                                <p>{variation.name}</p>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <div className='input-container_full'>
                                            <div className='collections-minting-control' >
                                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_hficon')?.click()}>
                                                    <Input
                                                        required
                                                        value={variation.rarity}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=''
                                                        id={"creator_share" + childKey}
                                                        pattern="[0-9]"
                                                        onChange={event => {
                                                            const { value } = event.target

                                                            if (!value || isNaN(value)) {
                                                                setLocalWeight(variation, '')
                                                            } else {
                                                                setLocalWeight(variation, parseFloat(value))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className='collections-minting-control-icon'>
                                                    {/* <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon> */}
                                                    {/* % */}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className='end'>
                                            {variation.rarity || 0}
                                        </p>
                                    </td>
                                    <td>
                                        <p className='end'>
                                            {variation.percentage || 0}%
                                        </p>
                                    </td>
                                </tr>
                            )
                        ))
                    }
                </tbody>
            </table>
        </div>
        {
            updateProperty &&
            <div className='collections-layers-dialog-display'>
                <p className='collections-layers-dialog-display-title'>
                    How often do you want the trait "{updateProperty.name}" to display?
                </p>
                <p className='collections-layers-dialog-display-subtitle'>

                    I.e. setting this to {updateProperty.percentage}% means that about {updateProperty.percentage}% of your NFTs will have "{property.name}"
                </p>
                <input type="range" id="vol0" name="vol0" min={0} max="100" value={updateProperty.percentage}
                    onChange={event => {
                        const { value } = event.target

                        if (!value) {
                        } else {
                            setLocalPercentage(parseFloat(value))
                        }
                    }}
                >
                </input>
            </div>
        }

    </div >



    const setLocalWeight = (variation, weight) => {
        let find = updateProperty.variations.find(_variation => _variation.id === variation.id);
        let findIndex = updateProperty.variations.findIndex(_variation => _variation.id === variation.id);
        if (!find) return;
        console.log('find', find);
        find.rarity = weight || 0;
        updateProperty.variations[findIndex].rarity = weight || 0;

        let total_traits = updateProperty.variations.reduce((acc, variation) => acc + parseFloat(variation.rarity || '0'), 0);
        console.log('total_traits', total_traits);
        updateProperty.variations.forEach((variation, index) => {
            const percentage = Math.ceil((variation.rarity * (100 / total_traits)))
            variation.percentage = percentage
        })

        setUpdateProperty({ ...updateProperty });
        // setPropertyAfterChange(updateProperty)
    }

    const setLocalWeightCard = (variation, weight) => {
        const search = setTimeout(async () => {

            let find = updateProperty.variations.find(_variation => _variation.id === variation.id);
            let findIndex = updateProperty.variations.findIndex(_variation => _variation.id === variation.id);
            if (!find) return;
            console.log('find', find);
            find.rarity = weight || 0;
            updateProperty.variations[findIndex].rarity = weight || 0;

            let total_traits = updateProperty.variations.reduce((acc, variation) => acc + parseFloat(variation.rarity || '0'), 0);
            console.log('total_traits', total_traits);
            updateProperty.variations.forEach((variation, index) => {
                const percentage = Math.ceil((variation.rarity * (100 / total_traits)))
                variation.percentage = percentage
            })

            setUpdateProperty({ ...updateProperty });
            const body = {
                name: find.name,
                image: find.image,
                rarity: find.rarity,
            }
            DataService.putCollectionTraitVariationById(collection_id, find.id, body).then(response => {
                toastrInfo('Trait updated')
            })
            // setPropertyAfterChange(updateProperty)
        }, 5000);
        return () => clearTimeout(search)
    }



    const setLocalPercentage = (value) => {
        updateProperty.percentage = value
        setUpdateProperty({ ...updateProperty });
    }

    const _handlePropertyAfter = () => {
        // property = updateProperty;
        console.log('updateProperty _handlePropertyAfter', updateProperty);
        property = {...updateProperty}
        setPropertyAfterChange(updateProperty)
        setShowModal(false)
    }
    return <div className='collections-attributes-row'>
        {
            showModal && __renderPOView()
        }
        <div className='collections-attributes-row-title'>
            <p>{property.name}</p>
            <UncontrolledButtonDropdown
                className="d-inline-block">
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag="a" onClick={() => { editProperties() }}>
                        <i className="fa fa-edit"></i>
                        {props.t(`Edit`)}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>

        <div className='collections-attributes-row-body'>
            {property.variations.map((variation, _childKey) => (
                <SortableCard key={'sortablecard' + _childKey} variation={variation} parent_key={parent_key} childKey={_childKey} setWeight={setLocalWeightCard} property={property}></SortableCard>
            ))}

        </div>
    </div>

}

export default withRouter(withNamespaces()(SortableItem))