import React, { useEffect, useState } from 'react'

import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"

import { isEmpty } from 'lodash'
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { toastrError } from "../../helpers/Toastr"
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { apiError, loginSuccess, loginUser, setToken } from "../../store/actions"

const Login = (props) => {
    const [nextPage] = useState(props.location?.state?.from?.pathname || '/collections')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [profile, setProfile] = useState()

    useEffect(() => {
        const { error } = props

        if (error) {
            toastrError('Utilizaste un usuario o password incorrecto', `Oop's`)
            props.apiError('')
        }
    }, [props?.error])


    useEffect(() => {
        if (!isEmpty(props.profile)) {
            setProfile(props.profile)
        }
    }, [props?.profile])

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateNull()) {
            toastrError('No olvides ingresar tu usuario y password')

            return false
        }

        const body = {
            email: email,
            password: password,
        }
        DataService.postAuthLogin(body).then(response => {

            if (response && response.data.token) {
                props.setToken(response.data.token)
                DataService.getAuthWhoami().then(response => {
                    // codigo para identificar usuarios 
                    window.analytics.identify(response.data.id, {
                        userId: response.data.id,
                        companies: response.data.company_name,
                        // name: `${response.data.name} ${response.data.last_name}`,
                        // firstName: response.data.name,
                        // lastName: response.data.last_name,
                        email: response.data.email,
                        created: new Date()
                    })

                    props.loginSuccess(response.data)
                    props.history.push('/collections')
                })
            }
        })
            .catch(err => {
                toastrError('Invalid credentials')
            })
    }

    const _validateNull = () => {
        if (email === '') { return false }
        if (password === '') { return false }

        return true
    }
    function _passwordFormat(value) {
        const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/;
        if (!PASSWORD_REGEXP.test(value)) {
            return false;
        }
        return true;
    }

    const __loginForm = () => {
        return (
            <div className="form">
                <h1>Login</h1>

                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                Email or Phone
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                Password
                            </Label>
                            <Input
                                type="password"
                                className="form-control"
                                value={password}
                                id="password"
                                onChange={event => {
                                    const { value } = event.target
                                    if (value === '' || !_passwordFormat(value)) {
                                        document.getElementById('password').classList.add('mt-required-error')
                                    } else {
                                        document.getElementById('password').classList.remove('mt-required-error')
                                    }
                                    setPassword(value)
                                }} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                            Login
                        </Button>
                    </Col>

                    <div style={{ padding: '4px 12px' }}>
                        <Link to="/recover-password" onClick={() => {
                            segmentTrack('go to recover password')
                        }}>Forgot password?</Link>
                        <Link to="/signup" style={{ marginLeft: '8px' }} onClick={() => {
                            segmentTrack('go to recover sign up')
                        }}>Sign up</Link>
                    </div>

                </Row>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={_handleSubmit}>
                        <div className="logo">
                            <img alt="Neefter" src="./assets/images/neefter.svg" />
                        </div>

                        {isEmpty(profile) && __loginForm()}
                    </Form>

                    <Row>
                        <Col sm={12} className="login-copyright">
                            <p>Copyright © {new Date().getFullYear()}. All rights reserved.</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error, profile } = state.Auth

    return { error, profile }
}

export default withRouter(
    connect(mapStatetoProps, {
        loginUser, apiError, setToken,
        loginSuccess,
    })(Login)
)