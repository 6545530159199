import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
    Card,
    CardBody,
    CardFooter,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import FilterContainer from './components/token-filters/filter-container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHome } from '@fortawesome/free-solid-svg-icons'

import { useParams } from 'react-router-dom'
import Modal from "../../components/Global/modals"
import Dropzone from "./components/dropzone"

import Whitecube from "../../assets/images/white-cube.jpg"
import ProgressHeader from '../../components/Global/Progress-header'
import TabHeaderMenu from './components/tab-header-menu'
import DataService from '../../helpers/DataService'
import { toastrError, toastrInfo } from "../../helpers/Toastr"
import { segmentTrack } from "../../helpers/Segment"
import { fileReader } from '../../helpers/constants';



const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    let { loading,
        setLoading,
        loading_files,
        setLoadingFiles,
        total_files,
        setTotalFiles,
        current_files,
        setCurrentFiles,
        total_size,
        setTotalSize,
        current_size,
        setCurrentSize,
        currentProject,
        setBreadcrumb,
    } = generalContext
    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);
    const { collection_id } = useParams();


    let [cache_zip, setCacheZip] = useState()
    let [tokensFromSettings, setTokensFromSettings] = useState(0)

    let [universeFiles, setUniverseFiles] = useState([]);
    let [pairFiles, setPairFiles] = useState([]);
    let [assetLoaded, onAssetLoaded] = useState([]);

    // orm

    let [assets, setAssets] = useState([]);
    let [step_number, setStepNumber] = useState(0);

    const [showModal, setShowModal] = useState(false)
    const [showModalCard, setShowModalCard] = useState(null)

    const [enableCloseModal, setEnableCloseModal] = useState(false)
    const [assetsUpdated, setAssetsUpdated] = useState(false)
    let [trait_hash__contains, set_trait_hash__contains] = useState([])


    const [collection_saved, setCollectionSaved] = useState('')
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    const [array_filters, setArrayFilters] = useState([])
    let [filteredData, setFilteredData] = useState([])
    let [filteredMixedData, setFilteredMixedData] = useState([])
    const limit = 50;
    let [actualPage, setActualPage] = useState(1)

    let [filteredMixedDataLength, setFilteredMixedDataLength] = useState(0)
    let [fetched, setFetched] = useState(false)

    let [filteredDataLength, setFilteredDataLength] = useState(0)
    let [newAssets, setNewAssets] = useState([]);
    let [newAssetFillters, setNewAssetFilters] = useState([]);
    let [universal_files, setUniversalFiles] = useState([]);

    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredDataLength < tokensFromSettings) {
                    setActualPage(++actualPage)
                    getCollectionNftListById()
                }
            }
        }

    }, [])
    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredDataLength < tokensFromSettings) {
                    setActualPage(++actualPage)
                    getCollectionNftListById()

                }
            } else {
            }
        }

    }, [filteredDataLength, filteredMixedDataLength])

    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Preview`)
        })
        setProjectId(currentProject.id)
        const doc = document.getElementById('content')
        if (doc) {
            doc.classList.add('no-max-width')
        }

    }, [currentProject])


    useEffect(() => {
        if (collection_id) {
            setLoading(true)
            DataService.getCollectionById(collection_id).then(
                response => {
                    if (response && response.data) {
                        const { step_number, name, total_tokens } = response.data;
                        setStepNumber(step_number)
                        tokensFromSettings = total_tokens;
                        setTokensFromSettings(total_tokens)
                        checkNftList()
                    }
                })
        }
    }, [collection_id])


    const checkNftList = () => {
        const params = {
            offset: 0,
            limit: 50,
            order_by: 'is_generative.asc,name.asc'
        }
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                const { data, total_count } = response.data;
                console.log('tokensFromSettings', tokensFromSettings);
                const filterData = data.map(item => {
                    const split = item.image.split('.')
                    const extension = split[split.length - 1]
                    let type = 'image'
                    if (extension.toLowerCase() === 'mp4') {
                        type = 'video'
                    } else if (extension.toLowerCase() === 'glb') {
                        type = '3d'
                    }
                    return {
                        ...item,
                        type: type
                    }
                })
                checkFiltersNftList()
                // set tokens
                filteredData = filterData;
                setFilteredData(filteredData)
                setLoading(false)


            }
        })
    }

    const checkFiltersNftList = () => {
        DataService.getCollectionNftFiltersListById(collection_id).then(response => {
            console.log('getCollectionNftFiltersListById', response.data);
            const checkfilters = []
            response.data.forEach((filter, index) => {
                const findFilter = checkfilters.findIndex(fl => fl.trait_id === filter.trait_id)
                if (findFilter > -1) {
                    checkfilters[findFilter].variations.push({
                        ...filter,
                        checked: false
                    })
                } else {
                    checkfilters.push({
                        trait_id: filter.trait_id,
                        trait_name: filter.trait_name,
                        variations: [{
                            ...filter,
                            checked: false
                        }]
                    })
                }
            });
            console.log('checkfilters', checkfilters);
            setArrayFilters(checkfilters)
            setLoading(false)
        })
    }

    const getCollectionNftListById = (reset = false) => {
        setLoading(true)

        const params = {
            offset: (actualPage - 1) * limit,
            limit: 50,
            order_by: 'is_generative.asc,name.asc',
            trait_hash__contains: trait_hash__contains.length > 0 ? trait_hash__contains.toLocaleString() : undefined
        }
        console.log('getCollectionNftListById', params);
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                const { data, total_count } = response.data;
                console.log('response.tokensFromSettings', tokensFromSettings);
                // set tokens
                const filterData = data.map(item => {
                    const split = item.image.split('.')
                    const extension = split[split.length - 1]
                    let type = 'image'
                    if (extension.toLowerCase() === 'mp4') {
                        type = 'video'
                    } else if (extension.toLowerCase() === 'glb') {
                        type = '3d'
                    }
                    return {
                        ...item,
                        type: type
                    }
                })
                console.log('filteredData', filteredData);
                if (reset) {
                    const ndata = [...filterData]
                    console.log('ndata', ndata);
                    filteredData = ndata;
                    setFilteredData([...filteredData])
                    setLoading(false)
                } else {
                    const ndata = [...filteredData, ...filterData]
                    console.log('ndata', ndata);
                    filteredData = ndata;
                    setFilteredData([...filteredData])
                    setLoading(false)
                }


            }

        })
    }


    useEffect(() => {

        if (actualPage > 1) {
            const actual = (actualPage - 1) * limit;
            const next = (actualPage * limit);
            setLoading(true);
            if (actual > filteredData.length) {
                setLoading(false);
                return;
            }
            toastrInfo('Loading more...', 'Info')


            setTimeout(() => {



                if (next >= filteredData.length - 1) {
                    setFilteredMixedData([...filteredMixedData, ...filteredData.slice(actual, filteredData.length)])

                    setLoading(false);
                } else {
                    setFilteredMixedData([...filteredMixedData, ...filteredData.slice(actual, next)])

                    setLoading(false);
                }
            }, 1500);

        }
    }, [actualPage])

    useEffect(() => {
        if (collection_id) {
            DataService.getCollectionById(collection_id).then(
                response => {

                    tokensFromSettings = response.data.total_tokens;
                    setTokensFromSettings(response.data.total_tokens)
                    if (response && response.data) {
                        const { step_number } = response.data;
                        setStepNumber(step_number)
                    }
                })
        }
    }, [collection_id])




    const onChangeCheckedOption = (key, item) => {
        console.log('key', key);
        const findParentFilterIndex = array_filters.findIndex(af => af.trait_id === item.trait_id)
        if (findParentFilterIndex === -1) return;
        const findFilterIndex = array_filters[findParentFilterIndex].variations.findIndex(variation => variation.trait_variation_id === item.trait_variation_id)
        if (findFilterIndex === -1) return;
        const value = !array_filters[findParentFilterIndex].variations[findFilterIndex].checked
        array_filters.forEach(af => {
            af.variations.forEach(variation => {
                variation.checked = false;
            })
        })
        array_filters[findParentFilterIndex].variations[findFilterIndex].checked = value;

        if (value) {
            addItemToFilterList(item)
        } else {
            removeItemToFilterList(item)
        }
    }

    const addItemToFilterList = (item) => {
        console.log('addItemToFilterList', item);
        // trait_hash__contains.push(item.trait_variation_id)
        trait_hash__contains = [item.trait_variation_id]
        set_trait_hash__contains([item.trait_variation_id])
        setActualPage(1)
        getCollectionNftListById(true)

    }
    const removeItemToFilterList = (item) => {
        console.log('removeItemToFilterList');
        setActualPage(1)
        let arr = trait_hash__contains;
        const find_arr = trait_hash__contains.findIndex(th => th === item.trait_variation_id)
        if (find_arr === -1) return;
        arr.splice(find_arr, 1)
        getCollectionNftListById(true)

    }

    const itemsPerPage = 20;
    const [hasMore, setHasMore] = useState(true);
    const [records, setrecords] = useState(itemsPerPage);
    const loadMore = () => {


        if (records === filteredData.length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                if (records >= filteredData.length) {
                    setrecords(filteredData.length);
                } else {
                    setrecords(records + itemsPerPage);
                }
            }, 3000);
        }
    };


    const _handleAssetsUploaded = async () => {
        if (!assetLoaded || assetLoaded.length === 0) {
            toastrError(props.t(`Don't forget to upload correctly yours assets`), 'Error')
            return false
        }
        setAssetsUpdated(true)
        setShowModal(false);
        console.log('assetLoaded', assetLoaded);
        console.log('universeFiles', universeFiles);
        const totalFiles = universeFiles.length;
        const totalFilesSize = universeFiles.reduce((acc, file) => acc + (!!file.file ? file.file.size : 0), 0);
        setTotalFiles(totalFiles)
        setTotalSize(totalFilesSize)
        setLoadingFiles(true)
        try {
            setLoading(true)
            for await (const file of universeFiles) {
                current_size = current_size + file.file.size
                file['file'] = await uploadFileToServer(file.file)
                current_files = current_files + 1;
                setCurrentFiles(current_files)
                console.log('current_files', current_files);
                setCurrentSize(current_size)
                console.log('current_size', current_size)
            }
            resetFileLoader()
            setLoading(true)
            _handleExport()
        } catch (error) {
            toastrError(`Failed to add ${collection_id}: ${error}`, 'Error');
            console.log('error', error)
            setLoading(false)

        }
    }
    const resetFileLoader = () => {
        setLoadingFiles(false)
        setTotalFiles(0)
        setTotalSize(0)
        setCurrentFiles(0)
        setCurrentSize(0)
    }
    const _handleExport = () => {
        console.log('universeFiles', universeFiles);
        const properties = assetLoaded.map(asset => {
            const findFile = universeFiles.find(uf => uf.name.toLowerCase() === asset.file.toLowerCase())
            const findFilePreview = !!asset.preview ? universeFiles.find(uf => uf.name.toLowerCase() === asset.preview.toLowerCase()) : undefined
            return {
                image: findFile ? fileReader + findFile.file : undefined,
                name: asset.name,
                image_preview: findFilePreview ? fileReader + findFilePreview.file : undefined,
                attributes: asset.data.attributes.map(att => {
                    return {
                        trait_type: att.trait_type,
                        value: att.value
                    }
                }),
            }
        })

        console.log('properties', properties);

        DataService.delCollectionNftResetGenerativeById(collection_id).then(response => {
            const body = {
                data: properties
            }
            DataService.postCollectionNftBulkById(collection_id, body).then(response_bulk => {
                if (response_bulk) {
                    setLoading(false)
                    setAssetsUpdated(false)
                    checkNftList()
                }
            })

        })
    }


    function uploadFileToServer(file) {
        const promise = new Promise(function (resolve, reject) {
            console.log('file', file);
            const name = file.name;
            const name_split = name.split('.')
            let ext = name_split[name_split.length - 1]
            if (ext === 'jpg') {
                ext = 'jpeg'
            }
            DataService.getUploadkey(ext.toLowerCase()).then(async response => {
                console.log('Uploading to: ', response.data.uploadURL)
                console.log('ext.toLowerCase()', ext.toLowerCase());
                if (ext.toLowerCase() === 'glb') {
                    var reader = new FileReader();
                    console.log('reader');
                    reader.onload = function (evt) {
                        fetch(response.data.uploadURL, {
                            method: 'PUT',
                            body: evt.target.result,
                            headers: {
                                'Content-Type': 'application/octet-stream',
                            }
                        }).then(result => {
                            console.log('Result: ', result)
                            if (result.status === 403) {
                                reject(undefined)
                            }
                            resolve(response.data.Key)
                        }).catch(err => {
                            reject(undefined)
                        })
                    };
                    reader.readAsArrayBuffer(file);


                } else {
                    fetch(response.data.uploadURL, {
                        method: 'PUT',
                        body: file,

                    }).then(result => {
                        console.log('Result: ', result)
                        if (result.status === 403) {
                            reject(undefined)
                        }
                        resolve(response.data.Key)
                    }).catch(err => {
                        reject(undefined)
                    })
                }


            }).catch(err => {
                console.log('err', err);
                reject(undefined)
            })
        })
        return promise
    }

    const _handleSubmit = async (e) => {
        setLoading(true)

        const body = {
            step_number: 3,
        }
        DataService.putCollectionById(collection_id, body).then(response => {
            if (response && response.data) {
                props.history.push(`/collections/minting/${collection_id}`)
                setLoading(false)
            }
        })
    }


    // const _handleSubmit = async (e) => {
    //     if (!enableSubdomain) {
    //         if (!!subdomain && !hasWhiteSpace(subdomain)) {
    //             const unique = await DataService.getCollectionSubdomain(subdomain);
    //             if (unique.data.exist) {
    //                 document.getElementById('subdomain').classList.add('input-error')
    //                 setEnableSubdomain(false);
    //             } if (!unique.data.exist) {
    //                 document.getElementById('subdomain').classList.remove('input-error')
    //                 setEnableSubdomain(true);
    //             } else {
    //                 document.getElementById('subdomain').classList.add('input-error')
    //                 setEnableSubdomain(false);
    //             }
    //         } else {
    //             document.getElementById('subdomain').classList.add('input-error')
    //             setEnableSubdomain(false);
    //         }
    //     }
    //     if (!sign) {
    //         if (!_validateStep()) return;
    //         setShowModal(true)
    //     } else {
    //         _handleDeploy()
    //     }
    // }
    const _handleDeploy = (e) => {
        if (!_validateFullStep()) return;
        sendFormToService()
    }



    const sendFormToService = (upload_id) => {
        const body = {
            subdomain: subdomain,
            sign: ((network_id + '') === '1') ? sign : blockhash,
            deploy: true,
        }
        console.log(body);
        return DataService.putCollectionById(collection_id, body).then(response => {
            setButtonLoader(false);
            if (selectedTicket) {
                props.setUserTicket({
                    ...selectedTicket,
                    collection_id: response.id,
                })
                props.history.push(`/collections/ticketing?step=3`)
            } else {
                props.history.push(`/collections/to-the-moon`)
            }
            setLoadPayment(false);
            setLoadMumbaiPayment(false);

        })
    }




    const __renderPOView = (orderId) => (
        <Modal
            className="xxl"
            title={props.t(`Upload again`)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={dropzoneContainer}
            footer={footer}
            styles={modalStyles}
        />
    )


    let handleAssetFilled = (assets) => {
        setNewAssets(assets);
    }
    let handleAssetFilterFilled = (assetsFilters) => {
        setNewAssetFilters(assetsFilters)
    }
    let handleAssetFull = (event) => {

        setEnableCloseModal(event);
    }

    let startModalShow = () => {
        setNewAssetFilters([])
        setNewAssets([])
        setShowModal(true)
    }
    const footer = <div className='modalcard-footer row'>
        <Button
            type="button"
            className="theme-button-white-primary"
            onClick={(e) => {
                e.preventDefault()
                onAssetLoaded([])
                setShowModal(false)
            }}
        >
            {props.t(`Cancelar`)}
        </Button>
        <Button
            type="button"
            color="primary"
            className="theme-button-secundary"
            onClick={(e) => {
                e.preventDefault()
                if (enableCloseModal) {
                    _handleAssetsUploaded()
                }
            }}
            disabled={!enableCloseModal}
        >
            {props.t(`Confirmar`)}
        </Button>
    </div>


    const dropzoneContainer = <div className='collections-create-form-upload'>
        <p className='collections-create-form-upload-title'>Upload your assets</p>
        <div className='collections-create-form-upload-container'>
            <div className='collections-create-form-upload-container-dropzone'>
                <Dropzone project_id={project_id} accept={'image/*,.json,.csv'} modalMode={true} onAssetLoaded={onAssetLoaded} onAssetFilterLoader={handleAssetFilterFilled} onAssetFull={handleAssetFull}
                    universeFiles={universeFiles} setUniverseFiles={setUniverseFiles}
                    pairFiles={pairFiles} setPairFiles={setPairFiles} />
            </div>
            <div className='collections-create-form-upload-container-list'>
                <ul>
                    <li>
                        Files must contain one .csv or .json file with metadata. - <a href='https://neefter.s3.us-west-1.amazonaws.com/downloads/neefter-csv-sample.csv' target="_blank">Download example.csv.</a>  <a href='https://neefter.s3.us-west-1.amazonaws.com/downloads/0.json' target="_blank">Download example.json.</a>
                    </li>
                    <li>
                        The csv must have a name column, which defines the name of the NFT.
                    </li>
                    <li>
                        Asset names must be sequential 0,1,2,3...n.[extension]. It doesn't matter at what number you begin. (Example: 131.png, 132.png).
                    </li>
                    <li>
                        Make sure to drag and drop the CSV/JSON and the images at the same time.
                    </li>
                    <li>
                        IMPORTANT: The assets for the layers of the collection have a limit of 2 MB
                    </li>
                </ul>
            </div>

        </div>
    </div>

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
        }
    }

    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
            minWidth: '900px',
        }
    }
    const __renderCardDetailView = (card) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={!!showModalCard}
            onRequestClose={() => {

                setShowModalCard(null)
            }}
            body={getModalContainerCard(card)}
            footer={footerModal}
            styles={modalCardStyles}
        />
    )

    const getModalContainerCard = (card) => {

        return <section className='modalcard'>
            <div className='modalcard-img'>
                <div className='modalcard-img-picture'>
                    {
                        card.type === 'video' && !card.image_preview &&
                        <video id='video' src={card.image} className='collections-token-resume-card-video' style={{
                            width: '100%',
                            height: '100%'
                        }}
                            muted playsInline autoPlay={false}>
                            {/* <source type="video/mp4" /> */}
                            Your browser does not support the video tag.
                        </video>
                    }
                    {
                        card.type === 'image' &&
                        <img id='image' style={{ width: '100%' }} alt={card.name} src={card.image} />
                    }
                    {
                        (card.type === '3d' || (card.type === 'video' && card.image_preview)) &&
                        <img id='3d' style={{ width: '100%' }} alt={card.name} src={card.image_preview || Whitecube} />
                    }
                </div>
            </div>
            <div className='modalcard-body'>
                <div className='modalcard-body-title'>
                    <p>{card.name}</p>
                </div>
                <div className='modalcard-body-cards'>
                    {
                        card.attributes_json.map((item, key) => (
                            <div className='modalcard-body-cards-item' key={key}>
                                <div style={{ display: 'inline', maxWidth: '120px', padding: '0px' }}>
                                    <p className='modalcard-body-cards-item-top'>
                                        {item.trait_type}
                                    </p>
                                    <p className='modalcard-body-cards-item-middle'>
                                        {item.value}
                                    </p>
                                    <p className='modalcard-body-cards-item-bot'>
                                        {key}% have this
                                    </p>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    }
    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowModalCard(null)
            }}
        >
            {props.t(`Close`)}
        </Button>

    </div>



    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={60} percentage={60}></ProgressHeader>

            {
                showModal && __renderPOView(12)
            }
            {
                showModalCard && __renderCardDetailView(showModalCard)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={step_number} active={3} collection_id={collection_id}></TabHeaderMenu>

                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`go to deploy settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <section className='collections-token'>
                        <div className='collections-token-form container-token-filter'>
                            <p className='collections-token-form-title'>Tokens</p>
                            <div>
                                <Button
                                    type="button"
                                    color="primary"

                                    className="theme-button-primary w-100"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`re-upload assets`)
                                        startModalShow();
                                    }}
                                >
                                    Upload again
                                </Button>
                            </div>

                            <p className='collections-token-form-title'>Attributes</p>
                            <FilterContainer array_filters={array_filters} changeCheckedOption={onChangeCheckedOption}></FilterContainer>


                        </div>
                        <div className='collections-token-resume'>
                            <div className='collections-token-resume-list container-token-table'>
                                <div className='collections-token-resume-list-infinite'>
                                    {filteredData.map(
                                        (item, key) => (
                                            <Card className='theme-token-card card-borderless collections-token-resume-card ' key={key} onClick={() => {

                                                setShowModalCard(item)
                                            }} >
                                                <div>
                                                    <CardBody>
                                                        <div className='card-picture'>
                                                            {
                                                                item.type === 'video' && !item.image_preview &&
                                                                <video id='video' src={item.image} className='collections-token-resume-card-video' style={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }}
                                                                    muted playsInline autoPlay={false}>
                                                                    {/* <source type="video/mp4" /> */}
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            }
                                                            {
                                                                item.type === 'image' &&
                                                                <img className='fixed' id='image' style={{ width: '100%' }} alt={item.name} src={item.image} />
                                                            }
                                                            {
                                                                (item.type === '3d' || (item.type === 'video' && item.image_preview)) &&
                                                                <img className='fixed' id='3d' style={{ width: '100%' }} alt={item.name} src={item.image_preview || Whitecube} />
                                                            }
                                                        </div>
                                                    </CardBody>
                                                </div>

                                                <CardFooter className="bg-transparent border-top">
                                                    <div className="d-flex">
                                                        <div className="flex-fill card-title container-token-nowrap">
                                                            <div className='container-token-nowrap' style={{ paddingTop: '4px', height: '16px' }}>
                                                                {item.name}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </CardFooter>
                                            </Card>
                                            // </Col>
                                        )
                                    )}
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                    <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>

                                </div>
                            </div>
                            {
                                filteredMixedData.length !== filteredData.length &&
                                <div className='collections-token-clearfix'>

                                </div>
                            }
                        </div>
                    </section>

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))
