import React, { useEffect, useState } from 'react'

import Sidebar from "../Global/Sidebar"
import Footer from "../Global/Footer"
import Content from "../Global/content"
import HeaderAdmin from "../Global/Header-admin"
import { connect } from "react-redux"

import { withRouter, useLocation } from "react-router-dom"

const Layout = ({ children }) => {
    const [toggleMenu, setToggleMenu] = React.useState(false)
    const [actualRoute, setActualRoute] = useState('')
    const location = useLocation();
    const twtIndex = '/twitter'
    const toggleMenuCallback = () => {
        if (toggleMenu) {
            document.getElementById("sidebar").classList.remove("open")
            document.getElementById("content").classList.remove("open")
            setToggleMenu(false)
        }

        if (!toggleMenu) {
            document.getElementById("sidebar").classList.add("open")
            document.getElementById("content").classList.add("open")
            setToggleMenu(true)
        }
    }

    useEffect(() => {
        console.log(location.pathname);
        setActualRoute(location.pathname)
    }, [])

    return (
        <React.Fragment>
            {
                actualRoute !== twtIndex &&
                (
                    <HeaderAdmin></HeaderAdmin>
                )
            }
            {
                actualRoute !== twtIndex &&
                (
                    <Sidebar id="sidebar" toggleMenuCallback={toggleMenuCallback} />
                )
            }
            <div id="content" className="content">
                <Content toggleMenuCallback={toggleMenuCallback} body={children} className="theme-subcontent" />
                {
                    actualRoute !== twtIndex &&
                    (
                        <Footer />
                    )
                }
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { Auth } = state
    return { Auth }
}

export default connect(mapStatetoProps)(withRouter(Layout))