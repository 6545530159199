import React, { useEffect, useState } from 'react'
import {
    Button,
    Container
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import backgroundImage from '../../assets/images/slide.png'
import { segmentTrack } from "../../helpers/Segment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import * as Loader03 from '../../assets/lottie/Loader03.json'
import * as Loader05 from '../../assets/lottie/Loader05.json'
import Lottie from 'react-lottie';
import { useParams } from 'react-router-dom'
import DataService from '../../helpers/DataService'

const Create = (props) => {
    const [is_ticket, setIsTicket] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const { collection_id } = useParams();
    let [collection, setCollection] = useState();

    useEffect(() => {
        console.log('collection_id', collection_id);
        getCollection()
    }, [collection_id])

    const getCollection = () => {
        if (collection_id) {
            DataService.getCollectionById(collection_id).then(response => {
                console.log('response.data', response.data);
                setCollection(response.data)

            })
        }
    }

    useEffect(() => {
        segmentTrack(`deploy confirmed`)
        const randInt = Math.floor(Math.random() * (2 - 0)) + 0;
        console.log('randInt', randInt);
        if (randInt) {
            setLoading1(true)
        } else {
            setLoading2(true)
        }
    }, [])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('ticket');

        if (foo) {
            setIsTicket(true)
        }
    }, [])

    const Loader03Options = {
        loop: true,
        autoplay: true,
        animationData: Loader03,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader05Options = {
        loop: true,
        autoplay: true,
        animationData: Loader05,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const goToCollections = () => {
        props.history.push(`/collections`)
    }
    const copyText = () => {
        navigator.clipboard.writeText('copied')
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <section className='container-tab-moon'>
                        <section className='collections-moon'>
                            <p className='collections-moon-title'>Ordinal Inscribed</p>
                            <div className='collections-moon-img card-picture'>
                                {/* <img style={{ width: '100%' }} alt="item" src={backgroundImage} /> */}
                                {
                                    loading1 &&
                                    <Lottie options={Loader03Options}
                                        height={400}
                                        width={400}
                                    />
                                }
                                {
                                    loading2 &&
                                    <Lottie options={Loader05Options}
                                        height={400}
                                        width={400}
                                    />
                                }

                            </div>
                            <p className='collections-moon-airwave'>Time to share your creation!</p>
                            {/* <p className='collections-moon-subtitle'>We’ve created a claim page for you to share with your audience.</p>
                            {
                                collection && (
                                    <div className='collections-moon-copy'>
                                        <div className='collections-moon-copy-inner'>
                                            <div className='collections-moon-copy-box'>
                                                https://mint.neefter.com/c/a-night-to-remember-129823
                                            </div>
                                            <div className='collections-moon-copy-icon' onClick={() => { copyText() }}>
                                                <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } */}
                            <div className='collections-moon-button'>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="theme-button-upload"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`Go to My projects`)
                                        goToCollections()
                                    }}
                                >
                                    {props.t(`go to my projects`)}
                                </Button>
                            </div>
                        </section>
                    </section>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
