import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faCloudArrowUp, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import {
    Button, Col, Container, FormGroup,
    Input, Label, Row
} from "reactstrap"
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { db } from "../../db"
import DataService from '../../helpers/DataService'
import { toastrError } from "../../helpers/Toastr"
import { scrollTop } from "../../helpers/utils"
import TabHeaderMenu from './Components/tab-header-menu'
import { useParams } from 'react-router-dom'
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext
    const { collection_id } = useParams();

    let [project_id, setProjectId] = useState(undefined);

    const [image_id, setImageId] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const [showImage, setShowImage] = useState(true)
    const [image, setImage] = useState('')


    let [tokenName, setTokenName] = useState('')
    let [externalUrl, setExternalUrl] = useState(undefined);
    let [description, setDescription] = useState(undefined);
    let [property_array, setPropertyArray] = useState([]);
    let [disableAll, setDisableAll] = useState(false);

    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }
    const commentErrorLength = 'Only 1000 characters or less can be added for the description field'

    let maxfilesizeImage = 1024 * 1024 * 2  // 2 Mb
    let maxfilesizeGlb = 1024 * 1024 * 10  // 2 Mb
    const prompt_error_sile_file = "The uploaded asset exceeds the size limit"


    let [selectedProperty, setSelectedProperty] = useState({
        id: 1,
        name: 'Property 1'
    });

    const filteredProperties = [
        {
            id: 1,
            name: 'Property 1'
        },
        {
            id: 2,
            name: 'Property 2'
        }
    ]

    const img_style = {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        padding: '8px'
    }
    useEffect(() => {
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: props.t(`Collections`),
            parentLink: `/collections`,
            item: props.t(`Create a Digital Collectible`)
        })
        setProjectId(currentProject.id)

    }, [currentProject])

    useEffect(() => {
        if (!!collection_id) {
            // DataService.getCollectionById(collection_id).then(
            //     response => {
            //         if (response && response.data) {
            //             setDisableAll(true)
            //             const { id } = response.data;
            //         }
            //     })
        }
    }, [collection_id])

    const _validateStep = () => {
        if (image_id === "") {
            toastrError(props.t(`Don't forget to enter the image`), 'Error')
            return false
        }
        if (tokenName === "") {
            toastrError(props.t(`Don't forget to enter the token name`), 'Error')
            return false
        }
        if (externalUrl === "" || !externalUrl) {
            toastrError(props.t(`External url is required`), 'Error')
            return false
        }
        if (property_array.length < 1 || property_array.filter(pa => (!!pa.value && !pa.trait_type) || (!pa.value && !!pa.trait_type)).length > 0) {
            toastrError(props.t(`Don't forget to enter at least one property`), 'Error')
            return false
        }
        if (description.length > 1000) {
            toastrError(props.t(`${commentErrorLength}`), 'Error')
            return false
        }
        return true
    }

    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;

        try {
            db.oneandone.clear()
            await db.oneandone.add({
                image_id: image_id,
                image: image,
                name: tokenName,
                external_url: externalUrl,
                description: description,
                properties: property_array.map(pa => {
                    return {
                        trait_type: pa.trait_type,
                        value: pa.value
                    }
                })
            })
            props.history.push(`/collections/oneandone/settings`)
            // scrollTop()
        } catch (error) {
            toastrError(`Failed to add ${tokenName}: ${error}`, 'Error');
        }


    }
    const _handleFileUpload = (event) => {
        let files = event?.target.files
        setLoading(true)

        const file = files[0];
        if (!file) return;


        const splt_img = files[0].name.split('.')
        console.log('files[0]', files[0]);
        const file_type = splt_img[splt_img.length - 1]
        console.log('file_type', file_type);

        const types = ['glb', 'jpeg', 'jpg', 'gif', 'mp4','png']
        if (types.every(type => file_type.toLowerCase() !== type)) {
            toastrError(props.t(`File type not allowed`), 'Error')
            setLoading(false)
            return
        }

        if (((file_type !== 'glb' && file_type !== 'GLB') && (file_type !== 'mp4' && file_type !== 'MP4') && (file_type !== 'gif' && file_type !== 'GIF'))) {
            if (file.size > maxfilesizeImage) {
                toastrError(prompt_error_sile_file, 'Error')
                setLoading(false)
                return
            }
        } else {
            console.log('file.size', file.size);
            console.log('maxfilesizeImage', maxfilesizeImage);
            if (file.size > maxfilesizeGlb) {
                toastrError(prompt_error_sile_file, 'Error')
                setLoading(false)
                return
            }
        }

        fileUpload(files[0]).then((response) => {

            setImageId(response.data.id)
            setImage(files[0])
            setImageTempo(URL.createObjectURL(files[0]));
            if (file_type.toLowerCase() === 'mp4') {
                setShowImage(false)
            } else {
                setShowImage(true)
            }
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }



    const addProperty = () => {

        property_array = [
            ...property_array,
            {
                trait_type: '',
                value: '',
                error: false,
            }
        ]
        setPropertyArray([...property_array]);
        // checkAllProperties()
    };
    const removePropertyInput = (index) => {
        setPropertyArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
        // checkAllProperties()
    }
    const handleChangePropertyAuthor = (event) => {
        event.preventDefault();
        const val = 'property_author';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        console.log('value', value);
        console.log('index', index);

        setPropertyArray(item => {
            const newArr = item.slice();
            newArr[index].trait_type = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    const handleChangePropertyValue = (event) => {
        event.preventDefault();
        const val = 'property_value';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        console.log('value', value);
        console.log('index', index);

        setPropertyArray(item => {
            const newArr = item.slice();
            newArr[index].value = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    // const handleChangePropertyValue = (event) => {
    //     event.preventDefault();
    //     const val = 'property_value';
    //     const index = event.target.id.slice(val.length);

    //     const value = event.target.value;

    //     setPropertyArray(item => {
    //         const newArr = item.slice();

    //         if (value === null || value === undefined || value === '') {
    //             newArr[index].error = true;
    //         } else {
    //             newArr[index].error = false;
    //         }
    //         var regexp = new RegExp(/[`~!@$%^&*()+{}[\]\\|,.//?;':"]/g, 'g');
    //         const indexHashtagError = value.match(regexp);
    //         const indexSpaceError = value.match(/\s/);
    //         if ((value === null || value === undefined || value === '') || (indexHashtagError && indexHashtagError?.length > 0) || (indexSpaceError && indexSpaceError?.length > 0)) {
    //             newArr[index].error = true;
    //             newArr[index].value = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
    //             const doc = document.getElementById(val + '_p' + index)
    //             console.log(val + 'p' + index, doc);
    //             if (doc) {
    //                 doc.classList.add('input-error')
    //             }
    //         } else {
    //             newArr[index].error = false;
    //             newArr[index].value = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
    //             const doc = document.getElementById(val + '_p' + index)
    //             if (doc) {
    //                 doc.classList.remove('input-error')
    //             }
    //         }
    //         return newArr;
    //     });


    // };
    // useEffect(() => {
    //     if (property_array.filter(ca => !ca.share).length > 0) return;
    //     const validate = setTimeout(() => {
    //         checkAllProperties()
    //     }, 5000);
    //     return () => clearTimeout(validate)
    // }, [property_array])

    const validateWebsiteUrl = websiteUrl => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

        return regex.test(String(websiteUrl).toLowerCase());
    };
    // function checkAllProperties() {
    //     let total = 0;
    //     const checkError = property_array.find(pa => pa.error)

    // }


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={10} percentage={10}></ProgressHeader>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={0} active={1} ></TabHeaderMenu>
                        <div className='collections-settings-header-button '>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                disabled={loading || disableAll}
                                onClick={(e) => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all oneandone'>
                        <section className='collections-collectible'>
                            <div className='collections-collectible-row'>
                                <div className='collections-collectible-img'>
                                    <div className='card-picture' style={img_style} onClick={() => !disableAll && document.getElementById('upload_img')?.click()}>
                                        {
                                            (image_tempo && showImage) &&
                                            <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo} />
                                        }
                                        {
                                            (image_tempo && !showImage) &&
                                            <video id='video' src={image_tempo} className='collections-token-resume-card-video' style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                                muted playsInline autoPlay={true}>
                                                {/* <source type="video/mp4" /> */}
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                        {
                                            !image_tempo &&
                                            <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                                        }


                                        <input type="file"
                                            id="upload_img"
                                            hidden
                                            disabled={disableAll}
                                            onChange={event => {
                                                _handleFileUpload(event)
                                            }}
                                        />
                                    </div>
                                    <div className='collections-create-form-row-ico' onClick={() => !disableAll && document.getElementById('upload_img')?.click()}>
                                        <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>

                            <div className='collections-create-form-upload-container-list'>
                                <li>
                                    <b>IMPORTANT</b>
                                </li>
                                <li>
                                    <b>Image assets (.png, .jpeg) have a size limit of 2 mb.</b>
                                </li>
                                <li>
                                    <b>Video/gif assets (.glb,.gif,.mp4) have a size limit of 10 mb.</b>
                                </li>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Token name`)}
                                            </Label>
                                            <Input
                                                required
                                                value={tokenName}
                                                type="text"
                                                id='tokenName'
                                                className="input-white-input circle"
                                                placeholder='Token name'
                                                disabled={disableAll}
                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (!value) {
                                                        document.getElementById('tokenName').classList.add('input-error')
                                                    } else {
                                                        document.getElementById('tokenName').classList.remove('input-error')
                                                    }
                                                    setTokenName(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`External url`)}
                                            </Label>

                                            <div className='collections-minting-control' id='externalUrl' >
                                                <div className='collections-minting-control-pre' >
                                                    <span>https://</span>
                                                </div>
                                                <div className='collections-minting-control-input'>
                                                    <Input
                                                        required
                                                        value={externalUrl}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Url or Link'
                                                        disabled={disableAll}
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('externalUrl').classList.add('input-error')
                                                            } else {
                                                                if (validateWebsiteUrl(value)) {
                                                                    document.getElementById('externalUrl').classList.remove('input-error')
                                                                } else {
                                                                    document.getElementById('externalUrl').classList.add('input-error')
                                                                }
                                                            }
                                                            setExternalUrl(event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>


                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Description`)}
                                            </Label>

                                            <Input
                                                required
                                                value={description}
                                                style={{ padding: 10 }}
                                                type="textarea"
                                                id='description'
                                                disabled={disableAll}
                                                className="input-white-input circle input-text-area"
                                                placeholder="The description will be included on the item's detail page underneath 
                                                    its image. Markdown syntax is supported."
                                                onChange={event => {
                                                    const { value } = event.target

                                                    if (!value) {
                                                        document.getElementById('description').classList.add('input-error')
                                                    } else {
                                                        if (value.length > 1000) {
                                                            toastrError('Only 1000 characters or less can be added for the description field')
                                                            document.getElementById('description').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('description').classList.remove('input-error')
                                                        }
                                                    }
                                                    setDescription(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </div>
                            <Row>
                                <Col sm={12}>
                                    <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                        <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                        <span>How to create a 1/1 (Single Edition)</span>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                        <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                        <span>How to create a Limited or Open Edition</span>
                                    </a>
                                </Col>
                            </Row>
                            <div>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            {
                                                !!property_array && property_array.length > 0 && (

                                                    <div className='input-container'>
                                                        <div className='input-container_lg'>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Property Name`)}
                                                            </Label>
                                                        </div>
                                                        <div className='input-container_sm'>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Value`)}
                                                            </Label>
                                                        </div>


                                                    </div>


                                                )
                                            }

                                            {
                                                property_array.map((item, i) => {
                                                    return (
                                                        <div className='input-container' key={'cont' + i} >
                                                            <div className='input-container_lg'>
                                                                <Input
                                                                    required
                                                                    value={item.trait_type}
                                                                    type="text"
                                                                    className="input-white-input circle"
                                                                    placeholder=''
                                                                    disabled={disableAll}
                                                                    id={"property_author" + i}
                                                                    onChange={handleChangePropertyAuthor}
                                                                />
                                                            </div>
                                                            <div className='input-container_sm'>
                                                                <div className='theme-form-control' id={"property_value_p" + i}>
                                                                    <div className='theme-form-control-input'>
                                                                        <Input
                                                                            required
                                                                            value={item.value}
                                                                            type="text"
                                                                            className="input"
                                                                            placeholder=''
                                                                            disabled={disableAll}
                                                                            id={"property_value" + i}
                                                                            onChange={handleChangePropertyValue}
                                                                        />
                                                                    </div>
                                                                    <div className='theme-form-control-post white'>
                                                                        {/* <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                !disableAll &&
                                                                <div className='input-container_icon' onClick={() => { removePropertyInput(i) }}>
                                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                </div>
                                                            }

                                                        </div>
                                                    )

                                                })
                                            }
                                        </FormGroup>
                                    </Col>

                                </Row>


                                <Row>
                                    <Col sm={6}>
                                        {
                                            !disableAll &&
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="theme-button-collaborator"
                                                onClick={addProperty}
                                            >
                                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                {props.t(`Add property`)}
                                            </Button>
                                        }

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Button
                                            type="button"
                                            color="secundary"
                                            className="theme-button-secundary mobile"
                                            disabled={loading || disableAll}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                _handleSubmit()
                                            }}
                                        >
                                            {props.t(`Next step`)}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                        <div className='container-video-all' style={{ flex: '0 1' }}>
                            <div className='container-video'>
                                <p>How to create a 1/1 (Single edition)</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                            </div>
                            <div className='container-video'>
                                <p>How to create a Limited or Open Edition</p>
                                <YoutubeEmbed embedId="YhEdU-ypBkc"></YoutubeEmbed>
                            </div>
                        </div>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
