import React, { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';
import image_box from '../../../assets/images/ImageBox.png'

const Image = (props) => {
    const { collection, image_id, is_desktop } = props;
    const [showImage, setShowImage] = useState(false)
    const [showMedia, setShowMedia] = useState(false)

    useEffect(() => {
        if (image_id) {
            const split = image_id.split('.')
            const extension = split[split.length - 1]
            if (extension.toLowerCase() === 'mp4') {
                setShowImage(false)
                const video = document.getElementById('video_player');
                if (video) {
                    video.setAttribute('autoplay', '');
                    video.setAttribute('muted', '');
                    video.setAttribute('playsinline', '');
                    video.setAttribute('loop', '');
                    console.log('video', video);

                    video.addEventListener('click', () => {
                        video.play();
                    });
                }
            } else {
                setShowImage(true)
            }
            setShowMedia(true)



        }
    }, [image_id])
    return (
        <div className={cn("collections-reclaim-img", is_desktop ? 'desktop' : 'mobile')}>
            <div className='collections-reclaim-img-space'>
            </div>
            <div className="collections-reclaim-img-container">
                <div className="collections-reclaim-img-container-object">
                    {
                        (showImage) &&
                        <img src={image_id || image_box} alt="image_id" ></img>
                    }
                    {
                        (!showImage && image_id) &&
                        <video id="video_player" alt="image_id" preload="auto" className='collections-token-resume-card-video'
                            muted playsInline autoPlay
                        >
                            <source src={image_id} type="video/mp4"></source>
                            Your browser does not support the video tag.
                        </video>
                    }
                </div>

            </div>

        </div >
    );
};

export default withRouter(withNamespaces()(Image))