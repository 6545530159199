import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Input,
    Col,
    Button,
    ButtonGroup
} from "reactstrap"

import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faTable } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames';


const CollectionsFilter = (props) => {
    const { search, setSearch, sort, setSort, display, setDisplay } = props;
    const sort_options = [
        {
            name: 'No sort',
            value: undefined
        },
        {
            name: 'First modified',
            value: 'modified_at.asc'
        },
        {
            name: 'Last modified',
            value: 'modified_at.desc'
        },
    ]
    return (
        <React.Fragment>
            <Row>
                <Col sm="7">
                    <div className='theme-form-control'>
                        <div className='theme-form-control-pre white'>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </div>
                        <div className='theme-form-control-input'>
                            <Input
                                required
                                value={search}
                                type="text"
                                className="form-control"
                                placeholder='Search by name'
                                onChange={event => {
                                    const { value } = event.target
                                    setSearch(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm="3">
                    <div className='theme-form-control'>
                        <div className='theme-form-control-pre white'>
                            Sort
                        </div>
                        <div className='theme-form-control-input'>
                            <select
                                required
                                value={sort}
                                type="select"
                                className="form-control"
                                placeholder='Sort'
                                onChange={event => {
                                    const { value } = event.target
                                    setSort(event.target.value)
                                }}
                            >
                                {sort_options.map((_sort, i) => {
                                    return (
                                        <option key={i} value={_sort.value}>{_sort.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                </Col>
                <Col sm="2">
                    <ButtonGroup className='input-white-buttongroup'>
                        <Button className={cn(display === 'list' ? 'selected' : '')} onClick={() => {
                            setDisplay('list')
                        }}>
                            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                        </Button>
                        <Button className={cn(display === 'table' ? 'selected' : '')}
                            onClick={() => {
                                setDisplay('table')
                            }}>
                            <FontAwesomeIcon icon={faTable}></FontAwesomeIcon>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>


        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(CollectionsFilter))