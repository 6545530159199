import React from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';
import {
    Button
} from "reactstrap"
const DetailComponent = (props) => {
    const { collection, buyNow, showDetails } = props;

    const doBuyNow = () => {
        buyNow()
    }

    return (
        <section className="collections-claim-detail-container">
            <div className="collections-claim-detail-title">
                {collection.name || ''}
            </div>
            {
                showDetails && (
                    <div className="collections-claim-detail-createdby">
                        {/* <p>
                            Created by {
                                collection.creators.map((item, i) => {
                                    return (
                                        <span key={i}> @{item.address || ''}</span>
                                    )
                                })
                            }
                        </p> */}
                        <p>
                            Created by  {collection.external_url || ''}
                        </p>
                    </div>
                )
            }
            {
                !showDetails && (
                    <div className="collections-claim-detail-created-detail">
                        <p>Created by </p>
                        {
                            collection.creators.map((item, i) => {
                                return (
                                    <div key={i} className="collections-claim-detail-created-detail-inner">
                                        <span >@{item.address || ''}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-subtitle" >
                        Description
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-body">
                        {collection.description || ''}
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-properties">
                        {
                            collection.global_properties.map((item, i) => {
                                return (
                                    <div key={i} className="collections-claim-detail-property">
                                        <span className="collections-claim-detail-property-subtitle">{item.trait_type}</span>
                                        <span className="collections-claim-detail-property-title">{item.value}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-link">
                        View on solscan
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-price">
                        {collection.price ? collection.price : '0'} {collection.symbol || ''}
                    </div>
                )
            }
            {
                !showDetails && (
                    <div className="collections-claim-detail-customprice">
                        {collection.price || '0'} {collection.symbol || ''}
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="collections-claim-detail-bottom">
                        <Button
                            type="button"
                            color="primary"
                            className=" theme-button-primary"
                            onClick={(e) => {
                                e.preventDefault()
                                doBuyNow()
                            }}
                        >
                            {props.t(`Buy now`)}
                        </Button>
                    </div>)
            }


        </section >
    );
};

export default withRouter(withNamespaces()(DetailComponent))