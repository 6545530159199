import React, { useEffect, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import {
    Row,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input
} from "reactstrap"
import Slider from 'react-slider-simple';
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBan } from '@fortawesome/free-solid-svg-icons'
const config = {
    lockToContainerEdges: true
}


const SortableCard = (props) => {
    // const { property, swapPositions, array_properties, setPropertyAfterChange } = props;
    const { variation, childKey, setWeight, property, parent_key } = props;
    const [stateWeight, setStateWeight] = useState(variation.rarity)

    useEffect(() => {
        setStateWeight(variation.rarity)
    }, [property])

    const setWeightCard = (variation, rarity) => {
        setWeight(variation, rarity)
        const fillColor = "#a066ff";
        const emptyColor = "#f8f5ff";
        const we = parseFloat(rarity + '')
        const dc = document.getElementById('vol' + parent_key + childKey)
        const percent = (100 * (we)) / (50) + "%";
        variation.rarity = rarity || 0;

        dc.style.backgroundImage = `linear-gradient( to right, ${fillColor}, ${fillColor} ${percent}, ${emptyColor} ${percent})`;
    }

    useEffect(() => {
        const fillColor = "#a066ff";
        const emptyColor = "#f8f5ff";
        const we = parseFloat(stateWeight + '')
        const dc = document.getElementById('vol' + parent_key + childKey)
        const percent = (100 * (we)) / (50) + "%";
        dc.style.backgroundImage = `linear-gradient( to right, ${fillColor}, ${fillColor} ${percent}, ${emptyColor} ${percent})`;

        const search = setTimeout(async () => {
            if (stateWeight === variation.rarity) return;
            setWeightCard(variation, stateWeight)
        }, 3000);
        return () => clearTimeout(search)
    }, [stateWeight])


    useEffect(() => {
        const fillColor = "#a066ff";
        const emptyColor = "#f8f5ff";
        const we = parseFloat(variation.rarity + '')


        const dc = document.getElementById('vol' + parent_key + childKey)
        const percent = (100 * (we)) / (50) + "%";


        //  this.setAttribute('value', this.value);
        //  this.setAttribute('title', this.value);
        dc.style.backgroundImage = `linear-gradient( to right, ${fillColor}, ${fillColor} ${percent}, ${emptyColor} ${percent})`;

    }, [])

    return <div className='container-tab-components-card' key={'child' + childKey}>
        {
            variation.image &&
            <div className='container-tab-components-card-img'>
                <img style={{ width: '100%' }} alt={variation.name} src={variation.image} key={childKey} />
            </div>
        }{
            !variation.image &&
            <div className='container-tab-components-card-img ico'>
                <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
            </div>
        }
        <div className='container-tab-components-card-title'>
            {variation.name}
        </div>
        <div className='container-tab-components-card-range'>
            <input type="range" className="input-range--custom" id={'vol' + parent_key + childKey} name="vol" min="0" max="50" value={stateWeight}
                onChange={event => {
                    const { value } = event.target

                    if (value === undefined) {
                    } else {
                        console.log('value', value);
                        setStateWeight(parseFloat(value))
                    }
                }}
            ></input>
        </div>
        <div className='container-tab-components-card-sublabel'>
            <span>Estimated:</span>
            <Input
                value={stateWeight}
                type="text"
                className="input-white-input circle"
                disabled={true}
                placeholder=''
            />
        </div>
    </div>

}

// export default withRouter(withNamespaces()(SortableElement(SortableItem, config)))
export default withRouter(withNamespaces()(SortableCard))