import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from "react-i18next";
import { useParams, withRouter } from 'react-router-dom';
import {
    Button,
    Container,
} from "reactstrap";
import ProgressHeader from '../../components/Global/Progress-header';
import { GeneralContext } from '../../context/';
import ComponentsTab from './components/attributes-tabs/components-tab';
import LayersAndRarityTab from './components/attributes-tabs/layer-and-rarity-tab';
import LegendaryNftTab from './components/attributes-tabs/legendary-nft-tab';
import SettingsTab from './components/attributes-tabs/settings-tab';


const Attributes = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);

    const { collection_id } = useParams();

    const { currentProject, setProject } = generalContext
    let [project_id, setProjectId] = useState([]);
    let [step_number, setStepNumber] = useState(0);

    useEffect(() => {
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Token`)
        })
        setProjectId(currentProject.id)

    }, [currentProject])

    const _handleSubmit = (e) => {

        props.history.push(`/collections/generative/token/${collection_id}`)
    }


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={30} percentage={30}></ProgressHeader>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className='collections-settings-header-item' >
                                <span>1. Upload</span>
                            </div>
                            <div className='collections-settings-header-item active'>
                                <span>2. Settings</span>
                            </div>
                            <div className='collections-settings-header-item'>
                                <span>3. Preview</span>
                            </div>
                            <div className='collections-settings-header-item'>
                                <span>4. Deployment</span>
                            </div>
                        </section>
                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-header'>
                            <section className='collections-settings-header-container'>

                                <div className={classnames('collections-settings-header-item', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}>
                                    <span>1. Components</span>
                                </div>
                                <div className={classnames('collections-settings-header-item', { active: activeTab === '2' })} onClick={() => activeTab !== '2' && setActiveTab('2')}>
                                    <span>2. Layers & rarity</span>
                                </div>
                                <div className={classnames('collections-settings-header-item disabled', { active: activeTab === '3' })}>
                                    <span>3. Legendary NFTs</span>
                                </div>
                                <div className={classnames('collections-settings-header-item disabled', { active: activeTab === '4' })}>
                                    <span>4. Settings</span>
                                </div>
                            </section>
                            <div className='collections-settings-header-button'>
                                <Button
                                    type="button"
                                    color="secundary"
                                    className="theme-button-secundary"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        // if (!_validateStep()) {
                                        //     toastr.error(props.t(`Don't forget to enter your name`))
                                        //     return
                                        // }
                                        _handleSubmit()
                                    }}
                                >
                                    {props.t(`Preview`)}
                                </Button>
                            </div>
                        </div>
                        <div className='collections-settings-body'>
                            {
                                activeTab === '1' &&
                                <ComponentsTab activeForm={activeForm} collection_id={collection_id} />
                            }
                            {
                                activeTab === '2' &&
                                <LayersAndRarityTab activeForm={activeForm} collection_id={collection_id} />
                            }
                            {
                                activeTab === '3' &&
                                <LegendaryNftTab activeForm={activeForm} collection_id={collection_id} />
                            }
                            {
                                activeTab === '4' &&
                                <SettingsTab activeForm={activeForm} collection_id={collection_id} />
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Attributes))
