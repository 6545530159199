import React, { Fragment, useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroupAddon,
    InputGroup,
    Input,
    Button,
    FormGroup,
    Label,
    CustomInput,
} from "reactstrap";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import cn from 'classnames';


const DropdownControl = (props) => {
    const { value, setValue, options, parent, index } = props
    const [dropdownOpen, setDropdownOpen] = useState(false)
    let [filteredOptions, setFilteredOptions] = useState([])
    let [searchValue, setSearchValue] = useState([])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }
    const _handleInputChange = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setSearchValue(value)
        // setFilteredOptions(options.filter(opt => opt.label.includes(value) || opt.options.filter(optopt => optopt.label.includes(value)).length > 0))
        setFilteredOptions(options.map(opt => {
            return {
                label: opt.label,
                options: opt.options.filter(optopt => optopt.label.toLowerCase().includes(value.toLowerCase()))
            }
        }))
    }

    function _checkExist(option) {
        if (JSON.stringify(value) === JSON.stringify(option.value)) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
            <DropdownToggle tag="div" onClick={toggle}
                className="input-drop-radio">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <div className="input-drop-radio-img">
                            {
                                (value && value.source_img) && (
                                    <img style={{ width: '100%' }} alt={value.child} src={value.source_img} />
                                )
                            }
                        </div>
                    </InputGroupAddon>
                    <Input
                        value={searchValue || value.label}
                        placeholder={value ? value.label : ''}
                        id={parent + index}
                        onChange={_handleInputChange}
                    />
                    <InputGroupAddon addonType="append">
                        <Button>
                            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </DropdownToggle>
            <DropdownMenu className="input-drop-radio-menu">
                {!!filteredOptions && filteredOptions.map((group, key) => (
                    <Fragment key={parent + key}>
                        <DropdownItem header key={parent + group.label}>
                            {group.label}
                        </DropdownItem>
                        {group.options.map(option => (
                            <DropdownItem key={parent + option.label} onClick={() => {
                                setSearchValue(option.label)
                                setValue(option.value, index)
                            }}>
                                <Input type="radio" className={cn('radio-input')} id={parent + 'radio' + index} checked={_checkExist(option)} readOnly />
                                <span className="item-span">{option.label}</span>
                            </DropdownItem>
                        ))}
                    </Fragment>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default withRouter(withNamespaces()(DropdownControl))