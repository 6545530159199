import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { toastrError } from '../../../../helpers/Toastr';
import { csvFileToArray } from '../../../../helpers/utils';
function Dropzone({ onAssetFull }) {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive, open } =
        useDropzone({
            noClick: true,
            useFsAccessApi: true
        });



    useEffect(() => {
        if (!acceptedFiles) return;
        if (acceptedFiles.length < 1) return;
        if (acceptedFiles.length > 1) {
            toastrError('You can add only a file')
            return;
        }
        const _file = acceptedFiles[0];
        if (!_file) return;
        if (_file.type !== "text/csv") {
            toastrError('You can only add csv files')
            return;
        }
        checkCsvFile(_file)

    }, [acceptedFiles])


    const checkCsvFile = async (file) => {
        const text = await file.text()
        const data = [...csvFileToArray(text)];
        if (data.length < 1) {
            toastrError('Malformed csv', 'Error')
            return;
        }

        onAssetFull(data)
        console.log('acceptedFiles', data);

    }

    return (
        <div style={{ width: '100%' }} onClick={() => open()}>
            <div {...getRootProps({ className: "collections-create-form-upload-drop" })}>
                <input className="input-zone" {...getInputProps()} webkitdirectory="" />

                <div className='collections-create-form-upload-drop-ico'>
                    <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                </div>
                {
                    isDragActive ? (

                        <p className='collections-create-form-upload-drop-title'>
                            Release to drop the files here
                        </p>
                    ) :
                        <p className='collections-create-form-upload-drop-title'>
                            Drop files here or click to upload your assets
                        </p>
                }
                <p className='collections-create-form-upload-drop-subtitle'>
                    Not sure what to do? Head to our&nbsp;&#32; <b>&nbsp;&#32;help section</b>
                </p>
            </div>
        </div>


    );
}
export default Dropzone;
