import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Card,
    CardBody,
    Container,
} from "reactstrap"
import { GeneralContext } from '../../context/'

import { withNamespaces } from "react-i18next"

const Index = (props) => {

    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Items`),
            parentLink: '/items',
            item: props.t(`Items`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <div className='page-content' >
                <Container fluid>
                    <Card>
                        <CardBody>
                            Aqui se van a ver mis items
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default withRouter(withNamespaces()(Index))

