import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Button
} from "reactstrap"
import { GeneralContext } from '../../context/'

import { withNamespaces } from "react-i18next"
import { segmentTrack } from "../../helpers/Segment"
import Twtheader from "./components/header"

import { loadState } from "../../store/localStorage"

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [uuid, setUuid] = useState();

    let text = `I am getting my free PFP NFT from Neefter.xxxx-xxxx-xxxx-xxxx #NftMyTwitter`
    useEffect(() => {
        setLoading(false);
    }, [])

    useEffect(() => {
        if (loadState() && loadState().Auth) {
            if (loadState().Auth.profile) {
                setUuid(loadState().Auth.profile.id)
                text = `I am getting my free PFP NFT from Neefter.com ${loadState().Auth.profile.id} #NftMyTwitter`
            }
        }
    }, [])

    const popupCenter = ({ url, title, w, h }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : w;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : h;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, 'popup',
            `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )

        if (window.focus) newWindow.focus();
    }


    const openTwt = () => {
        const _text = `I am getting my free PFP NFT from Neefter.com ${loadState().Auth.profile.id} #NftMyTwitter`
        popupCenter({ url: `https://twitter.com/intent/tweet?text=${_text}`, title: 'Neefter', w: 400, h: 600 })
        // window.open(`https://twitter.com/intent/tweet?text=${_text}`, 'popup', 'width=300,height=500')
        props.history.push(`/twitter/mint`)
    }
    const copyText = () => {
        navigator.clipboard.writeText(text)
    }

    return (
        <React.Fragment>
            <section className='twt'>
                <div className='twt-container'>
                    <Twtheader active={2}></Twtheader>
                    <div className='twt-verify'>
                        <span className='twt-verify-title'>
                            Post a tweet, to verify your account
                        </span>
                        <span className='twt-verify-subtitle'>
                            Now, let’s tweet the following, to verify your account. <br />
                            Make sure to include the ID: {uuid} in your tweet.
                        </span>
                        <span className='twt-verify-from'>
                            I am getting my free PFP NFT from Neefter.
                            <br />
                            {uuid} #NftMyTwitter
                        </span>
                        <span className='twt-verify-copy' onClick={() => { copyText() }}>
                            click to copy
                        </span>

                        <div className='twt-verify-connect'>
                            {/* <div className='TwitterShareButton'>
                                <TwitterShareButton
                                    style={{ visibility: 'hidden' }}
                                    id="TwitterShareButton"
                                    options={{
                                        text: `I am getting my free PFP NFT from Neefter.xxxx-xxxx-xxxx-xxxx #NftMyTwitter`
                                    }}

                                />
                            </div> */}
                            <Button
                                type="button"
                                color="primary"
                                id="btn-send"
                                className=" theme-button-third"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`Tweet now`)
                                    openTwt()
                                }}
                            >
                                {props.t(`Tweet now`)}
                            </Button>


                        </div>



                    </div>
                </div>
            </section>
        </React.Fragment >
    )

}
export default withRouter(withNamespaces()(Index))
