import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import SettingsTab from './settings-tabs/basic-settings'
import CollectionSelectTab from './settings-tabs/collection-select'
import ReviewTab from './settings-tabs/review-and-publish'

import classnames from 'classnames';
import { useParams } from 'react-router-dom'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import ProgressHeader from '../../components/Global/Progress-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { loadState } from "../../store/localStorage"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, currentProject, setProject } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);
    let [project_id, setProjectId] = useState([]);
    let [enableStepTwo, setEnableStepTwo] = useState(false);
    let [enableStepThree, setEnableStepThree] = useState(false);
    let [activeFormEnd, setActiveFormEnd] = useState(0);

    const { collection_id } = useParams();
    let [collection, setCollection] = useState();
    useEffect(() => {

        if (!currentProject) return;
        setProjectId(currentProject.id)
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Create an event`)
        })
    }, [currentProject])
    useEffect(() => {
        if (loadState()?.Auth?.ticket) {
            setEnableStepTwo(true)
            if (loadState()?.Auth?.ticket.collection_id) {
                setEnableStepThree(true)
            }
        }

    }, [loadState()?.Auth])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('step');

        const tab_selected = foo ? parseFloat(foo) : undefined
        if (tab_selected) {
            if (tab_selected === 3) {
                setActiveTab('3')
            }
        }
    }, [])


    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }
    const _handleSubmitEnd = (e) => {
        setActiveFormEnd(activeFormEnd + 1);
    }
    return (
        <React.Fragment>

            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={100} percentage={100}></ProgressHeader>

            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}  >
                                <span>Basic settings</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '2', disabled: !enableStepTwo })} onClick={() => (activeTab !== '2' && enableStepTwo) && setActiveTab('2')} >
                                <span>Select collection</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '3', disabled: !enableStepThree })} onClick={() => (activeTab !== '3' && enableStepThree) && setActiveTab('3')}>
                                <span>Review and publish</span>
                            </div>
                        </section>
                        <div className='collections-settings-header-button'>
                            {
                                activeTab === '1' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary collections-ticketing-not-submit"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _handleSubmit()
                                        }}
                                    >
                                        {props.t(`Next step`)}
                                    </Button>
                                )
                            }
                            {/* {
                                activeTab === '2' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary"
                                        onClick={(e) => {
                                            e.preventDefault()

                                        }}
                                    >
                                        {props.t(`Next step`)}
                                    </Button>
                                )
                            } */}
                            {
                                activeTab === '3' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-update collections-ticketing-not-submit"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _handleSubmitEnd()
                                        }}
                                    >
                                        {props.t(`Publish`)}
                                    </Button>
                                )
                            }

                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-header'>

                        </div>
                        <div className='collections-settings-body'>
                            {
                                (activeTab === '1') &&
                                <SettingsTab project_id={project_id} collection_id={collection_id} setActiveTab={setActiveTab} activeForm={activeForm} />
                            }
                            {
                                (activeTab === '2') &&
                                <CollectionSelectTab project_id={project_id} collection_id={collection_id} setActiveTab={setActiveTab} />
                            }
                            {
                                (activeTab === '3') &&
                                <ReviewTab project_id={project_id} collection_id={collection_id} setActiveTab={setActiveTab} activeForm={activeFormEnd} />
                            }


                        </div>
                    </div>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))
