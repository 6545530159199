import React, { useRef } from 'react'
import Breadcrumbs from './Breadcrumbs'

export default (props) => {
    const propContent = useRef(null)

    const toggleMenu = () => {
        props.toggleMenuCallback()
    }

    return (
        <div onClick={() => toggleMenu} ref={propContent}>
            <div className='centered'>
                <Breadcrumbs />
                {props.body}
            </div>
        </div>
    )
}