export function segmentTrack(item) {
    const hostname = window && window.location && window.location.hostname
    switch (hostname) {
        case 'localhost':
            console.log('segment track', item)
            break
        case 'neefter-front-git-develop-metafans.vercel.app':
            console.log('segment track', item)
            break
        case 'app.neefter.com':
            window.analytics.track(item)
            break
        default:
            console.log('segment track', item)
            break
    }
}
