import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import YoutubeEmbed from '../../../components/Global/EmbedYoutube'
import image_box from '../../../assets/images/ImageBox.png'
import DataService from '../../../helpers/DataService'
import { segmentTrack } from "../../../helpers/Segment"
import { dateHourFormater, dateSeparatedFormater, dateFromFormat } from "../../../helpers/utils"
import { toastrError } from "../../../helpers/Toastr"
import { faCloudArrowUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setUserTicket } from '../../../store/actions'
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import { loadState } from "../../../store/localStorage"
import { toBase64, hasWhiteSpace } from "../../../helpers/utils"
import { ReactComponent as PlayCircle } from '../../../assets/images/material-symbols_play-circle.svg';

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm, setActiveTab } = props;
    const [image_tempo, setImageTempo] = useState(undefined)
    const [sign, setSign] = useState('')
    const [description, setDescription] = useState('')
    const [logo, setLogo] = useState('')
    const [event_title, setEventTitle] = useState('')
    const [event_organizer, setEventOrganizer] = useState('')
    const [event_venue, setEventVenue] = useState('')
    const [event_venue_address, setEventVenueAddress] = useState('')
    const [event_start_date, setEventStartDate] = useState('')
    const [event_end_date, setEventEndDate] = useState('')
    const [event_description, setEventDescription] = useState('')
    const [event_start_date_minuter, setEventStartDateMinuter] = useState('')
    const [event_end_date_minuter, setEventEndDateMinuter] = useState('')
    const [subdomain, setSubdomain] = useState('')
    const [enableSubdomain, setEnableSubdomain] = useState(false)
    const subdomainWhiteSpacesError = 'Spaces characters cant be used on subdomain field'


    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }

    let [selectedTicket, setSelectedTicket] = useState(undefined)
    useEffect(() => {
        if (loadState()?.Auth?.ticket) {
            selectedTicket = loadState()?.Auth?.ticket;
            setSelectedTicket(loadState()?.Auth?.ticket)
        }

    }, [])

    useEffect(() => {
        if (!selectedTicket) return;
        console.log('selectedTicket', selectedTicket);
        const event_start_date = selectedTicket.event_start_date.replaceAll('/', '-')
        const event_end_date = selectedTicket.event_end_date.replaceAll('/', '-')
        const end_date = dateFromFormat(event_end_date)
        const start_date = dateFromFormat(event_start_date)
        const endHours = end_date.getHours()
        const endMinutes = end_date.getMinutes()
        const startHours = start_date.getHours()
        const startMinutes = start_date.getMinutes()
        const minute_end_date = `${endHours}:${endMinutes}`
        const minute_start_date = `${startHours}:${startMinutes}`
        console.log('end_date', dateSeparatedFormater(end_date));
        console.log('start_date', start_date);

        setDescription(selectedTicket.description)
        setLogo(selectedTicket.logo)
        setEventTitle(selectedTicket.event_title)
        setEventOrganizer(selectedTicket.event_organizer)
        setEventVenue(selectedTicket.event_venue)
        setEventVenueAddress(selectedTicket.event_venue_address)
        setEventStartDate(dateSeparatedFormater(start_date))
        setEventEndDate(dateSeparatedFormater(end_date))
        setEventDescription(selectedTicket.event_description)
        setEventStartDateMinuter(minute_start_date)
        setEventEndDateMinuter(minute_end_date)
        setImageTempo(selectedTicket.image_tempo)
        setSubdomain(selectedTicket.subdomain)
    }, [selectedTicket])


    useEffect(() => {
        if (activeForm > 0) {
            _handleSubmit();
        }
    }, [activeForm])


    const _validateStep = () => {
        if (logo === '') {
            toastrError(props.t(`Don't forget to add the logo of the event`), 'Error')
            return false
        }
        if (event_title === '') {
            toastrError(props.t(`Don't forget to enter the event title`), 'Error')
            return false
        }
        if (event_organizer === '') {
            toastrError(props.t(`Don't forget to enter the event organizer`), 'Error')
            return false
        }
        if (event_venue === '') {
            toastrError(props.t(`Don't forget to enter the event venue`), 'Error')
            return false
        }
        if (event_venue_address === '') {
            toastrError(props.t(`Don't forget to enter the event venue address`), 'Error')
            return false
        }
        if (event_start_date === '') {
            toastrError(props.t(`Don't forget to enter the event start date`), 'Error')
            return false
        }
        if (event_end_date === '') {
            toastrError(props.t(`Don't forget to enter the event end date`), 'Error')
            return false
        }
        if (event_description === '') {
            toastrError(props.t(`Don't forget to enter the event description`), 'Error')
            return false
        }
        if (event_start_date_minuter === '') {
            toastrError(props.t(`Don't forget to enter the event time starts`), 'Error')
            return false
        }
        if (event_end_date_minuter === '') {
            toastrError(props.t(`Don't forget to enter the event time ends`), 'Error')
            return false
        }
        if (subdomain === '') {
            toastrError(props.t(`Don't forget to add subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        if (!enableSubdomain) {
            toastrError(props.t(`Don't forget to add a non repeated subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        return true
    }



    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            setLoading(true)
            const _event_starts = dateHourFormater(event_start_date, event_start_date_minuter)
            const _event_ends = dateHourFormater(event_end_date, event_end_date_minuter)
            const body = {
                description: description,
                logo: logo,
                event_title: event_title,
                event_organizer: event_organizer,
                event_venue: event_venue,
                event_venue_address: event_venue_address,
                event_start_date: _event_starts,
                event_end_date: _event_ends,
                event_description: event_description,
                image_tempo: image_tempo,
                subdomain: subdomain
            }
            console.log('body', body);
            props.setUserTicket(body)
            setLoading(false)
            setActiveTab('2')


        } catch (error) {
            toastrError(`Failed to add ${event_title}: ${error}`, 'Error');

        }
    }

    const _handleFileUpload = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then(async (response) => {

            setLogo(response.data.id)

            const _file = await toBase64(files[0])
            setImageTempo(_file);
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }

    const _handleSubdomain = async (event) => {
        event.preventDefault();
        const value = event.target.value;
        if (!value) {
            document.getElementById('subdomain').classList.add('input-error')
        }
        if (hasWhiteSpace(value)) {
            toastrError(subdomainWhiteSpacesError, 'Error')
            document.getElementById('subdomain').classList.add('input-error')
        }
        setSubdomain(value)

    }
    useEffect(() => {
        const search = setTimeout(async () => {
            //Your search query and it will run the function after 3secs from user stops typing

            if (!!subdomain && !hasWhiteSpace(subdomain)) {
                const unique = await DataService.getCollectionSubdomain(subdomain);

                if (unique.data.exist) {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                } if (!unique.data.exist) {
                    document.getElementById('subdomain').classList.remove('input-error')
                    setEnableSubdomain(true);
                } else {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                }
            } else {
                document.getElementById('subdomain').classList.add('input-error')
                setEnableSubdomain(false);
            }
        }, 5000);
        return () => clearTimeout(search)
    }, [subdomain])


    return (
        <React.Fragment>
            <Form className='collections-ticketing'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Basic information`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>Name your event and tell event-goers why they should come. Add details that highlight what makes it unique.</span>
                            <div className='collections-ticketing-settings-form'>
                                <div className='collections-ticketing-settings-form-column input' >
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event avatar`)}
                                                </Label>
                                                <div className='collections-minting-control' >
                                                    <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_img')?.click()}>
                                                        <span>Choose File</span>
                                                    </div>
                                                    <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_img')?.click()}>
                                                        <Input
                                                            required
                                                            value={logo}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='No file choosen'
                                                            disabled
                                                            id="logotype"
                                                        />
                                                    </div>
                                                    <div className='collections-minting-control-icon'>
                                                        {
                                                            image_tempo &&
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        }
                                                    </div>
                                                </div>
                                                <span className='input-label-mini'>jpg or png.</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Event title`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_title}
                                                    type="text"
                                                    id='event_title'
                                                    className="input-white-input circle"
                                                    placeholder='Event title '
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_title').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_title').classList.remove('input-error')
                                                        }
                                                        setEventTitle(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Organizer`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_organizer}
                                                    type="text"
                                                    id='event_organizer'
                                                    className="input-white-input circle"
                                                    placeholder='Organizer'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_organizer').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_organizer').classList.remove('input-error')
                                                        }
                                                        setEventOrganizer(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Venue`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_venue}
                                                    type="text"
                                                    id='event_venue'
                                                    className="input-white-input circle"
                                                    placeholder='Venue'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_venue').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_venue').classList.remove('input-error')
                                                        }
                                                        setEventVenue(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Venue address`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_venue_address}
                                                    type="text"
                                                    id='event_venue_address'
                                                    className="input-white-input circle"
                                                    placeholder='Venue address'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_venue_address').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_venue_address').classList.remove('input-error')
                                                        }
                                                        setEventVenueAddress(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Subdomain`)}
                                                </Label>
                                                <div className='collections-minting-control1' >
                                                    <div className='collections-minting-control-pretext'>
                                                        <Input
                                                            required
                                                            value={subdomain}
                                                            type="text"
                                                            className="input-white-input circle"
                                                            placeholder='subdomain'
                                                            id="subdomain"
                                                            onChange={event => _handleSubdomain(event)}
                                                        />
                                                    </div>
                                                    <div className='collections-minting-control-posttext'>
                                                        <span>.neefter.com</span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event description`)}
                                                </Label>

                                                <Input
                                                    required
                                                    style={{ padding: 10 }}
                                                    type="textarea"
                                                    className="input-white-input circle input-text-area"
                                                    placeholder="The description will be included on the item's detail page underneath 
                    its image. Markdown syntax is supported."
                                                    value={event_description}
                                                    id="event_description"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_description').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_description').classList.remove('input-error')
                                                        }
                                                        setEventDescription(event.target.value)
                                                        setDescription(event.target.value)
                                                    }}
                                                />

                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                <span>How does NFT Ticketing work?</span>
                                            </a>
                                        </Col>
                                    </Row>

                                </div>
                                <div className='collections-ticketing-settings-form-column img' >
                                    <div className='collections-create-form-row-img' style={{ alignItems: 'flex-end' }}>
                                        <p>Avatar preview</p>
                                        <div className='card-picture' style={img_style} onClick={() => document.getElementById('upload_img')?.click()}>
                                            <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo ? image_tempo : image_box} />
                                            <input type="file"
                                                id="upload_img"
                                                hidden
                                                onChange={event => {
                                                    segmentTrack(`Upload project avatar`)
                                                    _handleFileUpload(event)
                                                }}
                                            />
                                        </div>
                                        <div className='collections-create-form-row-ico' onClick={() => document.getElementById('upload_img')?.click()}>
                                            <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Date and time`)}</CardTitle>
                            <div className='collections-settings-row'>
                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event starts*`)}
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="event_start_date"
                                                    id="event_start_date"
                                                    value={event_start_date}
                                                    placeholder="Event starts*"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        console.log('setEventStartDate', value);
                                                        if (!value) {
                                                            document.getElementById('event_start_date').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_start_date').classList.remove('input-error')
                                                        }
                                                        setEventStartDate(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Start time`)}
                                                </Label>
                                                <Input
                                                    type="time"
                                                    name="event_start_date_minuter"
                                                    id="event_start_date_minuter"
                                                    value={event_start_date_minuter}
                                                    placeholder="Start time"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        console.log('setEventStartDateMinuter', value);
                                                        if (!value) {
                                                            document.getElementById('event_start_date_minuter').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_start_date_minuter').classList.remove('input-error')
                                                        }
                                                        setEventStartDateMinuter(event.target.value)
                                                    }} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event ends*`)}
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="event_end_date"
                                                    id="event_end_date"
                                                    value={event_end_date}
                                                    placeholder="Event ends*"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        console.log('setEventEndDate', value);
                                                        if (!value) {
                                                            document.getElementById('event_end_date').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_end_date').classList.remove('input-error')
                                                        }
                                                        setEventEndDate(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`End time`)}
                                                </Label>
                                                <Input
                                                    type="time"
                                                    name="event_end_date_minuter"
                                                    id="event_end_date_minuter"
                                                    value={event_end_date_minuter}
                                                    placeholder="time placeholder"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        console.log('setEventEndDateMinuter', value);
                                                        if (!value) {
                                                            document.getElementById('event_end_date_minuter').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_end_date_minuter').classList.remove('input-error')
                                                        }
                                                        setEventEndDateMinuter(event.target.value)
                                                    }} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <a href='https://www.youtube.com/embed/YhEdU-ypBkc' target="_blank" className='collections-ticketing-video'>
                                                <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                <span>5 things you need to know before creating an NFT Ticket Event</span>
                                            </a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Button
                                                type="button"
                                                color="secundary"
                                                className="theme-button-secundary collections-ticketing-submit"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    _handleSubmit()
                                                }}
                                            >
                                                {props.t(`Next step`)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </section>
                        <section className='container-settings-tab-side'>
                            <div className='container-video-all'>
                                <div className='container-video'>
                                    <p>How to create your assets</p>
                                    <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                                </div>
                                <div className='container-video'>
                                    <p>Cómo lanzar tu colección de NFT sin código</p>
                                    <YoutubeEmbed embedId="YhEdU-ypBkc"></YoutubeEmbed>
                                </div>
                            </div>
                        </section>

                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ticket: state.Auth?.ticket

    }
}


export default (connect(mapStatetoProps, {
    setUserTicket
}))(withRouter(withNamespaces()(Settings)))