import React, { useEffect, useState } from 'react'
import {
    Button,
    Container
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { segmentTrack } from "../../helpers/Segment"
import * as Loader03 from '../../assets/lottie/Loader03.json'
import * as Loader05 from '../../assets/lottie/Loader05.json'
import Lottie from 'react-lottie';

const Create = (props) => {
    const [is_ticket, setIsTicket] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)

    useEffect(() => {
        segmentTrack(`deploy confirmed`)

        const randInt = Math.floor(Math.random() * (2 - 0)) + 0;
        console.log('randInt', randInt);
        if (randInt) {
            setLoading1(true)
        } else {
            setLoading2(true)
        }


    }, [])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('ticket');

        if (foo) {
            setIsTicket(true)
        }
    }, [])

    const Loader03Options = {
        loop: true,
        autoplay: true,
        animationData: Loader03,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader05Options = {
        loop: true,
        autoplay: true,
        animationData: Loader05,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }


    const goToCollections = () => {
        props.history.push(`/collections`)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <section className='container-tab-moon'>
                        <section className='collections-moon'>
                            <p className='collections-moon-title'>To the moon!</p>
                            <div className='collections-moon-img card-picture'>
                                {/* <img style={{ width: '100%' }} alt="item" src={backgroundImage} /> */}
                                {
                                    loading1 &&
                                    <Lottie options={Loader03Options}
                                        height={400}
                                        width={400}
                                    />
                                }
                                {
                                    loading2 &&
                                    <Lottie options={Loader05Options}
                                        height={400}
                                        width={400}
                                    />
                                }

                            </div>
                            {/* <p className='collections-moon-airwave'>Uploading assets to a Web3 Hosting</p> */}
                            <p className='collections-moon-subtitle'>We are working on your collection, we’ll <br /> let you know when it’s ready.</p>
                            <div className='collections-moon-button'>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="theme-button-upload"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`go to my projects`)
                                        goToCollections()
                                    }}
                                >
                                    {props.t(`go to my projects`)}
                                </Button>
                            </div>
                        </section>
                    </section>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
