import React from 'react'

const Footer = () => {
    return (
        <div className="theme-footer">
            <div className="theme-footer-container">
                <div className="theme-footer-group">
                    <p>
                        {new Date().getFullYear()}
                        &copy;
                        <span>
                            <a>
                                Neefter Launchpad
                            </a>
                        </span>
                    </p>

                </div>
                <div className="theme-footer-group end">
                    <a className="theme-footer-group-help">
                        Help
                    </a>
                    <a className="theme-footer-group-help">
                        Documentation
                    </a>
                    <a className="theme-footer-group-help">
                        Legal
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer