import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import SettingsTab from './components/Settings-tabs/Settings'
import MetadataTab from './components/Settings-tabs/Metadata'
import LayersAndRarityTab from './components/attributes-tabs/layer-and-rarity-tab'
import LegendaryNftTab from './components/attributes-tabs/legendary-nft-tab'
import ComponentsTab from './components/attributes-tabs/components-tab'
import classnames from 'classnames';
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import ProgressHeader from '../../components/Global/Progress-header'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { toastrInfo } from "../../helpers/Toastr"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, setLoading } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);
    const { currentProject, setProject } = generalContext
    let [project_id, setProjectId] = useState([]);
    let [rules_array, setRulesArray] = useState([]);
    const { collection_id } = useParams();
    const [tokens, setTokens] = useState('')
    let [step_number, setStepNumber] = useState(0);
    let [rules_updated, set_rules_updated] = useState(false);

    let [completed, setCompleted] = useState(20);
    useEffect(() => {
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Collection Settings`)
        })
        setProjectId(currentProject.id)
    }, [currentProject])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('step');

        const tab_selected = foo ? parseFloat(foo) : undefined
        toastrInfo('Loading properties', 'Info')
        if (tab_selected) {
            if (tab_selected === 3) {
                setActiveTab('2')
            } else if (tab_selected === 4) {
                setActiveTab('3')
            }
        }
    }, [])
    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }

    const _handleExport = async (_step_number) => {
        setLoading(true)

        const body = {
            step_number: _step_number,
        }
        DataService.putCollectionById(collection_id, body).then(response => {
            if (response && response.data) {
                setLoading(false)

                if (_step_number === 4) {
                    callBack4()
                } else {
                    callBack3()
                }
            }
        })

    }

    const callBack4 = async () => {
        setLoading(true)
        props.history.push(`/collections/generative/legendary/${collection_id}`)
        props.history.push(`/collections/generative/legendary/${collection_id}`)
        setLoading(false)

    }
    const callBack3 = () => {
        setStepNumber(3)
        setActiveTab('3')
        setCompleted(40)
    }
    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={completed} percentage={completed}></ProgressHeader>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className={classnames('collections-settings-header-item')}>
                                <span>1. Upload</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '1' })}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (step_number >= 1) {
                                        if (parseInt(activeTab) > 1) {
                                            setActiveTab('1')
                                            setCompleted(20)
                                        }
                                    }
                                }}>
                                <span>2. Settings</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '2' })}
                                onClick={(e) => {
                                    e.preventDefault()

                                    if (step_number >= 2) {
                                        if (parseInt(activeTab) !== 2) {
                                            setActiveTab('2')
                                            setLoading(true)
                                            setCompleted(30)
                                        }
                                    }
                                }}>
                                <span>3. Components</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '3' })}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (step_number >= 3) {
                                        if (parseInt(activeTab) !== 3) {
                                            setActiveTab('3')
                                            setLoading(true)
                                            toastrInfo('Loading layers', 'Info')
                                            setCompleted(40)
                                        }
                                    }
                                }}>
                                <span>4. Layers & rarity</span>
                            </div>
                            <div className={classnames('collections-settings-header-item')}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (step_number >= 4) {
                                        setLoading(true)
                                        toastrInfo('Loading tokens', 'Info')
                                        props.history.push(`/collections/generative/legendary/${collection_id}`)
                                    }
                                }}>
                                <span>5. Legendary Nft</span>
                            </div>
                            <div className={classnames('collections-settings-header-item')}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (step_number >= 5) {
                                        setLoading(true)
                                        toastrInfo('Loading tokens', 'Info')
                                        props.history.push(`/collections/generative/token/${collection_id}`)
                                    }
                                }}>
                                <span>6. Preview</span>
                            </div>
                            <div className={classnames('collections-settings-header-item')} onClick={(e) => {
                                e.preventDefault()
                                if (step_number >= 6) {
                                    setLoading(true)
                                    props.history.push(`/collections/generative/minting/${collection_id}`)
                                }
                            }}>
                                <span>7. Deployment</span>
                            </div>
                        </section >
                        <div className='collections-settings-header-button'>
                            {
                                activeTab === '1' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`go to components`)
                                            _handleSubmit()
                                        }}
                                    >
                                        {props.t(`Next step`)}
                                    </Button>
                                )
                            }
                            {
                                activeTab === '2' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`go to layers and rarity`)
                                            const btn = document.getElementById('_handleSubmitComponentsTab')
                                            if (btn) {
                                                btn.click()
                                            }

                                        }}
                                    >
                                        {props.t(`Next step`)}
                                    </Button>
                                )
                            }
                            {
                                activeTab === '3' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`go to project review`)
                                            const btn = document.getElementById('_handleSubmitRarityTab')
                                            if (btn) {
                                                btn.click()
                                            }

                                        }}
                                    >
                                        {rules_updated ? props.t(`Save changes`) : props.t(`Next step`)}
                                    </Button>
                                )
                            }

                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-body'>
                            {
                                activeTab === '1' &&
                                <SettingsTab project_id={project_id} collection_id={collection_id} activeForm={activeForm}
                                    tokens={tokens} setTokens={setTokens} setActiveTab={setActiveTab}
                                    step_number={step_number} setStepNumber={setStepNumber}
                                />
                            }
                            {
                                activeTab === '2' &&
                                <ComponentsTab collection_id={collection_id} tokens={tokens} _handleExport={_handleExport} />
                            }
                            {
                                activeTab === '3' &&
                                <LayersAndRarityTab activeForm={activeForm} collection_id={collection_id} tokens={tokens} setRulesArray={setRulesArray} rules_array={rules_array} _handleExport={_handleExport}
                                    rules_updated={rules_updated}
                                    set_rules_updated={set_rules_updated} />
                            }
                            {
                                activeTab === '4' &&
                                <LegendaryNftTab activeForm={activeForm} collection_id={collection_id} tokens={tokens} />
                            }
                            {
                                activeTab === '5' &&
                                <MetadataTab project_id={project_id} activeForm={activeForm} tokens={tokens} />
                            }
                        </div>
                    </div>
                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))
