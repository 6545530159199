import { toastrError } from "./Toastr"
import { PublicKey } from '@solana/web3.js'
import { utils } from 'ethers'
import { Buffer } from 'buffer';

export const saveStream = (contentType, fileName, text) => {
    if (window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        const blobObject = new Blob([text], { type: contentType })
        window.navigator.msSaveBlob(blobObject, fileName)
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement('a')
        anchor.download = fileName

        if (window.URL.createObjectURL) {
            // Everything else new.
            const blobObject = new Blob([text], { type: contentType })
            anchor.href = window.URL.createObjectURL(blobObject)
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = `data:${contentType};charset=utf-8,${encodeURIComponent(text)}`
        }

        // Now, click it.
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents')
            event.initEvent('click', true, true)
            anchor.dispatchEvent(event)
        } else {
            anchor.click()
        }
    }
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
export const dataToBlob = async (imageData) => {
    return await (await fetch(imageData)).blob();
};

export const base64ToFile = async (base64) => {
    const res = await fetch(base64)
    const buf = await res.arrayBuffer()
    const arr = base64.split(',')
    const type = (arr[0] && arr[0].match(/:(.*?);/)) ? arr[0].match(/:(.*?);/)[1] : undefined

    const file = new File([buf], "base64ToFile", {
        type: type
    })
    return file;
};

// export const dataUrlToFile = (dataUrl, filename) => {
//     const arr = dataUrl.split(',');
//     if (arr.length < 2) { return undefined; }
//     const mimeArr = arr[0].match(/:(.*?);/);
//     if (!mimeArr || mimeArr.length < 2) { return undefined; }
//     const mime = mimeArr[1];
//     const buff = Buffer.from(arr[1], 'base64');
//     return new File([buff], filename, { type: mime });
// }

export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var b64DataString = b64Data.substr(b64Data.indexOf(',') + 1);
    var byteCharacters = atob(b64DataString);
    
    var byteArrays = [];


    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    if ((byteCharacters.includes('PNG') || byteCharacters.includes('png') && !byteCharacters.includes('<!--'))) {
        contentType = 'png'
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    } else if ((byteCharacters.includes('JPG') || byteCharacters.includes('JPEG') || byteCharacters.includes('jpg') || byteCharacters.includes('jpeg')) && !byteCharacters.includes('<!--')) {
        contentType = 'jpg'
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    } else if ((byteCharacters.includes('MP4') || byteCharacters.includes('mp4')) && !byteCharacters.includes('<!--')) {
        contentType = 'mp4'
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    } else if ((byteCharacters.includes('GIF') || byteCharacters.includes('gif')) && !byteCharacters.includes('<!--')) {
        contentType = 'gif'
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    }
    return undefined;

}
export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
export const csvFileToArray = csv => {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");

    headers.forEach((_header, index) => {
        headers[index] = _header.trim();
    });

    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(',');

        // for (var j = 0; j < headers.length; j++) 
        //     // obj[headers[j]] = (j === 0) ? currentline[j].slice(0, currentline[j].length) : currentline[j].slice(0, currentline[j].length);
        // }
        if (currentline.length !== headers.length) {
            toastrError('Malformed csv', 'Error')
            return;
        }
        for (var j = 0; j < headers.length; j++) {

            // if (currentline[j] === undefined) currentline[j] = '';

            if (currentline[j][currentline[j].length - 1] === '\r') {

                currentline[j] = currentline[j].slice(0, currentline[j].length - 1)

            }
            if (currentline[j][0] === '"' && currentline[j][currentline[j].length - 1] === '"') {

                currentline[j] = currentline[j].slice(1, currentline[j].length - 1)


            }
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
    }
    return result;
}

export const isAddressWeb3 = (address) => {
    try {
        return utils.isAddress(address)
    } catch (error) {
        return false
    }
}

export const isAddressSolana = (address) => {
    try {
        const _address = new PublicKey(address);
        
        return PublicKey.isOnCurve(_address)
    } catch (error) {
        
        return false
    }
}
export function hasWhiteSpace(s) {
    return /\s/g.test(s);
}
/**
 * 
 * @params {
 * date: string,
 * time: string
 * } 
 * @returns string
 */
export function dateHourFormater(date, time) {
    if (!time || !date) return 'Error date'
    const datetime = new Date(date)

    const year = datetime.getFullYear()
    let month = parseInt(datetime.getMonth() + 1)
    // month = (month + '').length > 1 ? month : '0' + month
    // const day = ((datetime.getDate() + '').length > 1 ? datetime.getDate() : '0' + datetime.getDate());
    const day = datetime.getDate()
    let ndate = day + "-" + month + "-" + year;

    const _date = ndate.replaceAll('-', '/')
    const wholeDate = `${_date} ${time}:00`
    return wholeDate
}

/**
 * 
 * @params {
 * datetile: Date,
 * } 
 * @returns string
 */
export function dateSeparatedFormater(datetime) {
    if (!datetime) return;
    const year = datetime.getFullYear()
    let month = parseInt(datetime.getMonth() + 1)
    month = (month + '').length > 1 ? month : '0' + month
    const day = ((datetime.getDate() + '').length > 1 ? datetime.getDate() : '0' + datetime.getDate());
    let date = year + "-" + month + "-" + day;
    return date;
}

export function dateFromFormat(initialDate) {
    //Dividimos la fecha primero utilizando el espacio para obtener solo la fecha y el tiempo por separado
    var splitDate = initialDate.split(" ");
    var date = splitDate[0].split("-");
    var time = splitDate[1].split(":");

    // Obtenemos los campos individuales para todas las partes de la fecha
    var dd = date[0];
    var mm = date[1] - 1;
    var yyyy = date[2];
    var hh = time[0];
    var min = time[1];
    var ss = time[2];

    // Creamos la fecha con Javascript
    var fecha = new Date(yyyy, mm, dd, hh, min, ss);
    return fecha;
}

export function scrollTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
}

export const getImage = async (url) => {
    return await fetch(url).then(res => res.blob())
}