// import bugsnag from "bugsnag-js"
// import createPlugin from "bugsnag-react"
import React, { useEffect } from "react"
import Layout from './components/Layout/Index'
import Public from './components/Layout/Public'
import Claim from './components/Layout/Claim'
import Full from './components/Layout/Full'

import { authProtectedRoutes, publicRoutes, claimRoutes, fullRoutes } from "./routes/"
import AppRoute from "./routes/route"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { GeneralContextProvider } from './context'
import ScrollToTop from './scrollTopHook'
import "./assets/scss/theme.scss"

// const bugsnagClient = bugsnag("")
// const ErrorBoundary = bugsnagClient.use(createPlugin(React))
import * as buffer from "buffer";
window.Buffer = buffer.Buffer
const App = () => {
    return (
        <React.Fragment>
            <GeneralContextProvider>
                {/* <ErrorBoundary> */}
                <Router>
                    <ScrollToTop>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Public}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={false}
                                />
                            ))}
                            {fullRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Full}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={false}
                                />
                            ))}

                            {claimRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Claim}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={false}
                                />
                            ))}
                            {authProtectedRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                />
                            ))}
                        </Switch>
                    </ScrollToTop>
                </Router>
                {/* </ErrorBoundary> */}
            </GeneralContextProvider>
        </React.Fragment>
    )
}

export default App
