import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import GeneralTab from './org-tabs/general-tab'
import TeamMemberTab from './org-tabs/team-member-tab'
import InvitesTab from './org-tabs/invites-tab'

import classnames from 'classnames';
import { useParams } from 'react-router-dom'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import ProgressHeader from '../../components/Global/Progress-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const View = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, currentProject, setProject, setLoading } = generalContext
    let [activeTab, setActiveTab] = useState('1');


    let [activeForm, setActiveForm] = useState(0);
    let [project_id, setProjectId] = useState([]);
    let [userInfo, setUserInfo] = useState(undefined);

    useEffect(() => {

        if (!currentProject) return;
        setProjectId(currentProject.id)
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Organization settings`)
        })
        getAuthWhoami()

    }, [currentProject])


    function getAuthWhoami() {
        setLoading(true)
        return DataService.getAuthWhoami().then(response => {
            console.log(response.data);
            setUserInfo(response.data)
            setLoading(false)

        })
    }


    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }

    return (
        <React.Fragment>

            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={100} percentage={100}></ProgressHeader>

            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}  >
                                <span>General</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '2' })} onClick={() => activeTab !== '2' && setActiveTab('2')} >
                                <span>Team members</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '3' })} onClick={() => activeTab !== '3' && setActiveTab('3')} >
                                <span>Invites</span>
                            </div>
                        </section>
                        <div className='collections-settings-header-button'>
                            {
                                activeTab === '1' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-update"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setActiveForm(activeForm + 1)

                                        }}
                                    >
                                        {props.t(`Save`)}
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-header'>

                        </div>
                        <div className='collections-settings-body'>
                            {
                                (activeTab === '1') &&
                                <GeneralTab project_id={project_id} activeForm={activeForm} userInfo={userInfo} />
                            }
                            {
                                (activeTab === '2') &&
                                <TeamMemberTab project_id={project_id} userInfo={userInfo} />
                            }
                            {
                                (activeTab === '3') &&
                                <InvitesTab project_id={project_id} userInfo={userInfo} />
                            }
                        </div>
                    </div>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(View))
