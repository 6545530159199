import React, { useState, useEffect, useContext } from 'react'


import { withRouter, Link } from "react-router-dom"
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context'



const Login = (props) => {
    const [profile, setProfile] = useState()
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setLoading(true);
        setBreadcrumb({
            icon: '',
            parent: '',
            parentLink: '',
            item: ''
        })

        const script = document.createElement('script');

        script.src = "https://auth.magic.link/pnp/login";
        script.setAttribute('data-magic-publishable-api-key', "pk_live_9E32243E04763494")
        // script.setAttribute('data-terms-of-service-uri', "/path/to/your/terms-of-service")
        // script.setAttribute('data-privacy-policy-uri', "/path/to/your/privacy-policy")
        script.setAttribute('data-redirect-uri', "/magic_callback")


        document.body.appendChild((script));
        // setLoading(false);

        return () => {
            document.body.removeChild(script);
        }
    }, []);



    return (
        <React.Fragment>
            <script
                src="https://auth.magic.link/pnp/login"
                data-magic-publishable-api-key="pk_live_9E32243E04763494"
                // data-terms-of-service-uri="/path/to/your/terms-of-service"
                // data-privacy-policy-uri="/path/to/your/privacy-policy"
                data-redirect-uri="/magic_callback">
            </script>
            <div></div>
        </React.Fragment>
    )
}


export default withRouter(withNamespaces()(Login))