import React, { Fragment, useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroupAddon,
    InputGroup,
    Input,
    Button,
    FormGroup,
    Label,
    CustomInput,
} from "reactstrap";
import { faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'


const DropdownControl = (props) => {
    const { value, setValue, options, parent, index } = props
    let [dropdownOpen, setDropdownOpen] = useState(false)
    let [filteredOptions, setFilteredOptions] = useState([])
    let [searchValue, setSearchValue] = useState([])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])
    const toggle = () => {
        if (!dropdownOpen) {
            setDropdownOpen(!dropdownOpen);

            document.getElementById(parent + index).style.display = 'block'
            document.getElementById(parent + index).focus()
            document.getElementById(parent + index).click()
        } else {
            setDropdownOpen(!dropdownOpen);
        }

    }

    const _handleInputChange = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setSearchValue(value)
        // setFilteredOptions(options.filter(opt => opt.label.includes(value) || opt.options.filter(optopt => optopt.label.includes(value)).length > 0))
        setFilteredOptions(options.map(opt => {
            return {
                label: opt.label,
                options: opt.options.filter(optopt => optopt.label.toLowerCase().includes(value.toLowerCase()))
            }
        }))
    }

    function getOrderedValue() {
        let label = '';
        if (value.length > 0) {
            value.forEach(item => {
                label = label + item.child + ', '
            });
        }
        return label
    }
    function _checkExist(options) {
        const _index = value.findIndex(sa => JSON.stringify(sa) === JSON.stringify(options.value));
        return _index > -1
    }
    const _deleteItem = (item) => {
        setValue(item, index)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up" >
            <DropdownToggle tag="div"
                className="input-drop-radio">
                <InputGroup style={{ flexFlow: 'row', alignItems: 'center' }}>

                    <Input
                        style={{ display: dropdownOpen ? 'block' : 'none' }}
                        value={searchValue}
                        id={parent + index}
                        onChange={_handleInputChange}
                    />

                    {/* {
                        !dropdownOpen && (
                            <Input
                                style={{ display: !dropdownOpen ? 'block' : 'none' }}
                                value={getOrderedValue()}
                                id={parent + 'readOnly' + index}
                                readOnly
                            />
                        )
                    } */}
                    {
                        !dropdownOpen && (
                            <div className="colored-chips">
                                <div className="colored-chips-container">
                                    {
                                        value.map((item, index) => (
                                            <div className="colored-chips-item" key={index}>
                                                <span>{item.label}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }


                    <InputGroupAddon addonType="append" >
                        <Button onClick={toggle}>
                            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </DropdownToggle>
            <DropdownMenu className="input-drop-radio-menu">
                {!!filteredOptions && filteredOptions.map((group, key) => (
                    <Fragment key={parent + key}>
                        <DropdownItem header key={parent + group.label}>
                            {group.label}
                        </DropdownItem>
                        {group.options.map(option => (

                            <DropdownItem key={parent + option.label} onClick={() => {
                                setSearchValue(option.label)
                                setValue(option.value, index)
                            }}>
                                <Input type="checkbox" className="checkbox-item" checked={_checkExist(option)} readOnly />{' '}
                                <span>
                                    {option.label}
                                </span>

                            </DropdownItem>

                        ))}
                    </Fragment>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default withRouter(withNamespaces()(DropdownControl))