import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Users/Login"
import Logout from "../pages/Users/Logout"
import Signup from "../pages/Users/Signup"
import Verify from "../pages/Users/Verify"
import Users from "../pages/Users/Index"
import UsersEdit from "../pages/Users/Edit"
import UsersCreate from "../pages/Users/Create"
import recoverpassword from "../pages/Users/recoverpassword"
import ConfirmRecover from "../pages/Users/ConfirmRecover"

import Collections from "../pages/Collections/Index"
import CollectionsCreate from "../pages/Collections/Create"
import CollectionsChooseType from "../pages/Collections/ChooseType"
import CollectionsSeetings from "../pages/Collections/Settings"
import CollectionsToken from "../pages/Collections/Token"
import CollectionsMinting from "../pages/Collections/Minting"
import CollectionsToTheMoon from "../pages/Collections/ToTheMoon"
import CollectionsView from "../pages/Collections/View"
import CollectionsGenerativeCreate from "../pages/Generative/create"
import CollectionsGenerativeSettings from "../pages/Generative/settings"
import CollectionsGenerativeToken from "../pages/Generative/token"
import CollectionsGenerativeLegendary from "../pages/Generative/Legendary"
import CollectionsGenerativeAttributes from "../pages/Generative/attributes"
import CollectionsGenerativeMinting from "../pages/Generative/Minting"
import CollectionsOneAndOneTokenSettings from "../pages/OneAndOne/MintingSettings"
import CollectionsOneAndOneTokenDetail from "../pages/OneAndOne/TokenDetail"
import Collectionsquickmint from "../pages/OneAndOne/Quickmint"
import CollectionsTicketingSettings from "../pages/ticketing/settings"
import CollectionsTicketingView from "../pages/ticketing/view"
import CollectionsTicketingToTheMoon from "../pages/ticketing/ToTheMoon"

import CollectionsOrdinalsRecipientAddress from "../pages/Ordinals/RecipientAddress"
import CollectionsOrdinalsTransactionFee from "../pages/Ordinals/TransactionFee"
import CollectionsOrdinalsOrdinalDetails from "../pages/Ordinals/OrdinalDetails"
import CollectionsOrdinalsOrdinalToTheMoon from "../pages/Ordinals/ToTheMoon"
import CollectionsOrdinalsOrdinalView from "../pages/Ordinals/View"


import OrganizationView from "../pages/Organization/view"

import Claim from "../pages/Claim/Claim"
import Reclaim from "../pages/Claim/Reclaim"
import TwetterLogin from "../pages/twitter-set/login"
import TwetterMint from "../pages/twitter-set/mint"
import TwetterVerify from "../pages/twitter-set/verify"

import Testing from "../pages/Testing/index"

import Dashboard from "../pages/Dashboard/Index"
import Settings from "../pages/Settings/Index"
import Items from "../pages/items/Index"
import ResetPassword from "../pages/Users/ResetPassword"
import Projects from "../pages/Projects"
import ProjectsCreate from "../pages/Projects/Create"
import MagicLogin from "../pages/Users/MagicLogin"
import MagicCallback from "../pages/Users/MagicCallback"

const authProtectedRoutes = [
    { path: "/users/edit/:id", component: UsersEdit },
    { path: "/users/add", component: UsersCreate },
    { path: "/users", component: Users },

    // from projects
    { path: "/projects/:project_id/collections/token", component: CollectionsToken },
    { path: "/projects/:project_id/collections/minting", component: CollectionsMinting },
    { path: "/projects/:project_id/collections/to-the-moon", component: CollectionsToTheMoon },
    { path: "/projects/:project_id/collections/settings", component: CollectionsSeetings },
    { path: "/projects/:project_id/collections/choose-type", component: CollectionsChooseType },
    { path: "/projects/:project_id/collections/create", component: CollectionsCreate },
    { path: "/projects/:project_id/collections", component: Collections },


    { path: "/collections/token/:collection_id", component: CollectionsToken },
    { path: "/collections/minting/:collection_id", component: CollectionsMinting },
    { path: "/collections/to-the-moon", component: CollectionsToTheMoon },
    { path: "/collections/settings/:collection_id", component: CollectionsSeetings },
    { path: "/collections/choose-type", component: CollectionsChooseType },
    { path: "/collections/create", component: CollectionsCreate },
    { path: "/collections/view/:collection_id", component: CollectionsView },
    { path: "/collections/generative/settings/:collection_id", component: CollectionsGenerativeSettings },
    // { path: "/collections/generative/attributes/:collection_id", component: CollectionsGenerativeAttributes },
    { path: "/collections/generative/attributes/:collection_id", component: CollectionsGenerativeAttributes },
    { path: "/collections/generative/token/:collection_id", component: CollectionsGenerativeToken },
    { path: "/collections/generative/minting/:collection_id", component: CollectionsGenerativeMinting },
    { path: "/collections/generative/legendary/:collection_id", component: CollectionsGenerativeLegendary },
    { path: "/collections/generative", component: CollectionsGenerativeCreate },

    { path: "/collections/oneandone/settings", component: CollectionsOneAndOneTokenSettings },
    { path: "/collections/oneandone/view/:collection_id", component: CollectionsOneAndOneTokenSettings },



    { path: "/collections/oneandone/settings", component: CollectionsOneAndOneTokenSettings },
    { path: "/collections/oneandone/edit/:collection_id", component: CollectionsOneAndOneTokenDetail },
    { path: "/collections/oneandone", component: CollectionsOneAndOneTokenDetail },
    { path: "/collections/quickmint", component: Collectionsquickmint },
    { path: "/collections/ticketing/view/:collection_id", component: CollectionsTicketingView },
    { path: "/collections/ticketing/to-the-moon", component: CollectionsTicketingToTheMoon },
    { path: "/collections/ticketing", component: CollectionsTicketingSettings },
    { path: "/collections/ordinals/address", component: CollectionsOrdinalsRecipientAddress },
    { path: "/collections/ordinals/transaction", component: CollectionsOrdinalsTransactionFee },
    { path: "/collections/ordinals/to-the-moon/:collection_id", component: CollectionsOrdinalsOrdinalToTheMoon },
    { path: "/collections/ordinals/view/:collection_id", component: CollectionsOrdinalsOrdinalView },
    { path: "/collections/ordinals", component: CollectionsOrdinalsOrdinalDetails },


    { path: "/organization", component: OrganizationView },
    { path: "/collections", component: Collections },
    { path: "/testing", component: Testing },


    { path: "/projects/create", component: ProjectsCreate },
    { path: "/projects", component: Projects },
    { path: "/projects/edit/:project_id", component: ProjectsCreate },

    { path: "/settings/:route", component: Settings },
    { path: "/settings", component: Settings },
    { path: "/dashboard", component: Dashboard },
    { path: "/items", component: Items },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/collections" /> },
    { path: "/projects/:project_id", exact: true, component: () => <Redirect to="/projects/:project_id/collections" /> },

]

const claimRoutes = [
    { path: "/twitter/verify", component: TwetterVerify },
    { path: "/twitter/mint", component: TwetterMint },
    { path: "/twitter", component: TwetterLogin },
]
const fullRoutes = [
    { path: "/reclaim/:collection_id", component: Reclaim },
    { path: "/claim/:collection_id", component: Reclaim },
]

const publicRoutes = [
    { path: "/signup", component: Signup },
    // { path: "/login", component: Login },
    { path: "/verify/:userId/:verificationCode", component: Verify },
    { path: "/recover-password", component: recoverpassword },
    { path: "/confirm-recover-password/:token", component: ConfirmRecover },
    { path: "/logout", component: Logout },
    { path: "/reset-password", component: ResetPassword },
    { path: "/login", component: MagicLogin },
    { path: "/magic_callback", component: MagicCallback },




]

export { authProtectedRoutes, publicRoutes, claimRoutes, fullRoutes }
