import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faArrowLeft, faEllipsisVertical, faCloudArrowUp, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import {
    Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, FormGroup,
    Input, Label, Row
} from "reactstrap"
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { db } from "../../db"
import DataService from '../../helpers/DataService'
import TabHeaderMenu from './Components/tab-quick-header-menu'
import { useParams } from 'react-router-dom'
import bottomButton from '../../assets/images/bottom-button.png'
import Modal from "../../components/Global/modals"
import { ethers } from 'ethers'
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import { segmentTrack } from "../../helpers/Segment"
import { toastrError, toastrInfo } from '../../helpers/Toastr'
import { loadState } from "../../store/localStorage"

const contractAddress = "0x6562A3b9E2Aa62c9106D6C88167Ba3AeBf180A06";
const network = 'https://polygon.rpc.thirdweb.com' // use mumbai testnet
const ethers_provider = ethers.getDefaultProvider(network)
const abi = [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "string", "name": "name", "type": "string" }], "name": "NameChanged", "type": "event" }, { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "string", "name": "_name", "type": "string" }], "name": "setName", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
const { ethereum } = window;




const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext

    let [project_id, setProjectId] = useState(undefined);
    const tokenSupply = 1;
    const [button_loader, setButtonLoader] = useState(false)

    const [image_id, setImageId] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const [image, setImage] = useState('')
    const [blockchainNetwork, setBlockchainNetwork] = useState(1)
    const [symbol, setSymbol] = useState('Sol')
    const [showModal, setShowModal] = useState(false)
    const [file_type, setFileType] = useState('')
    let [tokenName, setTokenName] = useState('')
    let [send_to, setSendTo] = useState('')
    let [externalUrl, setExternalUrl] = useState(undefined);
    let [description, setDescription] = useState(undefined);
    let [property_array, setPropertyArray] = useState([]);
    let [disableAll, setDisableAll] = useState(false);
    const [showImage, setShowImage] = useState(true)


    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);

    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }

    const [menuBlockchain, setMenuBlockchain] = useState(false)
    const toggleBlockchain = () => { setMenuBlockchain(!menuBlockchain) }

    const image_multiplicator = 0.001;

    const commentErrorLength = 'Only 1000 characters or less can be added for the description field'
    let maxfilesizeImage = 1024 * 1024 * 2  // 2 Mb
    let maxfilesizeGlb = 1024 * 1024 * 10  // 2 Mb
    const prompt_error_sile_file = "The uploaded asset exceeds the size limit"

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }

    let [selectedProperty, setSelectedProperty] = useState({
        id: 1,
        name: 'Property 1'
    });
    let [selectedTicket, setSelectedTicket] = useState(undefined)
    useEffect(() => {
        if (loadState()?.Auth?.ticket) {
            selectedTicket = loadState()?.Auth?.ticket;
            setSelectedTicket(loadState()?.Auth?.ticket)
        }
    }, [loadState()?.Auth])

    const filteredProperties = [
        {
            id: 1,
            name: 'Property 1'
        },
        {
            id: 2,
            name: 'Property 2'
        }
    ]

    const img_style = {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        padding: '8px'
    }
    useEffect(() => {
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: props.t(`Collections`),
            parentLink: `/collections`,
            item: props.t(`Create a Quick Digital Collectible`)
        })
        setProjectId(currentProject.id)

    }, [currentProject])


    // Payment 
    const [loadPayment, setLoadPayment] = useState(false)
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);
    const [sign, setSign] = useState(undefined);
    const neefter_cash = 'C123NqmCoXtEUt3sbJrHtYw3853b6y5tXttTLGHGvB6a'
    const lamport = 1_000_000_000
    const NEEFTER_SOLANA_NODE = 'https://orbital-fittest-bird.solana-mainnet.quiknode.pro/bc45b120094c7d49e064f515fafd083b0e248f70/'
    let [counter, setCounter] = useState(0);
    const [enableButton, setEnableButton] = useState(true)
    const [showErrorTransaction, setShowErrorTransaction] = useState(false)
    const [finalizedSignature, setFinalizedSignature] = useState(undefined)

    useEffect(() => {
        getNetworkConfig()
        const provider = getProvider();
        console.log('provider', provider);
        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, []);

    function getNetworkConfig() {
        return DataService.getNetworkConfig().then(response => {
            console.log('getNetworkConfig', response.data);
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            setSelectedBlockchain(arr[0])
        })
    }
    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            // @ts-ignore
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
        // if ('phantom' in window) {
        //     // @ts-ignore
        //     const provider = window.phantom?.solana as any;

        //     if (provider?.isPhantom) {
        //         return provider as PhantomProvider;
        //     }
        // }
    };



    /**
    * @description prompts user to connect wallet if it exists
    */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;

        if (solana) {
            try {
                const response = await solana.connect();
                console.log('response', response);
                setWalletKey(response.publicKey.toString());
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };


    const _handleBuyCollection = () => {
        buyCollection()
    }

    let buyCollection = async () => {

        const provider = getProvider();

        setEnableButton(true);
        setLoadPayment(true);
        setShowErrorTransaction(false);
        const network = NEEFTER_SOLANA_NODE;


        const connection = new Connection(network);

        // setLoading(true);
        try {
            let transaction = Transaction;
            const to_public_key = new PublicKey(neefter_cash);
            const from_public_key = new PublicKey(walletKey);
            const lamp = lamport * image_multiplicator * tokenSupply;
            console.log('walletKey', walletKey);
            console.log('from_public_key', from_public_key);
            const txn = await createTransferTransaction(to_public_key, from_public_key, connection, lamp)


            const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
            try {
                return provider?.signAndSendTransaction(txn)
                    .then(async response => {
                        const signature = response?.signature
                        let getSignatureStatus;
                        let conditionExit = false;
                        while (!conditionExit && counter < 8) {
                            getSignatureStatus = await connection.getSignatureStatus(signature);
                            await ndelay(5000)
                            conditionExit = (!!getSignatureStatus.value && getSignatureStatus.value.confirmationStatus === 'finalized');
                            counter = counter + 1;
                        }
                        setFinalizedSignature(signature);
                        // const signature_status = await connection.getSignatureStatus(signature);

                        // openModal('MAIL_FORM')

                        // const data = {
                        //     signature: response?.signature,
                        //     email: '',
                        // }
                        // setpostBftMintByNftIPubKeyData({ id, wallet: walletState.data.Wallet?.wallet, data, clickNextStep, connection })
                        // setStepperView(true);
                        // return postBftMintByNftIPubKey(id, walletState.data.Wallet?.wallet, data, clickNextStep)

                    })
                    .catch(err => {
                        setShowErrorTransaction(true);
                        setLoading(false);
                        setEnableButton(false);
                        setLoadPayment(false);

                    })
            } catch (err) {
                setShowErrorTransaction(true);
                setLoading(false);
                setEnableButton(false);
                setLoadPayment(false);

            }
        } catch (err) {
            setShowErrorTransaction(true);
            setLoading(false);
            setEnableButton(false);

        }
    }

    useEffect(() => {
        if (finalizedSignature) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setSign(finalizedSignature)
            setLoading(false)

        }
    }, [finalizedSignature])

    useEffect(() => {
        if (sign) {
            _handleDeploy()
        }
    }, [sign])

    /**
* 
* 
* 
* 
* Purchase options
* 
* 
* 
* 
* 
*/

    /**
    * Creates an arbitrary transfer transaction
    * @param   {String}      publicKey  a public key
    * @param   {Connection}  connection an RPC connection
    * @returns {Transaction}            a transaction
    */
    const createTransferTransaction = async (toPublicKey, fromPublicKey, connection, lamports) => {
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: fromPublicKey,
                toPubkey: toPublicKey,
                lamports: lamports,
            })
        );


        transaction.feePayer = fromPublicKey;

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;


        return transaction;
    };

    const _handleDeploy = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService();
    }

    /**
 * Mumbai stuff
 * ____________________________________________________________________________________
 */

    const [balance, setBalance] = useState("Loading...")
    const [currentAccount, setCurrentAccount] = useState(null);
    const [transferBalance, setTransferBalance] = useState('0.0001')
    const [transferAddress, setTransferAddress] = useState('0x11Ef8475B65C63161e2269eD5b5FF6fa6c2Bf9bD')
    const [name, setName] = useState("")
    const [nameInfo, setNameInfo] = useState("")
    const [blockhash, setBlockhash] = useState(undefined);
    const [loadMumbaiPayment, setLoadMumbaiPayment] = useState(false)
    const [finalizedSignatureMumbai, setFinalizedSignatureMumbai] = useState(undefined)

    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    const getName = async () => {
        let getName_provider = ethers.getDefaultProvider(network)
        const erc20 = new ethers.Contract(contractAddress, abi, getName_provider)
        const nameInf = await erc20.name()
        setNameInfo(nameInf)

    }


    const submitName = async () => {
        let submitName_provider = ethers.getDefaultProvider(network)
        const privateKey = "########################################";
        let wallet = new ethers.Wallet(privateKey, submitName_provider)
        const learnToken = new ethers.Contract(contractAddress, abi, wallet)


        learnToken.on("NameChanged", (newName) => {

        });

        const createReceipt = await learnToken.setName(name);
        await createReceipt.wait();



    }
    async function transferETH() {
        setLoadMumbaiPayment(true);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        try {
            let receiverAddress = transferAddress
            let amountInEther = (image_multiplicator * (tokenSupply || 1)) + '';
            console.log('amountInEther', amountInEther);
            let tx = {
                to: receiverAddress,
                value: ethers.utils.parseEther(amountInEther)
            }

            const _provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = _provider.getSigner();
            // Send the transaction and log the receipt
            const receipt = await signer.sendTransaction(tx);

            let wrongResult = false;
            let _transaction;
            let conditionExit = false;
            while (!conditionExit && counter < 10) {

                _transaction = await _provider.getTransactionReceipt(receipt.hash);

                await ndelay(5000)
                counter = counter + 1;
                if (_transaction) {
                    conditionExit = (_transaction.status !== 2 && _transaction.status !== 3)
                    wrongResult = _transaction.status === 1;
                }
            }
            if (!wrongResult) {

                setLoadMumbaiPayment(false);
            } else {

                setFinalizedSignatureMumbai(receipt.hash)
                // setLoadMumbaiPayment(false);
            }

        } catch (err) {

            setLoadMumbaiPayment(false);

        }

    }

    const checkWalletIsConnected = async () => {
        if (!ethereum) {

            return;
        } else {

        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];

            setCurrentAccount(account);
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                setBalance(`${balanceInEth} ETH`)
            })

        } else {

        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

            setCurrentAccount(accounts[0]);
        } catch (err) {

        }
    }

    const connectWalletButton = () => {
        return (
            <Button
                type="button"
                color="primary"
                className=" theme-button-secundary"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`Connect wallet to deploy`)
                    connectWalletHandler()
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        )
    }

    useEffect(() => {
        if (finalizedSignatureMumbai) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setBlockhash(finalizedSignatureMumbai)
            setLoading(false)

        }
    }, [finalizedSignatureMumbai])

    useEffect(() => {
        if (blockhash) {
            _handleDeployMumbai()
        }
    }, [blockhash])

    const _handleDeployMumbai = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService();
    }


    /**
     * ___________________________________________________________________________________________
     */

    const _validateFullStep = () => {
        if (!tokenSupply) {
            toastrError(props.t(`Don't forget to select the token supply`), 'Error')
            return false
        }
        if (image_id === "") {
            toastrError(props.t(`Don't forget to enter the image`), 'Error')
            return false
        }
        if (tokenName === "") {
            toastrError(props.t(`Don't forget to enter the token name`), 'Error')
            return false
        }
        if (externalUrl === "") {
            toastrError(props.t(`Don't forget to enter the external url`), 'Error')
            return false
        }
        if (property_array.length < 1 || property_array.filter(pa => (!!pa.value && !pa.trait_type) || (!pa.value && !!pa.trait_type)).length > 0) {
            toastrError(props.t(`Don't forget to enter at least one property`), 'Error')
            return false
        }
        if (blockchainNetwork === "") {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        if (!sign && !blockhash) {
            toastrError(props.t(`First you need paid for the service`), 'Error')
            return false
        }
        return true
    }

    const _validateStep = () => {
        if (image_id === "") {
            toastrError(props.t(`Don't forget to enter the image`), 'Error')
            return false
        }
        if (tokenName === "") {
            toastrError(props.t(`Don't forget to enter the token name`), 'Error')
            return false
        }
        if (externalUrl === "") {
            toastrError(props.t(`Don't forget to enter the external url`), 'Error')
            return false
        }
        if (property_array.length < 1 || property_array.filter(pa => (!!pa.value && !pa.trait_type) || (!pa.value && !!pa.trait_type)).length > 0) {
            toastrError(props.t(`Don't forget to enter at least one property`), 'Error')
            return false
        }
        if (blockchainNetwork === "") {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        if (description.length > 1000) {
            toastrError(props.t(`${commentErrorLength}`), 'Error')
            return false
        }
        return true
    }

    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        setShowModal(true)
    }

    const sendFormToService = () => {
        if (!_validateStep()) return;
        try {
            const body = {
                image_id: image_id,
                name: tokenName,
                external_url: externalUrl,
                project_id: project_id,
                description: description,
                symbol: symbol,
                network_id: blockchainNetwork,
                sign: (blockchainNetwork === 1 || blockchainNetwork === '1') ? sign : blockhash,
                file_type: file_type,
                send_to: (blockchainNetwork === 1 || blockchainNetwork === '1') ? walletKey : currentAccount,
                properties: property_array.map(pa => {
                    return {
                        trait_type: pa.trait_type,
                        value: pa.value
                    }
                }),
            }
            return DataService.postCollectionQuick(body).then(response => {
                db.oneandone.clear();
                setButtonLoader(false);
                if (selectedTicket) {
                    props.history.push(`/collections/ticketing?step=3`)
                } else {
                    props.history.push(`/collections/to-the-moon`)
                } setLoadPayment(false);
                setLoadMumbaiPayment(false);
            })
        } catch (error) {
            toastrError(`Failed to add ${tokenName}: ${error}`, 'Error');
        }

    }
    const _handleFileUpload = (event) => {
        let files = event?.target.files
        setLoading(true)
        const file = files[0];
        if (!file) return;
        const splt_img = files[0].name.split('.')
        console.log('files[0]', files[0]);
        const file_type = splt_img[splt_img.length - 1]
        console.log('file_type', file_type);
        if (((file_type !== 'glb' && file_type !== 'GLB') && (file_type !== 'mp4' && file_type !== 'MP4')  && (file_type !== 'gif' && file_type !== 'GIF'))) {
            if (file.size > maxfilesizeImage) {
                toastrError(prompt_error_sile_file, 'Error')
                setLoading(false)
                return
            }
        } else {
            if (file.size > maxfilesizeGlb) {
                toastrError(prompt_error_sile_file, 'Error')
                setLoading(false)
                return
            }
        }
        if (file)
            fileUpload(files[0]).then((response) => {
                setImageId(response.data.id)
                setImage(files[0])
                setFileType(file_type)
                setImageTempo(URL.createObjectURL(files[0]));
                setLoading(false)

            }).catch(error => {

                setLoading(false)

            })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }



    const addProperty = () => {

        property_array = [
            ...property_array,
            {
                trait_type: '',
                value: '',
                error: false,
            }
        ]
        setPropertyArray([...property_array]);
        // checkAllProperties()
    };
    const removePropertyInput = (index) => {
        setPropertyArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
        // checkAllProperties()
    }
    const handleChangePropertyAuthor = (event) => {
        event.preventDefault();
        const val = 'property_author';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        console.log('value', value);
        console.log('index', index);

        setPropertyArray(item => {
            const newArr = item.slice();
            newArr[index].trait_type = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    const handleChangePropertyValue = (event) => {
        event.preventDefault();
        const val = 'property_value';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        console.log('value', value);
        console.log('index', index);

        setPropertyArray(item => {
            const newArr = item.slice();
            newArr[index].value = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };


    const validateWebsiteUrl = websiteUrl => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

        return regex.test(String(websiteUrl).toLowerCase());
    };
    // function checkAllProperties() {
    //     let total = 0;
    //     const checkError = property_array.find(pa => pa.error)

    // }



    /////////////

    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You’re about to publish a Digital Collectible of </p>
            <p className='deploy-modal-count'> {tokenName} </p>
            <div className='deploy-modal-token-container'>
                <img src={image_tempo} />
            </div>

            {
                (blockchainNetwork === 1 || blockchainNetwork === '1') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {image_multiplicator * (tokenSupply || 1)} </b> SOL  </span> to deploy your collection.</p>
            }
            {
                (blockchainNetwork === 2 || blockchainNetwork === '2') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {image_multiplicator * (tokenSupply || 1)} </b>  MATIC  </span> to deploy your collection.</p>
            }
        </div>
    </section>

    const footer = <div className='deploy-modal-footer'>

        {
            (blockchainNetwork === 1 || blockchainNetwork === '1') && (
                <div>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className=" theme-button-secundary"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {provider && !!walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="custom-row theme-button-secundary"
                            disabled={loadPayment}
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`pay deploy`)
                                _handleBuyCollection()
                            }}
                        >
                            {props.t(`Pay ${image_multiplicator * (tokenSupply || 1)} SOL and deploy`)}
                            {loadPayment &&
                                <div className='min-loader'>
                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                        <div></div><div><div></div></div>
                                    </div></div>
                                </div>
                            }

                        </Button>
                    )}
                    {!provider && (
                        <p>
                            No provider found. Install{" "}
                            <a href="https://phantom.app/">Phantom Browser extension</a>
                        </p>
                    )}
                </div>
            )
        }
        {
            (blockchainNetwork === 2 || blockchainNetwork === '2') && (
                <div>
                    {currentAccount ?
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className=" theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`pay deploy`)
                                    transferETH()
                                }}
                            >
                                {props.t(`Pay ${image_multiplicator * (tokenSupply || 1)} MATIC and deploy`)}
                                {loadMumbaiPayment &&
                                    <div className='min-loader'>
                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                            <div></div><div><div></div></div>
                                        </div></div>
                                    </div>
                                }
                            </Button>
                        </div>
                        : connectWalletButton()
                    }
                </div>
            )
        }
    </div>



    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={10} percentage={10}></ProgressHeader>
            {
                showModal && __renderPOView(12)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={0} active={1} ></TabHeaderMenu>
                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-update desktop"
                                disabled={loading || disableAll}
                                onClick={(e) => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Pay and Mint`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all quickmint'>
                        <section className='collections-collectible'>
                            <div className='collections-collectible-row'>
                                <div className='collections-collectible-img'>
                                    <div className='card-picture' style={img_style} onClick={() => !disableAll && document.getElementById('upload_img')?.click()}>
                                        {
                                            (image_tempo && showImage) &&
                                            <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo} />
                                        }
                                        {
                                            (image_tempo && !showImage) &&
                                            <video id='video' src={image_tempo} className='collections-token-resume-card-video' style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                                muted playsInline autoPlay={false}>
                                                {/* <source type="video/mp4" /> */}
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                        {
                                            !image_tempo &&
                                            <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                                        }


                                        <input type="file"
                                            id="upload_img"
                                            hidden
                                            disabled={disableAll}
                                            onChange={event => {
                                                _handleFileUpload(event)
                                            }}
                                        />
                                    </div>
                                    <div className='collections-create-form-row-ico' onClick={() => !disableAll && document.getElementById('upload_img')?.click()}>
                                        <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>
                            <div className='collections-create-form-upload-container-list'>
                                <li>
                                    <b>IMPORTANT</b>
                                </li>
                                <li>
                                    <b>Image assets (.png, .jpeg) have a size limit of 2 mb.</b>
                                </li>
                                <li>
                                    <b>Video/gif assets (.glb,.gif,.mp4) have a size limit of 10 mb.</b>
                                </li>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className='input-label_monserrat'>
                                                {props.t(`Blockchain network`)}
                                            </Label>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                isOpen={menuBlockchain}
                                                toggle={toggleBlockchain}
                                                className="d-inline-block"
                                                disabled={disableAll}
                                            >
                                                <DropdownToggle
                                                    className="input-drop-white"
                                                    id="select-block-dropdown"
                                                >
                                                    <span className="header-item-project-name">
                                                        {selectedBlockChain && (
                                                            <span>{selectedBlockChain.name}</span>
                                                        )}
                                                    </span>
                                                    <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                </DropdownToggle>
                                                <DropdownMenu right className='input-drop-white-item'>
                                                    {filteredBlockchains.map(
                                                        (item, key) => (
                                                            <div key={key} className="dropdown-item " onClick={() => {
                                                                setSelectedBlockchain(item)
                                                                setBlockchainNetwork(item.neefter_id)
                                                                setSymbol(item.usdc_api_currency_name)
                                                                setMenuBlockchain(false)
                                                            }}>
                                                                <span>{item.name}</span>
                                                            </div>
                                                        ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Token name`)}
                                            </Label>
                                            <Input
                                                required
                                                value={tokenName}
                                                type="text"
                                                id='tokenName'
                                                className="input-white-input circle"
                                                placeholder='Token name'
                                                disabled={disableAll}
                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (!value) {
                                                        document.getElementById('tokenName').classList.add('input-error')
                                                    } else {
                                                        document.getElementById('tokenName').classList.remove('input-error')
                                                    }
                                                    setTokenName(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`External url`)}
                                            </Label>

                                            <div className='collections-minting-control' id='externalUrl' >
                                                <div className='collections-minting-control-pre' >
                                                    <span>https://</span>
                                                </div>
                                                <div className='collections-minting-control-input'>
                                                    <Input
                                                        required
                                                        value={externalUrl}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Url or Link'
                                                        disabled={disableAll}
                                                        onChange={event => {
                                                            event.preventDefault()
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('externalUrl').classList.add('input-error')
                                                            } else {
                                                                if (validateWebsiteUrl(value)) {
                                                                    document.getElementById('externalUrl').classList.remove('input-error')
                                                                } else {
                                                                    document.getElementById('externalUrl').classList.add('input-error')
                                                                }
                                                            }
                                                            setExternalUrl(event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>


                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-collectible-row'>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Description`)}
                                            </Label>

                                            <Input
                                                required
                                                value={description}
                                                style={{ padding: 10 }}
                                                type="textarea"
                                                id='description'
                                                disabled={disableAll}
                                                className="input-white-input circle input-text-area"
                                                placeholder="The description will be included on the item's detail page underneath 
                                                    its image. Markdown syntax is supported."
                                                onChange={event => {
                                                    const { value } = event.target

                                                    if (!value) {
                                                        document.getElementById('description').classList.add('input-error')
                                                    } else {
                                                        if (value.length > 1000) {
                                                            toastrError('Only 1000 characters or less can be added for the description field')
                                                            document.getElementById('description').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('description').classList.remove('input-error')
                                                        }
                                                    }
                                                    setDescription(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            {
                                                !!property_array && property_array.length > 0 && (

                                                    <div className='input-container'>
                                                        <div className='input-container_lg'>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Property Name`)}
                                                            </Label>
                                                        </div>
                                                        <div className='input-container_sm'>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Value`)}
                                                            </Label>
                                                        </div>


                                                    </div>


                                                )
                                            }

                                            {
                                                property_array.map((item, i) => {
                                                    return (
                                                        <div className='input-container' key={'cont' + i} >
                                                            <div className='input-container_lg'>
                                                                <Input
                                                                    required
                                                                    value={item.trait_type}
                                                                    type="text"
                                                                    className="input-white-input circle"
                                                                    placeholder=''
                                                                    disabled={disableAll}
                                                                    id={"property_author" + i}
                                                                    onChange={handleChangePropertyAuthor}
                                                                />
                                                            </div>
                                                            <div className='input-container_sm'>
                                                                <div className='theme-form-control' id={"property_value_p" + i}>
                                                                    <div className='theme-form-control-input'>
                                                                        <Input
                                                                            required
                                                                            value={item.value}
                                                                            type="text"
                                                                            className="input"
                                                                            placeholder=''
                                                                            disabled={disableAll}
                                                                            id={"property_value" + i}
                                                                            onChange={handleChangePropertyValue}
                                                                        />
                                                                    </div>
                                                                    <div className='theme-form-control-post white'>
                                                                        {/* <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                !disableAll &&
                                                                <div className='input-container_icon' onClick={() => { removePropertyInput(i) }}>
                                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                </div>
                                                            }

                                                        </div>
                                                    )

                                                })
                                            }
                                        </FormGroup>
                                    </Col>

                                </Row>


                                <Row>
                                    <Col sm={6}>
                                        {
                                            !disableAll &&
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="theme-button-collaborator"
                                                onClick={addProperty}
                                            >
                                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                {props.t(`Add property`)}
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Button
                                            type="button"
                                            color="secundary"
                                            className="theme-button-update mobile"
                                            disabled={loading || disableAll}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                _handleSubmit()
                                            }}
                                        >
                                            {props.t(`Pay and Mint`)}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </section>

                        <section className='container-settings-tab-side'>
                            <section className='container-settings-tab-example' style={{ height: '55rem' }}>
                                <p>Token MetaData</p>
                                <div className='container-settings-tab-example-custom'>
                                    <div className='container-settings-tab-example-titles'>
                                        <span className='container-settings-tab-example-titles token-name'>
                                            Token Name
                                        </span>
                                        <span className='container-settings-tab-example-titles token-media'>
                                            Token Media
                                        </span>
                                        <span className='container-settings-tab-example-titles token-description'>
                                            Token <br /> Description
                                        </span>
                                        <span className='container-settings-tab-example-titles external-url'>
                                            External URL
                                        </span>
                                        <div className='container-settings-tab-example-stroke stoken-name'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke stoken-media'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke stoken-description'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke sexternal-url'>
                                        </div>
                                    </div>
                                    <div className='container-settings-tab-example-card'>
                                        <div className='container-settings-tab-example-card-title'>
                                            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                            <span> {tokenName}</span>
                                            <FontAwesomeIcon icon={faEllipsisVertical}></FontAwesomeIcon>
                                        </div>
                                        <div className='container-settings-tab-example-card-img'>
                                            <img src={image_tempo} />
                                        </div>
                                        <div className='container-settings-tab-example-card-buttons'>
                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                Send
                                            </div>
                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                List
                                            </div>
                                        </div>
                                        <div className='container-settings-tab-example-card-description'>
                                            <span className='container-settings-tab-example-card-description-title'>
                                                Description
                                            </span>
                                            <span>
                                                {description}
                                            </span>
                                            <span className='container-settings-tab-example-card-description-url'>
                                                {externalUrl}
                                            </span>
                                        </div>
                                        <div className='container-settings-tab-example-card-bottom'>
                                            <img src={bottomButton} />
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </section>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
