import ApiHelper from '../../helpers/ApiHelper'

export class ApiService {
    getBaseURL() {
        return ApiHelper.getBaseURL()
    }

    async signup(user) {
        return ApiHelper.post(`/v1/user/signup`, user)
    }

    async verifyAccount(data) {
        return ApiHelper.post(`/v1/user/verify`, data)
    }

    async login(login) {
        return ApiHelper.post(`/v1/user/login`, login)
    }

    async passwordRecover(data) {
        return ApiHelper.post(`/v1/user/password-recover`, data)
    }

    async validatePasswordRecoverToken(token) {
        return ApiHelper.get(`/v1/user/validate-password-recover-token/${token}`)
    }

    async confirmPasswordRecover(data) {
        return ApiHelper.post(`/v1/user/confirm-password-recover`, data)
    }

    async updatePassword(data) {
        return ApiHelper.put(`/v1/user/update-password`, data)
    }

    async getUsers() {
        return ApiHelper.get(`/v1/user/get`)
    }

    async getUserById(userId) {
        return ApiHelper.get(`/v1/user/get-by-id/${userId}`)
    }

    async createUser(user) {
        return ApiHelper.post(`/v1/user/save`, user)
    }

    async updateUser(user) {
        return ApiHelper.put(`/v1/user/update`, user)
    }

    async deleteUser(id) {
        return ApiHelper.delete(`/v1/user/delete/${id}`)
    }

    async uploadUserAvatar(data) {
        return ApiHelper.postWithFiles(`/v1/user/upload-avatar`, data)
    }

    // collections
    async getCollections() {
        // return ApiHelper.get(`/v1/collections/get`)
        return {
            data: {
                collections: [{
                    id: 1,
                    name: 'Crypto Lucha Club',
                    avatar: '/assets/images/layers.png'
                }, {
                    id: 2,
                    name: 'Crypto Lucha Leyendas',
                    avatar: '/assets/images/avatar-2.png'
                }]
            }
        }
    }

    async getCollectionById(id) {
        return ApiHelper.get(`/v1/collections/get/${id}`)
    }

    async createCollection(collection) {
        return ApiHelper.post(`/v1/collections/save`, collection)
    }

    async updateCollection(collection) {
        return ApiHelper.put(`/v1/collections/update`, collection)
    }

    async deleteCollection(id) {
        return ApiHelper.delete(`/v1/collections/delete/${id}`)
    }
}

export default new ApiService()
