import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GeneralContext } from '../../../context/';
import ProgressBar from './progressbar';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { segmentTrack } from "../../../helpers/Segment"
import { toBase64 } from "../../../helpers/utils"

function Dropzone({ open, onAssetFull, modalMode, onAssetLoaded, universeFiles, setUniverseFiles,
    onAssetFilterLoader, reloadDropzone, pairFiles, setPairFiles }) {
    const prompt_csv_no_json = "you cant upload both csv and json files combined" // ----si sube csv no puede subir jsons -> 
    const prompt_json_no_csv = "you cant upload both csv and json files combined" // ----si sube jsons no puede subir csv -> 
    const prompt_csv_column_image = "missing images from the csv file" //---- si sube csv todas las imagenes de la columna neefter_image deben de existir ->
    const prompt_json_pair_image = "file pairs do not match" // ----si sube imagenes + json cada json debe de tener su par en imagenes y cada imagen debe tener su par en json ->
    const prompt_different_format_asset = "file type of pairs do not match" // ----si sube imagenes con formatos diferentes ->

    const required_cols = ['neefter_name', 'neefter_description', 'neefter_image', 'neefter_symbol', 'neefer_external_url'];
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
        useDropzone({

        });
    // let [arr_filter, setArrayFilters] = useState([]);
    let [dataCsv, setdataCsv] = useState([]);
    let [dataFromCsv, setdataFromCsv] = useState([]);
    let [totalFiles, setTotalFiles] = useState(0);
    let [totalFilesChecked, setTotalFilesChecked] = useState(0);
    let [actualFileLength, setActualFileLength] = useState(0);
    const bgcolor = '#A066FF'
    let [completed, setCompleted] = useState(0);
    let [dataLoader, setdataloader] = useState(false);
    let onlyJson = false;
    let onlyCsv = false;
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    let [image_ext, setImageExt] = useState();
    let maxfilesizeImage = 1024 * 1024 * 2  // 2 Mb
    let maxfilesizeGlb = 1024 * 1024 * 10  // 2 Mb
    // const prompt_error_sile_file = `One or more of the uploaded assets exceeds the ${maxfilesizeImage} mb size limit`
    // const prompt_error_sile_file_media = `One or more of the uploaded assets exceeds the ${maxfilesizeGlb} mb size limit`
    const prompt_error_sile_file = `One or more of the uploaded assets exceeds the size limit`
    function blobHandler() {
        const type = 'text/csv';
        let blob = new Blob(['', toCSV(dataCsv)], { type });
    }
    const toCSV = (data) => {
        return arrays2csv(data);
    };
    const arrays2csv = ((data) =>
        joiner(data)
    );
    const joiner = ((data, separator = ',', enclosingCharacter = '"') => {
        return data
            .filter(e => e)
            .map(
                row => row
                    .map((element) => elementOrEmpty(element))
                    .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
                    .join(separator)
            )
            .join(`\n`);
    });

    function parseCsv(parseJson, file) {
        let array = [];
        const parseKeys = {
            'neefter_name': parseJson.name || '',
            'neefter_image': file.name || '',
            'neefter_symbol': parseJson.symbol || '',
            'neefter_description': parseJson.description || '',
            'neefter_seller_fee_basis_points': parseJson.seller_fee_basis_points || '',
            'neefter_external_url': parseJson.external_url || '',
        }
        for (var key in parseKeys) {
            array.push(parseKeys[key])
        }

        parseJson.attributes.forEach(att => {
            let existKey = dataCsv[0].findIndex(_data => _data === att.trait_type);
            if (existKey < 0) {
                dataCsv[0].push(att.trait_type)
            }
            existKey = dataCsv[0].findIndex(_data => _data === att.trait_type);
            if (existKey >= 0) {
                for (let i = 0; i < existKey; i++) {
                    if (array[i] === undefined) {
                        array.push('');
                    }
                }
                if (array[existKey] === '') {
                    array[existKey] = att.value;
                } else {
                    array.push(att.value)
                }
            }
        });

        return array;
    }
    const elementOrEmpty = (element) =>
        (typeof element === 'undefined' || element === null) ? '' : element;

    const resetDropzone = () => {
        setLoading(false)
        setTotalFiles(0)
        setTotalFilesChecked(0)
        actualFileLength = 0;
        setActualFileLength(0)
        setLoading(false)
    }

    useEffect(() => {
        segmentTrack(`Select assets to upload`)
        if (!acceptedFiles) return;
        if (acceptedFiles.length < 1) return;
        setdataCsv([[
            'neefter_name',
            'neefter_image',
            'neefter_symbol',
            'neefter_description',
            'neefter_seller_fee_basis_points',
            'neefter_external_url',
            'neefter_creator_1_address',
            'neefter_creator_1_share',
            'neefter_creator_2_address',
            'neefter_creator_2_share',
            'neefter_creator_3_address',
            'neefter_creator_3_share',
        ]])

        setdataloader(true);


        setTotalFiles(acceptedFiles.length);
        actualFileLength = 0;
        totalFilesChecked = 0;
        setTotalFilesChecked(totalFilesChecked)

        setActualFileLength(actualFileLength)
        onAssetFull(false)

        let arr_filter = [];
        // setArrayFilters(arr_filter)
        onlyJson = false;
        onlyCsv = false;
        // let currentFormatAsset = undefined;
        dataFromCsv = [];
        setdataFromCsv(dataFromCsv)

        let err_ext = false
        universeFiles = []
        setUniverseFiles(universeFiles)

        if (!modalMode) setLoading(true);

        acceptedFiles.forEach(async (file, index) => {
            const _name = file.name.split('.')[0];
            const fl_split = file.name.split('.')
            const _extension = fl_split[fl_split.length - 1]
            let tempData = arr_filter.find(ar => ar.name === _name)
            if (file.type !== "text/csv" && file.type !== "application/json") {
                console.log('image_ext', image_ext);
                if (!image_ext) {
                    image_ext = _extension
                    setImageExt(_extension)

                } else {
                    if (image_ext !== _extension && (image_ext === 'glb' || image_ext === 'GLB' || image_ext === 'mp4' || image_ext === 'MP4')) {
                        const fl_split_in = file.name.split('.')
                        const _extension_in = fl_split_in[fl_split_in.length - 1]
                        image_ext = _extension_in
                        setImageExt(_extension_in)
                    } else if (image_ext !== _extension && ((_extension !== 'glb' && _extension !== 'GLB') && (_extension !== 'mp4' && _extension !== 'MP4'))) {
                        console.log('image_ext', image_ext);
                        console.log('_extension', _extension);
                        toastrError(prompt_different_format_asset)

                        resetDropzone()
                        err_ext = true;
                        reloadDropzone()
                        return;
                    } else if (image_ext !== _extension && (!(_extension === 'glb' || _extension === 'GLB' || _extension === 'mp4' || _extension === 'MP4'))) {
                        console.log('_extension', _extension);
                        toastrError(prompt_different_format_asset)
                        toastrError(prompt_different_format_asset)

                        resetDropzone()
                        err_ext = true;
                        reloadDropzone()
                        return;
                    }
                }
            }
            if (file.type !== "text/csv" && file.type !== "application/json") {
                if (((_extension !== 'glb' && _extension !== 'GLB') && (_extension !== 'mp4' && _extension !== 'MP4') && (_extension !== 'gif' && _extension !== 'GIF'))) {
                    if (file.size > maxfilesizeImage) {
                        toastrError(prompt_error_sile_file, 'Error')
                        resetDropzone()
                        err_ext = true;
                        reloadDropzone()
                        return;
                    }
                } else {
                    if (file.size > maxfilesizeGlb) {
                        toastrError(prompt_error_sile_file, 'Error')
                        resetDropzone()
                        err_ext = true;
                        reloadDropzone()
                        return;
                    }
                }
            }
            if (err_ext) return;
            if ((!tempData) && file.type !== "text/csv") {
                arr_filter.push({ name: _name, file: undefined, data: undefined })
                tempData = arr_filter.find(ar => ar.name === _name)
            }
            if (file.type !== "application/json" && file.type !== 'text/csv') {
                universeFiles.push({ name: file.name, file: file })
                setUniverseFiles([...universeFiles])
                if ((_extension === 'glb' || _extension === 'GLB' || _extension === 'mp4' || _extension === 'MP4')) {
                    tempData['file'] = file.name;
                } else {
                    tempData['preview'] = file.name;
                    if (!tempData['file']) {
                        tempData['file'] = file.name
                    }
                }


            } else if (file.type === "application/json") {
                if (!onlyJson && !onlyCsv) {
                    onlyJson = true;
                }
                if (onlyCsv) {
                    toastrError(prompt_csv_no_json, 'Error')
                    return;
                }

                const data = await file.text()
                try {
                    const parseJson = JSON.parse(data);
                    tempData['data'] = parseJson;
                }
                catch (error) {
                    toastrError('malformed json file', 'Error')

                }

            } else if (file.type === 'text/csv') {
                if (!onlyJson && !onlyCsv) {
                    onlyCsv = true;
                }
                if (onlyJson) {
                    toastrError(prompt_json_no_csv)
                    return;
                }
                const text = await file.text()
                if (dataFromCsv.length > 0) {
                    toastrError('You have already uploaded a csv file', 'Error')
                    return;
                } else {
                    const data = [...csvFileToArray(text)];
                    if (data.length === 0) {
                        setLoading(false)
                        return;
                    }
                    dataFromCsv = data
                    setdataFromCsv([...dataFromCsv]);
                    actualFileLength = actualFileLength + 1;
                    setActualFileLength(actualFileLength);
                    csvFileConfiguration(data, acceptedFiles)
                    return;
                }
            }
            console.log('err_ext', err_ext);
            if (err_ext) return;
            _hanldeAddAssetTodb(_name, tempData, index)


        });
        if (err_ext) return;
        setdataloader(false);
    }, [acceptedFiles])


    const csvFileConfiguration = (dataFromCsv, acceptedFiles) => {
        totalFiles = dataFromCsv.length
        setTotalFiles(totalFiles);
        actualFileLength = 0;
        setActualFileLength(actualFileLength)
        pairFiles = [];
        setPairFiles(pairFiles)
        if (dataFromCsv.length > 0 && acceptedFiles.length > 0) {
            let arr_filter = [];
            dataFromCsv.forEach(_data => {
                let tempData = {};
                const filename = _data.neefter_image.split('.')[0];


                tempData['name'] = _data.neefter_name;
                // tempData['file'] = acceptedFiles.find(file => file.name === _data.neefter_image);
                tempData['file'] = _data.neefter_image

                if (_data.neefter_preview_file) {
                    tempData['preview'] = _data.neefter_preview_file
                }
                tempData['data'] = csvColumnFilter(_data.neefter_name);
                if (!!tempData.file && !!tempData.data) {
                    _hanldeAddAssetTodbCsv(filename, tempData)
                } else {
                    toastrError(prompt_csv_column_image, 'Error')
                }
            })

        }

    }

    const _hanldeAddAssetTodb = async (_name, _asset, index) => {
        console.log('_hanldeAddAssetTodb');
        const asset = pairFiles.findIndex(pf => pf.name === _name)
        if (asset < 0) {
            pairFiles.push(
                {
                    name: _name,
                    data: _asset.data,
                    file: _asset.file,
                    preview: _asset.preview
                }
            )
        } else {
            pairFiles[asset] = {
                name: _name,
                data: _asset.data,
                file: _asset.file,
                preview: _asset.preview
            }
        }

        setPairFiles([...pairFiles])
        console.log('onlyCsv', onlyCsv);
        if (!onlyCsv) {
            setTotalFilesChecked(index)
            console.log('_asset', _asset);
            if (!!_asset.file && !!_asset.data && !!_asset.preview) {
                if (_asset.file !== _asset.preview) {
                    actualFileLength = actualFileLength + 3;
                    setActualFileLength(actualFileLength);
                } else {
                    actualFileLength = actualFileLength + 2;
                    setActualFileLength(actualFileLength);
                }
            } else if (!!_asset.file && !!_asset.data) {

                actualFileLength = actualFileLength + 2;
                setActualFileLength(actualFileLength);
            }
        }

    }
    const _hanldeAddAssetTodbCsv = async (_name, _asset) => {
        const asset = pairFiles.findIndex(pf => pf.name === _name)
        if (asset < 0) {
            pairFiles.push(
                {
                    name: _asset.name,
                    data: _asset.data,
                    // file: await toBase64(_asset.file),
                    file: _asset.file,
                    preview: _asset.preview
                }
            )
        } else {
            pairFiles[asset] = {
                name: _asset.name,
                data: _asset.data,
                // file: await toBase64(_asset.file),
                file: _asset.file,
                // preview: await toBase64(_asset.preview),
                preview: _asset.preview
            }
        }
        setPairFiles(pairFiles)
        actualFileLength = actualFileLength + 1;
        setActualFileLength(actualFileLength);
    }
    useEffect(() => {
        if (pairFiles.length > 0) {
            const search = setTimeout(async () => {

                const check = pairFiles.filter(pf => !(!!pf.file && !!pf.data))

                if (check.length > 0) {
                    toastrError(prompt_json_pair_image, 'Error');
                    console.error(prompt_json_pair_image)
                    if (!modalMode) setLoading(false)
                    return;
                } else {
                    const extension = pairFiles[0].data.image.split('.')[1];
                    const checkExtension = pairFiles.filter(pf => pf.data.image.split('.')[1] !== extension);
                    if (checkExtension.length > 0) {
                        toastrError(prompt_different_format_asset, 'Error');

                        if (!modalMode) setLoading(false)
                        return;
                    }
                }
            }, 3000);
            return () => clearTimeout(search)
        }
    }, [pairFiles])

    useEffect(() => {
        console.log('actualFileLength', actualFileLength);
        console.log('totalFiles', totalFiles);
        if (totalFiles > 0) {
            const advance = parseInt(((100 / totalFiles) * actualFileLength).toFixed(0), 0);
            console.log('advance', advance);


            // if (actualFileLength === totalFiles - 10) {
            //     endPass()
            // }
            if (advance === 100) {
                onAssetFull(true);
                if (!modalMode) setLoading(false);
                segmentTrack(`Assets uploaded`)
                toastrSuccess('Files uploaded succesfully', '¡Success!')
                endPass()

            }
            setCompleted(advance)
        }
    }, [actualFileLength])

    let endPass = async () => {
        console.log('pairFiles', pairFiles);
        const check = pairFiles.filter(pf => !(!!pf.file && !!pf.data))
        if (check.length > 0) {
            toastrError(prompt_json_pair_image, 'Error');
            console.error(prompt_json_pair_image)
            return;
        } else {
            try {
                if (modalMode) {
                    onAssetLoaded(pairFiles)
                    setLoading(false)
                } else {
                    console.log('universeFiles', universeFiles);
                    setLoading(false)
                    onAssetLoaded(pairFiles)
                }

            } catch (err) {
                console.log('err', err);
                setLoading(false)
            }

        }

    }

    let csvColumnFilter = (name) => {
        const selected = dataFromCsv.find(data => data.neefter_name === name);
        if (!selected) return undefined;
        let value = {
            name: selected.neefter_name,
            symbol: selected.neefter_symbol,
            description: selected.neefter_description,
            seller_fee_basis_points: selected.neefter_seller_fee_basis_points,
            image: selected.neefter_image,
            external_url: selected.neefter_external_url,
            preview: selected.neefter_preview_file,
            edition: selected.neefter_creator_1_share,
            attributes: [
            ],
            properties: {
                creators: [
                    {
                        address: selected.neefter_creator_1_address,
                        share: selected.neefter_creator_1_share,
                    },
                    {
                        address: selected.neefter_creator_2_address,
                        share: selected.neefter_creator_2_share,
                    },
                    {
                        address: selected.neefter_creator_3_address,
                        share: selected.neefter_creator_3_share,
                    }

                ]
            }
        }
        for (const key in selected) {
            if (key.substring(0, 7) !== 'neefter') {
                value.attributes.push({
                    trait_type: key,
                    value: selected[key],
                })
            }
        }
        return value;
    }

    const csvFileToArray = csv => {
        console.log('csv.....................', csv);
        try {
            var lines = csv.split("\n");
            var result = [];
            var regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
            var headers = lines[0].split(regex);

            headers.forEach((_header, index) => {
                headers[index] = _header.toLowerCase().trim();
            });

            for (var i = 1; i < lines.length; i++) {
                var obj = {};
                var currentline = lines[i].split(regex);

                if (currentline.length !== headers.length) {
                    toastrError('Malformed csv', 'Error')
                    console.error('Malformed csv', currentline)
                    return [];
                }
                for (var j = 0; j < headers.length; j++) {

                    // if (currentline[j] === undefined) currentline[j] = '';

                    if (currentline[j][currentline[j].length - 1] === '\r') {

                        currentline[j] = currentline[j].slice(0, currentline[j].length - 1)

                    }
                    if (currentline[j][0] === '"' && currentline[j][currentline[j].length - 1] === '"') {

                        currentline[j] = currentline[j].slice(1, currentline[j].length - 1)


                    }
                    obj[headers[j]] = currentline[j];
                }

                result.push(obj);
            }

            const check = result.filter(res => !!res.neefter_name
                && !!res.neefter_image
            )
            if (check.length !== result.length && check.length > 0) {
                console.error(prompt_csv_column_image, check);
                toastrError(prompt_csv_column_image, 'Error');
                return [];

            }

            return result;
        } catch (error) {
            console.log('err', error);
        }

    }

    return (
        <div style={{ width: '100%' }}>
            <div {...getRootProps({ className: "collections-create-form-upload-drop" })}>
                <input className="input-zone" {...getInputProps()} />

                <div className='collections-create-form-upload-drop-ico'>
                    <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                </div>
                {
                    isDragActive ? (

                        <p className='collections-create-form-upload-drop-title'>
                            Release to drop the files here
                        </p>
                    ) :
                        <p className='collections-create-form-upload-drop-title'>
                            Drop files here or click to <br /> upload your assets
                        </p>
                }
                <p className='collections-create-form-upload-drop-subtitle'>
                    Not sure what to do?<br /> Head to our <span> help section</span>
                </p>
            </div>
            <div>
                <ProgressBar key={'progress-1'} bgcolor={bgcolor} completed={completed} />
            </div>
            {
                dataLoader &&
                <div className='collections-create-form-upload-drop-subtitle'>
                    Validating assets
                </div>
            }

        </div>


    );
}
export default Dropzone;
