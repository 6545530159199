import toastr from 'toastr'

export function toastrSuccess(element, title = '¡Success!') {
    // toastr.options = {
    //     "debug": false,
    //     "positionClass": "toast-bottom-full-width",
    //     "onclick": null,
    //     "fadeIn": 300,
    //     "fadeOut": 1000,
    //     "timeOut": 5000,
    //     "extendedTimeOut": 1000
    // }
    toastr.options = {
        "positionClass": "toast-bottom-right",
    }
    toastr.success(element, title)
}

export function toastrError(element, title = 'Error') {
    toastr.options = {
        "positionClass": "toast-bottom-right",
    }
    toastr.error(element, title)

}

export function toastrInfo(element, title = 'Info') {
    toastr.options = {
        "positionClass": "toast-bottom-right",
    }
    toastr.info(element, title)
}

export function toastrInfoPermanent(element, title = 'Info') {
    toastr.options = {
        "positionClass": "toast-bottom-right",
    }
    toastr.options.timeOut = 0;
    toastr.options.extendedTimeOut = 0;
    toastr.info(element, title)
}

export function toastClear() {
    toastr.clear()

}

