import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    DropdownItem,
    FormGroup,
    CustomInput,
} from "reactstrap"

import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"


const CheckboxContainer = (props) => {
    const { options, type, changeCheckedOption } = props;
    let handleOnChange = (item) => {
        changeCheckedOption(type, item)
    }


    return (
        <React.Fragment>

            <FormGroup >
                <div className="collections-token-form-border-group">

                    {options.map(
                        (item, key) => (
                            <div className="collections-token-form-border-checkbox" key={'border-checkbox' + type + key} id={'border-checkbox-id' + type + key}>
                                <CustomInput className='checkbox' type="checkbox" label={item.trait_variation_name} key={'checkbox' + type + key} id={'checkbox-id' + type + key}
                                    onChange={() => handleOnChange(item)} checked={item.checked} />
                                <p className='counter'>{item.quantity}</p>
                            </div>
                        )
                    )}

                </div>
            </FormGroup>



        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(CheckboxContainer))