import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Button
} from "reactstrap"
import { GeneralContext } from '../../context/'

import { withNamespaces } from "react-i18next"

import image_source from '../../assets/images/Variant2.png'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { loadState } from "../../store/localStorage"
import Twtheader from "./components/header"

import Modal from "../../components/Global/modals"
import { toastrSuccess } from '../../helpers/Toastr'


const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [userImg, setUserImg] = useState();
    const [screen_name, setScreenName] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [followers_count, setFollowersCount] = useState();
    const [friends_count, setFriendsCount] = useState();
    const [location, setLocation] = useState();
    const [created, setCreated] = useState();
    const [showModal, setShowModal] = useState(false)
    const [currentAccount, setCurrentAccount] = useState(null);
    const [loadMumbaiPayment, setLoadMumbaiPayment] = useState(false)

    useEffect(() => {
        if (loadState() && loadState().Auth) {
            if (loadState().Auth.profile && loadState().Auth.profile.twitter_info) {
                const { profile_image_url, screen_name, name, description, followers_count, friends_count, location, created_at } = loadState().Auth.profile.twitter_info
                setUserImg(profile_image_url)
                setScreenName(screen_name)
                setName(name)
                setDescription(description)
                setFollowersCount(followers_count)
                setFriendsCount(friends_count)
                setLocation(location)
                const date = new Date(created_at)
                setCreated(date.getFullYear())
            }
        }
    }, [])

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }
    const connectWalletButton = () => {
        return (
            <Button
                type="button"
                color="primary"
                className=" theme-button-secundary"
                onClick={(e) => {
                    e.preventDefault()
                    connectWalletHandler()
                    segmentTrack(`Connect wallet to Confirm transaction`)
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        )
    }
    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You’re about to mint your twitter pfp on the Polygon blockchain,
                now, let’s confirm the transaction, but don’t worry, we’ll pay for the gas. </p>

            <div className='card-picture'>

                <img style={{ width: '100%' }} alt={'twitter img'} src={userImg || image_source} />
            </div>





        </div>
    </section >


    const footer = <div className='deploy-modal-footer'>


        <div>
            {currentAccount ?
                <div>
                    <Button
                        type="button"
                        color="primary"
                        className=" theme-button-secundary"
                        onClick={(e) => {
                            e.preventDefault()
                            confirmTransaction()
                            segmentTrack(`tweeter confirm transaction`)
                        }}
                    >
                        {props.t(`Confirm transaction`)}
                        {loadMumbaiPayment &&
                            <div className='min-loader'>
                                <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                    <div></div><div><div></div></div>
                                </div></div>
                            </div>
                        }
                    </Button>
                </div>
                : connectWalletButton()
            }
        </div>

    </div>
    const confirmTransaction = () => {
        if (!currentAccount) return;
        setLoading(true)
        setLoadMumbaiPayment(true)
        const body = {}
        DataService.postClaimTwitter(currentAccount).then(response => {
            
            toastrSuccess('Claim')
            props.history.push('/')
        })
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            
            setCurrentAccount(accounts[0]);
        } catch (err) {
            
        }
    }


    return (
        <React.Fragment>
            {
                showModal && __renderPOView()
            }
            <section className='twt'>
                <div className='twt-container'>
                    <Twtheader active={3}></Twtheader>
                    <div className='twt-mint'>
                        <div className='twt-mint-body'>
                            <div className='twt-mint-body-img'>
                                <img src={userImg || image_source}></img>
                            </div>
                            <div className='twt-mint-body-contain'>
                                <span className='twt-mint-body-contain-top'>
                                    @{screen_name}
                                </span>
                                <span className='twt-mint-body-contain-title'>
                                    {description}
                                </span>
                                {/* <span className='twt-mint-body-contain-title'>
                                    Powered by Neefter
                                </span> */}
                                <span className='twt-mint-body-contain-strong'>
                                    Attributes
                                </span>
                                <div className='twt-mint-body-contain-cards'>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Account
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            {name}
                                        </div>
                                    </div>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Followers
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            {followers_count}
                                        </div>
                                    </div>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Joined
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            {created}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='twt-mint-body-contain-cards'>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Powered by
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            Neefter
                                        </div>
                                    </div>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Method
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            Tracked
                                        </div>
                                    </div>
                                    <div className='twt-mint-body-contain-cards-item'>
                                        <div className='twt-mint-body-contain-cards-item-subtitle'>
                                            Aperture
                                        </div>
                                        <div className='twt-mint-body-contain-cards-item-title'>
                                            f/4
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='twt-mint-connect'>

                            <Button
                                type="button"
                                color="primary"
                                id="btn-send"
                                className=" theme-button-third"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`Tweet connect wallet and mint`)
                                    setShowModal(true)
                                }}
                            >
                                {props.t(`Connect wallet and mint`)}
                            </Button>
                        </div>





                    </div>
                </div>
            </section>
        </React.Fragment >
    )

}
export default withRouter(withNamespaces()(Index))
