import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import image_tempo from '../../assets/images/ImageBox.png'
import { GeneralContext } from '../../context/'
import { useParams } from 'react-router-dom'
import { segmentTrack } from '../../helpers/Segment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faHome } from '@fortawesome/free-solid-svg-icons'
import {
    Container
} from "reactstrap"
import DataService from '../../helpers/DataService'
import { b64toBlob } from '../../helpers/utils'
const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, currentProject } = generalContext
    let [status, setStatus] = useState('yellow')
    let [status_data, set_status_data] = useState('');
    const { collection_id } = useParams();
    let [collection, setCollection] = useState();
    let [project_id, setProjectId] = useState();
    let [subdomain, setSubdomain] = useState('');
    let [full_subdomain, setFullSubdomain] = useState('');
    let [is_deployed, setIsDeployed] = useState(false);
    const [ordinalInfo, setOrdinalInfo] = useState()

    let intervalId;
    const mempool = 'https://mempool.space/es/tx/'
    let [mempool_url, set_mempool_url] = useState('');
    let [review_url, set_review_url] = useState('');
    let [item_image_id, setItemImage] = useState('')

    const [showImage, setShowImage] = useState(true)
    useEffect(() => {
        // This code will run when the component mounts

        // Specify cleanup logic to run when the component is unmounted
        return () => {
            // This code will run when the component is unmounted or destroyed
            console.log('Component is unmounted or destroyed.');
            if (intervalId) {
                clearInterval(intervalId)
            }
            // You can perform any necessary cleanup tasks here
        };
    }, []); // The empty dependency array means this effect only runs on mount and unmount



    useEffect(() => {
        if (!currentProject) return;
        setProjectId(currentProject.id)
    }, [currentProject])
    useEffect(() => {
        getCollection()
    }, [collection_id])
    const getCollection = () => {
        if (collection_id) {
            DataService.getCollectionById(collection_id).then(response => {

                const { brackground, candy_machine_address, csv, description, external_url,
                    family, favicon, high_fidelity_icon, id, image_id, is_candy_machine, is_deployed,
                    is_deploying, launch_date, logo, name, network_id, organization_id, price, project_id,
                    slug, subdomain, symbol, seller_fee_basis_points,
                    creators, blockchain, is_locked, type_id
                } = response.data;
                setCollection(response.data)
                setIsDeployed(is_deployed)
                setSubdomain(subdomain)
                setFullSubdomain(`https://${subdomain}.neefter.com`)

                if (!is_locked && ((type_id + '' !== '3') && (type_id + '' !== '4'))) {
                    status = 'draft';
                    setStatus('draft')
                } else {
                    if (!is_deployed && !is_deploying) {
                        status = 'red';
                        setStatus('red')
                    } else if (!is_deployed && is_deploying) {
                        status = 'yellow';
                        setStatus('yellow')
                    } if (is_deployed && !is_deploying) {
                        status = 'green';
                        setStatus('green')
                    }
                }
                getOrdinalInfo()

                setBreadcrumb({
                    icon: '',
                    parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    parentLink: `/collections`,
                    item: props.t(name),
                    extra: getExtraBreadcrumb(status)
                })
            })
        }
    }
    const getOrdinalInfo = () => {
        intervalId = window.setInterval(function () {
            DataService.getBtcInscribe(collection_id).then(response => {

                const { address, created_at, filename, payment_address, rate, send_to, uuid, webhook, status, tx_id, base64_file } = response.data

                if (!item_image_id) {
                    check_base64_file(base64_file)
                }

                setOrdinalInfo(response.data)
                if (payment_address) {
                    const _address = payment_address.substring(0, payment_address.length - 2)
                    set_mempool_url(`${mempool}${tx_id}`)
                }
                const _status = getStatusData(response.data)
                set_status_data(_status)
            })
        }, 5000);

    }

    function getStatusData(status_data) {
        const date = new Date(status_data.created_at);
        let current_date = new Date();
        let difference = current_date.getTime() - date.getTime();
        let hoursMilli = 1000 * 60 * 60 * 24; // milliseconds * seconds * minutes

        if (!status_data.is_sent && !(Math.abs(difference) < hoursMilli)) {
            return 'Not paid'
            // const OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)

            // 
            // 


            // if (date.getTime() < OneDay) {
            //     // The yourDate time is less than 1 days from now
            //     return 'Payment pending'
            // }
            // else if (date.getTime() >= OneDay) {
            //     // The yourDate time is exactly/more than 1 days from now
            //     return 'Not paid'
            // }

        } else {
            if (status_data.status === 0) {
                return 'Payment pending'
            } else if (status_data.status === 1) {
                return 'Processing'
            } else if (status_data.status === 2) {
                return 'Inscribing'
            } else if (status_data.status === 3) {
                return 'Inscribed'
            }
        }

    }


    function getExtraBreadcrumb(status) {
        return <div className='collections-settings-row-title' onClick={(e) => {
            props.push(`Ordinals`)
        }}>
            {
                status === 'yellow' &&
                <div className='collections-settings-tab-banner'>

                    <div className='collections-settings-tab-banner-dot yellow'>
                    </div>
                    Processing
                </div>
            }
            {
                status === 'red' &&
                <div className='collections-settings-tab-banner'>

                    <div className='collections-settings-tab-banner-dot red'>
                    </div>
                    Error
                </div>
            }
            {
                status === 'green' &&
                <div className='collections-settings-tab-banner'>
                    <div className='collections-settings-tab-banner-dot green'>
                    </div>
                    Procecced
                </div>
            }
        </div>
    }

    const setItemImageValidated = (image_id) => {
        if (!image_id) {
            if (item.type + '' === 6) {
                setItemImage('/assets/images/ordinals.png')
                setShowImage(true)
            } else {
                setItemImage(undefined)
            }
        } else {
            setItemImage(image_id)

            const splt_img = !!image_id ? image_id.split('.') : []

            const file_type = splt_img.length > 0 ? splt_img[splt_img.length - 1] : null
            if (!!file_type && file_type.toLowerCase() === 'mp4') {
                setShowImage(false)
            } else {
                setShowImage(true)
            }
        }

    }
    const check_base64_file = async (base64) => {
        if (!base64) return;
        const base64_file = b64toBlob(base64);

        if (base64_file) {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(base64_file);

            setItemImageValidated(imageUrl)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-ordinals-view'>
                        <div className='collections-ordinals-view-contain'>
                            <div className='collections-ordinals-view-contain-img'>
                                {
                                    (item_image_id && showImage) &&
                                    <img alt={'file'} src={item_image_id || '/assets/images/avatar-2.png'} />
                                }
                                {
                                    (item_image_id && !showImage) &&
                                    <video id='video' alt={'file'} src={item_image_id} className='collections-token-resume-card-video' style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                        muted playsInline autoPlay={true}>
                                        {/* <source type="video/mp4" /> */}
                                        Your browser does not support the video tag.
                                    </video>
                                }
                                {
                                    !item_image_id &&
                                    <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                                }

                            </div>
                            <div className='collections-ordinals-view-contain-body'>
                                <p className='collections-ordinals-view-contain-body-title'>
                                    {collection?.name}
                                </p>

                                <div className='collections-ordinals-view-contain-body-detail'>
                                    <h2>
                                        {
                                            !status_data &&
                                            <b>Waiting</b>
                                        }
                                        {
                                            status_data === 'Not paid' &&
                                            <b>Expired</b>
                                        }
                                        {
                                            status_data === 'Payment pending' &&
                                            <b>Waiting on payment</b>
                                        }
                                        {
                                            status_data === 'Processing' &&
                                            <b>Procesing payment... (1/3)</b>
                                        }
                                        {
                                            status_data === 'Inscribing' &&
                                            <b>Inscribing ordinal... (2/3)</b>
                                        }
                                        {
                                            status_data === 'Inscribed' &&
                                            <b>Ordinal incoming!... (3/3)</b>
                                        }

                                    </h2>
                                    {
                                        status_data === 'Not paid' &&
                                        <div>
                                            <p className='mb-6'>This invoice has expired</p>
                                            <p className='mb-6'>If you tried to send a payment, it has not yet been accepted by the network. We have not yet received your funds.</p>
                                            <p className='mb-6'>Order ID: {collection_id}</p>
                                        </div>
                                    }
                                    {
                                        status_data === 'Payment pending' &&
                                        <div className='mb-6'>
                                            <p className='mb-6'>Order ID: {collection_id}</p>
                                            <p>Network Fees: {ordinalInfo.network_fee} sats</p>
                                            <p>Service Fee: {ordinalInfo.service_fee} sats</p>
                                            <p className='mb-6'>Postage: {ordinalInfo.postage} sats</p>
                                            <p className='mb-6'>Total amount: {ordinalInfo.total_cost} Sats</p>

                                        </div>
                                    }
                                    {
                                        status_data === 'Processing' &&
                                        <div>
                                            <p className='mb-6'>Your payment is being processed by the Bitcoin blockchain.</p>
                                            <p className='mb-6'>Order ID: {collection_id}</p>
                                            <p>Network Fees: {ordinalInfo.network_fee} sats</p>
                                            <p>Service Fee: {ordinalInfo.service_fee} sats</p>
                                            <p className='mb-6'>Postage: {ordinalInfo.postage} sats</p>
                                            <p>Total amount: {ordinalInfo.total_cost} Sats</p>
                                            <div className='collections-ordinals-view-contain-body-status mt-12 mb-6'>
                                                {
                                                    (!!mempool_url && ordinalInfo.tx_id) &&
                                                    <a href={`${mempool_url}`} target='_blank'>{ordinalInfo.tx_id}</a>
                                                }
                                                {/* <p>Processing</p> */}
                                            </div>
                                        </div>
                                    }
                                    {
                                        status_data === 'Inscribing' &&
                                        <div>
                                            <p className='mb-6'>Your payment is complete.</p>
                                            <p className='mb-6'>Your ordinal is being processed and about to be inscribed.</p>
                                            <p className='mb-6'>Order ID: {collection_id}</p>
                                            {/* <p className='review-fragment mt-12 mb-6'>Inscribing</p> */}
                                        </div>
                                    }
                                    {
                                        status_data === 'Inscribed' &&
                                        <div>
                                            <p className='mb-6'>Your payment is complete.</p>
                                            <p className='mb-6'>Your inscription have been submitted to the mempool.</p>
                                            <p className='mb-6'>Order ID: {collection_id}</p>
                                            <div className='collections-ordinals-view-contain-body-status mt-12 mb-12'>
                                                {
                                                    (!!mempool_url && ordinalInfo.tx_id) &&
                                                    <a href={`${mempool_url}`} target='_blank'>{ordinalInfo.tx_id}</a>
                                                }
                                                {/* <p>Submitted</p> */}
                                            </div>
                                            {/* {
                                                !!review_url &&
                                                <a href={`${review_url}`} target='_blank' className='review-fragment mt-12 mb-6'>Review your ordinal</a>
                                            } */}
                                        </div>
                                    }

                                </div>
                                {/* <p className='collections-ordinals-view-contain-body-subtitle'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan rhoncus nunc vel egestas. Pellentesque convallis varius mollis. Quisque quis orci sed sem malesuada egestas sit amet eget metus.
                                </p>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Type:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        IMAGE/PNG
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Content Length:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        3439
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Sat:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        1914020128072764
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Sat Rarity:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        Common
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        ID:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        835B46FD3B935EDB23757FDCF03AD357C44EC72E0F4DAFD19EF7740EA367053FI0
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Inscribed by:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        BC1PDAS5F64K5NJ7V0SDZCSSA3767WGY48K5W9Q3EU0KN2QJZKCCVEWSTUDUMY
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Owned by:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        coming soon hirosystems/ordinals-api#23
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Last transfer date:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        FEBRUARY 20, 2023 AT 5:28:00 PM
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Inscription height:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        777563
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Inscription fee:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        999
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Inscription txnid:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        835B46FD3B935EDB23757FDCF03AD357C44EC72E0F4DAFD19EF7740EA367053F
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Output:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        835B46FD3B935EDB23757FDCF03AD357C44EC72E0F4DAFD19EF7740EA367053F
                                    </div>
                                </div>
                                <div className='collections-ordinals-view-contain-body-rows'>
                                    <div className='collections-ordinals-view-contain-body-rows-title'>
                                        Offset:
                                    </div>
                                    <div className='collections-ordinals-view-contain-body-rows-text'>
                                        0
                                    </div>
                                </div>
                                <span className='collections-ordinals-view-contain-explorer'>
                                    View on explorer
                                </span> */}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default withRouter(withNamespaces()(Create))
