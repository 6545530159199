import React, { useContext, useEffect, useState } from 'react';

import { withNamespaces } from "react-i18next";
import { withRouter } from 'react-router-dom';
import {
    Dropdown, DropdownMenu, DropdownToggle, FormGroup
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames';

const Preview = (props) => {
    const { value, setValue, options, index } = props
    // togle 
    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }
    const [selectedItem, setSelectedItem] = useState()

    useEffect(() => {
        console.log('value');
        if (!selectedItem) {
            const _find = options.find(item => item.value === value)
            setSelectedItem(_find)
        }
    }, [value])
    return (
        <React.Fragment>
            <FormGroup>
                <Dropdown
                    style={{ width: '100%' }}
                    isOpen={menu}
                    toggle={toggle}
                    className="d-inline-block"
                >
                    <DropdownToggle
                        className={cn('input-drop-white', value + '' === '1' ? 'input-drop-green' : 'input-drop-red')}
                        id={'mix_input' + index}
                    >
                        <span >
                            {selectedItem && (
                                <span>{selectedItem.name}</span>
                            )}
                        </span>
                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </DropdownToggle>
                    <DropdownMenu right className='input-drop-white-item'>
                        {options.map(
                            (item, key) => (
                                <div key={key} className={cn('dropdown-item', item.value + '' === '1' ? 'green' : 'red')} onClick={() => {
                                    setSelectedItem(item)
                                    setValue(item.value, index)
                                    setMenu(false)
                                }}>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Preview))
