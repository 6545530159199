import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import {
    Button, Col, Container, FormGroup,
    Input, Label, Row
} from "reactstrap"
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { useParams } from 'react-router-dom'
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';
import TabHeaderMenu from './components/tab-header-menu'
import Modal from "../../components/Global/modals"

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { segmentTrack } from '../../helpers/Segment'
import image_tempo from '../../assets/images/txticon.png'
import { faFileAlt, faHome } from '@fortawesome/free-solid-svg-icons'
import * as btc from '@scure/btc-signer';
import { getAddress, signTransaction } from 'sats-connect'
import { setUserOrdinal } from '../../store/actions'
import DataService from '../../helpers/DataService'
import { toBase64, base64ToFile } from '../../helpers/utils'
import { loadState } from "../../store/localStorage"
import { toastrSuccess, toastrError } from '../../helpers/Toastr'
import { hex, base64 } from '@scure/base'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext
    const { collection_id } = useParams();

    const disableAll = false;
    const [showModal, setShowModal] = useState(false)
    const [selectedTransaction, setSelectedTransaction] = useState()
    const [custom_price, setCustomPrice] = useState()
    const [loadPayment, setLoadPayment] = useState(false)
    const [status_view, set_status_view] = useState()


    /*images*/
    const [name, setName] = useState('')
    const [file, setFile] = useState()
    const [description, setDescription] = useState('')

    /******************** btc payment methods */
    const [selectedAddressOrdinals, setSelectedAddressOrdinals] = useState('')
    const [selectedAddressPayment, setSelectedAddressPayment] = useState('')
    const [selectedPublicKeyOrdinals, setSelectedPublicKeyOrdinals] = useState('')
    const [selectedPublicKeyPayment, setSelectedPublicKeyPayment] = useState('')
    let [addressPayment, setAddressPayment] = useState('')
    const [halfHourFee, setHalfHourFee] = useState(0)

    const netUsed = 'Mainnet'

    const [labelEditor, setLabelEditor] = useState('')
    const [wordCount, setWordCount] = useState(0)
    let [sizeInKB, setSizeInKB] = useState('')
    const [vBytesCount, setvBytesCount] = useState('')
    let [global_total_cost, setTotalCost] = useState(0)
    const [sizeInBytes, setSizeInBytes] = useState(0)
    const [inscriptionCost, setInscriptionCost] = useState(0)
    const [inscription_sats, setInscriptionSats] = useState(0)
    const [network_fee, setNetworkFee] = useState(0)
    const [service_fee, setServiceFee] = useState(0)
    const [satdollarprice, setSatDollarPrice] = useState(0)

    const [transactions, setTransactions] = useState([])
    const filler_price = 1_000;

    const fillData = async () => {
        setLoading(true)
        const dollarpriceurl = 'https://price.jup.ag/v4/price?ids=WBTC'
        const dollarresponse = await DataService.getPath(dollarpriceurl);
        console.log('dollarresponse', dollarresponse);
        const dollarprice = dollarresponse.data.data.WBTC.price;
        const satdollarprice = dollarprice / 100_000_000;
        setSatDollarPrice(satdollarprice)
        DataService.getBtcInscribeCostRates().then(async response => {
            console.log('response getBtcInscribeCostRates', response.data);
            const costrates = [];
            for (var key in response.data) {
                const body = {
                    size: sizeInKB,
                    rate: response.data[key]
                }
                const cost = await DataService.postBtcInscribeCost(body);
                console.log('cost' + key, cost);
                const { inscription_sats, service_fee, total_cost } = cost.data
                costrates.push({
                    title: key,
                    satsvbyte: response.data[key],
                    price: (satdollarprice * (total_cost + filler_price)).toFixed(2),
                    sats: inscription_sats + filler_price,
                    fee: service_fee,
                })
            }
            console.log('costrates', costrates);
            let topcostrates = [];
            topcostrates.push(costrates.find(rate => rate.title === "economyFee"))
            topcostrates.push(costrates.find(rate => rate.title === "halfHourFee"))
            topcostrates.push(costrates.find(rate => rate.title === "fastestFee"))

            // let topcostrates = costrates.sort((a, b) => {
            //     return b.satsvbyte - a.satsvbyte;
            // }).splice(0, 3)
            // topcostrates = topcostrates.sort((a, b) => {
            //     return a.satsvbyte - b.satsvbyte;
            // })
            topcostrates[0]['color'] = '#34D399'
            topcostrates[1]['color'] = '#B6A8F8'
            topcostrates[2]['color'] = '#A066FF'
            topcostrates[0]['within'] = 'Within an hour'
            topcostrates[1]['within'] = 'Within half an hour'
            topcostrates[2]['within'] = 'Within a few'
            topcostrates.push({
                title: 'Custom Fee',
                satsvbyte: 'Custom',
                color: '#6B7280',
                price: 0,
                sats: 0,
                fee: '0.00',
                within: 'Based on selected fee',
            })

            console.log('topcostrates', topcostrates);
            setTransactions(topcostrates)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
        DataService.getBtcInscribeCostPayment().then(response => {
            console.log('response getBtcInscribeCostPayment', response.data);
            addressPayment = response.data.address
            setAddressPayment(response.data.address)
        }).catch(err => {
            console.log(err);
            setLoading(false)

        })
    }


    useEffect(() => {
        if (custom_price !== undefined) {
            transactions[3].satsvbyte = custom_price;
            setHalfHourFee(custom_price)
        }
    }, [custom_price])


    useEffect(() => {
        console.log('halfHourFee', halfHourFee);
        if (halfHourFee === '' || halfHourFee === 'Custom' || !transactions[3]) return;
        // transactions[3].price = 0
        // transactions[3].sats = 0
        // transactions[3].fee = 0
        // transactions[3].satsvbyte = 'Custom'

        setInscriptionCost(halfHourFee * parseFloat(vBytesCount))
        calculateCost()
    }, [halfHourFee])

    useEffect(() => {
        if (!!labelEditor) {
            console.log('halfHourFee', halfHourFee);
            setWordCount(labelEditor.trim().split(/[\0\s]+/g).length)
            const sizeInBytes = new Blob([labelEditor]).size;
            setSizeInBytes(sizeInBytes)
            sizeInKB = (sizeInBytes / 1000).toFixed(2)
            setSizeInKB(sizeInKB)
            const num_inputs = 1;
            const num_outputs = 2;
            const virtual_size = 144.5 + (num_inputs * 148) + (num_outputs * 34)
            const vBytesamount = virtual_size + Number(sizeInBytes / 3.977)
            setvBytesCount(vBytesamount + '')
            let total_cost = Number(vBytesamount) * halfHourFee;
            const postage = 546;
            total_cost = total_cost + postage;
            total_cost = (total_cost + filler_price) * 1.1;
            // setInscriptionCost(Number(total_cost))

            // const sizeInBytes = new Blob([labelEditor]).size;
            // console.log('sizeInBytes', sizeInBytes);
            // setSizeInBytes(sizeInBytes)
            // sizeInKB = (sizeInBytes / 1024).toFixed(2)
            // console.log('sizeInKB', sizeInKB);
            // setSizeInKB(sizeInKB)
            // const vBytesamount = (sizeInBytes / 1024).toFixed(2)
            // setvBytesCount(vBytesamount)


            fillData()
        } else if (!!file) {
            _checkImage()
        }

    }, [labelEditor, file])


    const _checkImage = async () => {
        const blob = await base64ToFile(file);
        console.log('blob', blob);
        const sizeInBytes = blob.size;
        console.log('sizeInBytes', sizeInBytes);
        setSizeInBytes(sizeInBytes)
        sizeInKB = (sizeInBytes / 1000).toFixed(2)
        setSizeInKB(sizeInKB)
        const num_inputs = 1;
        const num_outputs = 2;
        const virtual_size = 144.5 + (num_inputs * 148) + (num_outputs * 34)
        const vBytesamount = virtual_size + Number(sizeInBytes / 3.977)
        setvBytesCount(vBytesamount + '')
        let total_cost = Number(vBytesamount) * halfHourFee;
        const postage = 546;
        total_cost = total_cost + postage;
        total_cost = (total_cost + filler_price) * 1.1;
        fillData()

    }
    const calculateCost = () => {
        const inscribeBody = {
            size: parseFloat(sizeInKB),
            rate: halfHourFee,
        }
        console.log('inscribeBody', inscribeBody);
        DataService.postBtcInscribeCost(inscribeBody).then(response => {
            if (response.data) {
                console.log('postBtcInscribeCost', response.data);
                const { inscription_sats, network_fee, service_fee, total_cost } = response.data

                setInscriptionSats(inscription_sats)
                setNetworkFee(network_fee)
                setServiceFee(service_fee)
                global_total_cost = total_cost;
                setTotalCost(global_total_cost)
                console.log('total_cost', total_cost);
                console.log('selectedTransaction.title', selectedTransaction.title);
                if (selectedTransaction.title === 'Custom Fee') {
                    transactions[3].price = (satdollarprice * (total_cost + filler_price)).toFixed(2);
                    transactions[3].sats = inscription_sats + filler_price;
                    transactions[3].fee = service_fee;
                    console.log('transactions[3]', transactions[3]);
                }
            }

        })
    }
    const saveThis = () => {
        console.log('intenta guardar esto');
        if (!sizeInKB) {
            console.log('sizeInKB needed');
            return
        }
        if (!selectedAddressPayment) {
            console.log('connect wallet first');
            return
        }
        signprothis()

    }

    const getAddressOptions = {
        payload: {
            purposes: ['ordinals', 'payment'],
            message: 'Address for receiving Ordinals and payments',
            network: {
                type: netUsed
            },
        },
        onFinish: async (response) => {
            console.log('getAddressOptions', response)
            if (response.addresses && response.addresses.length > 0) {
                setSelectedAddressOrdinals(response.addresses[0].address)
                setSelectedAddressPayment(response.addresses[1].address)
                setSelectedPublicKeyOrdinals(response.addresses[0].publicKey)
                setSelectedPublicKeyPayment(response.addresses[1].publicKey)
            }
        },
        onCancel: () => alert('Request canceled'),
    }
    const bitcoinTestnet = {
        bech32: 'tb',
        pubKeyHash: 0x6f,
        scriptHash: 0xc4,
        wif: 0xef,
    }

    // wrapped-segwit
    const signprothis = async () => {
        const tx = new btc.Transaction()
        console.log('selectedPublicKeyPayment', selectedPublicKeyPayment);
        const publicKey = hex.decode(selectedPublicKeyPayment)
        const p2wpkh = btc.p2wpkh(publicKey, netUsed === 'Testnet' ? bitcoinTestnet : undefined);
        const p2sh = btc.p2sh(p2wpkh, netUsed === 'Testnet' ? bitcoinTestnet : undefined);

        const global_total_cost_harcoded = global_total_cost

        /**
       
      Si un utxo, tiene un valor igual o mayor a mi cobro, usar ese utxo, pero en el value usar un valor a mi cobro
      Si, se van a usar mas de un utxo, 
       */
        // const mempoolAddress = '1KFHE7w8BhaENAswwryaoccDb6qcT6DbYY'
        const mempoolAddress = selectedAddressPayment

        const path = `https://mempool.space/api/address/${mempoolAddress}/utxo`
        const mempoolData = await DataService.getPath(path)
        console.log('mempoolData', mempoolData);
        if (mempoolData.data.length === 0) {
            console.error('no money')
            toastrError('no money')
            return;
        }
        const outputs = mempoolData.data;
        // Encontrar a uno mayor o igual
        const transactionSizeSingle =
            1 * 146 + 2 * 34 + 10 - 1;
        const feeSingle = transactionSizeSingle * halfHourFee;

        const selectedOutput = outputs.find((otp) => otp.value >= (global_total_cost_harcoded + feeSingle));
        console.log('selectedOutput', selectedOutput);
        let value_to_return = 0;
        let total_inputs_added = 0;
        // Si no existe  uno mayor o igual entrar a un ciclo
        if (!selectedOutput) {
            // Ordenar outputs de mayor a menor
            const ordered_outputs = outputs.sort((a, b) => {
                return b.value - a.value;
            });
            console.log('ordered_outputs', ordered_outputs);
            const total_input_values = ordered_outputs.reduce((acc, item) => acc + item.value, 0)
            console.log('total_input_values', total_input_values);
            if (total_input_values < global_total_cost_harcoded) {
                console.error('no money', total_input_values)
                toastrError("Not Enough Funds: Please ensure your connected wallet has a sufficient balance")
                return;
            }
            let currentValue = 0;
            let lasttx;
            let index = 0;
            const transactionSizeSingle = (index + 1) * 146 + 2 * 34 + 10 - 1;
            let feeWhile = transactionSizeSingle * halfHourFee;
            while (currentValue < (global_total_cost_harcoded + feeWhile) && index <= ordered_outputs.length) {
                console.log(index, ordered_outputs[index].txid);
                console.log('addinput ----' + index, {
                    txid: ordered_outputs[index].txid,
                    index: ordered_outputs[index].vout,
                    witnessUtxo: {
                        script: p2sh.script,
                        amount: BigInt(ordered_outputs[index].value),
                    },
                    redeemScript: p2sh.redeemScript,
                });
                total_inputs_added = total_inputs_added + 1;
                tx.addInput({
                    txid: ordered_outputs[index].txid,
                    index: ordered_outputs[index].vout,
                    witnessUtxo: {
                        script: p2sh.script,
                        amount: BigInt(ordered_outputs[index].value),
                    },
                    redeemScript: p2sh.redeemScript,
                })
                currentValue = currentValue + ordered_outputs[index].value;
                value_to_return = currentValue - global_total_cost_harcoded;
                index = index + 1;
                const transactionSizeSingle = (index + 1) * 146 + 2 * 34 + 10 - 1;
                feeWhile = transactionSizeSingle * halfHourFee;
            }
            if (total_input_values < (global_total_cost_harcoded + feeWhile)) {
                console.error('no money', total_input_values)
                toastrError("Not Enough Funds: Please ensure your connected wallet has a sufficient balance")
                return
            }
        } else {
            console.log('selectedOutput.value', selectedOutput.value);
            total_inputs_added = 1;
            tx.addInput({
                txid: selectedOutput.txid,
                index: selectedOutput.vout,
                witnessUtxo: {
                    script: p2sh.script,
                    amount: BigInt(selectedOutput.value),
                },
                redeemScript: p2sh.redeemScript,
                witnessScript: p2sh.witnessScript,
                sighashType:
                    btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY,
            })
        }




        // You can add more inputs here as necessary

        // Add outputs
        const recipient = addressPayment
        // const changeAddress = selectedAddressOrdinals
        console.log('recipient', recipient);
        console.log('total_cost', global_total_cost);

        const transactionSize =
            total_inputs_added * 146 + 2 * 34 + 10 - total_inputs_added;
        const fee = transactionSize * halfHourFee;


        tx.addOutputAddress(recipient, BigInt(global_total_cost_harcoded), netUsed === 'Testnet' ? bitcoinTestnet : undefined)
        if (!!selectedOutput) {
            tx.addOutputAddress(selectedAddressPayment, BigInt(selectedOutput.value - fee - global_total_cost_harcoded))
        } else {
            tx.addOutputAddress(selectedAddressPayment, BigInt(value_to_return - fee))
        }

        // Generate the base64 encoded PSBT that can be 
        // passed to a compatible wallet for signing
        console.log('tx---------------------', tx)
        const psbt = tx.toPSBT(0)
        const psbtB64 = base64.encode(psbt)


        /**
            const outputs = await mempool/(address taproot)
            buscar un utxo igual o mayor la transferencia ::: outputs.find(ot => ot.precio >= total_cost)
            si no hay uno mayor, acomodarlos de mayor a menor, y agarrar los dos primeros, si no hay dos primeros, pues no hay dinero 
            error de saldo insuficiente, 
            por cada utxo que se utilice, se genera un input
    
    
         */

        // const _inputsToSign = [];
        // for (let index = 0; index < total_inputs_added; index++) {
        //     _inputsToSign.push(
        //         {
        //             address: selectedAddressPayment,
        //             signingIndexes: [index],
        //         },
        //     )

        // }
        // console.log('_inputsToSign', _inputsToSign);


        const signPsbtOptions = {
            payload: {
                network: {
                    type: netUsed,
                },
                message: "Sign Transaction",
                psbtBase64: psbtB64,
                broadcast: true,
                inputsToSign: [
                    {
                        address: selectedAddressPayment,
                        signingIndexes: [0],
                        sigHash:
                            btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY,
                    },
                ],
            },
            onFinish: (response) => {
                // alert(response.psbtBase64);
                console.log('response.psbtBase64', response);
                if (!!labelEditor) {
                    sendQueue()
                } else {
                    sendQueueImage()
                }
            },
            onCancel: () => {
                console.log('canceled')

            },
        };
        await signTransaction(signPsbtOptions).catch(err => console.log(err))

        /**
         No debería ser guardar, debería ser inscribe, el boton de inscribe debe de mandar a conectar, luego a pagar, alinear todo a la izquierda, y quitar el logo de meta
         */

    }


    const sendQueue = () => {
        const blob = new Blob([labelEditor], { type: 'plain/text' });
        console.log('blob', blob);
        const formData = new FormData()
        formData.append('upload_file', blob, 'ordinal.txt')

        const body = {
            payment_address: addressPayment, // address que regresa el back
            send_to: selectedAddressOrdinals, // address del usuario tipo ordinals
            rate: halfHourFee,
            name: name,
        }
        DataService.uploadInscribeQueue(formData, body).then(response => {
            console.log(response)
            if (response && response.data) {
                props.setUserOrdinal(undefined)
                props.history.push(`/collections/ordinals/to-the-moon/${response.data.id}`)
            }
        }).catch(err => {
            console.log('err', err);

        })
    }

    const sendQueueImage = async () => {
        const blob = await base64ToFile(file);
        console.log('blob', blob);
        const extension = blob.type.split('/')[1]
        const formData = new FormData()
        formData.append('upload_file', blob, `${blob.name}.${extension}`)

        const body = {
            payment_address: addressPayment, // address que regresa el back
            send_to: selectedAddressOrdinals, // address del usuario tipo ordinals
            rate: halfHourFee,
            name: name,
            description: description,
        }
        DataService.uploadInscribeQueue(formData, body).then(response => {
            console.log(response)
            if (response && response.data) {
                props.history.push(`/collections/ordinals/to-the-moon/${response.data.id}`)
            }
        }).catch(err => {
            console.log('err', err);

        })
    }
    /*************************** */




    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: 'Ordinals',
        })
    }, [])

    useEffect(() => {
        if (loadState()?.Auth) {
            if (loadState()?.Auth?.ordinal) {
                console.log('ordinal', loadState()?.Auth?.ordinal);
                const { body, file, status_view, labelEditor } = loadState()?.Auth?.ordinal
                set_status_view(status_view)
                if (status_view === 'text') {
                    setLabelEditor(labelEditor)
                    setName(body.name)
                }
                if (status_view === 'image' || status_view === 'file') {
                    setName(body.name)
                    setFile(file)
                    setDescription(body.description)
                }
            }
        }

    }, [])



    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }



    const connectWallet = () => {
        console.log('connectWallet');
    }

    const _handleSubmit = (e) => {
        if (!selectedTransaction) {
            toastrError('First, you need to select a Fee')
            return;
        }
        setShowModal(true)
    }


    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You’re about to inscribe an ordinal</p>
            <p className='deploy-modal-count'> {name || ''} </p>
            <div className='deploy-modal-token-container ico'>
                {/* <img src={file || image_tempo} /> */}
                {
                    status_view === 'file' &&
                    <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                }
                {
                    status_view === 'image' &&
                    <img src={file || image_tempo} />}
                {
                    status_view === 'text' &&
                    <img src={image_tempo} />}

            </div>


            <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {global_total_cost} </b> SATS  </span> to inscribe your ordinal.</p>

        </div>
    </section>

    const footer = <div className='deploy-modal-footer'>
        <div>
            {
                !selectedAddressOrdinals && (
                    <Button
                        type="button"
                        color="primary"
                        className=" theme-button-secundary"
                        onClick={() => {
                            // e.preventDefault()
                            segmentTrack(`connect wallet to deploy`)
                            getAddress(getAddressOptions)
                        }}
                    >
                        {props.t(`Connect wallet`)}

                    </Button>
                )
            }
            {
                !!selectedAddressOrdinals && (
                    <Button
                        type="button"
                        color="primary"
                        className="custom-row theme-button-secundary"
                        disabled={loadPayment}
                        onClick={(e) => {
                            e.preventDefault()
                            segmentTrack(`pay deploy`)
                            saveThis()
                        }}
                    >
                        {props.t(`Pay ${global_total_cost} SATS and deploy`)}
                        {loadPayment &&
                            <div className='min-loader'>
                                <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                    <div></div><div><div></div></div>
                                </div></div>
                            </div>
                        }

                    </Button>

                )
            }




        </div>
    </div>

    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={40} percentage={40}></ProgressHeader>
            {
                showModal && __renderPOView(12)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={2} active={2} ></TabHeaderMenu>
                        <div className='collections-settings-header-button '>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                onClick={(e) => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Pay and inscribe`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all ordinals'>
                        <section className='collections-ordinals transaction'>

                            <div className='collections-ordinals-inscription'>
                                <p className='collections-ordinals-title'>
                                    Set your Transaction Fee
                                </p>
                                <span className='collections-ordinals-subtitle'>
                                    Bitcoin network fees are needed to create your inscription. Higher fees can help your inscription get created sooner, but even high fee time estimates are not guaranteed, so be patient.
                                </span>
                                <Row>
                                    <Col sm={12}>
                                        <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                            <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                            <span>How to inscribe an ordinal</span>
                                        </a>
                                    </Col>
                                </Row>
                                <div className='collections-ordinals-transactions'>
                                    {
                                        transactions.map((transaction, key) => (
                                            <div className={cn('collections-ordinals-transactions-box', (selectedTransaction && selectedTransaction.title === transaction.title) ? 'selected' : '')} key={key} onClick={() => {
                                                setHalfHourFee(transaction.satsvbyte)
                                                setSelectedTransaction(transaction)
                                            }}>
                                                <span className='collections-ordinals-transactions-box-title' style={{ color: transaction.color }}>
                                                    {transaction.title}
                                                </span>
                                                <span className='collections-ordinals-transactions-box-satsvbyte'>
                                                    {transaction.satsvbyte} sats/Byte
                                                </span>
                                                <span className='collections-ordinals-transactions-box-price'>
                                                    ${transaction.price}
                                                </span>
                                                <div className='collections-ordinals-transactions-box-info'>
                                                    <span className='collections-ordinals-transactions-box-sats'>
                                                        {transaction.sats} sats
                                                    </span>
                                                    <span className='collections-ordinals-transactions-box-fee'>
                                                        {transaction.fee} service fee
                                                    </span>
                                                </div>
                                                <span className='collections-ordinals-transactions-box-within'>
                                                    {transaction.within}
                                                </span>
                                            </div>
                                        ))
                                    }


                                </div>
                                {
                                    (selectedTransaction && selectedTransaction.title) === 'Custom Fee' && (
                                        <div>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        {/* <Label className='input-label_monserrat'>
                                                            {props.t(`Royalties %`)}
                                                        </Label> */}

                                                        <div className='theme-form-control' id='custom_price'>
                                                            <div className='theme-form-control-input'>
                                                                <Input
                                                                    required
                                                                    value={custom_price}
                                                                    type="text"
                                                                    className="input"
                                                                    placeholder=''
                                                                    step="0.01"
                                                                    pattern='[0-9]*'
                                                                    onChange={event => {
                                                                        const { value } = event.target
                                                                        const validValue = Math.abs(parseFloat(value || 0));
                                                                        if (!value || !event.target.validity.valid || (validValue && isNaN(validValue)) || value < 1) {
                                                                            document.getElementById('custom_price').classList.add('input-error')
                                                                        } else {
                                                                            document.getElementById('custom_price').classList.remove('input-error')
                                                                        }
                                                                        setCustomPrice(validValue)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='theme-form-control-post white'>
                                                                <span>
                                                                    sats/vByte
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </div>
                                    )
                                }
                            </div>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary mobile"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal(true)
                                }}
                            >
                                {props.t(`Pay and inscribe`)}
                            </Button>
                        </section>
                        <div className='container-video-all' style={{ flex: '0 1' }}>
                            <div className='container-video'>
                                <p>How to inscribe an ordinal</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                            </div>

                        </div>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ordinal: state.Auth?.ordinal
    }
}

export default (connect(mapStatetoProps, {
    setUserOrdinal
}))(withRouter(withNamespaces()(Create)))

