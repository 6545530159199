import React, { useContext, useEffect, useState } from 'react'
import {
    Button, CardTitle, Col, Dropdown, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../../context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowLeft, faChevronDown, faEllipsisVertical, faPercentage, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DataService from '../../../../helpers/DataService'

import bottomButton from '../../../../assets/images/bottom-button.png'
import { toastrError, toastrInfo } from '../../../../helpers/Toastr'
import { isAddressSolana, isAddressWeb3 } from '../../../../helpers/utils'
import { segmentTrack } from '../../../../helpers/Segment'
import cn from 'classnames';
import Modal from "../../../../components/Global/modals"


const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, importDb, exportDb } = generalContext
    const [tokenDescription, setTokenDescription] = useState('')
    const [tokenExternal, setTokenExternal] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [collectionFamily, setCollectionFamily] = useState('')
    const [collectionSymbol, setCollectionSymbol] = useState('')
    const [tokenName, setTokenName] = useState('')
    const [blockchainNetwork, setBlockchainNetwork] = useState(1)
    const [launch_date, setLaunchDate] = useState('')
    const [price, setPrice] = useState('')
    const [seller_fee_basis_points, setSellerFeesBasisPoints] = useState('')
    const [image_id, setImageId] = useState('')
    const [image_updated, setImageUpdated] = useState(false)
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);
    let [cache_zip, setCacheZip] = useState()
    let [fetched, setFetched] = useState(false)
    const [showModal, setShowModal] = useState(false)


    // togle 
    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }


    const commentErrorLength = 'Only 1000 characters or less can be added for the description field'

    const [assets, setAssets] = useState([])
    const [collection_saved, setCollectionSaved] = useState('')
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    const [tokensModified, setTokensModified] = useState(false)

    const { project_id, activeForm, collection_id, tokens, setTokens, setActiveTab, step_number, setStepNumber } = props;
    const min_creator_arr = [
        {
            address: '',
            share: '',
            error: false,
        }
    ];
    let [creator_array, setCreatorArray] = useState([]);
    let [show_neefter_creator, setShowNeefterCreator] = useState(true);

    useEffect(() => {
        const promises = [];
        promises.push(getNetworkConfig())
        Promise.all(promises).then(() => {
            DataService.getCollectionById(collection_id).then(
                response => {
                    console.log('getCollectionById', response.data);
                    if (response && response.data) {

                        const { description, external_url,
                            family, launch_date, name, network_id, price,
                            symbol, seller_fee_basis_points, total_tokens,
                            creators, step_number, network_config } = response.data;
                        setCollectionName(name)
                        const launch = launch_date ? new Date(launch_date) : '';
                        if (response && response.data && response.data.cache_zip) {
                            cache_zip = response.data.cache_zip;
                            setCacheZip(response.data.cache_zip)
                        }
                        if (launch) {
                            const year = launch.getFullYear()
                            let month = parseInt(launch.getMonth() + 1)
                            month = (month + '').length > 1 ? month : '0' + month
                            const day = ((launch.getDate() + '').length > 1 ? launch.getDate() : '0' + launch.getDate());
                            let date = year + "-" + month + "-" + day;
                            setLaunchDate(date)
                        }
                        setCollectionName(name || '')
                        setBlockchainNetwork(network_id || 1)
                        // setSelectedBlockchain(filteredBlockchains.find(fb => (fb.neefter_id + '') === (network_id + '')))
                        setSelectedBlockchain(network_config)


                        setImageId(response.data.image_id)

                        setCollectionFamily(family || '')
                        setCollectionSymbol(symbol || '')
                        setTokenExternal(external_url || '')
                        setTokenDescription(description || '')
                        setPrice(price || '')
                        setStepNumber(step_number)
                        setSellerFeesBasisPoints(seller_fee_basis_points !== undefined ? seller_fee_basis_points / 100 : '')
                        console.log('seller_fee_basis_points', seller_fee_basis_points);
                        if (!seller_fee_basis_points && price) {
                            console.log('setShowNeefterCreator false');
                            setShowNeefterCreator(false)
                        }
                        setTokens(total_tokens)
                        if (creators && creator_array.length === 0 && seller_fee_basis_points && (network_id + '') === '1') {
                            const solana_address = 'FZSbRL64bBk42QHCea44pdvxAGv67ofk9FAh8ZgBKJSE'
                            const resumeCreators = creators.filter(creator => creator.address !== solana_address).map(creator => {
                                return {
                                    address: creator.address,
                                    share: creator.share,
                                    error: false,
                                }
                            })
                            creator_array = resumeCreators
                            setCreatorArray([...resumeCreators])
                            if (creators.length > 1) {
                                setShowNeefterCreator(true)
                            }
                        } else if (creators && creator_array.length === 0 && seller_fee_basis_points && (network_id + '') !== '1') {
                            const resumeCreators = creators.filter(creator => creator.share === 100).map(creator => {
                                return {
                                    address: creator.address,
                                    share: creator.share,
                                    error: false,
                                }
                            })
                            creator_array = resumeCreators
                            setCreatorArray([...resumeCreators])
                        }
                        // if (creators.length > 1) {
                        //     show_neefter_creator = false;
                        //     setShowNeefterCreator(show_neefter_creator)
                        //     console.log('setShowNeefterCreator false');

                    }
                }
            )
        })

    }, [collection_id])

    function getNetworkConfig() {
        return DataService.getNetworkConfig().then(response => {
            console.log('getNetworkConfig', response.data);
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            setSelectedBlockchain(arr[0])
        })
    }

    useEffect(() => {
        if (activeForm > 0) {
            _handleSubmit();
        }
    }, [activeForm])


    const _validateStep = () => {

        if (collectionName === '') {
            console.log('collectionName', collectionName);
            toastrError(props.t(`Don't forget to enter the collection name`), 'Error')
            return false
        }
        if (blockchainNetwork === '') {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        if (tokens === '' || parseFloat(tokens) < 1) {
            toastrError(props.t(`Don't forget to enter the number of tokens`), 'Error')
            return false
        }
        if (collectionSymbol === '') {
            toastrError(props.t(`Don't forget to enter the collection symbol`), 'Error')
            return false
        }
        if (tokenExternal === '' || !validateWebsiteUrl(tokenExternal)) {
            toastrError(props.t(`Don't forget to enter the token external`), 'Error')
            return false
        }
        if (tokenDescription === '') {
            toastrError(props.t(`Don't forget to enter the token description`), 'Error')
            return false
        }
        if (tokenDescription.length > 1000) {
            toastrError(props.t(`${commentErrorLength}`), 'Error')
            return false
        }
        if (launch_date === '') {
            toastrError(props.t(`Don't forget to enter the launch date`), 'Error')
            return false
        }
        if (price === '') {
            toastrError(props.t(`Don't forget to enter the price`), 'Error')
            return false
        }
        if (seller_fee_basis_points === '') {
            toastrError(props.t(`Don't forget to enter the royalties`), 'Error')
            return false
        }
        if (!!seller_fee_basis_points) {
            const v = parseFloat(seller_fee_basis_points)
            if (!(v >= 0 && v < 100)) {
                toastrError(props.t(`The royalties can't be less than 0 and greater than 100`), 'Error')
                return false;
            }
        }
        const total = creator_array.reduce((acc, creator) => acc + parseFloat(creator.share || 0), 0);
        if (blockchainNetwork + '' === '2') {
            if ((total !== 100) && creator_array.length > 0) {
                toastrError('The total of the sum of the shares between all creators must to be 100%')
                return false;
            }
        } else if (blockchainNetwork + '' === '1') {
            if ((total !== 90) && creator_array.length > 0) {
                toastrError('The total of the sum of the shares between all creators must to be 100%')
                return false;
            }
        }
        const addressError = creator_array.find(ca => ca.addressError)
        console.log('addressError', addressError);
        if (!!addressError) {
            toastrError(props.t(`Wallet address it´s not valid. Please verify and enter a valid address`), 'Error')
            return false;
        }
        if (creator_array.length > 0) {
            if (blockchainNetwork + '' === '2') {
                const findError = creator_array.find(creator => !creator.address || !isAddressWeb3(creator.address))
                if (!!findError) {
                    toastrError(props.t(`Wallet address it´s not valid. Please verify and enter a valid address`), 'Error')
                    return false;
                }
            } else if (blockchainNetwork + '' === '1') {
                const findError = creator_array.find(creator => !creator.address || !isAddressSolana(creator.address))
                if (!!findError) {
                    toastrError(props.t(`Wallet address it´s not valid. Please verify and enter a valid address`), 'Error')
                    return false;
                }
            }
        }
        return true
    }


    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            const body = {
                name: collectionName,
                project_id: project_id,
                image_id: image_updated ? (image_id || undefined) : undefined,
                family: collectionFamily,
                external_url: tokenExternal,
                symbol: collectionSymbol,
                description: tokenDescription,
                network_id: blockchainNetwork,
                launch_date: launch_date,
                step_number: 2,
                seller_fee_basis_points: parseFloat((seller_fee_basis_points * 100) || 0),
                price: parseFloat(price || 0),
                total_tokens: tokens,
                creators: (!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') ? [] : creator_array.filter(creator => creator.address !== 'FZSbRL64bBk42QHCea44pdvxAGv67ofk9FAh8ZgBKJSE').map(creator => {
                    return {
                        address: creator.address,
                        share: creator.share,
                    }
                })

            }
            if (step_number > 2) {
                delete body.step_number
            }

            DataService.putCollectionById(collection_id, body).then(response => {
                if (response && response.data) {
                    // props.history.push(`/collections/generative/token/${collection_id}`)
                    setActiveTab('2')
                    setStepNumber(2)
                }
            })


        } catch (error) {
            toastrError(`Failed to add ${collectionName}: ${error}`, 'Error')

        }
    }
    const addCreator = () => {

        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) {
            document.getElementById('seller_fee_basis_points').classList.add('input-error')
            toastrError('Royalties missing', 'Error')
            return;
        }
        if (blockchainNetwork === 2 || blockchainNetwork === '2') {
            if (creator_array.length === 1) {
                return;
            }
        }

        creator_array = [
            ...creator_array,
            {
                address: '',
                share: (blockchainNetwork === 2 || blockchainNetwork === '2') ? '100' : '',
                error: false,
            }
        ]
        setCreatorArray([...creator_array]);


        checkAllCreators()
    };
    const removeCreatorInput = (index) => {
        console.log('index', index);
        console.log('creatonArray', creator_array);
        setCreatorArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
        console.log('creatonArray', creator_array);
    }
    const handleChangeCreatorAddress = (event) => {
        event.preventDefault();
        const val = 'creator_address';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;


        setCreatorArray(item => {
            const newArr = item.slice();
            newArr[index].address = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                console.log('blockchainNetwork', blockchainNetwork);
                if ((blockchainNetwork + '') === '1') {
                    // solana
                    if (isAddressSolana(value)) {
                        newArr[index]['addressError'] = false;
                        document.getElementById(val + index).classList.remove('input-error')
                    } else {
                        newArr[index]['addressError'] = true;
                        document.getElementById(val + index).classList.add('input-error')
                    }

                } else {
                    // Not solana
                    if (isAddressWeb3(value)) {
                        newArr[index]['addressError'] = false;
                        document.getElementById(val + index).classList.remove('input-error')
                    } else {
                        newArr[index]['addressError'] = true;
                        document.getElementById(val + index).classList.add('input-error')
                    }
                }

            }
            return newArr;
        });
    };
    const handleChangeCreatorShare = (event) => {
        event.preventDefault();
        const val = 'creator_share';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        setCreatorArray(item => {
            const newArr = item.slice();

            if (value === null || value === undefined || value === '') {
                newArr[index].error = true;
            } else {
                newArr[index].error = false;
            }
            var regexp = new RegExp(/[`~!@$%^&*()+{}[\]\\|,.//?;':"]/g, 'g');
            const indexHashtagError = value.match(regexp);
            const indexSpaceError = value.match(/\s/);
            if ((value === null || value === undefined || value === '') || (indexHashtagError && indexHashtagError?.length > 0) || (indexSpaceError && indexSpaceError?.length > 0)) {
                newArr[index].error = true;
                newArr[index].share = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
                const doc = document.getElementById(val + '_p' + index)
                console.log(val + 'p' + index, doc);
                if (doc) {
                    doc.classList.add('input-error')
                }
            } else {
                newArr[index].error = false;
                newArr[index].share = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
                const doc = document.getElementById(val + '_p' + index)
                if (doc) {
                    doc.classList.remove('input-error')
                }
            }
            return newArr;
        });
        // checkAllCreators()


    };

    const fileUploadUniqueService = (blob) => {
        let formData = new FormData()
        formData.append('upload_file', blob, 'content.zip')
        return DataService.uploadImage(formData)
    }
    useEffect(() => {
        if (creator_array.filter(ca => !ca.share).length > 0) return;
        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) return;
        const validate = setTimeout(() => {
            checkAllCreators()
        }, 5000);
        return () => clearTimeout(validate)
    }, [creator_array])

    const validateWebsiteUrl = websiteUrl => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

        return regex.test(String(websiteUrl).toLowerCase());
    };
    function checkAllCreators() {
        let total = 0;
        const checkError = creator_array.find(creator => creator.error)
        if (!checkError) {
            total = creator_array.reduce((acc, creator) => acc + parseFloat(creator.share || 0), 0);
            if (blockchainNetwork === 2 || blockchainNetwork === '2') {
                console.log('total', total);
                if ((total !== 100) && creator_array.length > 0) {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        if (document.getElementById(val + '_p' + index)) {
                            document.getElementById(val + '_p' + index).classList.add('input-error')
                        }
                        return {
                            ...creator,
                            error: true
                        }
                    })
                    setCreatorArray([...n_array])
                    toastrInfo('The total of the sum of the shares between all creators must to be 100%')

                } else {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        if (document.getElementById(val + '_p' + index)) {
                            document.getElementById(val + '_p' + index).classList.remove('input-error')
                        }
                        return {
                            ...creator,
                            error: false
                        }
                    })
                    setCreatorArray([...n_array])
                }
            } else {
                if (((show_neefter_creator && total !== 90) || (!show_neefter_creator && total !== 100)) && creator_array.length > 0) {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        if (document.getElementById(val + '_p' + index)) {
                            document.getElementById(val + '_p' + index).classList.add('input-error')
                        }
                        return {
                            ...creator,
                            error: true
                        }
                    })
                    setCreatorArray([...n_array])
                    toastrInfo('The total of the sum of the shares between all creators must to be 100%')

                } else {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        document.getElementById(val + index).classList.remove('input-error')
                        return {
                            ...creator,
                            error: false
                        }
                    })
                    setCreatorArray([...n_array])
                }
            }


        }

    }

    function naiveRound(num, decimalPlaces = 5) {
        if (!num) return 0;
        var p = Math.pow(10, decimalPlaces);
        return Math.round(num * p) / p;
    }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '90%'

        }
    }
    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            styles={modalStyles}
        />
    )
    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Token MetaData</p>
            <section className={cn('container-settings-tab-example dialog')}>
                <div className='container-settings-tab-example-custom dialog'>
                    <div className='container-settings-tab-example-titles'>
                        <span className='container-settings-tab-example-titles token-name'>
                            Token Name
                        </span>
                        <span className='container-settings-tab-example-titles token-media'>
                            Token Media
                        </span>
                        <span className='container-settings-tab-example-titles token-description'>
                            Token <br /> Description
                        </span>
                        <span className='container-settings-tab-example-titles external-url'>
                            External URL
                        </span>
                        <div className='container-settings-tab-example-stroke stoken-name'>
                        </div>
                        <div className='container-settings-tab-example-stroke stoken-media'>
                        </div>
                        <div className='container-settings-tab-example-stroke stoken-description'>
                        </div>
                        <div className='container-settings-tab-example-stroke sexternal-url'>
                        </div>
                    </div>
                    <div className='container-settings-tab-example-card'>
                        <div className='container-settings-tab-example-card-title'>
                            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                            <span> {collectionName}</span>
                            <FontAwesomeIcon icon={faEllipsisVertical}></FontAwesomeIcon>
                        </div>
                        <div className='container-settings-tab-example-card-img'>
                            <img src={image_id} />
                        </div>
                        <div className='container-settings-tab-example-card-buttons'>
                            <div className='container-settings-tab-example-card-buttons-card'>
                                Send
                            </div>
                            <div className='container-settings-tab-example-card-buttons-card'>
                                List
                            </div>
                        </div>
                        <div className='container-settings-tab-example-card-description'>
                            <span className='container-settings-tab-example-card-description-title'>
                                Description
                            </span>
                            <span>
                                {tokenDescription}
                            </span>
                            <span className='container-settings-tab-example-card-description-url'>
                                {tokenExternal}
                            </span>
                        </div>
                        <div className='container-settings-tab-example-card-bottom'>
                            <img src={bottomButton} />
                        </div>
                    </div>

                </div>
            </section>

        </div>
    </section >
    const footer = <div className='deploy-modal-footer'>

    </div>



    return (
        <React.Fragment>
            {
                showModal && __renderPOView(12)
            }
            <Form id="settings-tab-form" >
                <div className='collections-settings text-align-left'>

                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`General settings`)}</CardTitle>
                            <div className='collections-settings-row'>

                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Collection name`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={collectionName}
                                                    type="text"
                                                    className="input-white-input circle"
                                                    placeholder='Collection name'
                                                    id='collectionName'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('collectionName').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('collectionName').classList.remove('input-error')
                                                        }
                                                        setCollectionName(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Collection Family`)}
                                                </Label>

                                                <Input
                                                    value={collectionFamily}
                                                    type="text"
                                                    id='collectionFamily'
                                                    className="input-white-input circle"
                                                    placeholder='Stark'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('collectionFamily').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('collectionFamily').classList.remove('input-error')
                                                        }
                                                        setCollectionFamily(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`How many tokens`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={tokens}
                                                    type="text"
                                                    id="tokens"
                                                    className="input-white-input circle"
                                                    placeholder='0'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        setTokensModified(true)
                                                        if (!value) {
                                                            document.getElementById('tokens').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('tokens').classList.remove('input-error')
                                                        }
                                                        setTokens(value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Collection symbol`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={collectionSymbol}
                                                    id="collectionSymbol"
                                                    type="text"
                                                    className="input-white-input circle"
                                                    placeholder='CPT'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('collectionSymbol').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('collectionSymbol').classList.remove('input-error')
                                                        }
                                                        setCollectionSymbol(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Blockchain network`)}
                                                </Label>
                                                <Dropdown
                                                    style={{ width: '100%' }}
                                                    isOpen={menu}
                                                    toggle={toggle}
                                                    className="d-inline-block"
                                                >
                                                    <DropdownToggle
                                                        className="input-drop-white"
                                                        id="select-block-dropdown"
                                                    >
                                                        <span className="header-item-project-name">
                                                            {selectedBlockChain && (
                                                                <span>{selectedBlockChain.name}</span>
                                                            )}
                                                        </span>
                                                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                    </DropdownToggle>
                                                    <DropdownMenu right className='input-drop-white-item'>
                                                        {filteredBlockchains.map(
                                                            (item, key) => (
                                                                <div key={key} className="dropdown-item " onClick={() => {
                                                                    setSelectedBlockchain(item)
                                                                    setBlockchainNetwork(item.neefter_id)
                                                                    setMenu(false)
                                                                }}>
                                                                    <span>{item.name}</span>
                                                                </div>
                                                            ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`External URL`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={tokenExternal}
                                                    id="tokenExternal"
                                                    type="text"
                                                    className="input-white-input circle"
                                                    placeholder='URL or Link'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('tokenExternal').classList.add('input-error')
                                                        } else {
                                                            if (validateWebsiteUrl(value)) {
                                                                document.getElementById('tokenExternal').classList.remove('input-error')
                                                            } else {
                                                                document.getElementById('tokenExternal').classList.add('input-error')
                                                            }
                                                        }
                                                        setTokenExternal(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                            <p className='title-mini'>This URL will appear next to the asset’s image.</p>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Token description`)}
                                                </Label>

                                                <Input
                                                    required
                                                    style={{ padding: 10 }}
                                                    type="textarea"
                                                    className="input-white-input circle form-control input-text-area"
                                                    placeholder="The description will be included on the item's detail page underneath 
                                            its image. Markdown syntax is supported."
                                                    value={tokenDescription}
                                                    id="tokenDescription"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('tokenDescription').classList.add('input-error')
                                                        } else {
                                                            if (value.length > 1000) {
                                                                toastrError(commentErrorLength)
                                                                document.getElementById('tokenDescription').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('tokenDescription').classList.remove('input-error')
                                                            }
                                                        }
                                                        setTokenDescription(event.target.value)
                                                    }}
                                                />

                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Button
                                                type="button"
                                                color="secundary"
                                                className="theme-button-collaborator mobile"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    // segmentTrack(`update project`)
                                                    setShowModal(true)
                                                }}
                                            >
                                                {props.t(`Show token metadata`)}
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Go live settings`)}</CardTitle>
                            <div className='collections-settings-row'>
                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Go live date`)}
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="launch_date"
                                                    id="launch_date"
                                                    value={launch_date}
                                                    placeholder="Launch date"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('launch_date').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('launch_date').classList.remove('input-error')
                                                        }
                                                        setLaunchDate(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Price`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={price}
                                                    id="price"
                                                    type="text"
                                                    className="input-white-input circle"
                                                    placeholder='Price'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('price').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('price').classList.remove('input-error')
                                                        }
                                                        setPrice(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <CardTitle className='collections-settings-tab-title'>{props.t(`Royalties & comissions`)}</CardTitle>
                            <div className='collections-settings-row'>
                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Royalties %`)}
                                                </Label>

                                                <div className='theme-form-control' id='seller_fee_basis_points'>
                                                    <div className='theme-form-control-input'>
                                                        <Input
                                                            required
                                                            value={seller_fee_basis_points}
                                                            type="text"
                                                            className="input"

                                                            placeholder=''
                                                            step="0.01"
                                                            pattern="[0-9]*"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={event => {
                                                                const { value } = event.target
                                                                if (!value || !event.target.validity.valid) {
                                                                    document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                } else {
                                                                    const v = parseFloat(value)
                                                                    if (v >= 0 && v < 100) {
                                                                        const strAfterDot = value.split('.', 2)[1]
                                                                        if (!strAfterDot || strAfterDot.length <= 2) {
                                                                            document.getElementById('seller_fee_basis_points').classList.remove('input-error')
                                                                        } else {
                                                                            document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                        }
                                                                    } else {
                                                                        document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                    }

                                                                    if (v > 0 && (blockchainNetwork + '') === '1') {
                                                                        setShowNeefterCreator(true)
                                                                    }
                                                                }

                                                                setSellerFeesBasisPoints(event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='theme-form-control-post white'>
                                                        <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                    </div>
                                                </div>

                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`creators %`)}
                                                </Label>
                                                <p className='title-mini'>
                                                    Payments will be split between the wallet address you list here, according to their share.
                                                </p>

                                                {
                                                    (show_neefter_creator && seller_fee_basis_points > 0 && (blockchainNetwork === 1 || blockchainNetwork === '1')) && (
                                                        <div className='input-container' key={'cont0'} >
                                                            <div className='input-container_lg'>
                                                                <Input
                                                                    required
                                                                    value="Neefter creator"
                                                                    type="text"
                                                                    className="input-white-input circle"
                                                                    disabled={true}
                                                                    placeholder=''
                                                                    id={"creator_address"}
                                                                />
                                                            </div>

                                                            <div className='input-container_sm'>


                                                                <div className='theme-form-control'>
                                                                    <div className='theme-form-control-input'>
                                                                        <Input
                                                                            required
                                                                            value="10"
                                                                            type="text"
                                                                            disabled={true}
                                                                            className="input"
                                                                            placeholder=''
                                                                            id={"creator_shar0"}
                                                                            pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                        />
                                                                    </div>
                                                                    <div className='theme-form-control-post white'>
                                                                        <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    creator_array.map((item, i) => {
                                                        return (
                                                            <div className='input-container' key={'cont' + i} >
                                                                <div className='input-container_lg'>
                                                                    <Input
                                                                        required
                                                                        value={item.address}
                                                                        type="text"
                                                                        className="input-white-input circle"
                                                                        placeholder=''
                                                                        id={"creator_address" + i}
                                                                        disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')}
                                                                        onChange={handleChangeCreatorAddress}
                                                                    />
                                                                </div>
                                                                <div className='input-container_sm'>
                                                                    <div className='theme-form-control' id={"creator_share_p" + i}>
                                                                        <div className='theme-form-control-input'>
                                                                            <Input
                                                                                required
                                                                                value={item.share}
                                                                                type="text"
                                                                                className="input"
                                                                                placeholder=''
                                                                                id={"creator_share" + i}
                                                                                pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                                disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') || (blockchainNetwork === 2 || blockchainNetwork === '2')}
                                                                                onChange={handleChangeCreatorShare}
                                                                            />
                                                                        </div>
                                                                        <div className='theme-form-control-post white'>
                                                                            <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='input-container_icon' onClick={() => { removeCreatorInput(i) }}>
                                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <Row>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="theme-button-collaborator"
                                                onClick={addCreator}
                                            >
                                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                {props.t(`Add creator`)}
                                            </Button>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                        <section className='container-settings-tab-side'>
                            <section className='container-settings-tab-example preview' style={{ height: '55rem' }}>
                                <p>Token MetaData</p>
                                <div className='container-settings-tab-example-custom'>
                                    <div className='container-settings-tab-example-titles'>
                                        <span className='container-settings-tab-example-titles token-name'>
                                            Token Name
                                        </span>
                                        <span className='container-settings-tab-example-titles token-media'>
                                            Token Media
                                        </span>
                                        <span className='container-settings-tab-example-titles token-description'>
                                            Token <br /> Description
                                        </span>
                                        <span className='container-settings-tab-example-titles external-url'>
                                            External URL
                                        </span>
                                        <div className='container-settings-tab-example-stroke stoken-name'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke stoken-media'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke stoken-description'>
                                        </div>
                                        <div className='container-settings-tab-example-stroke sexternal-url'>
                                        </div>
                                    </div>
                                    <div className='container-settings-tab-example-card'>
                                        <div className='container-settings-tab-example-card-title'>
                                            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                            <span> {collectionName}</span>
                                            <FontAwesomeIcon icon={faEllipsisVertical}></FontAwesomeIcon>
                                        </div>
                                        <div className='container-settings-tab-example-card-img'>
                                            <img src={image_id} />
                                        </div>
                                        <div className='container-settings-tab-example-card-buttons'>
                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                Send
                                            </div>
                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                List
                                            </div>
                                        </div>
                                        <div className='container-settings-tab-example-card-description'>
                                            <span className='container-settings-tab-example-card-description-title'>
                                                Description
                                            </span>
                                            <span>
                                                {tokenDescription}
                                            </span>
                                            <span className='container-settings-tab-example-card-description-url'>
                                                {tokenExternal}
                                            </span>
                                        </div>
                                        <div className='container-settings-tab-example-card-bottom'>
                                            <img src={bottomButton} />
                                        </div>
                                    </div>

                                </div>
                            </section>
                            <section className='container-settings-tab-example table'>
                                <span className='container-settings-tab-example-table-title'>Future Earnings</span>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Contract</th>
                                            <th>Token</th>
                                            <th>Estimated Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{collectionName}</td>
                                            <td>{tokens}</td>
                                            <td>{naiveRound((price * tokens))} {selectedBlockChain ? selectedBlockChain.native_token : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Revenue percentage</th>
                                            <th>Royalties</th>
                                            <th>Estimated earning per re-sale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {creator_array.length > 0 && (
                                            creator_array.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.address.length <= 5 ? item.address : '...' + item.address.substring(item.address.length - 6, item.address.length)}</td>
                                                        <td>{item.share * (seller_fee_basis_points || 0)}</td>
                                                        <td>{naiveRound(((price * tokens) / 100) * item.share)} {selectedBlockChain.native_token}</td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                        {creator_array.length === 0 && (
                                            <tr>
                                                <td>No revenue</td>
                                                <td>No Royalties</td>
                                                <td>No earning</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </section>
                        </section>
                        <Row>
                            <Col>
                                <Button
                                    type="button"
                                    color="secundary"
                                    className="theme-button-secundary mobile"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`go to components`)
                                        _handleSubmit()
                                    }}
                                >
                                    {props.t(`Next step`)}
                                </Button>
                            </Col>
                        </Row>
                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))