import {
    SET_ACTIVE_PROJECT,
    UPDATE_PROJECT
} from "./actionTypes"
import { loadState } from "../../localStorage"

const persistedState = loadState()
const initialState = persistedState ? persistedState?.Data : {
    project: null
}

const ProjectReducer = (state = initialState, action) => {
    const { data } = action

    switch (action.type) {
        case UPDATE_PROJECT:
            return {
                ...state,
                project: data
            }
        case SET_ACTIVE_PROJECT:
            return {
                ...state,
                project: data
            }
        default:
            return { ...state }
    }
    return state
}

export default ProjectReducer
