import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GeneralContext } from '../../../context/';
import ProgressBar from './progressbar';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { toastrError, toastrSuccess } from "../../../helpers/Toastr";
import { segmentTrack } from "../../../helpers/Segment"

function Dropzone({ onAssetFull, dimensionError, setDimensionError, properties,
    setProperties }) {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { getRootProps, getInputProps, acceptedFiles, isDragActive, open
    } =
        useDropzone({
            noClick: true,
            useFsAccessApi: false
        });

    // const { } = props
    let [globalImage, setGlobalImg] = useState('');
    let [globalWidth, setGlobalWidth] = useState(0);
    let [globalHeight, setGlobalHeight] = useState(0);

    let [totalFiles, setTotalFiles] = useState(0);
    let [totalFilesChecked, setTotalFilesChecked] = useState(0);
    const bgcolor = '#A066FF'
    let [completed, setCompleted] = useState(0);
    const dimensionErrorLabel = `Don't forget, all the images need to be of the same size of width and height`
    let [dataLoader, setdataloader] = useState(false);
    const prompt_error_sile_file = "One or more of the uploaded assets exceeds the 2 mb size limit"
    let maxfilesizeImage = 1024 * 1024 * 2  // 2 Mb
    let maxfilesizeGlb = 1024 * 1024 * 2  // 2 Mb

    async function bulkAddWithProgress(table, objects, chunkSize, onProgress, pos = 0) {
        try {
            await table.bulkAdd(objects.slice(pos, pos + chunkSize));
            onProgress(pos + chunkSize);
            if (objects.length > pos + chunkSize) {
                await bulkAddWithProgress(
                    table,
                    objects,
                    chunkSize,
                    onProgress,
                    pos + chunkSize
                );
            }
        }
        catch (err) {
            console.log('err', err);
        }

    }
    useEffect(() => {
        segmentTrack(`Select assets to upload`)
        if (!acceptedFiles) return;
        if (acceptedFiles.length < 1) return;

        setAcceptedFiles(acceptedFiles)

    }, [acceptedFiles])

    const setAcceptedFiles = async (acceptedFiles) => {
        let width = null;
        let height = null;
        let localProperties = [];
        totalFiles = acceptedFiles.length;
        totalFilesChecked = 0;
        setTotalFilesChecked(totalFilesChecked)
        setdataloader(true)
        setTotalFiles(totalFiles)
        setDimensionError(false)
        const posts = await Promise.all(
            acceptedFiles.map(async (file, index) => {
                const folderArray = file.path.split('/');
                if (folderArray.length < 2) {
                    toastrError(`Folder format not allowed`)
                    totalFilesChecked = 0;
                    setTotalFilesChecked(totalFilesChecked)
                    return;
                } else if (folderArray[folderArray.length - 1] === '.DS_Store') {
                    totalFiles = totalFiles - 1;
                    setTotalFiles(totalFiles)
                } else if (file.type === "application/json") {
                    toastrError(`Can't upload json files, you can only use json files on fixed art minting`)
                    totalFilesChecked = 0;
                    setTotalFilesChecked(totalFilesChecked)
                    return;
                } else if (file.type === "text/csv") {
                    toastrError(`Can't upload csv files, you can only use csv files on fixed art minting`)
                    totalFilesChecked = 0;
                    setTotalFilesChecked(totalFilesChecked)
                    return;
                } else if (file.size > maxfilesizeImage) {
                    toastrError(prompt_error_sile_file, 'Error')
                    totalFilesChecked = 0;
                    setTotalFilesChecked(totalFilesChecked)
                    return
                } else if (folderArray[folderArray.length - 1] !== '.DS_Store') {
                    const folderName = folderArray[folderArray.length - 2];
                    const property = localProperties.find(lp => lp.name === folderName)


                    // universeFiles.push({ name: folderName + '-' + file.name, file: file })
                    // setUniverseFiles([...universeFiles])

                    if (!property) {
                        localProperties.push({
                            name: folderName,
                            percentage: 100,
                            variations: [{ name: file.name.split('.')[0], image: file, rarity: 1 }],
                            order: localProperties.length,
                        })
                    } else {
                        property.variations.push({ name: folderArray[folderArray.length - 1].split('.')[0], image: file, rarity: 1 })
                    }
                    totalFilesChecked = totalFilesChecked + 1;
                    setTotalFilesChecked(totalFilesChecked)
                    if (file.type !== "image/gif") {
                        try {
                            const { f_width, f_height } = await getImageDimensions(URL.createObjectURL(file))
                            const dim = f_width / f_height
                            if (!width && !height) {
                                width = dim
                            } else if (width !== dim) {
                                setDimensionError(true)
                            }
                            console.log(file.name + '--------------------' + f_width, f_height);
                        } catch (error) {
                            // console.log('error----' + file.name, error);
                        }
                    }
                }
            })
        )
        // localProperties.forEach(lp => {
        //     lp.traits.forEach((trait, index) => {
        //         if (index > 0) {
        //             trait.weight = (100 / (lp.traits.length - 1)).toFixed(0);
        //             trait.percentage = (100 / (lp.traits.length - 1)).toFixed(0);
        //         }
        //     });
        // });
        setdataloader(false)
        setProperties([...localProperties])
    }


    useEffect(() => {
        if (properties.length > 0) {
            endPass(properties)
        }
    }, [properties])


    async function getImageDimensions(file) {
        let img = new Image();
        img.src = (file);
        await img.decode();
        let f_width = img.width;
        let f_height = img.height;
        return {
            f_width,
            f_height,
        }
    }


    const getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
        const img = new Image()
        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width
            })
        }
        img.src = dataURL
    })


    let endPass = async (properties) => {
        if (dimensionError) {
            setCompleted(0)
            totalFilesChecked = 0;
            setTotalFilesChecked(0)
            toastrError(dimensionErrorLabel)
            return;
        }
        try {
            console.log('properties', properties);
            console.log(`Successfully properties added`);
        } catch (err) {

        }

        let arr_filter = [];

        // properties.forEach(property => {
        //     let exist = arr_filter.findIndex(filter => filter.type === property.name);
        //     if (exist < 0) {
        //         arr_filter.push({ type: property.name, options: [] });
        //     }
        //     exist = arr_filter.findIndex(filter => filter.type === property.name);
        //     if (exist >= 0) {
        //         property.traits.forEach(trait => {
        //             const value = trait.name.split('.')[0];
        //             let exit_value = arr_filter[exist].options.findIndex(opt => opt.value === value);
        //             if (exit_value < 0) {
        //                 arr_filter[exist].options.push({ value: value, counter: 0, checked: false });
        //             }
        //             // exit_value = arr_filter[exist].options.findIndex(opt => opt.value === value);
        //             // if (exit_value >= 0) {
        //             //     arr_filter[exist].options[exit_value].counter = arr_filter[exist].options[exit_value].counter + 1;
        //             // }
        //         });
        //     }
        // });
        // try {

        //     // const half = (arr_filter.length / 2).toFixed(0)
        //     // await db.filters.bulkAdd(arr_filter.slice(0, half));
        //     // await db.filters.bulkAdd(arr_filter.slice(half, properties.length));
        //     console.log('arr_filter', arr_filter);
        //     await db.filters.clear()
        //     await bulkAddWithProgress(db.filters, arr_filter, 100, numAdded => {
        //         console.log(`Successfully filters ${numAdded} members`);
        //     }).catch(error => {
        //         console.error(`Failure: ${error}`);
        //     });
        // } catch (err) {

        // }
    }
    let delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();


    useEffect(() => {
        if (totalFiles > 0) {


            const advance = parseInt((100 / totalFiles) * totalFilesChecked, 0);



            // if (totalFilesChecked === totalFiles - 10) {
            //     endPass()
            // }
            if (advance === 100) {
                onAssetFull(true);
                setLoading(false);
                toastrSuccess('Files uploaded succesfully', '¡Success!')
                segmentTrack(`Assets uploaded`)

            }
            setCompleted(advance)

        }
    }, [totalFilesChecked])



    return (
        <div style={{ width: '100%' }} onClick={() => open()}>
            <div {...getRootProps({ className: "collections-create-form-upload-drop" })}>
                <input className="input-zone" {...getInputProps()} webkitdirectory="" />

                <div className='collections-create-form-upload-drop-ico'>
                    <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                </div>
                {
                    isDragActive ? (

                        <p className='collections-create-form-upload-drop-title'>
                            Release to drop the files here
                        </p>
                    ) :
                        <p className='collections-create-form-upload-drop-title'>
                            Drop files here or click to upload your assets
                        </p>
                }
                <p className='collections-create-form-upload-drop-subtitle'>
                    Not sure what to do? Head to our&nbsp;&#32; <b>&nbsp;&#32;help section</b>
                </p>
            </div>
            <div>
                <ProgressBar key={'progress-1'} bgcolor={bgcolor} completed={completed} />
            </div>
            {
                dataLoader &&
                <div className='collections-create-form-upload-drop-subtitle'>
                    Validating assets
                </div>
            }


        </div>


    );
}
export default Dropzone;
