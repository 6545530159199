import React, { useContext, useEffect, useState } from 'react'
import {
    Button, ButtonGroup, Card,
    CardBody,
    CardFooter, Col, Container, Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { faCheckCircle, faCloudArrowUp, faComputer, faHome, faMobileAlt, faTablet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import cn from 'classnames'
import { withNamespaces } from "react-i18next"
import { useParams, withRouter } from 'react-router-dom'
import image_source from '../../assets/images/NeefterSVG6.svg'
import backgroundImage from '../../assets/images/all-city-shark.png'
import Whitecube from "../../assets/images/white-cube.jpg"
import ProgressHeader from '../../components/Global/Progress-header'
import Modal from "../../components/Global/modals"
import { GeneralContext } from '../../context/'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import TabHeaderMenu from './components/tab-header-menu'
import { base64ToFile, hasWhiteSpace } from "../../helpers/utils"
import { loadState } from "../../store/localStorage"
import { setUserTicket } from '../../store/actions'
import { connect } from "react-redux"


import { ethers } from 'ethers'
import { toastrError, toastrInfo } from "../../helpers/Toastr"
import { fileReader } from "../../helpers/constants"



const abi = [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "string", "name": "name", "type": "string" }], "name": "NameChanged", "type": "event" }, { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "string", "name": "_name", "type": "string" }], "name": "setName", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
const { ethereum } = window;


const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { currentProject } = generalContext
    const { setBreadcrumb } = generalContext
    const [logotype, setLogotype] = useState('')
    const [background, setBackground] = useState('')
    const [favicon, setFavicon] = useState('')
    const [hficon, setHficon] = useState('')
    const [subdomain, setSubdomain] = useState('')
    const { collection_id } = useParams();
    let [project_id, setProjectId] = useState([]);

    const [tokens, setTokens] = useState('')
    const [image_id, setImageId] = useState('')
    let [step_number, setStepNumber] = useState(0);
    const [network_id, setNetworkId] = useState('')
    const [network_config, setNetworkConfig] = useState()
    const [image_tempo, setImageTempo] = useState(undefined)
    const [button_loader, setButtonLoader] = useState(false)
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    const { setLoading, importDb } = generalContext
    let [assets, setAssets] = useState([])
    let [sampleAssetsTop, setSampleAssetsTop] = useState([])
    let [sampleAssetsBot, setSampleAssetsBot] = useState([])
    let [universeFiles, setUniverseFiles] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [total_price, setTotalPrice] = useState(0)
    let [assetsCount, setAssetsCount] = useState(0)
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);
    const [sign, setSign] = useState(undefined);
    const bgcolor = '#6042D6'
    const neefter_cash = 'C123NqmCoXtEUt3sbJrHtYw3853b6y5tXttTLGHGvB6a'
    const lamport = 1_000_000_000
    const NEEFTER_SOLANA_NODE = 'https://orbital-fittest-bird.solana-mainnet.quiknode.pro/bc45b120094c7d49e064f515fafd083b0e248f70/'
    let [completedPurchase, setCompleted] = useState(0.001);
    const [enableButton, setEnableButton] = useState(true)
    const [showErrorTransaction, setShowErrorTransaction] = useState(false)
    const [loadPayment, setLoadPayment] = useState(false)
    const [enableSubdomain, setEnableSubdomain] = useState(false)
    let [cache_zip, setCacheZip] = useState()
    let [universe_files_db, setUniverseFilesDb] = useState([]);

    let video_multiplicator = 0.001;
    let image_multiplicator = 0.001;
    let dimension_multiplicator = 0.001;
    const [finalizedSignature, setFinalizedSignature] = useState(undefined)
    let [counter, setCounter] = useState(0);
    let [extension, setExtension] = useState('');

    let [display, setDisplay] = useState('tablet');
    let [fetched, setFetched] = useState(false)
    let [selectedTicket, setSelectedTicket] = useState(undefined)






    /**
     * Mumbai stuff
     * ____________________________________________________________________________________
     */

    // let network = 'https://polygon.rpc.thirdweb.com' // use mumbai testnet
    // let ethers_provider = ethers.getDefaultProvider(network)
    let [network, setNetwork] = useState();
    let [ethers_provider, setEthersProvider] = useState();
    let [chainId, setChainId] = useState();
    let [rpcUrls, setrpcUrls] = useState([])
    let [chainName, setchainName] = useState()


    const [balance, setBalance] = useState("Loading...")
    const [currentAccount, setCurrentAccount] = useState(null);
    const [transferBalance, setTransferBalance] = useState('0.001')
    const [transferAddress, setTransferAddress] = useState('0x11Ef8475B65C63161e2269eD5b5FF6fa6c2Bf9bD')
    const [blockhash, setBlockhash] = useState(undefined);
    const [loadMumbaiPayment, setLoadMumbaiPayment] = useState(false)
    const [finalizedSignatureMumbai, setFinalizedSignatureMumbai] = useState(undefined)
    const subdomainWhiteSpacesError = 'Spaces characters cant be used on subdomain field'
    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    async function transferETH() {
        setLoadMumbaiPayment(true);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        try {
            let receiverAddress = transferAddress
            let amountInEther = transferBalance
            let tx = {
                to: receiverAddress,
                value: ethers.utils.parseEther(amountInEther)
            }
            await switchNetwork(chainId)

            const _provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = _provider.getSigner();
            // Send the transaction and log the receipt
            const receipt = await signer.sendTransaction(tx);
            let wrongResult = false;
            let _transaction;
            let conditionExit = false;
            while (!conditionExit && counter < 10) {

                _transaction = await _provider.getTransactionReceipt(receipt.hash);

                await ndelay(5000)
                counter = counter + 1;
                if (_transaction) {
                    conditionExit = (_transaction.status !== 2 && _transaction.status !== 3)
                    wrongResult = _transaction.status === 1;
                }
            }
            if (!wrongResult) {
                setLoadMumbaiPayment(false);
            } else {

                setFinalizedSignatureMumbai(receipt.hash)
                // setLoadMumbaiPayment(false);
            }

        } catch (err) {
            console.log('err', err);
            setLoadMumbaiPayment(false);
            toastrError(`Payment Error, check your wallet`)

        }

    }



    async function switchNetwork(chain_id) {
        console.log('chain_id', chain_id);
        const hexa = chain_id.toString(16)
        console.log('hexa', hexa);
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${hexa}` }],    // chainId must be in HEX with 0x in front
        });
    }


    const checkWalletIsConnected = async () => {
        if (!ethereum) {
            return;
        } else {

        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });
        if (accounts.length !== 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                console.log('balanceInEth', balanceInEth);
                setBalance(`${balanceInEth} ETH`)
            })

        } else {

        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;
        if (!ethereum) {
            alert("Please install Metamask!");
        }
        try {
            console.log('connectWalletHandler');
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            console.log('accounts', accounts);
            setCurrentAccount(accounts[0]);
        } catch (err) {
            console.log('err', err);
        }
    }

    const connectWalletButton = () => {
        return (
            <Button
                type="button"
                color="primary"
                className=" theme-button-secundary"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`Connect wallet to deploy`)
                    connectWalletHandler()
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        )
    }

    useEffect(() => {
        if (finalizedSignatureMumbai) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setBlockhash(finalizedSignatureMumbai)
            setLoading(false)

        }
    }, [finalizedSignatureMumbai])

    useEffect(() => {
        if (blockhash) {
            _handleDeployMumbai()
        }
    }, [blockhash])

    const _handleDeployMumbai = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService()

    }


    /**
     * ___________________________________________________________________________________________
     */

    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }
    // solana options

    useEffect(() => {
        const provider = getProvider();

        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, []);


    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }

    let [dataCsv, setdataCsv] = useState([[
        'neefter_name',
        'neefter_image',
        // 'neefter_symbol',
        // 'neefter_description',
        // 'neefter_seller_fee_basis_points',
        // 'neefter_external_url',
        // 'neefter_creator_1_address',
        // 'neefter_creator_1_share',
        // 'neefter_creator_2_address',
        // 'neefter_creator_2_share',
        // 'neefter_creator_3_address',
        // 'neefter_creator_3_share',
    ]]);

    // useEffect(() => {
    //     setBreadcrumb({
    //         icon: '',
    //         parent: props.t(`Collections`),
    //         parentLink: `/projects/${project_id}/collections`,
    //         item: props.t(`Minting site`)
    //     })

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [project_id])
    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Deployment`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const provider = getProvider();

        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, [currentProject])


    useEffect(() => {
        if (!collection_id) return;
        DataService.getCollectionById(collection_id).then(
            response => {

                if (response && response.data) {

                    const { brackground, favicon, high_fidelity_icon, id, image_id, network_config,
                        logo, network_id, step_number } = response.data;
                    setHficon(high_fidelity_icon || '')
                    setLogotype(logo || '')
                    setBackground(brackground || '')
                    setImageTempo(brackground || '')
                    setNetworkId(network_id)
                    setNetworkConfig(network_config)
                    setImageId(image_id || '')
                    setStepNumber(step_number)
                    setFavicon(favicon || '')

                    if (response && response.data && response.data.cache_zip) {
                        cache_zip = response.data.cache_zip;
                        setCacheZip(response.data.cache_zip)
                    }
                    _setRpcCashInfo(response.data)
                    checkNftList()

                }
            })



    }, [collection_id])

    const checkNftList = () => {
        const params = {
            offset: 0,
            limit: 10,
            order_by: 'is_generative.asc'
        }
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                const { data, total_count } = response.data;
                setAssetsCount(total_count)
                const filterData = data.map(item => {
                    const split = item.image.split('.')
                    const extension = split[split.length - 1]
                    let type = 'image'
                    if (extension.toLowerCase() === 'mp4') {
                        type = 'video'
                    } else if (extension.toLowerCase() === 'glb') {
                        type = '3d'
                    }
                    return {
                        ...item,
                        type: type
                    }
                })

                if (filterData.length > 9) {
                    setSampleAssetsTop(filterData.slice(0, 5))
                    setSampleAssetsBot(filterData.slice(5, 10))
                    console.log('data.slice(0, 5)', filterData.slice(0, 5));
                    console.log('data.slice(5, 10)', filterData.slice(5, 10));
                } else {
                    let i = 0;
                    let samples = [];
                    while (samples.length < 10 && filterData.length > 0) {
                        samples = [...samples, ...filterData]
                    }
                    setSampleAssetsTop(samples.slice(0, 5))
                    setSampleAssetsBot(samples.slice(5, 10))
                }
                console.log('filterData[0]', filterData[0]);
                const split = filterData[0].image.split('.')
                const extension = split[split.length - 1];
                setExtension(extension)
            }


        })
    }


    const _setRpcCashInfo = (collection) => {
        if (!collection || !collection.network_config) return;
        const { rpc_api, prices, chain_id, name } = collection.network_config
        const { total_tokens } = collection;

        setNetwork(rpc_api[0])
        setEthersProvider(ethers.getDefaultProvider(network))
        setChainId(chain_id)
        setrpcUrls(rpc_api)
        setchainName(name)
        video_multiplicator = prices.video
        image_multiplicator = prices.image
        dimension_multiplicator = prices.glb

        const total = parseFloat((total_tokens * dimension_multiplicator).toFixed(10));
        setTotalPrice(total)
        setTransferBalance(total + '')
        console.log('total', total);

        checkWalletIsConnected()
        console.log('_setRpcCashInfo', collection.network_config);
    }



    const fileUploadUniqueService = async (asset) => {
        if (!asset.file.includes('data:')) {
            const promise = new Promise(function (resolve, reject) {
                const universalFile = {
                    name: asset.name,
                    file: asset.file,
                    upload_id: fileReader + asset.file
                }
                universeFiles.push(universalFile);
                setUniverseFiles([...universeFiles]);
                resolve()
            })
            return promise;
        }
        let formData = new FormData()
        let _file = await base64ToFile(asset.file)
        console.log('asset', asset);
        console.log('_file', _file);
        console.log('_file.type', _file.type);
        formData.append('upload_file', _file, asset.name)


        return DataService.uploadImage(formData).then(response => {
            const universalFile = {
                name: asset.name,
                file: asset.file,
                upload_id: response.data.id
            }
            universeFiles.push(universalFile);
            setUniverseFiles([...universeFiles]);
        }).catch(err => {
            console.log('err', err);
        })
    }


    const sendFormToService = (upload_id) => {
        const body = {
            subdomain: subdomain,
            sign: ((network_id + '') === '1') ? sign : blockhash,
            file_type: extension,
            deploy: true,
        }
        return DataService.putCollectionById(collection_id, body).then(response => {
            setButtonLoader(false);

            if (selectedTicket) {
                props.setUserTicket({
                    ...selectedTicket,
                    collection_id: response.id,
                })
                props.history.push(`/collections/ticketing?step=3`)
            } else {
                props.history.push(`/collections/to-the-moon`)
            } setLoadPayment(false);
            setLoadMumbaiPayment(false);
        })
    }

    const toCSV = (data) => {
        return arrays2csv(data);
    };
    const arrays2csv = ((data) =>
        joiner(data)
    );
    const joiner = ((data, separator = ',', enclosingCharacter = '') => {
        return data
            .filter(e => e)
            .map(
                row => row
                    .map((element) => elementOrEmpty(element))
                    .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
                    .join(separator)
            )
            .join(`\n`);
    });
    const elementOrEmpty = (element) =>
        (typeof element === 'undefined' || element === null) ? '' : element;

    function parseCsv(parseJson, neefter_image, neefter_preview_file) {
        console.log('parseCsv parseJson', parseJson);
        console.log('parseCsv neefter_image', neefter_image);
        console.log('parseCsv neefter_preview_file', neefter_preview_file);

        let array = [];
        const parseKeys = {
            'neefter_name': parseJson.name || '',
            'neefter_image': neefter_image || '',
            'neefter_preview_file': neefter_preview_file || undefined,
            // 'neefter_symbol': parseJson.symbol || '',
            // 'neefter_description': parseJson.description || '',
            // 'neefter_seller_fee_basis_points': parseJson.seller_fee_basis_points || '',
            // 'neefter_external_url': parseJson.external_url || '',
            // 'neefter_creator_1_address': parseJson.properties.creators[0] ? parseJson.properties.creators[0].address || '' : '',
            // 'neefter_creator_1_share': parseJson.properties.creators[0] ? parseJson.properties.creators[0].share || '' : '',
            // 'neefter_creator_2_address': parseJson.properties.creators[1] ? parseJson.properties.creators[1].address || '' : '',
            // 'neefter_creator_2_share': parseJson.properties.creators[1] ? parseJson.properties.creators[1].share || '' : '',
            // 'neefter_creator_3_address': parseJson.properties.creators[2] ? parseJson.properties.creators[2].address || '' : '',
            // 'neefter_creator_3_share': parseJson.properties.creators[2] ? parseJson.properties.creators[2].share || '' : '',
        }
        for (var key in parseKeys) {
            if (!!parseKeys[key]) {
                array.push(parseKeys[key])
            }
        }

        parseJson.attributes.forEach(att => {
            let existKey = dataCsv[0].findIndex(_data => _data === att.trait_type);
            if (existKey < 0) {
                dataCsv[0].push(att.trait_type)
            }
            existKey = dataCsv[0].findIndex(_data => _data === att.trait_type);
            if (existKey >= 0) {
                for (let i = 0; i < existKey; i++) {
                    if (array[i] === undefined) {
                        array.push('');
                    }
                }
                if (array[existKey] === '') {
                    array[existKey] = att.value;
                } else {
                    array.push(att.value)
                }
            }
        });

        return array;
    }

    const _validateFullStep = () => {

        if (logotype === '') {
            toastrError(props.t(`Don't forget to enter the logotype input`), 'Error')
            document.getElementById('logotype').classList.add('input-error')
            return false
        }
        if (background === '') {
            toastrError(props.t(`Don't forget to enter the background input`), 'Error')
            document.getElementById('background').classList.add('input-error')
            return false
        }
        if (favicon === '') {
            toastrError(props.t(`Don't forget to enter the favicon input`), 'Error')
            document.getElementById('favicon').classList.add('input-error')
            return false
        }
        if (hficon === '') {
            toastrError(props.t(`Don't forget to enter the hficon input`), 'Error')
            document.getElementById('hficon').classList.add('input-error')
            return false
        }
        if (subdomain === '') {
            toastrError(props.t(`Don't forget to subdomain the price`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        if (!enableSubdomain) {
            toastrError(props.t(`Don't forget to add a non repeated subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        if (!sign && !blockhash) {
            toastrError(props.t(`First you need paid for the service`), 'Error')
            return false
        }
        return true
    }
    const _validateStep = () => {

        if (logotype === '') {
            toastrError(props.t(`Don't forget to enter the logotype input`), 'Error')
            document.getElementById('logotype').classList.add('input-error')
            return false
        }
        if (background === '') {
            toastrError(props.t(`Don't forget to enter the background input`), 'Error')
            document.getElementById('background').classList.add('input-error')
            return false
        }
        if (favicon === '') {
            toastrError(props.t(`Don't forget to enter the favicon input`), 'Error')
            document.getElementById('favicon').classList.add('input-error')
            return false
        }
        if (hficon === '') {
            toastrError(props.t(`Don't forget to enter the hficon input`), 'Error')
            document.getElementById('hficon').classList.add('input-error')
            return false
        }
        if (subdomain === '') {
            toastrError(props.t(`Don't forget to add subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        if (!enableSubdomain) {
            toastrError(props.t(`Don't forget to add a non repeated subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        return true
    }

    const _handleSubmit = async (e) => {
        if (!enableSubdomain) {
            if (!!subdomain && !hasWhiteSpace(subdomain)) {
                const unique = await DataService.getCollectionSubdomain(subdomain);
                if (unique.data.exist) {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                } if (!unique.data.exist) {
                    document.getElementById('subdomain').classList.remove('input-error')
                    setEnableSubdomain(true);
                } else {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                }
            } else {
                document.getElementById('subdomain').classList.add('input-error')
                setEnableSubdomain(false);
            }
        }
        if (!sign) {
            if (!_validateStep()) return;
            setShowModal(true)
        } else {
            _handleDeploy()
        }

    }
    const _handleDeploy = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService()
    }
    const _backToSettings = (e) => {
        props.history.push(`/collections/settings`)
    }

    const _handleFileLogotype = (event) => {
        let files = event?.target.files

        // const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif, */ico, image/x-png, image/*']


        // if (types.every(type => files[0] && files[0].type !== type)) {
        //     return
        // }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                logo: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setLogotype(put_response.data.logo)
                document.getElementById('logotype').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }

    const _handleFileBackground = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            const body = {
                brackground: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setBackground(put_response.data.brackground)
                setImageTempo(URL.createObjectURL(files[0]));
                document.getElementById('background').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const _handleFileFavicon = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                favicon: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setFavicon(put_response.data.favicon)
                document.getElementById('favicon').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const _handleFileHfIcon = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                high_fidelity_icon: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setHficon(response.data.id)
                document.getElementById('hficon').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const _handleImageUpload = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                image_id: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setImageId(put_response.data.image_id)
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }

    const _handleSubdomain = async (event) => {
        event.preventDefault();
        const value = event.target.value;
        if (!value) {
            document.getElementById('subdomain').classList.add('input-error')
        }
        if (hasWhiteSpace(value)) {
            toastrError(subdomainWhiteSpacesError, 'Error')
            document.getElementById('subdomain').classList.add('input-error')
        }
        setSubdomain(value)

    }

    useEffect(() => {
        const search = setTimeout(async () => {
            //Your search query and it will run the function after 3secs from user stops typing

            if (!!subdomain && !hasWhiteSpace(subdomain)) {
                const unique = await DataService.getCollectionSubdomain(subdomain);

                if (unique.data.exist) {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                } if (!unique.data.exist) {
                    document.getElementById('subdomain').classList.remove('input-error')
                    setEnableSubdomain(true);
                } else {
                    document.getElementById('subdomain').classList.add('input-error')
                    setEnableSubdomain(false);
                }
            } else {
                document.getElementById('subdomain').classList.add('input-error')
                setEnableSubdomain(false);
            }

        }, 5000);
        return () => clearTimeout(search)
    }, [subdomain])



    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)
        return DataService.uploadImage(formData)
    }
    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You are about to deploy a Candy Machine of </p>
            <p className='deploy-modal-count'> {assetsCount} tokens. </p>
            <div className='deploy-modal-entries-container scroll-track'>
                <div className='deploy-modal-entries'>
                    {
                        sampleAssetsTop.map(
                            (item, key) => (
                                <Card className='card-borderless collections-token-resume-card theme-token-card minting' key={key} >
                                    <div>
                                        <CardBody>
                                            <div className='card-picture'>
                                                {
                                                    item.type === 'video' && !item.image_preview &&
                                                    <video id='video' src={item.image} className='collections-token-resume-card-video' style={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                        muted playsInline autoPlay={false}>
                                                        {/* <source type="video/mp4" /> */}
                                                        Your browser does not support the video tag.
                                                    </video>
                                                }
                                                {
                                                    item.type === 'image' &&
                                                    <img id='image' style={{ width: '100%' }} alt={item.name} src={item.image} />
                                                }
                                                {
                                                    (item.type === '3d' || (item.type === 'video' && item.image_preview)) &&
                                                    <img id='3d' style={{ width: '100%' }} alt={item.name} src={item.image_preview || Whitecube} />
                                                }
                                            </div>
                                        </CardBody>
                                    </div>

                                    <CardFooter className="bg-transparent border-top">
                                        <div className="d-flex" style={{ paddingTop: '0' }}>
                                            <div className="flex-fill" style={{ maxWidth: '100%' }}>
                                                <div className='ellipsis'>
                                                    {item.name}
                                                </div>
                                            </div>

                                            <div className="flex-fill" style={{ textAlign: 'right' }}>

                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )
                        )
                    }
                </div>
                <div className='deploy-modal-entries'>
                    {
                        sampleAssetsBot.map(
                            (item, key) => (
                                <Card className='card-borderless collections-token-resume-card theme-token-card minting' key={key} >
                                    <div>
                                        <CardBody>
                                            <div className='card-picture'>
                                                {
                                                    item.type === 'video' && !item.image_preview &&
                                                    <video id='video' src={item.image} className='collections-token-resume-card-video' style={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                        muted playsInline autoPlay={false}>
                                                        {/* <source type="video/mp4" /> */}
                                                        Your browser does not support the video tag.
                                                    </video>
                                                }
                                                {
                                                    item.type === 'image' &&
                                                    <img id='image' style={{ width: '100%' }} alt={item.name} src={item.image} />
                                                }
                                                {
                                                    (item.type === '3d' || (item.type === 'video' && item.image_preview)) &&
                                                    <img id='3d' style={{ width: '100%' }} alt={item.name} src={item.image_preview || Whitecube} />
                                                }
                                            </div>
                                        </CardBody>
                                    </div>

                                    <CardFooter className="bg-transparent border-top">
                                        <div className="d-flex" style={{ paddingTop: '0' }}>
                                            <div className="flex-fill" style={{ maxWidth: '100%' }}>
                                                <div className='ellipsis'>
                                                    {item.name}
                                                </div>
                                            </div>

                                            <div className="flex-fill" style={{ textAlign: 'right' }}>

                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )
                        )
                    }
                </div>
            </div>

            {
                ((network_id + '') === '1') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {total_price} </b> SOL </span> to deploy your collection.</p>
            }
            {
                ((network_id + '') !== '1') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {transferBalance} </b> {network_config ? network_config.native_token : ''} </span> to deploy your collection.</p>
            }

        </div>
    </section>

    const footer = <div className='deploy-modal-footer'>

        {
            ((network_id + '') === '1') && (
                <div>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className=" theme-button-secundary"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {provider && !!walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="custom-row theme-button-secundary"
                            disabled={loadPayment}
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`pay deploy`)
                                _handleBuyCollection()
                            }}
                        >
                            {props.t(`Pay ${total_price} SOL and deploy`)}
                            {loadPayment &&
                                <div className='min-loader'>
                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                        <div></div><div><div></div></div>
                                    </div></div>
                                </div>
                            }

                        </Button>
                    )}
                    {!provider && (
                        <p>
                            No provider found. Install{" "}
                            <a href="https://phantom.app/">Phantom Browser extension</a>
                        </p>
                    )}
                </div>
            )
        }
        {
            (((network_id + '') !== '1')) && (
                <div>
                    {currentAccount ?
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className=" theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`pay deploy`)
                                    transferETH()
                                }}
                            >
                                {props.t(`Pay ${transferBalance} ${network_config ? network_config.native_token : ''} and deploy`)}
                                {loadMumbaiPayment &&
                                    <div className='min-loader'>
                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                            <div></div><div><div></div></div>
                                        </div></div>
                                    </div>
                                }
                            </Button>
                        </div>
                        : connectWalletButton()
                    }
                </div>
            )
        }

    </div>


    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            // @ts-ignore
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
        // if ('phantom' in window) {
        //     // @ts-ignore
        //     const provider = window.phantom?.solana as any;

        //     if (provider?.isPhantom) {
        //         return provider as PhantomProvider;
        //     }
        // }
    };



    /**
   * @description prompts user to connect wallet if it exists
   */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;

        if (solana) {
            try {
                const response = await solana.connect();

                setWalletKey(response.publicKey.toString());
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                console.log({ code: 4001, message: 'User rejected the request.' });
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };

    const _handleBuyCollection = () => {
        buyCollection()
    }

    let buyCollection = async () => {

        const provider = getProvider();

        setEnableButton(true);
        setLoadPayment(true);
        setShowErrorTransaction(false);
        const network = NEEFTER_SOLANA_NODE;


        const connection = new Connection(network);

        // setLoading(true);
        try {
            let transaction = Transaction;
            const to_public_key = new PublicKey(neefter_cash);
            const from_public_key = new PublicKey(walletKey);
            const lamp = lamport * total_price;

            const txn = await createTransferTransaction(to_public_key, from_public_key, connection, lamp)


            const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
            try {
                return provider?.signAndSendTransaction(txn)
                    .then(async response => {
                        const signature = response?.signature
                        let getSignatureStatus;
                        let conditionExit = false;
                        while (!conditionExit && counter < 8) {
                            getSignatureStatus = await connection.getSignatureStatus(signature);
                            await ndelay(5000)
                            conditionExit = (!!getSignatureStatus.value && getSignatureStatus.value.confirmationStatus === 'finalized');
                            counter = counter + 1;
                        }
                        setFinalizedSignature(signature);
                        // const signature_status = await connection.getSignatureStatus(signature);

                        // openModal('MAIL_FORM')

                        // const data = {
                        //     signature: response?.signature,
                        //     email: '',
                        // }
                        // setpostBftMintByNftIPubKeyData({ id, wallet: walletState.data.Wallet?.wallet, data, clickNextStep, connection })
                        // setStepperView(true);
                        // return postBftMintByNftIPubKey(id, walletState.data.Wallet?.wallet, data, clickNextStep)

                    })
                    .catch(err => {
                        setShowErrorTransaction(true);
                        setLoading(false);
                        setEnableButton(false);
                        setLoadPayment(false);

                    })
            } catch (err) {
                setShowErrorTransaction(true);
                setLoading(false);
                setEnableButton(false);
                setLoadPayment(false);

            }
        } catch (err) {
            setShowErrorTransaction(true);
            setLoading(false);
            setEnableButton(false);

        }
    }

    useEffect(() => {
        if (finalizedSignature) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setSign(finalizedSignature)
            setLoading(false)

        }
    }, [finalizedSignature])

    useEffect(() => {
        if (sign) {
            _handleDeploy()
        }
    }, [sign])

    /**
    * 
    * 
    * 
    * 
    * Purchase options
    * 
    * 
    * 
    * 
    * 
    */

    /**
    * Creates an arbitrary transfer transaction
    * @param   {String}      publicKey  a public key
    * @param   {Connection}  connection an RPC connection
    * @returns {Transaction}            a transaction
    */
    const createTransferTransaction = async (toPublicKey, fromPublicKey, connection, lamports) => {
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: fromPublicKey,
                toPubkey: toPublicKey,
                lamports: lamports,
            })
        );


        transaction.feePayer = fromPublicKey;

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;


        return transaction;
    };


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={80} percentage={80}></ProgressHeader>

            {
                showModal && __renderPOView(12)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={step_number} active={4} collection_id={collection_id}></TabHeaderMenu>

                        <div className='collections-settings-header-button'>

                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-update"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`initiate deploy`)
                                    _handleSubmit()
                                }} >
                                {
                                    button_loader &&
                                    <div className='min-loader'>
                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                            <div></div><div><div></div></div>
                                        </div></div>
                                    </div>

                                }
                                {!button_loader &&
                                    props.t(`Deploy`)
                                }

                            </Button>
                        </div>
                    </div>
                    <section className='collections-minting'>
                        <div className='collections-minting-top'>
                            <section className='container-deploy'>
                                <Form className='collections-minting-top' id="minting-form" style={{ width: '100%' }}>
                                    <section className='collections-minting-top-form'>
                                        <div className='collections-minting-top-form-img'>
                                            <div className='collections-create-form-row-img'>
                                                <p>Collection image</p>
                                                <div className='card-picture' style={img_style} onClick={() => document.getElementById('upload_img')?.click()}>
                                                    <img style={{ width: '100%' }} alt="item" src={image_id ? image_id : '/assets/images/avatar-2.png'} />
                                                    <input type="file"
                                                        id="upload_img"
                                                        hidden
                                                        onChange={event => {
                                                            _handleImageUpload(event)
                                                        }}
                                                    />
                                                </div>
                                                <div className='collections-create-form-row-ico' onClick={() => document.getElementById('upload_img')?.click()}>
                                                    <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='collections-minting-top-form-img'>
                                            <div className='text-align-left'>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Logotype`)}
                                                                <span className='input-label-mini'>.jpg o .png</span>
                                                            </Label>
                                                            <div className='collections-minting-control' >
                                                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_logotype')?.click()}>
                                                                    <span>Choose File</span>
                                                                </div>
                                                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_logotype')?.click()}>
                                                                    <Input
                                                                        required
                                                                        value={logotype}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='No file choosen'
                                                                        disabled
                                                                        id="logotype"
                                                                    />
                                                                    <input type="file"
                                                                        id="upload_logotype"
                                                                        hidden
                                                                        accept='image/*'
                                                                        onChange={event => {
                                                                            _handleFileLogotype(event)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={cn('collections-minting-control-icon', !!logotype ? 'active' : '')} >
                                                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Background`)}
                                                                <span className='input-label-mini'>.jpg o .png</span>
                                                            </Label>
                                                            <div className='collections-minting-control' >
                                                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_background')?.click()}>
                                                                    <span>Choose File</span>
                                                                </div>
                                                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_background')?.click()}>
                                                                    <Input
                                                                        required
                                                                        value={background}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='No file choosen'
                                                                        disabled
                                                                        id="background"
                                                                    />
                                                                    <input type="file"
                                                                        id="upload_background"
                                                                        hidden
                                                                        onChange={event => {
                                                                            _handleFileBackground(event)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={cn('collections-minting-control-icon', !!background ? 'active' : '')} >
                                                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`Favicon`)}
                                                                <span className='input-label-mini'>.jpg o .png o .ico</span>
                                                            </Label>
                                                            <div className='collections-minting-control' >
                                                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_favicon')?.click()}>
                                                                    <span>Choose File</span>
                                                                </div>
                                                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_favicon')?.click()}>
                                                                    <Input
                                                                        required
                                                                        value={favicon}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='No file choosen'
                                                                        disabled
                                                                        id="favicon"
                                                                    />
                                                                    <input type="file"
                                                                        id="upload_favicon"
                                                                        hidden
                                                                        onChange={event => {
                                                                            _handleFileFavicon(event)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={cn('collections-minting-control-icon', !!favicon ? 'active' : '')} >
                                                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                                </div>

                                                            </div>
                                                        </FormGroup>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label className='input-label_monserrat'>
                                                                {props.t(`High fidelity icon `)}
                                                                <span className='input-label-mini'>.jpg o .png</span>
                                                            </Label>
                                                            <div className='collections-minting-control' >
                                                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_hficon')?.click()}>
                                                                    <span>Choose File</span>
                                                                </div>
                                                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_hficon')?.click()}>
                                                                    <Input
                                                                        required
                                                                        value={hficon}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='No file choosen'
                                                                        id="hficon"
                                                                        disabled
                                                                    />
                                                                    <input type="file"
                                                                        id="upload_hficon"
                                                                        hidden
                                                                        onChange={event => {
                                                                            _handleFileHfIcon(event)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={cn('collections-minting-control-icon', !!hficon ? 'active' : '')} >
                                                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Subdomain`)}
                                                            </Label>
                                                            <div className='collections-minting-control1' >
                                                                <div className='collections-minting-control-pretext'>
                                                                    <Input
                                                                        required
                                                                        value={subdomain}
                                                                        type="text"
                                                                        className="input-white-input circle"
                                                                        placeholder='Cryptolucha'
                                                                        id="subdomain"
                                                                        onChange={event => _handleSubdomain(event)}
                                                                    />
                                                                </div>
                                                                <div className='collections-minting-control-posttext'>
                                                                    <span>.neefter.com</span>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </div>
                                    </section>

                                    <div className='collections-minting-top-group'>
                                        <ButtonGroup className='input-white-buttongroup'>
                                            <Button className={cn(display === 'pc' ? 'selected' : '')} onClick={() => {
                                                setDisplay('pc')
                                            }}>
                                                <FontAwesomeIcon icon={faComputer}></FontAwesomeIcon>
                                            </Button>
                                            <Button className={cn(display === 'tablet' ? 'selected' : '')}
                                                onClick={() => {
                                                    setDisplay('tablet')
                                                }}>
                                                <FontAwesomeIcon icon={faTablet}></FontAwesomeIcon>
                                            </Button>
                                            <Button className={cn(display === 'phone' ? 'selected' : '')}
                                                onClick={() => {
                                                    setDisplay('phone')
                                                }}>
                                                <FontAwesomeIcon icon={faMobileAlt}></FontAwesomeIcon>
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <div className={cn('collections-minting-top-display', display)}>
                                        <div className='collections-minting-top-display-container'>
                                            <div className='collections-minting-top-display-background'>
                                                <img alt="item-1"
                                                    src={background ? background : backgroundImage} />
                                            </div>
                                            <div className='collections-minting-top-display-connect'>
                                                <div className='collections-minting-top-display-connect-black'>
                                                    <div className='collections-minting-top-display-connect-primary'>
                                                        <span>Connect wallet</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='collections-minting-top-display-logo'>
                                                {
                                                    logotype &&
                                                    <img alt="item-3"
                                                        src={logotype} />
                                                }
                                            </div>
                                            <div className='collections-minting-top-display-powered'>
                                                <div className="custom-footer">
                                                    <a href="https://twitter.com/Neefter_">
                                                        Powered by  <img src={image_source}></img>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </div>
                        {/* <div className='collections-minting-sample'>
                            <div className='collections-minting-sample-land'>
                                <img className='collections-minting-sample-image1' alt="item-1"
                                    src={background ? background : backgroundImage} />
                                <div className='collections-minting-sample-land-logo'>
                                    {
                                        logotype &&
                                        <img className='collections-minting-sample-land-logo-img' alt="item-3"
                                            src={logotype} />
                                    }

                                </div>
                                <div className="custom-footer">
                                    <a href="https://twitter.com/Neefter_">
                                        Powered by  <img src={image_source}></img>
                                    </a>
                                </div>

                            </div>
                            <div className='collections-minting-sample-phone'>
                                <img className='collections-minting-sample-image2' alt="item-2"
                                    src={background ? background : backgroundImage} />
                                <div className='collections-minting-sample-phone-logo'>
                                    {
                                        logotype &&
                                        <img className='collections-minting-sample-phone-logo-img' alt="item-3"
                                            src={logotype} />
                                    }

                                </div>
                                <div className="custom-footer mini">
                                    <a href="https://twitter.com/Neefter_">
                                        Powered by  <img src={image_source}></img>
                                    </a>
                                </div>
                            </div>

                            <img className='collections-minting-sample-image' alt="item"
                                src={backgroundImageMockup} />
                        </div> */}
                    </section>
                </Container>
            </div>
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ticket: state.Auth?.ticket

    }
}
export default (connect(mapStatetoProps, {
    setUserTicket
}))(withRouter(withNamespaces()(Create)))
