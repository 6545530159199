import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, Button,
    CardFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import { Link, withRouter } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import { segmentTrack } from "../../../helpers/Segment"
import Modal from "../../../components/Global/modals"
import DataService from '../../../helpers/DataService'
import Labelicon from '../../../assets/images/Labelicon.svg';
import { base64ToFile, dataUrlToFile, dataToBlob, dataURLtoFile, b64toBlob } from '../../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign } from '@fortawesome/free-solid-svg-icons';

const DataTableCard = (props) => {
    const { display, key, item, _deleteItem, profile } = props
    let [status, setStatus] = useState('yellow')
    let [timeCalc, setTimeCalc] = useState()

    let [url, setUrl] = useState('')
    let [type_name, setTypeName] = useState('')
    const todayDate = new Date();
    const [showModalCard, setShowModalCard] = useState(false)
    const [showNfy, setShowNfy] = useState(false)
    let [item_name, setItemName] = useState('')
    let [item_image_id, setItemImage] = useState('')
    const [showFullRow, setShowFullRow] = useState(false)
    const [ordinalInfo, setOrdinalInfo] = useState()

    const [showImage, setShowImage] = useState(true)


    useEffect(() => {
        if (item) {
            if (item.type_id === 5 || item.type_id === '5') {
                const { is_deployed, is_deploying, is_generative, organization_id,
                    modified_at, type_id, is_locked, id, csv,
                } = item.collection_info;

                setItemName(item.event_info.event_title)
                setItemImageValidated(item.logo)
                if (!is_locked && !csv && ((type_id + '' !== '3') && (type_id + '' !== '4'))) {
                    status = 'draft';
                    setStatus('draft')
                } else {
                    if (!is_deployed && !is_deploying) {
                        status = 'red';
                        setStatus('red')
                    } else if (!is_deployed && is_deploying) {
                        status = 'yellow';
                        setStatus('yellow')
                    } if ((is_deployed || !!csv) && !is_deploying) {
                        status = 'green';
                        setStatus('green')
                    }
                }
            } else if ((item.type_id + '') === '6') {
                setItemName(item.name)
                // setItemImage(item.image_id)
                status = 'yellow';
                setStatus('yellow')
                getOrdinalInfo()
            } else {
                const { is_deployed, is_deploying, is_generative, organization_id,
                    modified_at, type_id, is_locked, id, name, image_id, csv
                } = item;
                setItemName(name)
                setItemImageValidated(image_id)


                if (!is_locked && !csv && ((type_id + '' !== '3') && (type_id + '' !== '4'))) {
                    status = 'draft';
                    setStatus('draft')
                } else {
                    if (!is_deployed && !is_deploying) {
                        status = 'red';
                        setStatus('red')
                    } else if (!is_deployed && is_deploying) {
                        status = 'yellow';
                        setStatus('yellow')
                    } if ((is_deployed || !!csv) && !is_deploying) {
                        status = 'green';
                        setStatus('green')
                    }
                }
            }

            const { is_generative, organization_id,
                modified_at, type_id, is_locked, id, csv,
            } = item;
            if (item.type_id === 5 || item.type_id === '5') {
                setUrl(`/collections/ticketing/view/${id}`)
                setTypeName('ticketing')
            } else if (item.type_id === 6 || item.type_id === '6') {
                setUrl(`/collections/ordinals/view/${id}`)
                setTypeName('1/1')
            } else if (item.type_id === 3 || item.type_id === '3') {
                setUrl(`/collections/oneandone/view/${id}`)
                setTypeName('1/1')
            } else if (!!is_locked || csv) {
                setUrl(`/collections/view/${id}`)
                setTypeName('1/1')
            } else if (is_generative) {
                setUrl(`/collections/generative/settings/${id}`)
                setTypeName('1/1')
            } else {
                setUrl(`/collections/settings/${id}`)
                setTypeName('1/1')
            }

            const n_date = new Date(modified_at)
            const minus_date = calcInputs(n_date, todayDate)
            setTimeCalc(minus_date)
            if (profile) {
                setShowNfy(profile.id !== organization_id)

            }

        }
    }, [item])

    const setItemImageValidated = (image_id) => {
        if (!image_id) {
            if (item.type + '' === 6) {
                setItemImage('/assets/images/ordinals.png')
                setShowImage(true)
            } else {
                setItemImage(undefined)
            }
        } else {
            setItemImage(image_id)

            const splt_img = !!image_id ? image_id.split('.') : []

            const file_type = splt_img.length > 0 ? splt_img[splt_img.length - 1] : null
            if (!!file_type && file_type.toLowerCase() === 'mp4') {
                setShowImage(false)
            } else {
                setShowImage(true)
            }
        }

    }
    const check_base64_file = async (base64) => {
        if (!base64) return;
        const base64_file = b64toBlob(base64);
        
        if (base64_file) {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(base64_file);
            
            setItemImageValidated(imageUrl)
        }
    }
    const getOrdinalInfo = () => {
        DataService.getBtcInscribe(item.id).then(response => {
            const { address, created_at, filename, payment_address, rate, send_to, uuid, webhook, base64_file } = response.data
            
            check_base64_file(base64_file)


            setOrdinalInfo(response.data)
            switch (response.data.status) {
                case 1:
                    status = 'yellow';
                    setStatus('yellow')
                    break;
                case 2:
                    status = 'yellow';
                    setStatus('yellow')
                    break;
                case 3:
                    status = 'green';
                    setStatus('green')
                    break;

            }
        })
    }

    //use best practices by labeling your constants.
    let MS_PER_SEC = 1000
        , SEC_PER_HR = 60 * 60
        , HR_PER_DAY = 24
        , MS_PER_DAY = MS_PER_SEC * SEC_PER_HR * HR_PER_DAY
        ;

    //let's assume we get Date objects as arguments, otherwise return 0.
    function dateDiffInDays(date1Time, date2Time) {
        if (!date1Time || !date2Time) return 0;
        return Math.round((date2Time - date1Time) / MS_PER_DAY);
    }

    function getUTCTime(dateStr) {
        const date = new Date(dateStr);
        // If use 'Date.getTime()' it doesn't compute the right amount of days
        // if there is a 'day saving time' change between dates
        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    }


    function calcInputs(date1, date2) {
        if (date1 && date2) {
            //remove non-date characters

            let date1Time = getUTCTime(date1)
                , date2Time = getUTCTime(date2)
                , result = dateDiffInDays(date1Time, date2Time)
                ;
            return result;
        } else {
            return 'no date'
        }
    }
    const _openDetailModal = () => {
        setShowModalCard(true)
    }
    const manageRoute = () => {
        if ((item.type_id + '') === '4') {
            _openDetailModal()
        } else {

            props.history.push(url);
        }
    }

    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
            minWidth: '900px',
        }
    }
    const __renderCardDetailView = () => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModalCard}
            onRequestClose={() => {
                setShowModalCard(false)
            }}
            body={modalContainerCard}
            footer={footerModal}
            styles={modalCardStyles}
        />
    )

    const modalContainerCard = <section className='modalcard'>
        <div className='modalcard-img'>
            <div className='modalcard-img-picture generative'>
                <div className='card-picture' >

                    {
                        showImage &&
                        <img style={{ 'position': 'absolute' }} alt={item_name} src={item_image_id || '/assets/images/avatar-2.png'} />
                    }
                    {
                        !showImage &&
                        <video id='video' alt={item_name} src={item_image_id} className='collections-token-resume-card-video' style={{
                            width: '100%',
                            height: '100%'
                        }}
                            muted playsInline autoPlay={true}>
                            {/* <source type="video/mp4" /> */}
                            Your browser does not support the video tag.
                        </video>
                    }


                </div>
            </div>
        </div>
        <div className='modalcard-body'>
            <div className='modalcard-body-title'>
                <p> {item_name}</p>
            </div>
            <div className='modalcard-body-cards'>
                {
                    !!item.global_properties && item.global_properties.map((property, key) => (
                        <div className='modalcard-body-cards-item' key={key}>
                            <div style={{ display: 'inline', maxWidth: '100px', padding: '0 12px' }}>
                                <p className='modalcard-body-cards-item-top'>
                                    {property.trait_type}
                                </p>
                                <p className='modalcard-body-cards-item-middle'>
                                    {property.value}
                                </p>

                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    </section>

    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowModalCard(undefined)
            }}
        >
            {props.t(`Close`)}
        </Button>

    </div>
    return (
        <React.Fragment>
            {
                showModalCard && __renderCardDetailView(showModalCard)
            }
            {
                display === 'table' && (
                    <Card className='card-borderless' key={key}>
                        <Link to={'#'} onClick={manageRoute}>
                            <CardBody>
                                <div className='card-picture'>
                                    {
                                        showImage &&
                                        <img style={{ width: '100%' }} alt={item_name} src={(!item_image_id && item.type_id + '' === '6') ? Labelicon : item_image_id || '/assets/images/avatar-2.png'} />
                                    }
                                    {
                                        !showImage &&
                                        <video id='video' alt={item_name} src={item_image_id} className='collections-token-resume-card-video' style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                            muted playsInline autoPlay={true}>
                                            {/* <source type="video/mp4" /> */}
                                            Your browser does not support the video tag.
                                        </video>
                                    }

                                    <div className='card-picture-float'>
                                        {
                                            showNfy &&
                                            <div className={cn('rowcard-option-type nfy')}>

                                                <span>NFY</span>

                                            </div>
                                        }
                                        <div className={cn('rowcard-option-type', item.is_generative ? 'generative' : 'fixed')}>
                                            {
                                                (item.type_id + '') === '3' &&
                                                <span>1 / 1</span>
                                            }
                                            {
                                                (item.type_id + '') === '4' &&
                                                <span>Quick Mint</span>
                                            }
                                            {
                                                (item.type_id + '') === '5' &&
                                                <span>Ticket</span>
                                            }
                                            {
                                                (item.type_id + '') === '6' &&
                                                <span>Ordinal</span>
                                            }
                                            {
                                                ((item.type_id + '') !== '3' && (item.type_id + '') !== '4' && (item.type_id + '') !== '5' && (item.type_id + '') !== '6') && item.is_generative &&
                                                <span>Generative</span>
                                            }
                                            {
                                                ((item.type_id + '') !== '3' && (item.type_id + '') !== '4' && (item.type_id + '') !== '5' && (item.type_id + '') !== '6') && !item.is_generative &&
                                                <span>Fixed art</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Link>

                        <CardFooter className="bg-transparent border-top">
                            <div className="d-flex">
                                <div className="flex-fill card-title rowcard-mtitle">
                                    <Link to={'#'} onClick={manageRoute}>
                                        {item_name}
                                    </Link>
                                </div>
                                <div className='subrow'>
                                    <div className='rowcard-body-sub' >
                                        {
                                            status === 'draft' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot yellow'>
                                                </div>
                                                Draft
                                            </div>
                                        }
                                        {
                                            status === 'yellow' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot yellow'>
                                                </div>
                                                Processing
                                            </div>
                                        }
                                        {
                                            status === 'red' &&
                                            <div className='rowcard-body-sub-banner'>

                                                <div className='rowcard-body-sub-banner-dot red'>
                                                </div>
                                                Error
                                            </div>
                                        }
                                        {
                                            status === 'green' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot green'>
                                                </div>
                                                Processed
                                            </div>
                                        }
                                        <span title={timeCalc + ' days ago'}>
                                            {timeCalc} days ago
                                        </span>
                                    </div>
                                    <div className='rowcard-option-network'>
                                        {
                                            (item.type_id !== 6 && (item.network_id + '') === '1') &&
                                            <img src={`/assets/images/Solana.svg`} />
                                        }
                                        {
                                            (item.type_id !== 6 && (item.network_id + '') === '2') &&
                                            <img src={`/assets/images/Matic.svg`} />
                                        }
                                        {
                                            (item.network_id === 3 || item.network_id === '3') &&
                                            <img src={`/assets/images/Ether.svg`} />
                                        }
                                        {
                                            (item.network_id === 4 || item.network_id === '4') &&
                                            <img src={`/assets/images/BNB.svg`} />
                                        }
                                        {
                                            (item.network_id === 4 || item.network_id === '4') &&
                                            <img src={`/assets/images/BNB.svg`} />
                                        }
                                        {
                                            (item.type_id === 6) &&
                                            <img src={`/assets/images/bitcoin.svg`} />
                                        }
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <UncontrolledButtonDropdown
                                            className="d-inline-block">
                                            <DropdownToggle
                                                className="btn header-item waves-effect button-menu-dots"
                                                id="page-header-user-dropdown"
                                                tag="button"
                                            >
                                                <i className="fa fa-ellipsis-v"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem tag="a" href={'#'}
                                                    to={'#'} onClick={() => {
                                                        segmentTrack('Go to edit collection')
                                                        manageRoute()
                                                    }}
                                                >
                                                    <i className="bx bx-edit font-size-17 align-middle mr-1"></i>
                                                    {props.t(`Edit`)}
                                                </DropdownItem>
                                                <DropdownItem tag="a" href="/#" onClick={(e) => {
                                                    e.preventDefault()
                                                    _deleteItem(item.id)
                                                    segmentTrack('Delete collection')
                                                }}>
                                                    <i className="bx bx-trash font-size-17 align-middle mr-1"></i>
                                                    {props.t(`Delete`)}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>

                            </div>
                        </CardFooter>
                    </Card>
                )
            }
            {
                display === 'list' && (
                    <div className={cn('rowcard', showFullRow ? 'hauto' : '')}>
                        <Link className='rowcard-img' to={'#'} onClick={manageRoute}>
                            {
                                showImage &&
                                <img alt={item_name} src={item_image_id || '/assets/images/avatar-2.png'} />
                            }
                            {
                                !showImage &&
                                <video id='video' alt={item_name} src={item_image_id} className='collections-token-resume-card-video' style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                    muted playsInline autoPlay={true}>
                                    {/* <source type="video/mp4" /> */}
                                    Your browser does not support the video tag.
                                </video>
                            }
                        </Link>
                        <div className={cn('rowcard-parent', showFullRow ? 'hauto' : '')}>
                            <div className='rowcard-parent-top'>
                                <Link title={item_name} to={'#'} onClick={manageRoute}>
                                    {item_name}
                                </Link>
                                <div className='rowcard-option' onClick={() => { setShowFullRow(!showFullRow) }}>
                                    <div className={cn('rowcard-option-type', item.is_generative ? 'generative' : 'fixed')}>
                                        {
                                            (item.type_id + '') === '3' &&
                                            <span>1 / 1</span>
                                        }
                                        {
                                            (item.type_id + '') === '4' &&
                                            <span>Quick Mint</span>
                                        }
                                        {
                                            (item.type_id + '') === '5' &&
                                            <span>Ticket</span>
                                        }
                                        {
                                            (item.type_id + '') === '6' &&
                                            <span>Ordinal</span>
                                        }
                                        {
                                            ((item.type_id + '') !== '3' && (item.type_id + '') !== '4' && (item.type_id + '') !== '5') && item.is_generative &&
                                            <span>Generative</span>
                                        }
                                        {
                                            ((item.type_id + '') !== '3' && (item.type_id + '') !== '4' && (item.type_id + '') !== '5') && !item.is_generative &&
                                            <span>Fixed art</span>
                                        }
                                    </div>
                                    <div className='rowcard-option-network'>
                                        {
                                            (item.network_id === 1 || item.network_id === '1') &&
                                            <img src={`/assets/images/Solana.svg`} />
                                        }
                                        {
                                            (item.network_id === 2 || item.network_id === '2') &&
                                            <img src={`/assets/images/Matic.svg`} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='rowcard-parent-bottom' onClick={() => { setShowFullRow(!showFullRow) }}>
                                <div className='rowcard-body-sub'>
                                    {
                                        status === 'yellow' &&
                                        <div className='rowcard-body-sub-banner'>
                                            <div className='rowcard-body-sub-banner-dot yellow'>
                                            </div>
                                            Processing
                                        </div>
                                    }
                                    {
                                        status === 'red' &&
                                        <div className='rowcard-body-sub-banner'>

                                            <div className='rowcard-body-sub-banner-dot red'>
                                            </div>
                                            Error
                                        </div>
                                    }
                                    {
                                        status === 'green' &&
                                        <div className='rowcard-body-sub-banner'>
                                            <div className='rowcard-body-sub-banner-dot green'>
                                            </div>
                                            Processed
                                        </div>
                                    }
                                    <span>
                                        {timeCalc} days ago
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='rowcard-body' id="second-app-title">
                            <Link title={item_name} to={'#'} onClick={manageRoute}>
                                {item_name}
                            </Link>
                            <Tooltip
                                anchorSelect="second-app-title"
                                anchorId="second-app-title"
                                place="bottom"
                                variant="info"
                                content="{item_name}"
                            />
                            <div className='rowcard-body-sub'>
                                {
                                    status === 'yellow' &&
                                    <div className='rowcard-body-sub-banner'>
                                        <div className='rowcard-body-sub-banner-dot yellow'>
                                        </div>
                                        Processing
                                    </div>
                                }
                                {
                                    status === 'red' &&
                                    <div className='rowcard-body-sub-banner'>

                                        <div className='rowcard-body-sub-banner-dot red'>
                                        </div>
                                        Error
                                    </div>
                                }
                                {
                                    status === 'green' &&
                                    <div className='rowcard-body-sub-banner'>
                                        <div className='rowcard-body-sub-banner-dot green'>
                                        </div>
                                        Processed
                                    </div>
                                }
                                <span>
                                    {timeCalc} days ago
                                </span>
                            </div>
                        </div> */}
                        {/* <div className='rowcard-option'>
                            <div className={cn('rowcard-option-type', item.is_generative ? 'generative' : 'fixed')}>
                                {
                                    item.is_generative &&
                                    <span>Generative</span>
                                }
                                {
                                    !item.is_generative &&
                                    <span>Fixed art</span>
                                }
                            </div>
                            <div className='rowcard-option-network'>
                                {
                                    (item.network_id === 1 || item.network_id === '1') &&
                                    <img src={`/assets/images/Solana.svg`} />
                                }
                                {
                                    (item.network_id === 2 || item.network_id === '2') &&
                                    <img src={`/assets/images/Matic.svg`} />
                                }
                            </div>
                        </div> */}
                        <div className='rowcard-action'>
                            <UncontrolledButtonDropdown
                                className="d-inline-block">
                                <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                >
                                    <i className="fa fa-ellipsis-v"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem tag="a" href={(!!item.is_locked || ((item.type_id + '') === '3' && item.is_deployed)) ? `/collections/view/${item.id}` : (item.is_generative) ? `/collections/generative/settings/${item.id}` : `/collections/settings/${item.id}`}
                                        onClick={() => {
                                            segmentTrack('Go to edit collection')
                                        }}>
                                        <i className="bx bx-edit font-size-17 align-middle mr-1"></i>
                                        {props.t(`Edit`)}
                                    </DropdownItem>
                                    <DropdownItem tag="a" href="/#" onClick={(e) => {
                                        e.preventDefault()
                                        _deleteItem(item.id)
                                        segmentTrack('Delete collection')
                                    }}>
                                        <i className="bx bx-trash font-size-17 align-middle mr-1"></i>
                                        {props.t(`Delete`)}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>

                )
            }
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(DataTableCard))
