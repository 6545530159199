import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import { GeneralContext } from '../../context/'
import { db } from "../../db"
import Table from './components/Datatable'
import { scrollTop } from '../../helpers/utils'

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);
    let [collections, setCollections] = useState([]);

    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            home: true,
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`My projects`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        _checkDbVersion();

    }, [currentProject])
    const parentIco = <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>

    const _checkDbVersion = () => {
        db.on("versionchange", function () {
            db.close(); // Allow other page to upgrade schema.
            db.open() // Reopen the db again.
                .then(() => {
                    // New table can be accessed from now on.
                }).catch(err => {
                    // Failed to open. Log or show!
                });
            return false; // Tell Dexie's default implementation not to run.
        })
    }

    return (
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    <div className='container-deploy'>
                        <Table />

                    </div>
                    <Row>
                        <Col sm={12} className='backStart'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    scrollTop()
                                }}
                            >
                                {props.t(`Back to start`)}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))