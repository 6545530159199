export default [
    {
        label: '(+52) México',
        value: '+52',
        active: true
    },
    {
        label: '(+1) USA',
        value: '+1',
        active: true
    }
]