import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { reactI18nextModule } from "react-i18next"

import _es_MX from "./locales/es/translation.json"
import _en_US from "./locales/en/translation.json"

// the translations
const resources = {
    es: {
        translation: _es_MX,
    },
    en: {
        translation: _en_US,
    },
}

i18n.use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        }
    })

export default i18n