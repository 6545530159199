import React, { useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { toastrError } from "../../helpers/Toastr"
import ApiService from '../../data/services/ApiService'

const Login = (props) => {
    const [userId] = useParams()
    const [verificationCode] = useParams()

    useEffect(() => {
        (async () => {
            try {
                const { success } = await ApiService.verifyAccount({
                    verificationCode,
                    userId
                })

                if (!success) {
                    toastrError(props.t(`We couldn't verify your account, want to try again?`))

                    return
                }

                props.history.push('/login')
            } catch (error) {
                props.history.push(`/login`)
            }
        })()

        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment></React.Fragment>
    )
}

export default withRouter(Login)