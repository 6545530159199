import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import {
    Button, Col, Container, FormGroup,
    Input, Label, Row
} from "reactstrap"
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { useParams } from 'react-router-dom'
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';
import TabHeaderMenu from './components/tab-header-menu'
import { ReactComponent as Labelicon } from '../../assets/images/Labelicon.svg';
import { ReactComponent as ImageIcon } from '../../assets/images/ImageIcon.svg';
import { toastrSuccess, toastrError } from '../../helpers/Toastr'
import Dropzone from "./components/dropzone"
import { faArrowLeft, faFileAlt, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as btc from '@scure/btc-signer';
import { getAddress, signTransaction } from 'sats-connect'
import { setUserOrdinal } from '../../store/actions'
import DataService from '../../helpers/DataService'
import { toBase64 } from '../../helpers/utils'
import { loadState } from "../../store/localStorage"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext
    const { collection_id } = useParams();

    const [status_view, setStatusView] = useState('inscription')
    const [fileName, setFileName] = useState()

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: 'Ordinals',
        })
    }, [])

    useEffect(() => {
        if (loadState()?.Auth) {
            if (loadState()?.Auth?.ordinal) {
                console.log('ordinal', loadState()?.Auth?.ordinal);
                const { body, file, status_view, labelEditor } = loadState()?.Auth?.ordinal
                if (status_view) {
                    setStatusView(status_view)
                    if (status_view === 'text') {
                        setLabelEditor(labelEditor)
                    } else if (status_view === 'image') {

                    }
                }
            }
        }

    }, [])



    const disableAll = false;
    let handleAssetFilled = (asset) => {
        console.log('handleAssetFilled', asset);
        if (!!asset) {
            toastrSuccess('File uploaded')
            setFile(asset)
            setFileName(asset.name)

        }
    }

    const _handleSubmit = (e) => {
        if (status_view === 'inscription') {
            toastrError('You need to add assets to continue')
            return;
        }
        if (status_view === 'image' || status_view === 'file') {
            // if (!description) {
            //     toastrError('You need to write a description to continue')
            //     return;
            // }
            if (!name) {
                toastrError('You need to write a name to continue')
                return;
            } if (!file) {
                toastrError('You need to upload a file to continue')
                return;
            }
            const body = {
                description: !!description ? description : '',
                name
            }

            _continueOrdinal(file, body)

        } else if (status_view === 'text') {
            if (!labelEditor) {
                toastrError('You need to inscribe a text ordinal to continue')
                return;
            }
            const blob = new Blob([labelEditor], { type: 'plain/text' });
            const body = {
                name: label_name
            }
            _continueOrdinal(blob, body)
        }

    }


    const _continueOrdinal = async (file, body) => {
        console.log('file', file);
        console.log('body', body);
        // const url = window.URL.createObjectURL(file);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // // the filename you want
        // a.download = 'file.txt';
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
        const _file = await toBase64(file)
        const ordinal = {
            file: _file,
            body,
            status_view,
            labelEditor,
        }
        console.log('ordinal', ordinal);
        props.setUserOrdinal(ordinal)
        if (file && body) {
            props.history.push('/collections/ordinals/transaction')
        }
    }

    // useEffect(() => {
    //     DataService.getBtcInscribeCostRates().then(response => {
    //         console.log('response getBtcInscribeCostRates', response);
    //         setHalfHourFee(response.data.halfHourFee)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    //     DataService.getBtcInscribeCostPayment().then(response => {
    //         console.log('response getBtcInscribeCostPayment', response);
    //         addressPayment = response.data.address
    //         setAddressPayment(response.data.address)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }, [])


    /**
     * Upload image
     */
    const [name, setName] = useState('')
    const [file, setFile] = useState()
    const [description, setDescription] = useState('')

    /**
     * Upload text
     */

    const [labelEditor, setLabelEditor] = useState('')
    const [label_name, setLabelName] = useState('')


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={10} percentage={10}></ProgressHeader>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={0} active={1} ></TabHeaderMenu>
                        <div className='collections-settings-header-button '>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                onClick={(e) => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Set transaction fee`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all ordinals'>
                        <section className='collections-ordinals'>
                            {
                                status_view === 'inscription' && (
                                    <div className='collections-ordinals-inscription'>
                                        <p className='collections-ordinals-title'>
                                            Select your inscription type
                                        </p>
                                        <span className='collections-ordinals-subtitle'>
                                            Inscribe any image or text directly onto the Bitcoin blockchain.
                                        </span>
                                        <Row>
                                            <Col sm={12}>
                                                <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                    <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                    <span>How to inscribe an ordinal</span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <div className='collections-ordinals-row'>
                                            <span className='collections-ordinals-box' onClick={() => setStatusView('image')}>
                                                <span className='collections-ordinals-box-icon'>
                                                    <ImageIcon></ImageIcon>
                                                </span>
                                                <span className='collections-ordinals-box-label'>
                                                    Inscribe an Image
                                                </span>
                                            </span>
                                            <span className='collections-ordinals-box' onClick={() => setStatusView('text')}>
                                                <span className='collections-ordinals-box-icon'>
                                                    <Labelicon></Labelicon>
                                                </span>
                                                <span className='collections-ordinals-box-label'>
                                                    Inscribe text
                                                </span>
                                            </span>
                                            <span className='collections-ordinals-box' onClick={() => setStatusView('file')}>
                                                <span className='collections-ordinals-box-icon'>
                                                    <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                                                </span>
                                                <span className='collections-ordinals-box-label'>
                                                    Inscribe a File
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                status_view === 'image' && (
                                    <div className='collections-ordinals-inscription'>
                                        <Row>
                                            <Col sm={6}>
                                                <Button
                                                    style={{ width: 'auto' }}
                                                    type="button"
                                                    color="primary"
                                                    className="theme-button-collaborator"
                                                    onClick={() => { setStatusView('inscription') }}
                                                >
                                                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                                    {props.t(`Change inscription type`)}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <p className='collections-ordinals-title'>
                                            Inscribing an Image Ordinal
                                        </p>
                                        <span className='collections-ordinals-subtitle'>
                                            Upload your files and we will inscribe and send the inscriptions. Make sure it's perfect. Inscriptions can be viewed by anyone and they can never be changed or deleted.
                                        </span>
                                        <Row>
                                            <Col sm={12}>
                                                <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                    <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                    <span>How to inscribe an ordinal</span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <Dropzone accept={'*'} onAssetFull={handleAssetFilled} fileName={fileName} />
                                        <div className='collections-ordinals-help'>
                                            <ul>
                                                <li>High resolution usually results in higher fees</li>
                                                <li>Accepted file formats: WEBP (recommended), JPEG, PNG, SVG and GIF.</li>
                                            </ul>
                                            <span>
                                                Bitcoin block space is limited, so we may resize, compress, and convert your
                                                image file to WEBP format. For best results, keep your still images under 60KB and your GIFs under 200KB. <span className='collections-ordinals-help-more'>Learn more</span>
                                            </span>
                                        </div>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Name`)}
                                                    </Label>
                                                    <Input
                                                        required
                                                        value={name}
                                                        type="text"
                                                        id='name'
                                                        className="input-white-input circle"
                                                        placeholder='Name'
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('name').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('name').classList.remove('input-error')
                                                            }
                                                            setName(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                <span className='collections-ordinals-help-sub'>
                                                    Please consider that as of now, the ordinal name and description does not get inscribed.
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Description`)}
                                                    </Label>

                                                    <Input
                                                        required
                                                        value={description}
                                                        style={{ padding: 10 }}
                                                        type="textarea"
                                                        id='description'
                                                        disabled={disableAll}
                                                        className="input-white-input circle input-text-area"
                                                        placeholder="The description will be included on the item's detail page underneath 
                                                    its image. Markdown syntax is supported."
                                                        onChange={event => {
                                                            const { value } = event.target

                                                            if (!value) {
                                                                document.getElementById('description').classList.add('input-error')
                                                            } else {
                                                                // if (value.length > 1000) {
                                                                //     toastrError('Only 1000 characters or less can be added for the description field')
                                                                //     document.getElementById('description').classList.add('input-error')
                                                                // } else {
                                                                //     document.getElementById('description').classList.remove('input-error')
                                                                // }
                                                                document.getElementById('description').classList.remove('input-error')

                                                            }
                                                            setDescription(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                {/* <span className='collections-ordinals-help-sub'>
                                                    Markdown is supported
                                                </span> */}
                                            </Col>


                                        </Row>
                                    </div>
                                )
                            }
                            {
                                status_view === 'text' && (
                                    <div className='collections-ordinals-inscription'>
                                        <Row>
                                            <Col sm={6}>
                                                <Button
                                                    style={{ width: 'auto' }}
                                                    type="button"
                                                    color="primary"
                                                    className="theme-button-collaborator"
                                                    onClick={() => { setStatusView('inscription') }}
                                                >
                                                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                                    {props.t(`Change inscription type`)}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <p className='collections-ordinals-title'>
                                            Inscribing a Text Ordinal
                                        </p>
                                        <span className='collections-ordinals-subtitle'>
                                            Add your text below. We will inscribe exactly what you type in the following text area. Make sure it’s perfect. Inscriptions can be viewed by anyone and they can never be changed or deleted.
                                        </span>
                                        <Row>
                                            <Col sm={12}>
                                                <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                    <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                    <span>How to inscribe an ordinal</span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Name`)}
                                                    </Label>
                                                    <Input
                                                        required
                                                        value={label_name}
                                                        type="text"
                                                        id='label_name'
                                                        className="input-white-input circle"
                                                        placeholder='Name'
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('label_name').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('label_name').classList.remove('input-error')
                                                            }
                                                            setLabelName(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                <span className='collections-ordinals-help-sub'>
                                                    Please consider that as of now, the ordinal name does not get inscribed.
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Text to be inscribed`)}
                                                    </Label>

                                                    <Input
                                                        required
                                                        value={labelEditor}
                                                        style={{ padding: 10 }}
                                                        type="textarea"
                                                        id='labelEditor'
                                                        disabled={disableAll}
                                                        className="collections-ordinals-inscribed input-white-input circle input-text-area "
                                                        placeholder="Insert here the text that you want to inscribe. The inscription will be a Text file."
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('labelEditor').classList.add('input-error')
                                                            } else {
                                                                if (value.length > 1000) {
                                                                    toastrError('Only 1000 characters or less can be added for the description field')
                                                                    document.getElementById('labelEditor').classList.add('input-error')
                                                                } else {
                                                                    document.getElementById('labelEditor').classList.remove('input-error')
                                                                }
                                                            }
                                                            setLabelEditor(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                {/* <span className='collections-ordinals-help-sub'>
                                                    Markdown is supported
                                                </span> */}
                                            </Col>


                                        </Row>
                                    </div>
                                )
                            }
                            {
                                status_view === 'file' && (
                                    <div className='collections-ordinals-inscription'>
                                        <Row>
                                            <Col sm={6}>
                                                <Button
                                                    style={{ width: 'auto' }}
                                                    type="button"
                                                    color="primary"
                                                    className="theme-button-collaborator"
                                                    onClick={() => { setStatusView('inscription') }}
                                                >
                                                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                                    {props.t(`Change inscription type`)}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <p className='collections-ordinals-title'>
                                            Inscribing a File Ordinal
                                        </p>
                                        <span className='collections-ordinals-subtitle'>
                                            Upload your files and we will inscribe and send the inscriptions. Make sure it's perfect. Inscriptions can be viewed by anyone and they can never be changed or deleted.
                                        </span>
                                        <Row>
                                            <Col sm={12}>
                                                <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                    <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                    <span>How to inscribe an ordinal</span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <Dropzone accept={'image/*,.mp4,.gif'} multiple={false} onAssetFull={handleAssetFilled} fileName={fileName} />
                                        <div className='collections-ordinals-help'>
                                            <ul>
                                                <li>High resolution usually results in higher fees</li>
                                                <li>Accepted file formats:Any type of file</li>
                                            </ul>
                                            <span>
                                                Bitcoin block space is limited, so we may resize, compress, and convert your image file to WEBP format. For best results, keep your still images under 60KB and your GIFs under 200KB.
                                            </span>
                                        </div>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Name`)}
                                                    </Label>
                                                    <Input
                                                        required
                                                        value={name}
                                                        type="text"
                                                        id='name'
                                                        className="input-white-input circle"
                                                        placeholder='Name'
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('name').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('name').classList.remove('input-error')
                                                            }
                                                            setName(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                <span className='collections-ordinals-help-sub'>
                                                    Please consider that as of now, the ordinal name and description does not get inscribed.
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Description`)}
                                                    </Label>

                                                    <Input
                                                        required
                                                        value={description}
                                                        style={{ padding: 10 }}
                                                        type="textarea"
                                                        id='description'
                                                        disabled={disableAll}
                                                        className="input-white-input circle input-text-area"
                                                        placeholder="The description will be included on the item's detail page underneath 
                                                    its image. Markdown syntax is supported."
                                                        onChange={event => {
                                                            const { value } = event.target

                                                            if (!value) {
                                                                document.getElementById('description').classList.add('input-error')
                                                            } else {
                                                                if (value.length > 1000) {
                                                                    toastrError('Only 1000 characters or less can be added for the description field')
                                                                    document.getElementById('description').classList.add('input-error')
                                                                } else {
                                                                    document.getElementById('description').classList.remove('input-error')
                                                                }
                                                            }
                                                            setDescription(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                                {/* <span className='collections-ordinals-help-sub'>
                                                    Markdown is supported
                                                </span> */}
                                            </Col>


                                        </Row>
                                    </div>
                                )
                            }

                            <Row>
                                <Button
                                    type="button"
                                    color="secundary"
                                    className="theme-button-secundary mobile"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        _handleSubmit()
                                    }}
                                >
                                    {props.t(`Set transaction fee`)}
                                </Button>
                            </Row>
                        </section>
                        <div className='container-video-all' style={{ flex: '0 1' }}>
                            <div className='container-video'>
                                <p>How to inscribe an ordinal</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                            </div>

                        </div>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ordinal: state.Auth?.ordinal
    }
}


export default (connect(mapStatetoProps, {
    setUserOrdinal
}))(withRouter(withNamespaces()(Create)))
