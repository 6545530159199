import {
    SET_ACTIVE_PROJECT,
    UPDATE_PROJECT
} from "./actionTypes"

export const setActiveProject = (project) => {
    return {
        type: SET_ACTIVE_PROJECT,
        data: project
    }
}

export const updateProject = (project) => {
    return {
        type: UPDATE_PROJECT,
        data: project
    }
}