import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
} from "reactstrap"

import { GeneralContext } from '../../../../context'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"


const ComponentsTabs = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext


    return (
        <React.Fragment>

            <h1>
                components tabs
            </h1>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(ComponentsTabs))