import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    CardBody,
    CardFooter,
} from "reactstrap"
import cn from 'classnames';

import { GeneralContext } from '../../../context'
import { Link } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import layer from "../../../assets/images/layers-color.png"

const DataTable = (props) => {
    const filteredData = [{
        id: 'generative',
        name: 'Generative Art',
        subtitle: 'Coming soon',
        description: 'Bring in your layers, we’ll generate the art for you',
        avatar: layer,
        // class: 'gray-scale',
        route: '/collections/generative',
        disabled: false,
    }, {
        id: 'fixed',
        name: 'Fixed Art',
        description: 'Art ready? Let’s prepare them to deploy',
        avatar: '/assets/images/avatar-2.png',
        route: '/collections/create',
        disabled: false,
    }]


    useEffect(() => {
        (async () => {
            // await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <React.Fragment>
            <Row className='collections-choose-type-row-center'>
                {filteredData.map(
                    (item, key) => (
                        // <Col xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
                        <div className='card-borderless collections-choose-type' key={key}>
                            <Link to={item.disabled ? '#' : item.route}
                            >
                                <CardBody>
                                    <div className={cn('card-picture', item.class)}>
                                        <img style={{ width: '100%' }} alt={item.name} src={item.avatar} />
                                    </div>
                                </CardBody>
                            </Link>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill card-title" style={{ textAlign: 'center' }}>
                                        <Link to={item.disabled ? '#' : item.route}
                                        >
                                            {item.name}
                                        </Link>
                                    </div>

                                </div>
                                <div className="d-flex font-size-20">

                                    <div className="flex-fill" style={{ textAlign: 'center' }}>
                                        {item.description}
                                    </div>

                                </div>
                                {
                                    item.subtitle &&
                                    <div className="d-flex font-size-20">

                                        <div className="flex-fill c-label-primary" style={{ textAlign: 'center' }}>
                                            {item.subtitle}
                                        </div>
                                    </div>
                                }
                            </CardFooter>
                        </div>
                        // </Col>
                    )
                )}

            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(DataTable))
