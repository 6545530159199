import React from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';

const TabHeaderMenu = (props) => {
    const { step_number, active, collection_id, is_deployed } = props;

    return (
        <section className='collections-settings-header-container'>
            <div className={cn('collections-settings-header-item', active === 1 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 0 && !is_deployed) {
                        props.history.push(`/collections/oneandone`)
                    }
                }}>
                <span>1. Token Details</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 2 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 1) {
                        props.history.push(`/collections/oneandone/settings`)
                    }
                }}>
                <span>2. Minting Settings</span>
            </div>
        </section >
    );
};

export default withRouter(withNamespaces()(TabHeaderMenu))