import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Form,
    Button,
    Col,
    Input,
    FormGroup,
    Label,
    ButtonGroup

} from "reactstrap"

import { GeneralContext } from '../../../../context'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../db";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckCircle, faCloudArrowUp, faComputer, faTablet, faMobileAlt } from '@fortawesome/free-solid-svg-icons'

import DataService from '../../../../helpers/DataService'
import backgroundImage from '../../../../assets/images/all-city-shark.png'
import backgroundImageMockup from '../../../../assets/images/Launchpad-Neefter-mockup.png'
import image_source from '../../../../assets/images/NeefterSVG6.svg'

import cn from 'classnames';

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [logotype, setLogotype] = useState('')
    const [background, setBackground] = useState('')
    const [favicon, setFavicon] = useState('')
    const [hficon, setHficon] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)

    const { project_id, activeForm, collection_id } = props;
    const [image_id, setImageId] = useState('')
    const [is_deployed, setIsDeployed] = useState(true)

    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }
    let [display, setDisplay] = useState('tablet');

    useEffect(() => {
        if (!collection_id) return;

        DataService.getCollectionById(collection_id).then(
            response => {

                if (response && response.data) {

                    const { brackground, candy_machine_address, csv, description, external_url,
                        family, favicon, high_fidelity_icon, id, image_id, is_candy_machine, is_deployed,
                        is_deploying, launch_date, logo, name, network_id, organization_id, price, project_id,
                        slug, subdomain, symbol, seller_fee_basis_points,
                        creators, blockchain } = response.data;
                    setHficon(high_fidelity_icon || '')
                    setLogotype(logo || '')
                    setBackground(brackground || '')
                    setImageTempo(brackground || '')
                    setImageId(image_id || '')
                    setFavicon(favicon || '')
                    setIsDeployed(is_deployed)
                }
            })
            .finally(() => {
                // if (collections_settings, assets_bd) {
                //     _checkSettings();
                //     setAssets(assets_bd)
                // }
            })



    }, [collection_id])


    const _handleFileLogotype = (event) => {
        let files = event?.target.files

        // const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif, */ico, image/x-png, image/*']


        // if (types.every(type => files[0] && files[0].type !== type)) {
        //     return
        // }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                logo: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setLogotype(put_response.data.logo)
                document.getElementById('logotype').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }

    const _handleFileBackground = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            const body = {
                brackground: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setBackground(put_response.data.brackground)
                setImageTempo(URL.createObjectURL(files[0]));
                document.getElementById('background').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const _handleFileFavicon = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                favicon: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setFavicon(response.data.id)
                document.getElementById('favicon').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const _handleFileHfIcon = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                high_fidelity_icon: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setHficon(response.data.id)
                document.getElementById('hficon').classList.remove('input-error')
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }
    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)
        return DataService.uploadImage(formData)
    }
    const _handleImageUpload = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            const body = {
                image_id: response.data.id,
            }
            return DataService.putCollectionById(collection_id, body).then(put_response => {
                setImageId(put_response.data.image_id)
                setLoading(false)
            });
        }).catch(error => {
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <section className='collections-minting' style={{ width: '100%' }}>
                <div className='collections-minting-top'>
                    <section className='container-deploy' style={{ marginBottom: '12px', marginTop: '12px' }}>
                        <Form className='collections-minting-top' id="minting-form" style={{ width: '100%' }}>
                            <section className='collections-minting-top-form'>
                                <div className='collections-minting-top-form-img'>
                                    <div className='collections-create-form-row-img'>
                                        <p>Collection image</p>
                                        <div className='card-picture' style={img_style} onClick={() => document.getElementById('upload_img')?.click()}>
                                            <img style={{ width: '100%' }} alt="item" src={image_id ? image_id : '/assets/images/avatar-2.png'} />
                                            <input type="file"
                                                id="upload_img"
                                                hidden
                                                onChange={event => {
                                                    _handleImageUpload(event)
                                                }}
                                            />
                                        </div>
                                        <div className='collections-create-form-row-ico' onClick={() => document.getElementById('upload_img')?.click()}>
                                            <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                        </div>
                                        {/* <p className='input-label-primary'>Upload your image</p> */}
                                        {/* <Button
                                            style={{ marginTop: '12px' }}
                                            type="button"
                                            color="primary"
                                            onClick={() => document.getElementById('upload_img')?.click()}
                                            className="theme-button-upload"
                                        >
                                            {props.t(`Upload`)}
                                        </Button> */}
                                    </div>
                                </div>
                                <div className='collections-minting-top-form-img'>
                                    <div className='text-align-left'>
                                        {/* <CardTitle>{props.t(`General settings`)}</CardTitle> */}
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`Logotype`)}
                                                        <span className='input-label-mini'>.jpg o .png</span>
                                                    </Label>
                                                    <div className='collections-minting-control' >
                                                        <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_logotype')?.click()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_logotype')?.click()}>
                                                            <Input
                                                                required
                                                                value={logotype}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                disabled
                                                                id="logotype"
                                                            />
                                                            <input type="file"
                                                                id="upload_logotype"
                                                                hidden
                                                                accept='image/*'
                                                                onChange={event => {
                                                                    _handleFileLogotype(event)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={cn('collections-minting-control-icon', !!logotype ? 'active' : '')} >
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        </div>

                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`Background`)}
                                                        <span className='input-label-mini'>.jpg o .png</span>
                                                    </Label>
                                                    <div className='collections-minting-control' >
                                                        <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_background')?.click()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_background')?.click()}>
                                                            <Input
                                                                required
                                                                value={background}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                disabled
                                                                id="background"
                                                            />
                                                            <input type="file"
                                                                id="upload_background"
                                                                hidden
                                                                onChange={event => {
                                                                    _handleFileBackground(event)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={cn('collections-minting-control-icon', !!background ? 'active' : '')} >
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`Favicon`)}
                                                        <span className='input-label-mini'>.jpg o .png o .ico</span>
                                                    </Label>
                                                    <div className='collections-minting-control' >
                                                        <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_favicon')?.click()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_favicon')?.click()}>
                                                            <Input
                                                                required
                                                                value={favicon}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                disabled
                                                                id="favicon"
                                                            />
                                                            <input type="file"
                                                                id="upload_favicon"
                                                                hidden
                                                                onChange={event => {
                                                                    _handleFileFavicon(event)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={cn('collections-minting-control-icon', !!favicon ? 'active' : '')} >
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        </div>

                                                    </div>
                                                </FormGroup>

                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`High fidelity icon `)}
                                                        <span className='input-label-mini'>.jpg o .png</span>
                                                    </Label>
                                                    <div className='collections-minting-control' >
                                                        <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_hficon')?.click()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_hficon')?.click()}>
                                                            <Input
                                                                required
                                                                value={hficon}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                id="hficon"
                                                                disabled
                                                            />
                                                            <input type="file"
                                                                id="upload_hficon"
                                                                hidden
                                                                onChange={event => {
                                                                    _handleFileHfIcon(event)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={cn('collections-minting-control-icon', !!hficon ? 'active' : '')} >
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    type="button"
                                                    color="secundary"
                                                    className="theme-button-secundary mobile"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        const ref = document.getElementById('subdomain_ref');
                                                        if (ref) {
                                                            ref.click();
                                                        }
                                                    }}
                                                >
                                                    {props.t(`Visit site`)}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </section>

                            <div className='collections-minting-top-group'>
                                <ButtonGroup className='input-white-buttongroup'>
                                    <Button className={cn(display === 'pc' ? 'selected' : '')} onClick={() => {
                                        setDisplay('pc')
                                    }}>
                                        <FontAwesomeIcon icon={faComputer}></FontAwesomeIcon>
                                    </Button>
                                    <Button className={cn(display === 'tablet' ? 'selected' : '')}
                                        onClick={() => {
                                            setDisplay('tablet')
                                        }}>
                                        <FontAwesomeIcon icon={faTablet}></FontAwesomeIcon>
                                    </Button>
                                    <Button className={cn(display === 'phone' ? 'selected' : '')}
                                        onClick={() => {
                                            setDisplay('phone')
                                        }}>
                                        <FontAwesomeIcon icon={faMobileAlt}></FontAwesomeIcon>
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div className={cn('collections-minting-top-display', display)}>
                                <div className='collections-minting-top-display-container'>
                                    <div className='collections-minting-top-display-background'>
                                        <img alt="item-1"
                                            src={background ? background : backgroundImage} />
                                    </div>
                                    <div className='collections-minting-top-display-connect'>
                                        <div className='collections-minting-top-display-connect-black'>
                                            <div className='collections-minting-top-display-connect-primary'>
                                                <span>Connect wallet</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='collections-minting-top-display-logo'>
                                        {
                                            logotype &&
                                            <img alt="item-3"
                                                src={logotype} />
                                        }
                                    </div>
                                    <div className='collections-minting-top-display-powered'>
                                        <div className="custom-footer">
                                            <a href="https://twitter.com/Neefter_">
                                                Powered by  <img src={image_source}></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    </section>
                </div>
            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))