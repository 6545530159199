import React, { useEffect, useContext } from 'react'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import DataService from '../../helpers/DataService'
import { setToken, loginSuccess, loginUser, apiError } from '../../store/actions'
import { GeneralContext } from '../../context/'

const Login = (props) => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext

    useEffect(() => {
        setLoading(true);
        window.addEventListener('@magic/ready', (event) => {
            const { magic, idToken, userMetadata, oauth } = event.detail;

            if (!loading) {
                setEvent(event)
                setLoading(true);
            }
        });

        const script = document.createElement('script');

        script.src = "https://auth.magic.link/pnp/callback";
        script.setAttribute('data-magic-publishable-api-key', "pk_live_9E32243E04763494")


        document.body.appendChild((script));

        return () => {
            document.body.removeChild(script);
        }

    }, [])

    let setEvent = (event) => {

        const { magic, idToken, userMetadata, oauth } = event.detail;
        console.log('oauth', oauth);
        const body = {
            did_token: idToken,
            oauth: oauth || 'null',
        }
        return DataService.postMagicLogin(body).then(response => {
            if (response && response.data) {

                props.setToken(response.data.token)
                DataService.getAuthWhoami().then(response => {
                    const { is_twitter_nft_redeem, is_twitter_user } = response.data
                    window.analytics.identify(response.data.id, {
                        userId: response.data.id,
                        companies: response.data.company_name,
                        // name: `${response.data.name} ${response.data.last_name}`,
                        // firstName: response.data.name,
                        // lastName: response.data.last_name,
                        email: response.data.email,
                        created: new Date()
                    })
                    props.loginSuccess(response.data)
                    if (!is_twitter_nft_redeem && is_twitter_user) {
                        props.history.push('/twitter/verify')
                    } else {
                        props.history.push('/collections')
                    }
                })
            }
        })



    }



    return (
        <React.Fragment>
            <script
                src="https://auth.magic.link/pnp/callback"
                data-magic-publishable-api-key="pk_live_9E32243E04763494">
            </script>
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    const { error, profile } = state.Auth

    return { error, profile }
}

export default withRouter(
    connect(mapStatetoProps, {
        loginUser, apiError, setToken,
        loginSuccess,
    })(Login)
)