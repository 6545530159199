import React, { useContext, useEffect, useState } from 'react'
import {
    Button, Card,
    CardBody,
    CardTitle, Col, Form, FormGroup, Input, Label, Row
} from 'reactstrap'

import { isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/min/locales'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import DefaultAvatar from '../../../assets/images/avatar-1.png'
import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { GeneralContext } from '../../../context/'
import activeCountries from '../../../data/activeCountries'
import ApiService from '../../../data/services/ApiService'
import { updateAvatar, updateProfile } from '../../../store/actions'

moment.locale('es')

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [user, setUser] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        countryCode: '',
        phone: '',
        country: '',
        password: ''
    })
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    useEffect(() => {
        setUser(props.user)
    }, []) // eslint-disable-line

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastrError(props.t(`Images only`), 'Error')

            return
        }

        setLoading(true)
        fileUpload(files[0]).then((response) => {
            if (!response.data.success) {
                toastrError(props.t(`I couldn't update your avatar`), 'Error')
                setLoading(false)
                return
            }

            setUser({
                ...user,
                avatar: response.data.fileURL
            })

            setLoading(false)
            props.updateAvatar(response.data.fileURL)
            toastrSuccess(props.t(`Avatar updated`), props.t(`Success!`))
        }).catch(error => {

            setLoading(false)
            toastrError(props.t(`I couldn't update your avatar`), 'Error')
        })
    }

    const fileUpload = (file) => {
        try {
            setLoading(true)
            const formData = new FormData()

            formData.append('userId', user.id)
            formData.append('file', file)

            return ApiService.uploadUserAvatar(formData)
        } catch (error) {
            toastrError(props.t(`I couldn't upload your avatar, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const _handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const response = await ApiService.updateUser(user)
            if (!response) {
                toastrError(props.t(`I couldn't update your data`))

                return
            }

            props.updateProfile(user)
            toastrSuccess(props.t(`Profile updated`), '¡Success!')
        } catch (error) {
            toastrError(props.t(`I couldn't update your data`))
        } finally {
            setLoading(false)
        }
    }

    const _handleUdatePassword = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            toastrError(props.t(`Make sure both passwords match`))

            return
        }

        try {
            setLoading(true)
            const response = await ApiService.updatePassword({
                userId: user.id,
                password
            })

            if (!response) {
                toastrError(props.t(`I couldn't update your password, please try again`))
                return
            }

            toastrSuccess(props.t(`Password updated`), '¡Success!')
        } catch (error) {
            toastrError(props.t(`I couldn't update your password, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const setAvatar = () => {
        if (!isEmpty(user)) {
            if (!isEmpty(user.avatar)) {
                return user.avatar
            }
        }

        return DefaultAvatar
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={3}>
                    <Card>
                        <CardBody>
                            <img
                                className="rounded rounded-circle avatar-lg"
                                width={150}
                                src={setAvatar()}
                                alt={user.firstName}
                            />

                            <br /><br />

                            <FormGroup>
                                <Label>{props.t(`Updated avatar`)}</Label>

                                <Input type="file"
                                    id="image"
                                    onChange={_handleFileUpload}
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={9}>
                    <Card>
                        <CardBody>
                            <CardTitle>{props.t(`Maintain your data up to date`)}</CardTitle>

                            <Form id="create" method="POST" onSubmit={_handleSubmit}>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`First name`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={user.firstName}
                                                onChange={event => setUser({
                                                    ...user,
                                                    firstName: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Last name`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={user.lastName}
                                                onChange={event => setUser({
                                                    ...user,
                                                    lastName: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`E-Mail`)} *
                                            </Label>
                                            <Input
                                                type="email"
                                                className="form-control"
                                                value={user.email}
                                                onChange={event => setUser({
                                                    ...user,
                                                    email: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Country code`)}
                                            </Label>
                                            <select className="custom-select"
                                                onChange={event => setUser({
                                                    ...user,
                                                    countryCode: event.target.value
                                                })}
                                                defaultValue={user.countryCode}>
                                                {activeCountries.map((item, i) => {
                                                    let selected = user.countryCode === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Phone`)} *
                                            </Label>
                                            <Input
                                                type="tel"
                                                className="form-control"
                                                value={user.phone}
                                                onChange={event => setUser({
                                                    ...user,
                                                    phone: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'right' }}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                        >
                                            <i className={`mdi mdi-check`}></i> {props.t(`Update profile`)}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle>{props.t(`Updated password`)}</CardTitle>

                            <Form id="create" method="POST" onSubmit={_handleUdatePassword}>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Password`)}
                                            </Label>
                                            <Input
                                                id="password"
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Confirm password`)}
                                            </Label>
                                            <Input
                                                id="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                value={confirmPassword}
                                                onChange={event => setConfirmPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'right' }}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                        >
                                            <i className={`mdi mdi-check`}></i> {props.t(`Updated password`)}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return { user: state.Auth.profile }
}

export default connect(
    mapStatetoProps, { updateAvatar, updateProfile }
)(withRouter(withNamespaces()(Index)))