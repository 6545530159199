import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import SettingsTab from './components/View-tabs/Settings'
import MintingTab from './components/View-tabs/MintingTab'
import FundsTab from './components/View-tabs/FundsTab'
import WhitelistTab from './components/View-tabs/whitelist'
import AirdropsTab from './components/View-tabs/airdrops'
import DashboardTab from './components/View-tabs/dashboard'

import classnames from 'classnames';
import { useParams } from 'react-router-dom'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import ProgressHeader from '../../components/Global/Progress-header'
import { db } from "../../db"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);
    const { currentProject, setProject, importDb } = generalContext
    let [project_id, setProjectId] = useState([]);
    let [status, setStatus] = useState('yellow')
    let [subdomain, setSubdomain] = useState('');
    let [full_subdomain, setFullSubdomain] = useState('');
    let [inputLoader, setInputLoader] = useState(false);
    let [fetched, setFetched] = useState(false)
    let [cache_zip, setCacheZip] = useState()
    let [count_traits, setCountTraits] = useState(0);

    const { collection_id } = useParams();
    let [collection, setCollection] = useState();

    let [is_deployed, setIsDeployed] = useState(false);

    useEffect(() => {

        if (!currentProject) return;
        setProjectId(currentProject.id)
    }, [currentProject])

    useEffect(() => {
        getCollection()
    }, [collection_id])

    const getCollection = () => {
        if (collection_id) {
            DataService.getCollectionById(collection_id).then(response => {

                const { brackground, candy_machine_address, csv, description, external_url,
                    family, favicon, high_fidelity_icon, id, image_id, is_candy_machine, is_deployed,
                    is_deploying, launch_date, logo, name, network_id, organization_id, price, project_id,
                    slug, subdomain, symbol, seller_fee_basis_points, is_generative,
                    creators, blockchain
                } = response.data;
                setCollection(response.data)
                setIsDeployed(is_deployed)
                setSubdomain(subdomain)
                setFullSubdomain(`https://${subdomain}.neefter.com`)

                if (!is_deployed && !is_deploying) {
                    status = 'red';
                    setStatus('red')
                } else if (!is_deployed && is_deploying) {
                    status = 'yellow';
                    setStatus('yellow')
                } if (is_deployed && !is_deploying) {
                    status = 'green';
                    setStatus('green')
                }

                if (response && response.data && response.data.cache_zip && is_generative) {
                    cache_zip = response.data.cache_zip
                    setCacheZip(response.data.cache_zip)
                    checkSettings()
                }

                setBreadcrumb({
                    icon: '',
                    parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    parentLink: `/collections`,
                    item: props.t(name),
                    extra: getExtraBreadcrumb(status)
                })
            })
        }
    }
    const checkSettings = async () => {
        const collections_settings = await db.collections_settings.toArray();
        const count_assets = await db.generative_assets.count();
        console.log('count_assets', count_assets);
        if (collections_settings.length > 0) {
            const setting = collections_settings[0];
            const { id } = setting;
            if (setting.cache_zip !== cache_zip) {
                return _manageZipData()
            }
            setCountTraits(count_assets)
        } else if (collections_settings.length > 0) {
            const setting = collections_settings[0];
            // if (setting.collection_id !== collection_id) {
            //     return _manageZipData()
            // }
            if (setting.cache_zip !== cache_zip) {
                return _manageZipData()
            }
        } else {
            return _manageZipData()
        }
    }

    const _manageZipData = async () => {
        if (!cache_zip) return;
        if (fetched) return;
        const n = await importDb(cache_zip)
        checkSettings();
    }
    const setCollectionReload = () => {
        getCollection()
    }

    function getExtraBreadcrumb(status) {
        return <div className='collections-settings-row-title' onClick={(e) => {
            props.push(`${subdomain}`)
        }}>
            {
                status === 'yellow' &&
                <div className='collections-settings-tab-banner'>

                    <div className='collections-settings-tab-banner-dot yellow'>
                    </div>
                    Processing
                </div>
            }
            {
                status === 'red' &&
                <div className='collections-settings-tab-banner'>

                    <div className='collections-settings-tab-banner-dot red'>
                    </div>
                    Error
                </div>
            }
            {
                status === 'green' &&
                <div className='collections-settings-tab-banner'>
                    <div className='collections-settings-tab-banner-dot green'>
                    </div>
                    Processed
                </div>
            }
        </div>
    }

    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }

    return (
        <React.Fragment>

            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={100} percentage={100}></ProgressHeader>

            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}  >
                                <span>Dashboard</span>
                            </div>
                            <div className='collections-settings-header-item disabled' >
                                <span>Acceslist & VIP</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '3' })} onClick={() => activeTab !== '3' && setActiveTab('3')}>
                                <span>Settings</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '4' })} onClick={() => activeTab !== '4' && setActiveTab('4')}>
                                <span>Minting site</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '5' })} onClick={() => activeTab !== '5' && setActiveTab('5')}>
                                <span>Funds</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '6' })} onClick={() => activeTab !== '6' && setActiveTab('6')}>
                                <span>Airdrops</span>
                            </div>
                            <div className={classnames('collections-settings-header-item', { active: activeTab === '7' })} onClick={() => activeTab !== '7' && setActiveTab('7')}>
                                <span>Whitelist</span>
                            </div>
                            <div className='collections-settings-header-item disabled'>
                                <span>Internal mint</span>
                            </div>
                        </section>
                        <div className='collections-settings-header-button'>
                            {
                                activeTab === '3' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        disabled={inputLoader}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`update project`)
                                            _handleSubmit()
                                        }}
                                    >
                                        {props.t(`Update minting`)}
                                    </Button>
                                )
                            }
                            {
                                activeTab === '4' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const ref = document.getElementById('subdomain_ref');
                                            if (ref) {
                                                ref.click();
                                            }
                                        }}
                                    >
                                        {props.t(`Visit site`)}
                                    </Button>
                                )
                            }

                            {/* {
                                activeTab === '6' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const ref = document.getElementById('new_airdrop');
                                            if (ref) {
                                                ref.click();
                                            }
                                        }}
                                    >
                                        {props.t(`New airdrop`)}
                                    </Button>
                                )
                            }
                            {
                                activeTab === '7' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const ref = document.getElementById('new_white_list');
                                            if (ref) {
                                                ref.click();
                                            }
                                        }}
                                    >
                                        {props.t(`New white list`)}
                                    </Button>
                                )
                            } */}
                            <a href={full_subdomain} target="_blank" style={{ display: 'none' }} id="subdomain_ref">
                                {full_subdomain}
                            </a>

                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-header'>

                        </div>
                        <div className='collections-settings-body'>
                            {
                                (activeTab === '1' && collection) &&
                                <DashboardTab project_id={project_id} collection_id={collection_id} collection={collection} setActiveTab={setActiveTab} count_traits={count_traits} />
                            }
                            {
                                activeTab === '3' &&
                                <SettingsTab project_id={project_id} collection_id={collection_id} activeForm={activeForm}
                                    collection={collection} inputLoader={inputLoader} setInputLoader={setInputLoader} setCollectionReload={setCollectionReload} count_traits={count_traits} />
                            }
                            {
                                (activeTab === '4') &&
                                <MintingTab project_id={project_id} collection_id={collection_id} />
                            }
                            {
                                (activeTab === '5' && collection) &&
                                <FundsTab project_id={project_id} collection_id={collection_id} collection={collection}></FundsTab>
                            }
                            {
                                (activeTab === '6' && collection) &&
                                <AirdropsTab project_id={project_id} collection_id={collection_id} collection={collection}></AirdropsTab>
                            }
                            {
                                (activeTab === '7' && collection) &&
                                <WhitelistTab project_id={project_id} collection_id={collection_id} collection={collection}></WhitelistTab>
                            }

                        </div>
                    </div>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))
