import React, { useContext, useState } from 'react'
import { withRouter } from "react-router-dom"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { toastrError, toastrSuccess } from "../../helpers/Toastr"
import { GeneralContext } from '../../context'
import DataService from '../../helpers/DataService'

const RecoverPassword = () => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [email, setEmail] = useState('')

    const _handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const response = await DataService.postAuthRecover({ email })
            const { data } = response

            toastrSuccess(data.msg, '¡Success!')
        } catch (error) {
            setLoading(false)
            toastrError(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={_handleSubmit}>
                        <div className="logo">
                            <img alt={`Cloudadmin`} src="./assets/images/neefter.svg" />
                        </div>

                        <div className="form">
                            <h1>Recuperar Contraseña</h1>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="email">
                                            Correo Electrónico
                                        </Label>
                                        <Input
                                            id="email"
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            onChange={event => setEmail(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='form-actions'>
                                <Col sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        Continuar
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="login-copyright">
                                    <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                                    {/* <p>Hecho con amor por <a target="_blank" rel="noopener noreferrer" href="https://juvasoft.com/?utm_source=cloudadmin&utm_medium=login">Juvasoft</a></p> */}
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(RecoverPassword)