import React, { useState, useEffect } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { logoutUserSuccess } from "../../store/actions"
import { segmentTrack } from "../../helpers/Segment"
const ProfileMenu = (props) => {
    const [menu, setMenu] = useState(false)

    const toggle = () => { setMenu(!menu) }
    const { profile } = props;

    useEffect(() => {
        if (profile) {
            console.log('set analytics identify');
            window.analytics.identify(profile.id, {
                userId: profile.id,
                companies: profile.company_name,
                // name: `${profile.name} ${profile.last_name}`,
                // firstName: profile.name,
                // lastName: profile.last_name,
                email: profile.email,
                created: new Date()
            })
        }
    }, [profile])

    const _handleLogout = () => {
        segmentTrack('Cerrar Sesión')
        props.logoutUserSuccess()
        props.history.push('/login')
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={toggle}
                className="d-inline-block"
                onClick={() => {
                    segmentTrack('Profile: Menu')
                }}
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <span className="header-item-profile-name">
                        {profile && (
                            <span>{profile.email}</span>
                        )}
                    </span>

                    {props && props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={props.avatar}
                            alt={props.firstName}
                        />
                    )}

                    {props && !props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={`/assets/images/avatar-1.png`}
                            alt={props.firstName}
                        />
                    )}

                    <i className="header-item-chevron mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="/settings" onClick={() => {
                        segmentTrack('Ir a Configuraciones')
                    }}>
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        Settings
                    </DropdownItem>
                    <div className="dropdown-divider"></div> */}
                    <DropdownItem tag="a" href="/organization" onClick={() => {
                        segmentTrack('go to organization')
                    }}>
                        organization
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <Link to="#" className="dropdown-item" onClick={() => {
                        _handleLogout()
                    }}>
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>Logout</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar
    }
}

export default connect(
    mapStatetoProps, { logoutUserSuccess }
)(withRouter(ProfileMenu))
