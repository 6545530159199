import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    CardBody,
    CardFooter, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { Link, withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { toastrError } from "../../../helpers/Toastr"
import { GeneralContext } from '../../../context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataService from '../../../helpers/DataService'
import { segmentTrack } from "../../../helpers/Segment"

import { faPlus } from '@fortawesome/free-solid-svg-icons'

const DataTable = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () => {
        setLoading(true)
        try {
            DataService.getProject().then(response => {

                setData(response.data.data)
                setFilteredData(response.data.data)
            })
        } catch (error) {
            toastrError(props.t(`I couldn't fetch your collections`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _deleteItem = async (id) => {
        setLoading(true)
    }

    return (
        <React.Fragment>
            <Row>
                <Card className='card-borderless'>
                    <Link to="/projects/create">
                        <CardBody style={{ height: '260px' }}>
                            <div className='card-picture'>
                                <div className='card-picture-empty'>
                                    <FontAwesomeIcon icon={faPlus} className="card-picture-empty-icon"></FontAwesomeIcon>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter className="bg-transparent border-top" style={{ height: '50px' }}>
                            <div className="d-flex">
                                <div className="flex-fill card-title">{props.t(`Create new project`)}</div>
                            </div>
                        </CardFooter>
                    </Link>
                </Card>


                {filteredData.map(
                    (item, key) => (
                        <Card className='card-borderless' key={key}>
                            <Link to={`/projects/${item.id}/collections`}>
                                <CardBody>
                                    <div className='card-picture'>
                                        <img style={{ width: '100%' }} alt={item.name} src={item.image || '/assets/images/avatar-1.png'} />
                                    </div>
                                </CardBody>
                            </Link>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex ">
                                    <div className="flex-fill card-title rowcard-mtitle">
                                        <Link to={`/projects/${item.id}/collections`}>
                                            {item.name}
                                        </Link>
                                    </div>
                                    <div className='subrow' style={{ justifyContent: 'flex-end' }}>

                                        <div style={{ textAlign: 'right' }}>
                                            <UncontrolledButtonDropdown
                                                className="d-inline-block">
                                                <DropdownToggle
                                                    className="btn header-item waves-effect  button-menu-dots"
                                                    id="page-header-user-dropdown"
                                                    tag="button"
                                                >
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem tag="a" href={`/projects/edit/${item.id}`} onClick={() => {
                                                        segmentTrack('Go to edit user')
                                                    }}>
                                                        <i className="bx bx-edit font-size-17 align-middle mr-1"></i>
                                                        {props.t(`Edit`)}
                                                    </DropdownItem>
                                                    <DropdownItem tag="a" href="/#" onClick={(e) => {
                                                        e.preventDefault()
                                                        _deleteItem(item.id)
                                                        segmentTrack('Delete user')
                                                    }}>
                                                        <i className="bx bx-trash font-size-17 align-middle mr-1"></i>
                                                        {props.t(`Delete`)}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>

                                </div>
                            </CardFooter>
                        </Card>
                        // </Col>
                    )
                )}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(DataTable))