import React, { useContext, useEffect, useState } from 'react'
import {
    Button, Col, Container, FormGroup,
    Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { useParams, withRouter } from 'react-router-dom'
import { toastrError, toastrSuccess } from "../../helpers/Toastr"
import { GeneralContext } from '../../context/'
import DataService from '../../helpers/DataService'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const [data, setData] = useState('fixed')
    const [projectName, setProjectName] = useState('')
    const [image, setImage] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)

    const { loading, setLoading } = generalContext
    const { project_id } = useParams();
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Projects`),
            parentLink: '/projects',
            item: props.t(`Create project`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (!project_id) return;
        setEditMode(true);
        const params = {
            id: project_id
        }
        DataService.getProject(params).then(response => {
            const { data } = response;
            if (data && data.data.length > 0) {
                const project = data.data[0];
                setBreadcrumb({
                    icon: '',
                    parent: props.t(`Projects`),
                    parentLink: '/projects',
                    item: props.t(`Edit project: ${project_id}`)
                })
                setProjectName(project.name);
            }
        })


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project_id])


    const _validateStep = () => {
        if (projectName === '') { return false }
        if (image === '') { return false }

        return true
    }

    const _handleSubmit = async (e) => {
        setLoading(true)
        if (!_validateStep()) return;
        try {
            const body = {
                name: projectName,
                image_id: image
            }
            // const service = {
            //     method: this.editMode ? 'putRegionById' : 'postRegion',
            //     params: this.editMode ? [projectId, body] : [body],
            //     message: this.editMode ? `Project saved` : `Project updated`,
            // }

            // DataService[service.method](...service.params).then(response => {
            //     toastrSuccess(props.t(service.message))
            //     props.history.push('/projects')

            // })
            DataService.postProject(body).then(response => {
                toastrSuccess(props.t(`Project saved`), '¡Success!')
                props.history.push('/projects')
            })
        } catch (error) {
            toastrError(props.t(`I couldn't save your project`), 'Error')
        } finally {
            setLoading(false)
        }
    }


    const _handleFileProjectUpload = (event) => {
        let files = event?.target.files
        // const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        // if (types.every(type => files[0] && files[0].type !== type)) {
        //     return
        // }
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            setImage(response.data.id)
            setImageTempo(URL.createObjectURL(files[0]));
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <section className='collections-create-form'>

                        <div className='collections-create-form-row'>
                            <div className='collections-create-form-row-content text-align-left'>
                                {/* <Row>
                                        <Col sm={10}>
                                            <p className='general-title'>Create Collection</p>
                                        </Col>
                                    </Row> */}
                                {/* <CardTitle>{props.t(`Create collection`)}</CardTitle> */}
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Project name`)}*
                                            </Label>

                                            <Input
                                                required
                                                value={projectName}
                                                type="text"
                                                className="form-control"
                                                placeholder='collection'
                                                onChange={event => setProjectName(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Project image`)}*
                                            </Label>
                                            <div className='collections-minting-control' >
                                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_project_img')?.click()}>
                                                    <span>Choose File</span>
                                                </div>
                                                <div className='collections-minting-control-input' style={{ flex: 1 }} onClick={() => document.getElementById('upload_project_img')?.click()}>
                                                    <Input
                                                        required
                                                        value={image}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='No file choosen'
                                                        disabled
                                                    />
                                                    <input type="file"
                                                        id="upload_project_img"
                                                        hidden
                                                        onChange={event => {
                                                            _handleFileProjectUpload(event)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className='collections-create-form-row-img'>
                                <div className='card-picture'>
                                    <img style={{ width: '100%' }} alt="item" src={image_tempo ? image_tempo : '/assets/images/avatar-1.png'} />
                                </div>
                            </div>
                        </div>

                        <div className='collections-create-form-bottom'>
                            <div className='form-actions-row'>

                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        // if (!_validateStep()) {
                                        //     toastrError(props.t(`Don't forget to enter your name`))
                                        //     return
                                        // }
                                        _handleSubmit()
                                    }}
                                    className="theme-button-secundary"
                                >
                                    {props.t(`Continue`)}
                                </Button>
                            </div>
                        </div>
                    </section>



                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
