import React, { useContext, useEffect, useState } from 'react'
import {
    Container
} from "reactstrap"

import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { GeneralContext } from '../../context/'
import Table from './components/ContentToChoose'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { currentProject, setProject } = generalContext
    const { setBreadcrumb } = generalContext
    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);

    // useEffect(() => {
    //     setBreadcrumb({
    //         icon: '',
    //         parent: props.t(`Collections`),
    //         parentLink: `/projects/${project_id}/collections`,
    //         item: props.t(`Welcome, what are you selling today`)
    //     })
    //     setUseProjectId(project_id)
    // }, [project_id])

    useEffect(() => {
        
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Welcome, what are you selling today`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Row>
                        <Col sm={10}>
                            <p className='general-title'>Welcome, what are you selling today</p>
                        </Col>
                    </Row> */}
                    <Table project_id={project_id} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
