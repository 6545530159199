import React from 'react'

import Sidebar from "../Global/Sidebar"
import Footer from "../Global/Footer"
import Content from "../Global/content"
import HeaderAdmin from "../Global/Header-admin"

import { withRouter } from "react-router-dom"

const Layout = ({ children }) => {
    const [toggleMenu, setToggleMenu] = React.useState(false)

    const toggleMenuCallback = () => {
        if (toggleMenu) {
            document.getElementById("sidebar").classList.remove("open")
            document.getElementById("content").classList.remove("open")
            setToggleMenu(false)
        }

        if (!toggleMenu) {
            document.getElementById("sidebar").classList.add("open")
            document.getElementById("content").classList.add("open")
            setToggleMenu(true)
        }
    }

    return (
        <React.Fragment>
            <HeaderAdmin></HeaderAdmin>
            <Sidebar id="sidebar" toggleMenuCallback={toggleMenuCallback} />
            <div id="content" className="content">
                <Content toggleMenuCallback={toggleMenuCallback} body={children} className="theme-subcontent" />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default withRouter(Layout)