import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { GeneralContext } from '../../../../context'

import {
    Button, CardTitle, Col, Form, FormGroup, Input, InputGroup,
    InputGroupAddon, Label, Row
} from "reactstrap"

import backgroundImage from '../../../../assets/images/Neefter_Launchpad_Tour_CLC.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../../../db"

import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DataService from '../../../../helpers/DataService'
import { toastrError, toastrInfo } from "../../../../helpers/Toastr"

import { isAddressSolana, isAddressWeb3 } from '../../../../helpers/utils'


const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [tokenDescription, setTokenDescription] = useState('')
    const [tokenExternal, setTokenExternal] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [collectionFamily, setCollectionFamily] = useState('')
    const [tokens, setTokens] = useState('')
    const [collectionSymbol, setCollectionSymbol] = useState('')
    const [tokenName, setTokenName] = useState('')
    const [blockchainNetwork, setBlockchainNetwork] = useState(1)
    const [launch_date, setLaunchDate] = useState('')
    const [price, setPrice] = useState('')
    const [seller_fee_basis_points, setSellerFeesBasisPoints] = useState('')
    const [image_id, setImageId] = useState('')
    const [cache_zip, setCacheZip] = useState()


    const [assets, setAssets] = useState([])
    const getCollectionsSettings = () => db.collections_settings.toArray();
    const collections_settings = useLiveQuery(getCollectionsSettings);
    const getAssetsCount = () => db.assets.count();
    const assets_count = useLiveQuery(getAssetsCount);

    const [collection_saved, setCollectionSaved] = useState('')
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')

    const { project_id, activeForm, collection_id, setActiveTab } = props;
    const min_creator_arr = [
        {
            address: '',
            share: '',
            error: false,
        }
    ];
    let [creator_array, setCreatorArray] = useState([]);

    useEffect(() => {

        DataService.getCollectionById(collection_id).then(
            response => {
                if (response && response.data) {

                    const { brackground, candy_machine_address, csv, description, external_url,
                        family, favicon, high_fidelity_icon, id, image_id, is_candy_machine, is_deployed,
                        is_deploying, launch_date, logo, name, network_id, organization_id, price, project_id,
                        slug, subdomain, symbol, seller_fee_basis_points,
                        creators, blockchain } = response.data;
                    setCollectionName(name)
                    const launch = launch_date ? new Date(launch_date) : '';

                    if (launch) {
                        const year = launch.getFullYear()
                        let month = parseInt(launch.getMonth() + 1)
                        month = (month + '').length > 1 ? month : '0' + month
                        const day = ((launch.getDate() + '').length > 1 ? launch.getDate() : '0' + launch.getDate());
                        let date = year + "-" + month + "-" + day;
                        setLaunchDate(date)
                    }
                    setCollectionName(name || '')
                    setBlockchainNetwork(network_id || 1)
                    setCollectionFamily(family || '')
                    setTokens(tokens || 0)
                    setCollectionSymbol(symbol || '')
                    setTokenExternal(external_url || '')
                    setTokenDescription(description || '')
                    setPrice(price || '')
                    setSellerFeesBasisPoints(seller_fee_basis_points ? seller_fee_basis_points / 100 : '')
                    if (response && response.data && response.data.cache_zip) {
                        setCacheZip(response.data.cache_zip)
                    }

                    if (creators && creator_array.length === 0) {
                        const resumeCreators = creators.map(creator => {
                            return {
                                address: creator.address,
                                share: creator.share,
                                error: false,
                            }
                        })
                        creator_array = resumeCreators
                        setCreatorArray([...resumeCreators])
                    }
                    if (collections_settings && assets_count) {
                        _checkSettings();
                    }
                }
            }
        )
    }, [collections_settings, assets_count, collection_id])

    useEffect(() => {
        if (activeForm > 0) {
            _handleSubmit();
        }
    }, [activeForm])

    const _checkSettings = () => {

        if (collections_settings.length > 0) {
            const setting = collections_settings[0];
            const { id, name, blockchain, image_id, collectionFamily, tokens, collectionSymbol, tokenExternal, tokenDescription, launch_date, price, seller_fee_basis_points, creators } = setting;

            // if (setting.collection_id !== collection_id) {
            //     return _manageZipData()
            // }
            // if (setting.cache_zip !== cache_zip) {
            //     return _manageZipData()
            // }
            setCollectionSettingSaved(id)
            // setCollectionName(name)
            if (blockchain) setBlockchainNetwork(blockchain)
            if (collectionFamily) setCollectionFamily(collectionFamily || '')
            if (tokens || assets_count) setTokens(tokens || assets_count)
            if (collectionSymbol) setCollectionSymbol(collectionSymbol || '')
            if (tokenExternal) setTokenExternal(tokenExternal || '')
            if (tokenDescription) setTokenDescription(tokenDescription || '')
            if (launch_date) setLaunchDate(launch_date || '')
            if (image_id) setImageId(image_id || undefined)
            if (price) setPrice(price || '')
            if (seller_fee_basis_points) setSellerFeesBasisPoints(seller_fee_basis_points || '')

            if (creators && creator_array.length === 0) {

                creators.forEach(creator => {
                    setCreatorArray((arr) => {
                        return [
                            ...arr,
                            {
                                address: creator.address,
                                share: creator.share,
                                error: false,
                            }
                        ];
                    });
                });
            }
        }
    }


    const _validateStep = () => {

        if (collectionName === '') {
            toastrError(props.t(`Don't forget to enter the collection name`), 'Error')
            return false
        }
        if (blockchainNetwork === '') {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        // if (collectionFamily === '') {
        //     toastrError(props.t(`Don't forget to enter the collection family`))
        //     return false
        // }
        if (tokens === '') {
            toastrError(props.t(`Don't forget to enter the number of tokens`), 'Error')
            return false
        }
        if (collectionSymbol === '') {
            toastrError(props.t(`Don't forget to enter the collection symbol`), 'Error')
            return false
        }
        if (tokenExternal === '' || !validateWebsiteUrl(tokenExternal)) {
            toastrError(props.t(`Don't forget to enter the token external`), 'Error')
            return false
        }
        if (tokenDescription === '') {
            toastrError(props.t(`Don't forget to enter the token description`), 'Error')
            return false
        }
        // if (creator_array.length < 1) {
        //     toastrError(props.t(`Don't forget to enter creator's options`))
        //     return false
        // }
        if (launch_date === '') {
            toastrError(props.t(`Don't forget to enter the launch date`), 'Error')
            return false
        }
        if (price === '') {
            toastrError(props.t(`Don't forget to enter the price`), 'Error')
            return false
        }
        if (seller_fee_basis_points === '') {
            toastrError(props.t(`Don't forget to enter the royalties`), 'Error')
            return false
        }
        if (!!seller_fee_basis_points) {
            const v = parseFloat(seller_fee_basis_points)
            if (!(v >= 0 && v < 100)) {
                toastrError(props.t(`The royalties can't be less than 0 and greater than 100`), 'Error')
                return false;
            }
        }
        return true
    }


    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            if (collection_setting_saved && collection_setting_saved.length > 0) {
                await db.collections_settings.update(collection_setting_saved, {
                    collection_id: collection_saved,
                    name: collectionName,
                    collectionFamily,
                    tokens,
                    collectionSymbol,
                    tokenExternal,
                    tokenDescription,
                    launch_date,
                    price,
                    seller_fee_basis_points,
                    creators: (!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') ? [] : creator_array.map(creator => {
                        return {
                            address: creator.address,
                            share: creator.share,
                        }
                    })
                })
            } else {
                db.collections_settings.clear();
                await db.collections_settings.add({
                    collection_id: collection_saved,
                    name: collectionName,
                    collectionFamily,
                    tokens,
                    collectionSymbol,
                    tokenExternal,
                    tokenDescription,
                    launch_date,
                    price,
                    seller_fee_basis_points,
                    creators: (!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') ? [] : creator_array.map(creator => {
                        return {
                            address: creator.address,
                            share: creator.share,
                        }
                    })
                })
            }


            const body = {
                name: collectionName,
                project_id: project_id,
                image_id: image_id || undefined,
                family: collectionFamily,
                external_url: tokenExternal,
                symbol: collectionSymbol,
                description: tokenDescription,
                launch_date: launch_date,
                network_id: blockchainNetwork,
                step_number: 2,
                seller_fee_basis_points: parseFloat((seller_fee_basis_points * 100) || 0),
                price: parseFloat(price || 0),
                creators: (!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') ? [] : creator_array.map(creator => {
                    return {
                        address: creator.address,
                        share: creator.share,
                    }
                })

            }
            // props.history.push(`/projects/${project_id}/collections/token`)
            DataService.putCollectionById(collection_id, body).then(response => {
                if (response && response.data) {
                    // props.history.push(`/collections/generative/attributes/${collection_id}`)
                    setActiveTab('2')
                }
            })
        } catch (error) {
            toastrError(`Failed to add ${collectionName}: ${error}`, 'Error')

        }
    }
    const addCreator = () => {
        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) return;
        setCreatorArray((arr) => {
            return [
                ...arr,
                {
                    address: '',
                    share: '',
                    error: false,
                }
            ];
        });
    };
    const removeCreatorInput = (index) => {
        setCreatorArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
    }
    const handleChangeCreatorAddress = (event) => {
        event.preventDefault();
        const val = 'creator_address';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;


        setCreatorArray(item => {
            const newArr = item.slice();
            newArr[index].address = value;
            console.log('index', index);
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                if ((blockchainNetwork + '') === '1') {
                    // solana
                    if (isAddressSolana(value)) {
                        newArr[index].error = false;
                        document.getElementById(val + index).classList.remove('input-error')
                    } else {
                        newArr[index].error = true;
                        document.getElementById(val + index).classList.add('input-error')
                    }

                } else {
                    // Not solana
                    if (isAddressWeb3(value)) {
                        newArr[index].error = false;
                        document.getElementById(val + index).classList.remove('input-error')
                    } else {
                        newArr[index].error = true;
                        document.getElementById(val + index).classList.add('input-error')
                    }
                }
            }
            return newArr;
        });
    };
    const handleChangeCreatorShare = (event) => {
        event.preventDefault();
        const val = 'creator_share';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        setCreatorArray(item => {
            const newArr = item.slice();
            if (!value) {
                newArr[index].error = true;
            } else {
                newArr[index].error = false;
            }
            var regexp = new RegExp(/[`~!@$%^&*()+{}[\]\\|,.//?;':"]/g, 'g');
            const indexHashtagError = value.match(regexp);
            const indexSpaceError = value.match(/\s/);
            if (!value || (indexHashtagError && indexHashtagError?.length > 0) || (indexSpaceError && indexSpaceError?.length > 0)) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                newArr[index].share = parseFloat(value);
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });


    };
    useEffect(() => {
        if (creator_array.filter(ca => !ca.share).length > 0) return;
        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) return;
        const validate = setTimeout(async () => {
            checkAllCreators()
        }, 3000);
        return () => clearTimeout(validate)
    }, [creator_array])

    const validateWebsiteUrl = websiteUrl => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

        return regex.test(String(websiteUrl).toLowerCase());
    };

    function checkAllCreators() {
        let total = 0;
        const checkError = creator_array.find(creator => creator.error)
        if (!checkError) {
            total = creator_array.reduce((acc, creator) => acc + parseFloat(creator.share), 0);

            if (total !== 90 && creator_array.length > 0) {
                const val = 'creator_share';
                const n_array = creator_array.map((creator, index) => {
                    document.getElementById(val + index).classList.add('input-error')
                    return {
                        ...creator,
                        error: true
                    }
                })
                setCreatorArray([...n_array])
                toastrInfo('The total of the sum of the shares between all creators must to be 100%', 'Error')

            } else {
                const val = 'creator_share';
                const n_array = creator_array.map((creator, index) => {
                    document.getElementById(val + index).classList.remove('input-error')
                    return {
                        ...creator,
                        error: false
                    }
                })
                setCreatorArray([...n_array])
            }
        }

    }

    return (
        <React.Fragment>
            <Form id="settings-tab-form" >
                <div className='collections-settings text-align-left'>
                    <Row>
                        {/* <Col sm={6}>
                            <p className='general-title'>Collections settings</p>
                        </Col> */}
                        <Col sm={2}>
                            {/* <Button
                                type="button"
                                color="secundary"
                                className="button_gray theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    // if (!_validateStep()) {
                                    //     toastrError(props.t(`Don't forget to enter your name`))
                                    //     return
                                    // }
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Save settings`)}
                            </Button> */}
                        </Col>
                    </Row>
                    <CardTitle className='collections-settings-tab-title'>{props.t(`General settings`)}</CardTitle>
                    <div className='collections-settings-row'>

                        <div className='collections-settings-item'>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Collection name`)}
                                        </Label>

                                        <Input
                                            required
                                            value={collectionName}
                                            type="text"
                                            className="form-control"
                                            placeholder='Collection name'
                                            id='collectionName'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('collectionName').classList.add('input-error')
                                                } else {
                                                    document.getElementById('collectionName').classList.remove('input-error')
                                                }
                                                setCollectionName(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Collection Family`)}
                                        </Label>

                                        <Input
                                            value={collectionFamily}
                                            type="text"
                                            id='collectionFamily'
                                            className="form-control"
                                            placeholder='Stark'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('collectionFamily').classList.add('input-error')
                                                } else {
                                                    document.getElementById('collectionFamily').classList.remove('input-error')
                                                }
                                                setCollectionFamily(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`How many tokens`)}
                                        </Label>

                                        <Input
                                            required
                                            value={tokens}
                                            type="text"
                                            id="tokens"
                                            className="form-control"
                                            placeholder='0'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('tokens').classList.add('input-error')
                                                } else {
                                                    document.getElementById('tokens').classList.remove('input-error')
                                                }
                                                setTokens(value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Collection symbol`)}
                                        </Label>

                                        <Input
                                            required
                                            value={collectionSymbol}
                                            id="collectionSymbol"
                                            type="text"
                                            className="form-control"
                                            placeholder='CPT'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('collectionSymbol').classList.add('input-error')
                                                } else {
                                                    document.getElementById('collectionSymbol').classList.remove('input-error')
                                                }
                                                setCollectionSymbol(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Blockchain network`)}
                                        </Label>
                                        <Input
                                            required
                                            value={blockchainNetwork}
                                            type="select"
                                            id="maskSelect"
                                            className='minimal'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('maskSelect').classList.add('input-error')
                                                } else {
                                                    document.getElementById('maskSelect').classList.remove('input-error')
                                                }
                                                setBlockchainNetwork(event.target.value)
                                            }}
                                        >
                                            <option value={1}>Solana</option>
                                            <option value={2}>Polygon</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`External URL`)}
                                        </Label>

                                        <Input
                                            required
                                            value={tokenExternal}
                                            id="tokenExternal"
                                            type="text"
                                            className="form-control"
                                            placeholder='URL or Link'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('tokenExternal').classList.add('input-error')
                                                } else {
                                                    if (validateWebsiteUrl(value)) {
                                                        document.getElementById('tokenExternal').classList.remove('input-error')
                                                    } else {
                                                        document.getElementById('tokenExternal').classList.add('input-error')
                                                    }
                                                }
                                                setTokenExternal(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                    <p className='title-mini'>This URL will appear next to the asset’s image.</p>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Token description`)}
                                        </Label>

                                        <Input
                                            required
                                            style={{ padding: 10 }}
                                            type="textarea"
                                            className="form-control input-text-area"
                                            placeholder="The description will be included on the item's detail page underneath 
                                            its image. Markdown syntax is supported."
                                            value={tokenDescription}
                                            id="tokenDescription"
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('tokenExternal').classList.add('input-error')
                                                } else {
                                                    document.getElementById('tokenExternal').classList.remove('input-error')
                                                }
                                                setTokenDescription(event.target.value)
                                            }}
                                        />

                                    </FormGroup>

                                </Col>
                            </Row>
                        </div>
                        <div className='collections-settings-item'>
                            <div className='collections-settings-image'>
                                <img style={{ width: '100%' }} alt="item" src={backgroundImage} />
                            </div>
                        </div>
                    </div>
                    <CardTitle className='collections-settings-tab-title'>{props.t(`Go live settings`)}</CardTitle>
                    <div className='collections-settings-row'>
                        <div className='collections-settings-item'>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Go live date`)}
                                        </Label>
                                        <Input
                                            type="date"
                                            name="launch_date"
                                            id="launch_date"
                                            value={launch_date}
                                            placeholder="Launch date"
                                            className="form-control"
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('launch_date').classList.add('input-error')
                                                } else {
                                                    document.getElementById('launch_date').classList.remove('input-error')
                                                }
                                                setLaunchDate(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Price`)}
                                        </Label>

                                        <Input
                                            required
                                            value={price}
                                            id="price"
                                            type="text"
                                            className="form-control"
                                            placeholder='Price'
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('price').classList.add('input-error')
                                                } else {
                                                    document.getElementById('price').classList.remove('input-error')
                                                }
                                                setPrice(event.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className='collections-settings-item'></div>
                    </div>
                    {/* <CardTitle className='collections-settings-tab-title'>{props.t(`Art work settings`)}</CardTitle>
                    <div className='collections-settings-row'>
                        <div className='collections-settings-item'>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Art work size in pixels`)}
                                        </Label>

                                        <Input
                                            required
                                            value={collectionName}
                                            type="text"
                                            className="form-control"
                                            placeholder='1024'
                                            onChange={event => setCollectionName(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </div>
                        <div className='collections-settings-item'></div>
                    </div> */}
                    <CardTitle className='collections-settings-tab-title'>{props.t(`Royalties & comissions`)}</CardTitle>
                    <div className='collections-settings-row'>
                        <div className='collections-settings-item'>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Royalties %`)}
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                required
                                                value={seller_fee_basis_points}
                                                type="text"
                                                className="form-control"
                                                id='seller_fee_basis_points'
                                                placeholder=''
                                                step="0.01"
                                                pattern="[0-9]*"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (!value || !event.target.validity.valid) {
                                                        document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                    } else {
                                                        const v = parseFloat(value)
                                                        if (v >= 0 && v < 100) {
                                                            const strAfterDot = value.split('.', 2)[1]
                                                            if (!strAfterDot || strAfterDot.length <= 2) {
                                                                document.getElementById('seller_fee_basis_points').classList.remove('input-error')
                                                            } else {
                                                                document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                            }
                                                        } else {
                                                            document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                        }
                                                    }
                                                    setSellerFeesBasisPoints(event.target.value)
                                                }}
                                            />
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`creators %`)}
                                        </Label>
                                        <p className='title-mini'>
                                            Payments will be split between the wallet address you list here, according to their share.
                                        </p>

                                        {
                                            seller_fee_basis_points > 0 && (
                                                <div className='input-container' key={'cont0'} >
                                                    <div className='input-container_lg'>
                                                        <Input
                                                            required
                                                            value="Neefter creator"
                                                            type="text"
                                                            className="form-control"
                                                            disabled={true}
                                                            placeholder=''
                                                            id={"creator_address"}
                                                        />
                                                    </div>

                                                    <div className='input-container_sm'>
                                                        <InputGroup>
                                                            <Input
                                                                required
                                                                value="10"
                                                                type="text"
                                                                disabled={true}
                                                                className="form-control"
                                                                placeholder=''
                                                                id={"creator_shar"}
                                                                pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                            />
                                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            creator_array.map((item, i) => {
                                                return (
                                                    <div className='input-container' key={'cont' + i} >
                                                        <div className='input-container_lg'>
                                                            <Input
                                                                required
                                                                value={item.address}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=''
                                                                id={"creator_address" + i}
                                                                disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')}
                                                                onChange={handleChangeCreatorAddress}
                                                            />
                                                        </div>

                                                        <div className='input-container_sm'>
                                                            <InputGroup>
                                                                <Input
                                                                    required
                                                                    value={item.share}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=''
                                                                    id={"creator_share" + i}
                                                                    pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                    disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')}
                                                                    onChange={handleChangeCreatorShare}
                                                                />
                                                                <InputGroupAddon addonType="append">%</InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                        <div className='input-container_icon' onClick={() => { removeCreatorInput(i) }}>
                                                            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }
                                    </FormGroup>
                                </Col>

                            </Row>


                            <Row>
                                <Col sm={6}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="theme-button-collaborator"
                                        onClick={addCreator}
                                    >
                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                        {props.t(`Add creator`)}
                                    </Button>
                                </Col>

                            </Row>
                        </div>
                        <div className='collections-settings-item'></div>
                    </div>
                </div>
                <div className='bottom_clear'></div>

            </Form>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))