import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { toastrError } from "../helpers/Toastr"
import { loadState } from "../store/localStorage"

axios.interceptors.response.use(undefined, (error) => {
    const { response, config } = error
    const newResponse = {}
    const newConfig = {
        url: config.url,
        method: config.method,
        headers: config.headers
    }

    if (config.url != null) { newConfig.data = config.data }

    if (response != null) {
        if (response.status === 404) {
            return Promise.reject(error)
        }

        if (response.status === 403) {
            toastrError(`action unauthorized`)
            window.location.replace('/logout')
        }

        if (response.status === 401) {
            toastrError(`Tu sesión ha expirado`, 'Error')
            window.location.replace('/logout')
        }

        newResponse.status = response.status
        newResponse.statusText = response.statusText
        newResponse.headers = response.headers

        if (response.data != null && response.data.message) {
            newResponse.dataMessage = response.data.message
        }
    }

    return Promise.reject(error)
})
const twt = 'https://api.twitter.com/oauth/request_token'

export let baseURL = ''
const hostname = window && window.location && window.location.hostname

console.log('hostname', hostname);
switch (hostname) {
    case 'localhost':
        baseURL = 'https://api.neefter.com/v1'
        break
    case 'neefter-front-git-develop-metafans.vercel.app':
        baseURL = 'https://api-dev.neefter.com/v1'
        break
    case 'app.neefter.com':
        baseURL = 'https://api.neefter.com/v1'
        break
    default:
        baseURL = 'https://api-dev.neefter.com/v1'
        break
}

// eslint-disable-next-line react-hooks/exhaustive-deps
const useActiveToken = () => {
    const token = loadState()?.Auth?.token

    if (!token) return undefined;

    const auth = 'BEARER ' + token;
    return auth;
}
// eslint-disable-next-line react-hooks/exhaustive-deps
const useActiveAccount = () => {
    return loadState()?.Auth?.account
}

export class ApiHelper {
    getAuthToken() {
        return useActiveToken()
    }

    getActiveAccount() {
        return useActiveAccount()
    }

    getBaseURL() { return baseURL }

    getOutgoingHeaders() {
        const company = this.getActiveAccount()

        return {
            'authorization': this.getAuthToken(),
            'request-id': uuidv4(),
            'Request-Source': 'neefter_ui',
            'company-id': company?.id,
            'Content-Type': 'application/json'

        }
    }
    getTwtgoingHeaders() {
        return {
            Authorization: 'OAuth oauth_consumer_key="FWWmhana3zEgmoRUmSFFhPs90"',
            oauth_nonce: "$oauth_nonce",
            oauth_signature: "oauth_signature",
            oauth_signature_method: "HMAC-SHA1",
            oauth_timestamp: new Date().toISOString(),
            oauth_version: "1.0"
        }
    }

    async post(url, data) {
        try {
            return axios.post(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async postWithFiles(url, data) {
        let headers = this.getOutgoingHeaders()
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data'
        }

        try {
            return axios.post(url, data, {
                baseURL,
                headers
            })
        } catch (error) {
            throw error
        }
    }
    /**
  * Make a POST with files request
  * @param url string
  * @param data? any
  * @annotation token is required
  * @returns Promise<AxiosResponse<any, any>>
*/
    async postWithFilesData(url, data, info) {
        let headers = this.getOutgoingHeaders()
        let body = data;
        console.log('body', body);

        for (let key in info) {
            body.append(key, info[key])
        }

        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data'
        }

        try {
            const min_data = {
                baseURL,
                headers,
                itemAlias: 'upload_file',
                type: 'user'
            }

            return axios.post(url, body, min_data)
        } catch (error) {
            throw error
        }
    }

    async putUploadWithFiles(url, data) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        // try {
        //     return axios.put(url, data, {
        //         headers
        //     })
        // } catch (error) {
        //     throw error
        // }
        try {
            return axios({
                method: 'put', // the request method to be used when making the request request
                url: url, // the server URL that will be used for the request,
                // headers: headers, // custom headers to be sent
                data: data // --> the data to be sent as the request body
            });
        } catch (error) {

        }
    }

    async put(url, data, _cancelToken = undefined) {
        try {
            return axios.put(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async get(url, query = undefined) {
        try {
            return axios.get(url, {
                baseURL,
                headers: this.getOutgoingHeaders(),
                params: query,
            })
        } catch (error) {
            throw error
        }
    }

    async getCross(url, query = undefined) {
        try {
            return axios.get(url, {
                params: query,
            })
        } catch (error) {
            throw error
        }
    }

    async getUpload(url, query = undefined) {
        const baseURL = 'https://v1zndfebp1.execute-api.us-west-1.amazonaws.com'
        try {
            return axios.get(url, {
                baseURL,
                // headers: this.getOutgoingHeaders(),
                params: query,
            })
        } catch (error) {
            throw error
        }
    }

    async delete(url) {
        try {
            return axios.delete(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }


    /*
    curl --request POST \
      --url 'https://api.twitter.com/oauth/request_token?oauth_callback=$HTTP_ENCODED_CALLBACK_URL' \
      --header 'Authorization: OAuth oauth_consumer_key="$oauth_consumer_key", oauth_nonce="$oauth_nonce", oauth_signature="oauth_signature", oauth_signature_method="HMAC-SHA1", oauth_timestamp="$timestamp", oauth_version="1.0"'
    
    
    */

    async postTwtLogin() {
        try {
            return axios.post(`${twt}?oauth_callback=${window.location.origin}/magic_callback`, {
                headers: this.getTwtgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

}

export default new ApiHelper()