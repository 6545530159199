import React, { useContext, useEffect, useState } from 'react';
import {
    Button
} from "reactstrap";

import { GeneralContext } from '../../../context';

import { faArrowRight, faInfoCircle, faEthernet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withNamespaces } from "react-i18next";
import { withRouter } from 'react-router-dom';
import {
    PieChart, Pie, Sector, Cell, ResponsiveContainer, LineChart, Line, XAxis
} from 'recharts';
import backgroundImage from '../../../assets/images/all-city-shark.png'

import image_source from '../../../assets/images/NeefterSVG6.svg'
import cn from 'classnames';
import DataService from '../../../helpers/DataService'
import { ReactComponent as Matic } from '../../../assets/images/Matic.svg';
import { ReactComponent as Solana } from '../../../assets/images/Solana.svg';
import { ReactComponent as BNB } from '../../../assets/images/BNB.svg';
import { ReactComponent as Ether } from '../../../assets/images/Ether.svg';

const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC',];

const Dashboard = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { collection_id, collection, setActiveTab } = props
    const [payouts_table, setPayoutTable] = useState([])
    const [payout, setPayout] = useState({ total_revenue: 0, available_funds: 0 })
    const [logotype, setLogotype] = useState('')
    const [background, setBackground] = useState('')
    const [child_collection_id, setChildCollectionId] = useState('')
    const [network_id, setNetworkId] = useState('')
    const [total_tokens, setTotalTokens] = useState('')
    const [launch_date, setLaunchDate] = useState('')

    const [is_deployed, setIsDeployed] = useState(true)
    const [favicon, setFavicon] = useState('')
    const [hficon, setHficon] = useState('')
    const [image_id, setImageId] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const wallet1 = 'https://solscan.io/account/'
    const transaccion1 = ' https://solscan.io/tx/'
    const wallet2 = 'https://polygonscan.com/address/'
    const transaccion2 = 'https://polygonscan.com/tx/'
    const today = new Date()
    const [showChart, setShowChart] = useState(true)
    let [data, setData] = useState([
        { name: 'Sold', value: 450 },
        { name: 'Total', value: 1300 },
    ])

    let [dataLine, setDataLine] = useState([
        { month: 'Jan', value: 4 },
        { month: 'Feb', value: 24 },
        { month: 'Mar', value: 10 },
        { month: 'Apr', value: 30 },
    ])
    const COLORS = ['#8D78FB', '#DCD3FF99',];

    let [display, setDisplay] = useState('card');


    useEffect(() => {
        if (collection && collection.collection_info) {


            const { brackground, favicon, high_fidelity_icon, image_id, is_deployed, logo, network_id,
                total_tokens, launch_date, id
            } = collection.collection_info


            setChildCollectionId(id)
            setNetworkId(network_id)
            setTotalTokens(total_tokens)
            setLaunchDate(launch_date)

            setHficon(high_fidelity_icon || '')
            setLogotype(logo || '')
            setBackground(brackground || '')
            setImageTempo(brackground || '')
            setImageId(image_id || '')
            setFavicon(favicon || '')
            setIsDeployed(is_deployed)


        }
    }, [collection])
    useEffect(() => {
        if (child_collection_id) {
            callEndpoints();
        }
    }, [child_collection_id])

    const callEndpoints = () => {
        setLoading(true);
        DataService.getCollectionBalance(child_collection_id).then(response => {
            console.log('getCollectionBalance', response.data);
            setPayout(response.data)
            setData([
                { name: 'Sold', value: parseFloat(response.data.item_sold + '') },
                { name: 'Total', value: parseFloat(response.data.item_total + '') },
            ])
            console.log('data', data);
            // const n_date = new Date(launch_date)
            // if (n_date < today) {
            //     setDataLine([
            //         {
            //             month: monthNames[today.getMonth()], value: 0
            //         },
            //         {
            //             month: monthNames[n_date.getMonth()], value: parseFloat(response.data.item_sold + '')
            //         }
            //     ])
            // } else {
            //     setDataLine([
            //         {
            //             month: monthNames[n_date.getMonth()], value: 0
            //         }
            //     ])
            // }
        }).finally(() => {
            setShowChart(true)
        })
        DataService.getCollectionTransaction({ id: child_collection_id }).then(response => {
            console.log('getPayout', response.data.data);
            setPayoutTable(response.data.data)
        })
        setLoading(false);
    }


    // useEffect(() => {
    //     if (!collection) return;
    //     const { brackground, favicon, high_fidelity_icon, image_id, is_deployed, logo,
    //     } = collection;
    //     setHficon(high_fidelity_icon || '')
    //     setLogotype(logo || '')
    //     setBackground(brackground || '')
    //     setImageTempo(brackground || '')
    //     setImageId(image_id || '')
    //     setFavicon(favicon || '')
    //     setIsDeployed(is_deployed)
    // }, [collection])

    const toRouter = (source) => {
        if (!source) return;
        if (network_id === 1 || network_id === '1') {
            window.open(`${wallet1}${source}`, '_blank')
        } else {
            window.open(`${wallet2}${source}`, '_blank')
        }
    }
    const txnRouter = (source) => {
        if (!source) return;
        if (network_id === 1 || network_id === '1') {
            window.open(`${transaccion1}${source}`, '_blank')
        } else {
            window.open(`${transaccion2}${source}`, '_blank')
        }
    }

    const _viewAllEvents = () => {
        setActiveTab('5')
    }
    return (
        <React.Fragment>
            <section className='collections-dashboard' style={{ width: '100%' }}>
                <div className='collections-dashboard-charts'>
                    <div className='collections-dashboard-charts-card total-supply'>
                        <div className='collections-dashboard-charts-card-mini'>
                            <div className='collections-dashboard-charts-card-mini-item'>
                                <div className='collections-dashboard-charts-card-mini-item-sub'>
                                    1
                                </div>
                                <div className='collections-dashboard-charts-card-mini-item-label'>
                                    Collections
                                </div>
                            </div>
                            <div className='collections-dashboard-charts-card-mini-item'>
                                <div className='collections-dashboard-charts-card-mini-item-sub'>
                                    {
                                        ((network_id + '') === '2') &&
                                        <Matic fill="#8d78fb" stroke='#8d78fb'></Matic>
                                    }
                                    {
                                        ((network_id + '') === '1') &&
                                        <Solana fill="#8d78fb" stroke='#8d78fb' />
                                    }
                                    {
                                        ((network_id + '') === '3') &&
                                        <Ether fill="#8d78fb" stroke='#8d78fb' />
                                    }
                                    {
                                        ((network_id + '') === '4') &&
                                        <BNB fill="#8d78fb" stroke='#8d78fb' />
                                    }
                                </div>
                                <div className='collections-dashboard-charts-card-mini-item-label'>
                                    Network
                                </div>
                            </div>
                            <div className='collections-dashboard-charts-card-mini-item'>
                                <div className='collections-dashboard-charts-card-mini-item-sub'>
                                    {total_tokens}
                                </div>
                                <div className='collections-dashboard-charts-card-mini-item-label'>
                                    Tokens
                                </div>
                            </div>
                        </div>
                        <div className='collections-dashboard-charts-card-title'>
                            Total supply
                        </div>
                        {/* <div className='collections-dashboard-charts-card-subtitle'>
                            Some additional text here
                        </div> */}
                    </div>
                    <div className='collections-dashboard-charts-card claimed-supply'>
                        <div className='collections-dashboard-charts-card-pie-content'>
                            <span className='collections-dashboard-charts-card-pie-content-label'>
                                {payout?.item_sold} / {payout?.item_total}
                            </span>
                            {
                                showChart && (
                                    <PieChart width={240} height={240} barGap={0}>
                                        <Pie
                                            data={data}
                                            cx={120}
                                            cy={100}
                                            innerRadius={68}
                                            outerRadius={80}
                                            startAngle={0}
                                            fill="#8884d8"
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                )
                            }
                        </div>
                        <div className='collections-dashboard-charts-card-title'>
                            Claimed supply
                        </div>
                        {/* <div className='collections-dashboard-charts-card-subtitle'>
                            Some additional text here
                        </div> */}
                    </div>
                    <div className='collections-dashboard-charts-card claimed-supply'>
                        <div className='collections-dashboard-charts-card-pie-content'>
                            <span className='collections-dashboard-charts-card-pie-content-label'>
                                {payout?.item_sold} / {payout?.item_total}
                            </span>
                            {
                                showChart && (
                                    <PieChart width={240} height={240} barGap={0}>
                                        <Pie
                                            data={data}
                                            cx={120}
                                            cy={100}
                                            innerRadius={68}
                                            outerRadius={80}
                                            startAngle={0}
                                            fill="#8884d8"
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                )
                            }
                        </div>
                        <div className='collections-dashboard-charts-card-title'>
                            Tickets punched
                        </div>
                        {/* <div className='collections-dashboard-charts-card-subtitle'>
                            Some additional text here
                        </div> */}
                    </div>
                    <div className='collections-dashboard-charts-card minting'>
                        <div className='collections-dashboard-charts-card-title'>
                            Minting site
                        </div>
                        <div className='collections-dashboard-charts-card-content'>
                            <div className='collections-dashboard-charts-card-content-container'>
                                <div className={cn('collections-minting-top-display', display)}>
                                    <div className='collections-minting-top-display-container'>
                                        <div className='collections-minting-top-display-background'>
                                            <img alt="item-1"
                                                src={background ? background : backgroundImage} />
                                        </div>
                                        <div className='collections-minting-top-display-connect'>
                                            <div className='collections-minting-top-display-connect-black'>
                                                <div className='collections-minting-top-display-connect-primary'>
                                                    <span>Connect wallet</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='collections-minting-top-display-logo'>
                                            {
                                                logotype &&
                                                <img alt="item-3"
                                                    src={logotype} />
                                            }
                                        </div>
                                        <div className='collections-minting-top-display-powered'>
                                            <div className="custom-footer">
                                                <a href="https://twitter.com/Neefter_">
                                                    Powered by  <img src={image_source}></img>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='collections-dashboard-charts-card-buttons'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-upload"
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                {props.t(`View`)}
                            </Button>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                {props.t(`Edit`)}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='collections-dashboard-latest'>
                    <div className='collections-dashboard-latest-head'>
                        <p>Latest events</p>
                        <Button
                            type="button"
                            color="primary"
                            className="theme-button-view"
                            onClick={(e) => {
                                e.preventDefault()
                                _viewAllEvents()
                            }}
                        >
                            {props.t(`View all Events`)}
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                        </Button>
                    </div>
                    <div className='collections-funds-payout-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Status <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> </th>
                                    <th>Amount</th>
                                    <th>To</th>
                                    <th>Txn Hash</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payouts_table.length > 0 && (
                                    payouts_table.map((payout, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{payout.created_at}</td>
                                                <td className='status'>
                                                    {
                                                        payout.is_paid &&
                                                        <span>
                                                            Paid
                                                        </span>
                                                    }
                                                    {
                                                        payout.is_authorized && !payout.is_paid &&
                                                        <span>
                                                            Authorized
                                                        </span>
                                                    }
                                                    {
                                                        !payout.is_authorized && !payout.is_paid &&
                                                        <span>
                                                            Pending
                                                        </span>
                                                    }
                                                </td>
                                                <td>{payout.total}</td>
                                                <td className='to' onClick={() => toRouter(payout.wallet)}>{payout.wallet}</td>
                                                <td className='txn_hash' onClick={() => txnRouter(payout.network_transaction)}>{payout.network_transaction}</td>
                                            </tr>

                                        )
                                    })
                                )}
                                {(!payouts_table || payouts_table.length === 0) && (
                                    <tr>
                                        <td>No date</td>
                                        <td>No status</td>
                                        <td>No amount</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Dashboard))