import React, { useContext, useEffect, useState } from 'react'


import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../../context'

import { arrayMoveImmutable } from 'array-move'
import { toastrInfo } from "../../../../helpers/Toastr"
import DataService from '../../../../helpers/DataService'
import { fileReader } from "../../../../helpers/constants"

import SortableList from './SortableList'
import { segmentTrack } from '../../../../helpers/Segment'
import {
    Button, Col, Row,
} from "reactstrap"
const ComponentsTabs = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { _handleExport, activeForm, collection_id } = props;

    let [array_properties, setArrayProperties] = useState([])
    let [sorted, setSorted] = useState(false)
    let [fetched, setFetched] = useState(false)
    let [rules_array, setRulesArray] = useState([])

    useEffect(() => {
        console.log('collection_id', collection_id);
        if (!collection_id) return;
        toastrInfo('Loading properties', 'Info')
        setLoading(true)
        _checkCollectionProperties()
    }, [collection_id])


    const _checkCollectionProperties = () => {
        const params = {
            order_by: 'order.asc'
        }
        DataService.getCollectionAllTraitDataById(collection_id, params).then(responnse => {
            console.log('getCollectionAllTraitDataById responnse', responnse);
            const { data } = responnse.data;
            const orderedData = data.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
            })
            console.log('orderedData responnse', orderedData);
            array_properties = [...orderedData];
            setArrayProperties([...orderedData])
            _checkCollectionRules()
            setLoading(false)
        })
    }
    const _checkCollectionRules = () => {
        DataService.getCollectionAllRulesDataById(collection_id).then(responnse => {
            const { data } = responnse.data;
            console.log('getCollectionAllRulesDataById', data);
            let ordered_rules = [];
            console.log('array_properties', array_properties);
            data.forEach(rule => {
                const findRule = ordered_rules.findIndex(or => or.firstAttribute.id === rule.variation_1_id)
                const firstAttributeOption = array_properties.find(ap => !!ap.variations.find(apv => apv.id === rule.variation_1_id))
                console.log('firstAttributeOption', firstAttributeOption);
                const firstAttributeValue = firstAttributeOption.variations.find(apv => apv.id === rule.variation_1_id)
                console.log('firstAttributeValue', firstAttributeValue);
                const secondAttributeOption = array_properties.find(ap => !!ap.variations.find(apv => apv.id === rule.variation_1_id))
                const secondAttributeValue = secondAttributeOption.variations.find(apv => apv.id === rule.variation_1_id)
                if (findRule > -1) {
                    ordered_rules[findRule].secondAttribute.push(
                        {
                            id: secondAttributeValue.id,
                            source_img: secondAttributeValue.image,
                            label: secondAttributeValue.name,
                            parent: secondAttributeValue.name
                        }
                    )
                } else {
                    ordered_rules.push({
                        firstAttribute: {
                            id: firstAttributeValue.id,
                            source_img: firstAttributeValue.image,
                            label: firstAttributeValue.name,
                            parent: firstAttributeOption.name
                        },
                        secondAttribute: [
                            {
                                id: secondAttributeValue.id,
                                source_img: secondAttributeValue.image,
                                label: secondAttributeValue.name,
                                parent: secondAttributeOption.name
                            }
                        ],
                        type: rule.rule_type,
                        error: false,
                    })
                }
            });
            console.log('ordered_rules', ordered_rules);
            setRulesArray(ordered_rules)
            /*
             {
                firstAttribute: undefined,
                secondAttribute: [],
                type: 1,
                error: false,
            }
            */
            // setRulesArray([...data])
            // setLoading(false)
        })
    }

    let setPropertyAfterChange = async (property) => {
        if (!property) return;
        setLoading(true)
        const index = array_properties.findIndex(ap => ap.id === property.id)
        if (index < 0) return;
        array_properties[index] = property;

        setLoading(false)
        setArrayProperties([...array_properties]);
        const variation_promises = [];
        property.variations.forEach(variation => {
            const body = {
                rarity: variation.rarity,
            }
            variation_promises.push(DataService.putCollectionTraitVariationById(collection_id, variation.id, body))
        });
        Promise.all(variation_promises).then(() => {
            const bodyTrait = {
                percentage: property.percentage,
            }
            DataService.putCollectionTraitById(collection_id, property.id, bodyTrait).then(response => {
            })
        })
    }


    const changeSlider = (event, trait, property) => {
        const parseEvent = parseFloat(event.toFixed(2));

        trait.weight = parseEvent;




        const rest = (100 - parseEvent) / property.traits.length - 1;
        property.traits.forEach(_trait => {
            if (trait.name !== _trait.name) {
                _trait.weight = rest;
            }
        });

        array_properties.forEach((prop, ind) => {
            if (prop.order === property.order) {
                array_properties[ind] = property;
            }
        });

    }

    useEffect(() => {
        if (activeForm > 0) {

            _handleSubmit();
        }
    }, [activeForm])

    const _handleSubmit = () => {
        _handleExport(3)
    }
    // const _handleSubmit = async (e) => {
    //     setLoading(true)
    //     const properties = array_properties.map((ap, index) => {
    //         return {
    //             name: ap.name,
    //             order: index,
    //             percentage: ap.percentage,
    //             variations: ap.variations
    //         }
    //     })
    //     const body = {
    //         data: properties
    //     }
    //     DataService.putCollectionTraitBulkById(collection_id, body).then(response_bulk => {
    //         if (response_bulk) {
    //             findTraitsAndUpdateRules()
    //         }
    //     })
    //     // props.history.push(`/collections/generative/token/${collection_id}`)
    // }

    const findTraitsAndUpdateRules = () => {
        DataService.getCollectionAllTraitDataById(collection_id).then(response => {
            const { data } = response.data;
            console.log('getCollectionAllTraitDataById', data);
            let separated_rules = [];
            const rules_array_filtered = rules_array.filter(ra => !!ra.firstAttribute && ra.secondAttribute.length > 0)
            console.log('rules_array_filtered', rules_array_filtered);
            rules_array_filtered.forEach(rule => {
                const firstAttributeParent = data.find(property => property.name === rule.firstAttribute.parent)
                console.log('firstAttributeParent', firstAttributeParent);
                const variation_1_id = firstAttributeParent.variations.find(_variation => _variation.name === rule.firstAttribute.label)
                console.log('variation_1_id', variation_1_id);
                rule.secondAttribute.forEach(att => {
                    const secondAttributeParent = data.find(property => property.name === att.parent)
                    console.log('secondAttributeParent', secondAttributeParent);
                    const variation_2_id = secondAttributeParent.variations.find(variation => variation.name === att.label)
                    console.log('variation_2_id', variation_2_id);
                    separated_rules.push({
                        rule_type: rule.type,
                        variation_1_id: variation_1_id.id,
                        variation_2_id: variation_2_id.id
                    })

                });
            });
            console.log('separated_rules', separated_rules);
            const rules_body = {
                data: separated_rules
            }
            DataService.putCollectionRulesById(collection_id, rules_body).then(response_rules => {
                if (response_rules) {
                    _handleExport(3)
                }
            })

        })
    }


    const swapPositions = async (array, to, from) => {



        setLoading(true)


        [array_properties[to], array_properties[from]] = [array_properties[from], array_properties[to]]
        array_properties[to].order = to;
        array_properties[from].order = from;

        // await db.properties.update(array_properties[to].id, {
        //     order: to
        // })
        // await db.properties.update(array_properties[from].id, {
        //     order: from
        // })
        setArrayProperties([...array_properties])
        setLoading(false)


    }
    const onSortEnd = ({ oldIndex, newIndex }) => {


        setArrayProperties(prevItem => (arrayMoveImmutable(prevItem, oldIndex, newIndex)));
        swapAfterSort(oldIndex, newIndex)

    };

    const swapAfterSort = async (oldIndex, newIndex) => {
        setLoading(true)
        // await db.properties.update(array_properties[newIndex].id, {
        //     order: oldIndex
        // })
        // await db.properties.update(array_properties[oldIndex].id, {
        //     order: newIndex
        // })
        setLoading(false)

    }
    return (
        <React.Fragment>
            <section className='collections-attributes container-tab-components'>
                <h1 className='collections-attributes-title'>
                    Attributes
                    <div className="flex-fill" style={{ textAlign: 'right' }}>

                    </div>
                </h1>

                <SortableList collection_id={collection_id} items={array_properties} onSortEnd={onSortEnd} swapPositions={swapPositions} array_properties={array_properties} setPropertyAfterChange={setPropertyAfterChange} />
                <Row>
                    <Col>
                        <Button
                            type="button"
                            color="secundary"
                            className="theme-button-secundary mobile"
                            id="_handleSubmitComponentsTab"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`go to layers and rarity`)
                                _handleSubmit()

                            }}
                        >
                            {props.t(`Next step`)}
                        </Button>
                    </Col>
                </Row>
            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(ComponentsTabs))