import ApiHelper from "./ApiHelper";
import { Paths } from "./Paths";


export class DataService {
    async uploadImage(file) {
        return ApiHelper.postWithFiles('/upload', file)
    }
    async getAuthWhoami(params = null) {
        return ApiHelper.get(`${Paths.authWhoami}`, params)
    }
    async postUser(body) {
        return ApiHelper.post(`${Paths.user}`, body)
    }
    async postAuthReset(body) {
        return ApiHelper.post(`${Paths.authReset}`, body)
    }
    async postAuthLogin(body) {
        return ApiHelper.post(`${Paths.authLogin}`, body)
    }
    async postAuthRecover(body) {
        return ApiHelper.post(`${Paths.authRecover}`, body)
    }
    async postProject(body) {
        return ApiHelper.post(`${Paths.project}`, body)
    }
    async putProjectById(id, body) {
        return ApiHelper.put(`${Paths.project}/${id}`, body)
    }
    async putCollectionRedeploy(id, body) {
        return ApiHelper.put(`${Paths.collectionRedeploy}/${id}`, body)
    }
    async getProject(params) {
        return ApiHelper.get(`${Paths.project}`, params)
    }
    async postCollection(body) {
        return ApiHelper.post(`${Paths.collection}`, body)
    }
    async putCollectionById(id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${id}`, body, cancelToken)
    }
    async putCollectionTraitBulkById(id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${id}/trait/bulk`, body, cancelToken)
    }
    async putCollectionRulesById(id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${id}/rules`, body, cancelToken)
    }
    async putCollectionNftGenerateById(id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${id}/nft/generate`, body, cancelToken)
    }
    async getCollectionAllTraitDataById(id, params) {
        return ApiHelper.get(`${Paths.collection}/${id}/trait`, params)
    }
    async getCollectionNftListById(id, params) {
        return ApiHelper.get(`${Paths.collection}/${id}/nft`, params)
    }
    async delCollectionNftById(collection_id, nft_id) {
        return ApiHelper.delete(`${Paths.collection}/${collection_id}/nft/${nft_id}`)
    }
    async putCollectionTraitVariationById(collection_id, trait_variation_id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${collection_id}/trait_variation/${trait_variation_id}`, body, cancelToken)
    }
    async putCollectionTraitById(collection_id, trait_id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${collection_id}/trait/${trait_id}`, body, cancelToken)
    }
    async putCollectionRuleById(collection_id, rule_id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${collection_id}/rule/${rule_id}`, body, cancelToken)
    }
    async putCollectionNftById(collection_id, nft_id, body, cancelToken = undefined) {
        return ApiHelper.put(`${Paths.collection}/${collection_id}/nft/${nft_id}`, body, cancelToken)
    }
    async postCollectionNftBulkById(id, body, cancelToken = undefined) {
        return ApiHelper.post(`${Paths.collection}/${id}/nft/bulk`, body, cancelToken)
    }
    async getCollectionNftFiltersListById(id, params) {
        return ApiHelper.get(`${Paths.collection}/${id}/nft/filters`, params)
    }
    async getCollectionAllRulesDataById(id, params) {
        return ApiHelper.get(`${Paths.collection}/${id}/rule`, params)
    }
    async postCollectionNftGenerateById(id, body, cancelToken = undefined) {
        return ApiHelper.post(`${Paths.collection}/${id}/nft`, body, cancelToken)
    }
    async delCollectionNftResetGenerativeById(id) {
        return ApiHelper.delete(`${Paths.collection}/${id}/nft/reset_generative`)
    }




    /*

 


    @app.route("/v1/collection/<string:collection_id>/trait/<string:trait_id>", methods=['PUT'])
@requires_auth
def collection_trait_update(collection_id, trait_id):
    data = get_from_request(request.get_json(), {
        'name': ['str'],
        'percentage': ['int'],
        'order': ['int']
    })


       @app.route("/v1/collection/<string:collection_id>/trait_variation/<string:trait_variation_id>", methods=['PUT'])
@requires_auth
def collection_trait_variation_update(collection_id, trait_variation_id):
    data = get_from_request(request.get_json(), {
        'name': ['str'],
        'image': ['str'],
        'rarity': ['int'],
    })
    */

    async getCollection(params) {
        return ApiHelper.get(`${Paths.collection}`, params)
    }
    async postMagicLogin(body) {
        return ApiHelper.post(Paths.magicLogin, body);
    }
    async getCollectionById(id, params) {
        return ApiHelper.get(`${Paths.collection}/${id}`, params)
    }
    async getPublicCollectionById(id, params) {
        return ApiHelper.get(`${Paths.publicCollection}/${id}`, params)
    }
    async getCollectionSubdomain(subdomain) {
        return ApiHelper.get(`${Paths.collectionSubdomain}/${subdomain}`)
    }
    async deleteCollectionById(collection_id) {
        return ApiHelper.delete(`${Paths.collection}/${collection_id}`)
    }
    async getCollectionBalance(collection_id) {
        return ApiHelper.get(`${Paths.collection}/${collection_id}/balance`)
    }
    async getPublicCollectionBalance(collection_id) {
        return ApiHelper.get(`${Paths.publicCollection}/${collection_id}/balance`)
    }
    async postPayout(body) {
        return ApiHelper.post(`${Paths.payout}`, body)
    }
    async getPayout(params) {
        return ApiHelper.get(`${Paths.payout}`, params)
    }
    async getCollectionTransaction(params) {
        return ApiHelper.get(`${Paths.collectionTransaction}`, params)
    }
    async postCollection1_1(body) {
        return ApiHelper.post(`${Paths.collection1_1}`, body)
    }
    async postClaimByCollection(collection_id, body) {
        return ApiHelper.post(`${Paths.claim}/${collection_id}`, body)
    }
    async putCollection1_1(collection_id, body) {
        return ApiHelper.put(`${Paths.collection1_1}/${collection_id}`, body)
    }
    async postClaimTwitter(address, body) {
        return ApiHelper.post(`${Paths.claimTwitter}${address}`, body)
    }
    async getWhitelist(collection_id) {
        return ApiHelper.get(`${Paths.whitelist}/${collection_id}`)
    }
    async getAirdrop(collection_id) {
        return ApiHelper.get(`${Paths.airdrop}/${collection_id}`)
    }
    async deleteWhitelist(collection_id) {
        return ApiHelper.delete(`${Paths.whitelist}/${collection_id}`)
    }
    async deleteAirdrop(collection_id) {
        return ApiHelper.delete(`${Paths.airdrop}/${collection_id}`)
    }
    async postCollectionQuick(body) {
        return ApiHelper.post(`${Paths.collectionQuick}`, body)
    }
    async getNetworkConfig(params) {
        return ApiHelper.get(`${Paths.network_config}`, params)
    }
    async getUploadkey(key) {
        const params = { ext: key }
        return ApiHelper.getUpload('/uploads', params)
    }
    async putUploadkey(uploadURL, file) {
        return ApiHelper.putUploadWithFiles(uploadURL, file)
    }
    async getCollectionTransaction(params) {
        return ApiHelper.get(`${Paths.collectionTransaction}`, params)
    }
    async getPath(path, params) {
        return ApiHelper.getCross(`${path}`, params)
    }
    async uploadInscribeQueue(file, data) {
        return ApiHelper.postWithFilesData(Paths.btcInscribe, file, data)
    }
    /**
     * 
     * @param {
     *'collection_id': ['required', 'float'],
     *'address_list': ['required', 'list']
     * }  
     * @returns 
     */
    async postAirdrop(body) {
        return ApiHelper.post(`${Paths.airdrop}`, body)
    }
    /**
    * 
    * @param {
    *'collection_id': ['required', 'float'],
    *'address_list': ['required', 'list']
    * }  
    * @returns 
    */
    async postWhitelist(body) {
        return ApiHelper.post(`${Paths.whitelist}`, body)
    }

    /**
    * 
    * @param {
    * 'sign': ['required', 'str'],
    * 'description': ['required', 'str'],
    * 'logo': ['required', 'upload'],
    * 'event_title': ['required', 'str'],
    * 'event_organizer': ['required', 'str'],
    * 'event_venue': ['required', 'str'],
    * 'event_venue_address': ['required', 'str'],
    * 'event_start_date': ['required', 'datetime'],
    * 'event_end_date': ['required', 'datetime'],
    * 'event_description': ['required', 'str'],
    * 'cache_zip': ['required', 'upload'],
    * 'collection_id': ['required', 'collection']
    * }  
    * @returns 
    */
    async postCollectionTicketing(body) {
        return ApiHelper.post(`${Paths.collectionTicketing}`, body)
    }

    /**
  * 
  * @param {
    * 'description': ['str'],
    * 'logo': ['upload'],
    * 'event_title': ['str'],
    * 'event_organizer': ['str'],
    * 'event_venue': ['str'],
    * 'event_venue_address': ['str'],
    * 'event_start_date': ['datetime'],
    * 'event_end_date': ['datetime'],
    * 'event_description': ['str'],
    * 'cache_zip': ['upload'],
    *  'subdomain': ['str'],
    * }  
    * @returns 
    */
    async putCollectionTicketing(collection_id, body) {
        return ApiHelper.put(`${Paths.collectionTicketing}/${collection_id}`, body)
    }

    /**
     * @param {
     *'company_name': ['str'],
    *'twitter_user':  ['str'],
    *'billing_email':  ['mail'],
    *'billing_address':  ['str'],
    * }  
    * @returns 
    * @description: PUT PARA DATOS DE LA ORGANIZACION
    */
    async putOrganization(body) {
        return ApiHelper.put(`${Paths.organization}`, body)
    }
    /**
    * @param {
   *'billing_email':  ['required','mail'],
   * }  
   * @returns 
   * @description: CREAR NUEVA INVITACION
   */
    async postOrganizationInvite(body) {
        return ApiHelper.post(`${Paths.organizationInvite}`, body)
    }
    /**
  * @returns 
  * @description: LISTADO DE MIS INVITACIONES
  */
    async getOrganizationInvite(params) {
        return ApiHelper.get(`${Paths.organizationInvite}`, params)
    }
    /**
* @returns 
* @description: LISTADO DE MIEMBROS DE ORGANIZACION
*/
    async getOrganization(params) {
        return ApiHelper.get(`${Paths.organization}`, params)
    }
    /**
       * @param {
    * 'id': ['required', 'user_organization']
    * }  
    * @returns 
    * @description: ACEPTAR INVITACION
    */
    async postOrganizationInviteAccept(body) {
        return ApiHelper.post(`${Paths.organizationInviteAccept}`, body)
    }
    /**
      * @param {
    * 'id': ['required', 'user_organization']
    * }  
    * @returns 
    * @description: ELIMINAR MIEMBRO DE LA ORGANIZACION
    */
    async delOrganizationDelete(id) {
        return ApiHelper.delete(`${Paths.organizationDelete}/${id}`)
    }
    /**
     * @param {
    *'size': ['required', 'float'],
    *'rate': ['required', 'int']
    * }  
    * @returns 
    */
    async postBtcInscribeCost(body) {
        return ApiHelper.post(`${Paths.btcInscribeCost}`, body)
    }
    /**
  * @returns 
  * @description: LISTADO DE PRECIOS DISPONIBLES
  */
    async getBtcInscribeCostRates(params) {
        return ApiHelper.get(`${Paths.btcInscribeCostRates}`, params)
    }
    /**
  * @returns 
  * @description: LISTADO DE MIEMBROS DE ORGANIZACION
  */
    async getBtcInscribeCostPayment(params) {
        return ApiHelper.get(`${Paths.btcInscribeCostPayment}`, params)
    }
    /**
  * @returns 
  * @description: LISTADO DE MIEMBROS DE ORGANIZACION
  */
    async postBtcInscribe(params) {
        return ApiHelper.post(`${Paths.btcInscribe}`, params)
    }
    /**
* @returns 
* @description: LISTADO DE MIEMBROS DE ORGANIZACION
*/
    async getBtcInscribe(item_id, params) {
        return ApiHelper.get(`${Paths.btcInscribe}/${item_id}`, params)
    }




}

export default new DataService()