import React, { useEffect } from 'react';
import SortableItem from './sortable-item';
import { SortableContainer } from 'react-sortable-hoc';

const SortableList = (props) => {
    const { collection_id, array_properties, swapPositions, setPropertyAfterChange } = props;

    useEffect(() => {

    }, [props])
    return (
        <ul>
            {props.items.map((item, index) => (
                <section key={'s' + index}>
                    <SortableItem key={`item-${index}`} collection_id={collection_id} parent_key={'sortableItem' + index} index={index} property={item} swapPositions={swapPositions} array_properties={array_properties}
                        setPropertyAfterChange={setPropertyAfterChange} />
                    <span className='container-tab-components-separator'></span>
                </section>
            ))}
        </ul>
    );
}

export default SortableContainer(SortableList);