import React, { useEffect, useState, useContext } from "react"
import { GeneralContext } from '../../context/'

const ProgressHeader = (props) => {
    const { bgcolor, completed, percentage } = props;
    const generalContext = useContext(GeneralContext)
    const { importingDb, exportingDb } = generalContext

    const containerStyles = {
        height: 14,
        width: '100%',
        backgroundColor: "#DCD3FF",
        borderRadius: 50,
        margin: '0px 0px 42px 0px',
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <section className="progress-header">
            <div className="progress-header-contain">
                <div className="progress-header-labels">
                    <p className="progress-header-labels-progress">Progress</p>
                    <p className="progress-header-labels-percentage" style={{ marginLeft: '4px' }}>{percentage}%</p>
                </div>
                {
                    (importingDb || exportingDb) && (
                        <div className="progress-header-labels">
                            <p className="progress-header-labels-percentage text-rainbow-animation">Syncing</p>
                            <p className="progress-header-labels-progress" style={{ marginLeft: '4px' }}>We are working on the new changes applied to this collection, don’t close this browser tab to prevent data loss.</p>
                        </div>
                    )
                }
            </div>

            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}></span>
                </div>
            </div>
        </section >

    );
};

export default ProgressHeader;