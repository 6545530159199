import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"
import { loadState, saveState } from './localStorage'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadState()

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
)

store.subscribe(() => {
    saveState({
        Auth: store.getState().Auth,
        Data: store.getState().Data
    })
})

sagaMiddleware.run(rootSaga)

export default store