import React, { useContext, useEffect } from 'react'
import { Container } from 'reactstrap'
import Table from './components/Datatable'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Projects`),
            parentLink: '/projects',
            item: props.t(`My Projects`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    {/* <Row>
                        <Col sm={6}>
                            <p className='general-title'>My collections</p>
                        </Col>
                    </Row> */}
                    <Table />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))