import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import image_box from '../../../assets/images/ImageBox.png'


import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataService from '../../../helpers/DataService'
import Modal from "../../../components/Global/modals"
import { loadState } from "../../../store/localStorage"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { userInfo } = props;
    const [team_members, setTeamMembers] = useState([])
    const [showNewModalCard, setShowNewModalCard] = useState(false)
    const [company_name, setCompanyName] = useState('')
    const [billing_email, setBillingEmail] = useState('')
    const [current_id, setCurrentId] = useState('')
    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '66%',
            maxWidth: '1120px',
            minWidth: '640px',
        }
    }

    useEffect(() => {
        if (userInfo) {
            console.log('userInfo', userInfo);
            const { id, } = userInfo
            setCurrentId(id)
        }
        getTeamMembers()

    }, [userInfo])





    function getTeamMembers() {
        setLoading(true)
        return DataService.getOrganization().then(response => {
            console.log(response.data);
            if (response.data) {
                const { data } = response.data
                setTeamMembers(data)
                setLoading(false)
            }
        })
    }


    const saveNewTeamMember = () => {
        console.log('saveNewTeamMember');
        if (!_validateStep()) return;
        try {
            setLoading(true)
            const body = {
                email: billing_email,

            }
            DataService.postOrganizationInvite(body).then(response => {
                toastrSuccess('Organization invited successfully')
                setLoading(false)
                setShowNewModalCard(false)
                setBillingEmail('')
                getTeamMembers()

            })
                .catch(err => {
                    console.log(err)
                    toastrError(`Failed to update organization data`, 'Error');
                })
        } catch (error) {
            toastrError(`Failed to send invitation`, 'Error');

        }
    }

    const deleteMember = (item) => {
        setLoading(true)
        DataService.delOrganizationDelete(item.id).then(response => {
            toastrSuccess('User deleted')
            getTeamMembers()
        })
    }

    const _validateStep = () => {
        // if (company_name === '') {
        //     toastrError(props.t(`Don't forget to add the company name of the organization`), 'Error')
        //     return false
        // }
        if (billing_email === '') {
            toastrError(props.t(`Don't forget to add the  billing email of the organization`), 'Error')
            return false
        }
        return true
    }


    const __renderNewCardDetailView = (card) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showNewModalCard}
            onRequestClose={() => {
                setShowNewModalCard(false)
            }}
            body={bodyNewCardDetail}
            footer={footerModalNew}
            styles={modalCardStyles}
        />
    )
    const bodyNewCardDetail = <div className='modalcard'>
        <div className='modalcard-legendary'>
            <div className='modalcard-legendary-title'>
                Add team member
            </div>
            <div className='modalcard-legendary-contain'>
                <div className='modalcard-legendary-contain-body'>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Col sm={5}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Name`)}
                                </Label>

                                <Input
                                    required
                                    value={company_name}
                                    type="text"
                                    id='company_name'
                                    className="input-white-input circle"
                                    placeholder='Company name'
                                    onChange={event => {
                                        const { value } = event.target
                                        if (!value) {
                                            document.getElementById('company_name').classList.add('input-error')
                                        } else {
                                            document.getElementById('company_name').classList.remove('input-error')
                                        }
                                        setCompanyName(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col sm={5}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Email address`)}
                                </Label>

                                <Input
                                    required
                                    value={billing_email}
                                    type="text"
                                    id='billing_email'
                                    className="input-white-input circle"
                                    placeholder='email'
                                    onChange={event => {
                                        const { value } = event.target

                                        if (!value) {
                                            document.getElementById('billing_email').classList.add('input-error')
                                        } else {
                                            document.getElementById('billing_email').classList.remove('input-error')
                                        }
                                        setBillingEmail(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>

        </div>

    </div>


    const footerModalNew = <div className='modalcard-footer end'>
        <Button
            type="button"
            className="theme-button-white-primary"
            onClick={(e) => {
                e.preventDefault()
                setBillingEmail('')
                setShowNewModalCard(false)
            }}
        >
            {props.t(`Cancel`)}
        </Button>
        <Button
            type="button"
            className="theme-button-update "
            onClick={(e) => {
                e.preventDefault()
                saveNewTeamMember()
            }}
        >
            {props.t(`Add member`)}
        </Button>

    </div>

    return (
        <React.Fragment>
            {
                showNewModalCard && __renderNewCardDetailView()

            }
            <Form className='collections-ticketing review'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Team members`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>{props.t(`Add team members to help manage your project.`)}</span>
                            <div className='team-members'>
                                <div className='team-members-table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {/* <th style={{ padding: '16px 0' }}>
                                                    Id
                                                </th> */}
                                                <th style={{ padding: '16px 0' }}>
                                                    Email Address
                                                </th>
                                                <th style={{ padding: '16px 0' }}>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                team_members.map((item, key) => (
                                                    <tr key={key}>
                                                        {/* <td>
                                                            {item.user_id}
                                                        </td> */}
                                                        <td>
                                                            {item.user.email}
                                                        </td>
                                                        <td>
                                                            {
                                                                current_id !== item.user_id && (
                                                                    <span className='team-members-table-delete' onClick={() => {
                                                                        deleteMember(item)
                                                                    }}>
                                                                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                    </span>
                                                                )
                                                            }

                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="theme-button-collaborator"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setShowNewModalCard(true)
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                        {props.t(`Add member`)}
                                    </Button>
                                </Col>

                            </Row>
                        </section>
                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))