import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import image_box from '../../../assets/images/ImageBox.png'


import { toastrError } from "../../../helpers/Toastr"
import { faCloudArrowUp, faCheckCircle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm } = props;
    const [eventTitle, setEventTitle] = useState('')

    const img_style = {
        height: '265px',
        width: '250px',
        cursor: 'pointer'
    }


    return (
        <React.Fragment>
            <Form className='collections-ticketing review'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Approved bouncers`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>Bouncers are the people in charge of punching NFT Tickets at the Event entrance.</span>
                            <div className='collections-ticketing-settings-form'>
                                <div className='collections-ticketing-settings-form-column'>
                                    <Row style={{display:'flex', alignItems:'center'}}>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Email`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={eventTitle}
                                                    type="text"
                                                    id='eventTitle'
                                                    className="input-white-input circle"
                                                    placeholder='collection'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('eventTitle').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('eventTitle').classList.remove('input-error')
                                                        }
                                                        setEventTitle(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Password`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={eventTitle}
                                                    type="text"
                                                    id='eventTitle'
                                                    className="input-white-input circle"
                                                    placeholder='collection'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('eventTitle').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('eventTitle').classList.remove('input-error')
                                                        }
                                                        setEventTitle(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <div className='collections-ticketing-bouncer-trash' >
                                                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="theme-button-collaborator collections-ticketing-bouncer-trash-add"
                                            >
                                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                {props.t(`Add bouncer`)}
                                            </Button>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))