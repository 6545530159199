import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    UPDATE_AVATAR,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    SET_TOKEN,
    SET_PROJECT,
    SET_TICKET,
    SET_ORDINAL,
} from "./actionTypes"

export const loginUser = (data, history, from) => {
    return {
        type: LOGIN_USER,
        payload: { data, history, from }
    }
}

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        data: token
    }
}

export const setUserProject = (project) => {
    return {
        type: SET_PROJECT,
        data: project
    }
}
export const setUserTicket = (ticket) => {
    return {
        type: SET_TICKET,
        data: ticket
    }
}

export const setUserOrdinal = (ordinal) => {
    return {
        type: SET_ORDINAL,
        data: ordinal
    }
}
export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        data: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const updatePasswordSuccess = () => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: {}
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const updateAvatar = (avatar) => {
    return {
        type: UPDATE_AVATAR,
        data: avatar
    }
}

export const updateProfile = (data) => {
    return {
        type: UPDATE_PROFILE,
        data
    }
}

export const updatePassword = (data) => {
    return {
        type: UPDATE_PASSWORD,
        data
    }
}