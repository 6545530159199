import React, { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';
import Counterbutton from './CounterButton'
import ImageComponent from "./Image"

import {
    Button
} from "reactstrap"
import { segmentTrack } from "../../../helpers/Segment";
import { toastrInfo } from "../../../helpers/Toastr";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
const DetailComponent = (props) => {
    const { collection, showDetails, item_sold, item_total, counter, setCounter, network_id, touchScreenDevice,
        provider, walletKey, connectWallet, buyCollectionMobile, connectWalletHandler, showConnect, showBuyNow,
        _handleBuyCollection, loadPayment, transferETH, maticBalance, solBalance } = props;
    const [crossmintId, setCrossmintId] = useState('')
    const [collectionId, setCollectionId] = useState('')
    const [projectId, setProjectId] = useState('4dcf6c13-9434-419b-a108-34f48f13eee3')
    const [totalPrice, setTotalPrice] = useState('')
    const [external_url, setExternalUrl] = useState('')

    useEffect(() => {
        console.log('collection.price', collection.price);
        console.log('maticBalance', maticBalance);
    }, [collection, solBalance])

    useEffect(() => {
        if (!collection) return;
        const { crossmint_id, price, id } = collection;
        if (!crossmint_id) return;
        setCrossmintId(crossmint_id);
        setCollectionId(crossmint_id);
        setTotalPrice(price + '');
        if (collection.external_url) {
            if (collection.external_url.includes('http')) {
                setExternalUrl(collection.external_url)
            } else {
                setExternalUrl("https://" + collection.external_url)
            }
        }
    }, [collection])

    const openExternalUrl = () => {


        window.open(collection.external_url, '_blank');

    }
    const showConnectButton = <>
        {
            ((network_id === 1 || network_id === '1') && !touchScreenDevice) && (
                <div>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="theme-button-secundary claim"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {!provider && (
                        <p>
                            No provider found. Install{" "}
                            <a href="https://phantom.app/">Phantom Browser extension</a>
                        </p>
                    )}
                </div>
            )
        }
        {
            ((network_id === 1 || network_id === '1') && touchScreenDevice) && (
                <>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="theme-button-secundary claim"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {!provider && (
                        <Button
                            type="button"
                            color="primary"
                            className="theme-button-secundary claim"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                buyCollectionMobile()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                </>
            )
        }
        {
            (network_id === 2 || network_id === '2') &&
            <Button
                type="button"
                color="primary"
                className="theme-button-secundary claim"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`connect wallet to deploy`)
                    connectWalletHandler()
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        }
    </>

    const showBuyNowButton = <>
        {
            ((network_id === 1 || network_id === '1') && !isNaN(solBalance) && solBalance >= collection.price) &&
            <Button
                type="button"
                color="primary"
                className="theme-button-secundary claim"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`pay deploy`)
                    _handleBuyCollection()
                }}
            >
                {props.t(`CONFIRM`)}
                {loadPayment &&
                    <div className='min-loader'>
                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw black">
                            <div></div><div><div></div></div>
                        </div></div>
                    </div>
                }
            </Button>
        }
        {
            ((network_id + '') === '1' && !isNaN(solBalance) && solBalance < collection.price) &&
            <Button
                style={{ cursor: 'not-allowed' }}
                type="button"
                color="primary"
                className="theme-button-secundary claim"
                onClick={(e) => {
                    e.preventDefault()
                    toastrInfo('Insufficient funds')
                }}
            >
                {props.t(`Insufficient funds`)}
            </Button>
        }
        {
            ((network_id + '') === '2' && !isNaN(maticBalance) && maticBalance >= collection.price) &&
            <Button
                type="button"
                color="primary"
                className="theme-button-secundary claim"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`pay deploy`)
                    transferETH()
                }}
            >
                {props.t(`CONFIRM`)}
                {loadPayment &&
                    <div className='min-loader'>
                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw black">
                            <div></div><div><div></div></div>
                        </div></div>
                    </div>
                }
            </Button>
        }
        {
            ((network_id + '') === '2' && !isNaN(maticBalance) && maticBalance < collection.price) &&
            <Button
                style={{ cursor: 'not-allowed' }}
                type="button"
                color="primary"
                className="theme-button-secundary claim"
                onClick={(e) => {
                    e.preventDefault()
                    toastrInfo('Insufficient funds')
                }}
            >
                {props.t(`Insufficient funds`)}
            </Button>
        }

    </>
    return (
        <section className="collections-reclaim-data">
            <div className="collections-reclaim-data-contain">
                <ImageComponent collection={collection} image_id={collection.image_id} is_desktop={false}></ImageComponent>

                <div className="collections-reclaim-data-contain-row">
                    <span className="collections-reclaim-data-title">{item_sold} / {item_total} minted</span>
                </div>
                <div className="collections-reclaim-data-contain-row">
                    <span className="collections-reclaim-data-name"> {collection.name || ''}</span>
                </div>
                <div className="collections-reclaim-data-contain-row">
                    {
                        (collection.external_url && external_url) &&
                        <a href={external_url} target="_blank" rel="noopener noreferrer" className="collections-reclaim-data-url">{collection.external_url || ''}</a>
                    }
                </div>
                <div className="collections-reclaim-data-contain-row">
                    <span className="collections-reclaim-data-description">
                        {collection.description || ''}
                    </span>
                </div>
                <div className="collections-reclaim-data-contain-column">
                    <span className="collections-reclaim-data-title"> {collection.price ? collection.price : '0'} {collection.symbol || ''}</span>
                    <div className="collections-reclaim-data-contain-row buttons" >
                        {
                            item_sold < item_total && (
                                <>
                                    {
                                        showConnect &&
                                        <>
                                            {showConnectButton}
                                        </>
                                    }
                                    {
                                        showBuyNow &&
                                        <>
                                            {showBuyNowButton}
                                        </>
                                    }
                                    {
                                        (!showConnect && !showBuyNow) &&
                                        <>
                                            {showConnectButton}
                                        </>
                                    }
                                </>
                            )
                        }
                        {
                            item_sold >= item_total && (
                                <Button
                                    style={{ cursor: 'not-allowed' }}
                                    type="button"
                                    color="primary"
                                    className="theme-button-secundary claim"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toastrInfo('All the nfts hava been sold')
                                    }}
                                >
                                    {props.t(`Sold`)}
                                </Button>
                            )
                        }

                        {

                            ((network_id + '') === '2' && crossmintId) &&
                            <CrossmintPayButton
                                collectionId={collectionId}
                                projectId={projectId}
                                className="crossmint-custom"
                                mintConfig={{ "type": "thirdweb-edition-drop", "totalPrice": totalPrice, "quantity": "1", "tokenId": "0", }}
                            />

                        }
                    </div>
                </div>
            </div>
        </section >
    );
};

export default withRouter(withNamespaces()(DetailComponent))