import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import image_box from '../../../assets/images/ImageBox.png'


import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { faCheckCircle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataService from '../../../helpers/DataService'
import Modal from "../../../components/Global/modals"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm, project_id } = props;
    const [team_members, setTeamMembers] = useState([])
    const [showNewModalCard, setShowNewModalCard] = useState(false)
    const [company_name, setCompanyName] = useState('')
    const [billing_email, setBillingEmail] = useState('')
    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '66%',
            maxWidth: '1120px',
            minWidth: '640px',
        }
    }

    useEffect(() => {
        getTeamMembersInvite()
    }, [project_id])

    function getTeamMembersInvite() {
        setLoading(true)
        return DataService.getOrganizationInvite().then(response => {
            console.log(response.data);
            if (response.data) {
                const { data } = response.data
                setTeamMembers(data)
                setLoading(false)
            }
        })
    }

    const acceptInvite = (item) => {
        setLoading(true)
        const body = {
            id: item.id
        }
        DataService.postOrganizationInviteAccept(body).then(response => {
            toastrSuccess('Invite accepted')
            getTeamMembersInvite()
        })
    }

    return (
        <React.Fragment>
            <Form className='collections-ticketing review'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Team members`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>{props.t(`Add team members to help manage your project.`)}</span>
                            <div className='team-members'>
                                <div className='team-members-table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {/* <th style={{ padding: '16px 0' }}>
                                                    Name
                                                </th> */}
                                                <th style={{ padding: '16px 0' }}>
                                                    Email Address
                                                </th>
                                                <th style={{ padding: '16px 0' }}>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                team_members.map((item, key) => (
                                                    <tr key={key}>
                                                        {/* <td>
                                                            {item.user_id}
                                                        </td> */}
                                                        <td>
                                                            {item.user.email}
                                                        </td>
                                                        <td>
                                                            {
                                                                !item.is_accepted &&
                                                                <span className='team-members-table-invite' onClick={() => {
                                                                    acceptInvite(item)
                                                                }}>
                                                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                                </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <Row>
                                <Col sm={6}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="theme-button-collaborator"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setShowNewModalCard(true)
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                        {props.t(`Add member`)}
                                    </Button>
                                </Col>

                            </Row> */}
                        </section>
                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))