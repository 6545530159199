import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { toastrError, toastrInfo } from '../../../helpers/Toastr';
function Dropzone({ onAssetFull, fileName }) {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive, open } =
        useDropzone({
            useFsAccessApi: false
        });



    useEffect(() => {
        toastrInfo(!!acceptedFiles)
        if (!acceptedFiles) return;
        // toastrInfo('acceptedFiles.length ---------' + acceptedFiles.length)
        if (acceptedFiles.length < 1) return;
        if (acceptedFiles.length > 1) {
            toastrError('You can add only a file')
            return;
        }
        const _file = acceptedFiles[0];
        // toastrInfo('file name : ', _file.name)
        if (!_file) return;

        onAssetFull(acceptedFiles[0])

    }, [acceptedFiles])



    return (
        <div style={{ width: '100%' }}>
            <div {...getRootProps({ className: "collections-create-form-upload-drop" })}>
                <input className="input-zone" {...getInputProps()} />
                {
                    !!fileName &&
                    <p className='collections-create-form-upload-drop-title'>
                        File name: {fileName}
                    </p>
                }

                <div className='collections-create-form-upload-drop-ico'>
                    <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                </div>
                {
                    isDragActive ? (

                        <p className='collections-create-form-upload-drop-title'>
                            Release to drop the files here
                        </p>
                    ) :
                        <p className='collections-create-form-upload-drop-title'>
                            Drop files here or click to upload your assets
                        </p>
                }
                <p className='collections-create-form-upload-drop-subtitle'>
                    Not sure what to do? Head to our&nbsp;&#32; <b>&nbsp;&#32;help section</b>
                </p>
            </div>
        </div>


    );
}
export default Dropzone;
