import React from 'react';
import SortableItem from './sortable-item';
import { SortableContainer } from 'react-sortable-hoc';

const SortableList = (props) => {
    const { array_properties, swapPositions, setPropertyAfterChange } = props;
    return (
        <ul>
            {props.items.map((item, index) => (
                <SortableItem key={`item-${index}`} index={index} property={item} swapPositions={swapPositions} array_properties={array_properties}
                    setPropertyAfterChange={setPropertyAfterChange} />
            ))}
        </ul>
    );
}

export default SortableContainer(SortableList);