// db.js
import Dexie from 'dexie';

export const db = new Dexie('neefter');


db.version(26).stores({
    collections_settings: '++id,collection_id,name,blockchain,image_id,collectionFamily,tokens,collectionSymbol,tokenExternal,tokenDescription,launch_date,price,seller_fee_basis_points,creators,subdomain,cache_zip', // Primary key and indexed props
    assets: '++id,collection_id,name,data,file,preview',
    filters: '++id,collection_id,type,options',
    properties: '++id,collection_id,name,percentage,traits,order',
    generative_assets: '++id,collection_id,name,layers,image_id,file,preview,is_legendary',
    oneandone: '++id,image,image_id,name,external_url,description,properties',
    rules: '++id,type,firstAttribute,secondAttribute',
    img_universe: '++id,name,file',

});
