import React from "react"
import PropTypes from "prop-types"
import { exportDB, importDB, peakImportFile, importInto } from "dexie-export-import";
import JSZip from "jszip"
import { db } from "../db";
import DataService from '../helpers/DataService';
import { toastrError, toastrSuccess } from "../helpers/Toastr";
import axios from 'axios'

export const Context = React.createContext({})
export const Provider = (props) => {
    const { children } = props
    const [loading, setLoading] = React.useState(false)
    const [loading1, setLoading1] = React.useState(false)
    const [loading2, setLoading2] = React.useState(false)
    const [loading3, setLoading3] = React.useState(false)
    const [loading4, setLoading4] = React.useState(false)
    const [loading5, setLoading5] = React.useState(false)
    const [loading_files, setLoadingFiles] = React.useState(false)
    let [total_files, setTotalFiles] = React.useState(0)
    let [current_files, setCurrentFiles] = React.useState(0)
    let [total_size, setTotalSize] = React.useState(0)
    let [current_size, setCurrentSize] = React.useState(0)

    const [currentProject, setProject] = React.useState(undefined)
    const [currentRoute, setCurrentRoute] = React.useState('')
    const [pagination, setPagination] = React.useState([])
    const [cartItems, setCartItems] = React.useState([])
    const [activePriceList, setActivePriceList] = React.useState({})
    const [taxRate, setTaxRate] = React.useState(0)
    const [folio, setFolio] = React.useState()
    const [activeBranch, setActiveBranch] = React.useState({
        value: null,
        label: ''
    })
    const [breadcrumb, setBreadcrumb] = React.useState({
        icon: '',
        parent: '',
        parentLink: '',
        item: '',
        home: false
    })
    const [activeCustomer, setActiveCustomer] = React.useState({
        value: null,
        label: ''
    })
    let [importingDb, setImportingDb] = React.useState(false)
    let [exportingDb, setExportingDb] = React.useState(false)
    const abortController = new AbortController()
    const cancelTokenSource = axios.CancelToken.source();


    const addItemToCart = async (data) => {
        try {
            let itemfound = false
            let items = cartItems

            setLoading(true)
            await items.forEach((item) => {
                if (item.product) {
                    if (item.product.id === data.product.id) {
                        let unitPrice = item.unitPrice
                        let quantity = +item.quantity + +data.quantity
                        let taxAmount = 0
                        let subtotal = +item.subtotal + (+data.quantity * +item.unitPrice)
                        let scheme = item.product.product.taxScheme

                        for (let tax of scheme.value) {
                            if (scheme.taxInclusive) {
                                let priceBeforeTaxes = +unitPrice / (+tax.taxRate + 1)
                                subtotal = +item.subtotal + (+data.quantity * +priceBeforeTaxes)
                            }

                            taxAmount += +subtotal * +tax.taxRate
                        }

                        let discount = subtotal * (+data.discount / 100)
                        const total = (+subtotal - +discount) + +taxAmount

                        item.quantity = quantity
                        item.subtotal = subtotal
                        item.discount = discount
                        item.taxAmount = taxAmount
                        item.total = total
                        itemfound = true
                    }
                }
            })

            if (!itemfound) { items.push(data) }

            setCartItems(items)
        } finally {
            setLoading(false)
        }
    }

    const removeItemFromCart = async (item) => {
        const result = cartItems.filter(i => i.product.id !== item.product.id)
        setCartItems(result)
    }

    const increaseItemQuantity = async (item, i) => {
        try {
            setLoading(true)

            let quantity = item.quantity++
            let importe = +quantity * +item.unitPrice
            let discount = importe * (+item.discount / 100)
            let subtotal = importe - discount
            let taxAmount = 0
            let scheme = item.product.product.taxScheme

            for (let tax of scheme.value) {
                taxAmount += +subtotal * +tax.taxRate
            }

            let total = +subtotal + +taxAmount
            const items = [...cartItems]
            items[i] = {
                ...items[i],
                quantity: item.quantity++,
                subtotal,
                discount,
                taxAmount,
                total,
                scheme
            }

            setCartItems(items)
        } finally {
            setLoading(false)
        }
    }

    const decreaseItemQuantity = async (item, i) => {
        try {
            setLoading(true)

            let quantity = item.quantity--
            let importe = +quantity * +item.unitPrice
            let discount = importe * (+item.discount / 100)
            let subtotal = importe - discount
            let taxAmount = 0
            let scheme = item.product.product.taxScheme

            for (let tax of scheme.value) {
                taxAmount += +subtotal * +tax.taxRate
            }

            let total = +subtotal + +taxAmount
            const items = [...cartItems]
            items[i] = {
                ...items[i],
                quantity: item.quantity--,
                subtotal,
                discount,
                taxAmount,
                total,
                scheme
            }

            setCartItems(items)
        } finally {
            setLoading(false)
        }
    }

    const getCartTotal = () => {
        if (!cartItems.length) { return 0 }

        return cartItems.reduce((ac, next) => ac + next.quantity * getPriceByActiveListId(next.product), 0)
    }

    const getPriceByActiveListId = (item) => {
        const price = item.prices.filter(p => p.priceTypeId === activePriceList.id)

        return price[0].amount
    }

    const clearCart = () => {
        setCartItems([])
    }

    const getCartTotals = () => {
        if (!cartItems.length) { return 0 }

        const subtotal = cartItems.reduce((a, b) => a + +b.subtotal, 0)
        const discount = cartItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)
        const taxAmount = cartItems.reduce((a, b) => a + +b.taxAmount, 0)
        const total = cartItems.reduce((a, b) => a + +b.total, 0)

        return {
            subtotal,
            discount,
            taxAmount,
            total
        }
    }


    const importDb = (cache_zip) => {
        console.log('importingDb', cache_zip);
        console.log('is importingDb?', importingDb);
        if (importingDb) return;
        if (!cache_zip) return
        return fetch(cache_zip)
            .then(res => res.blob())
            .then(async blob => {
                const zipper = new JSZip();
                try {
                    setImportingDb(true)
                    importingDb = true;
                    const unzippedFiles = await zipper.loadAsync(blob, {});
                    console.log('unzippedFiles', unzippedFiles);
                    console.log('unzippedFiles.files', unzippedFiles.files);

                    if (!unzippedFiles || !unzippedFiles.files) return;
                    for (const zipped in unzippedFiles.files) {
                        if (!unzippedFiles.files[zipped]) return;
                        const _file = unzippedFiles.files[zipped];
                        try {
                            console.log('!db.isOpen()', !db.isOpen());
                            if (!db.isOpen()) return;
                            await db.delete().then(() => db.open())
                        } catch (error) {
                            console.log('error', error);
                            setImportingDb(false)
                        }

                        await _file.async('blob').then(async (fileData) => {
                            if (!fileData) return;
                            try {
                                // const db = await importDB(fileData);
                                await importInto(db, fileData, {
                                    acceptVersionDiff: true,
                                    acceptMissingTables: true,
                                    acceptNameDiff: true,
                                    acceptChangedPrimaryKey: true,
                                    overwriteValues: true,
                                    noTransaction: true,
                                    clearTablesBeforeImport: true,
                                });
                                const importMeta = await peakImportFile(fileData);

                                const collections_settings = await db.collections_settings.toArray();
                                const setting = collections_settings[0];
                                // if (!setting.cache_zip) {
                                db.collections_settings.update(setting.id, { cache_zip: cache_zip })
                                // }

                                setImportingDb(false)
                                toastrSuccess('Syncing import complete!')
                                console.log("Database name:", importMeta.data.databaseName);
                                console.log("Database version:", importMeta.data.databaseVersion);
                            } catch (error) {
                                console.log('error', error);
                                setImportingDb(false)
                            }
                        })
                    }
                } catch (error) {
                    console.log('error', error);
                    setImportingDb(false)
                }
            }) // saveAs is a function from the file-saver package. 
            .catch((err) => {
                console.log('error', err);
                // setImportingDb(false)
                toastrError(`Can't fetch info, reload page and try again`)
                // window.location.reload();
            });
    }
    const exportDb = async (collection_id) => {
        //
        // Export to Blob
        //
        // const blob = await db.export({ prettyJson: false });
        console.log('try to exportDb');

        if (exportingDb) {
            console.log('cancelTokenSource');
            cancelTokenSource.cancel()
            exportingDb = false;
            setExportingDb(false)
            return exportDb(collection_id)
        }
        const blob = await exportDB(db, { prettyJson: false });
        console.log('await blob', blob);
        setExportingDb(true)

        let zip = new JSZip()
        zip.file('exportdb.json', blob, { binary: true })

        zip.generateAsync({
            type: "blob", compression: "DEFLATE", compressionOptions: {
                level: 9
            }
        }).then(async function (content) {
            //do things here
            console.log('content', content);
            const response = await fileUploadUniqueService(content)
            console.log('uploadImage blob', response);
            DataService.putCollectionById(collection_id, {
                cache_zip: response.data.id,
            }, cancelTokenSource).then(async response => {
                if (response.data && response.data.id) {
                    const collections_settings = await db.collections_settings.toArray();
                    const setting = collections_settings[0];

                    db.collections_settings.update(setting.id, { cache_zip: response.data.cache_zip })
                    setExportingDb(false)
                    toastrSuccess('Syncing succeded!')
                }
            })
                .catch(err => {
                    console.log('putCollectionById', err);
                })
        })
    }


    const fileUploadUniqueService = (blob) => {
        let formData = new FormData()
        formData.append('upload_file', blob, 'content.zip')
        return DataService.uploadImage(formData)
    }

    const setPreviewLoader = (_loader) => {
        if (_loader) {
            if (loading1 || loading2) return;
            const randInt = Math.floor(Math.random() * (2 - 0)) + 0;
            if (randInt) {
                setLoading1(true)
                setLoading2(false)
            } else {
                setLoading2(true)
                setLoading1(false)

            }
        } else {
            setLoading1(false)
            setLoading2(false)
        }
    }
    const setToTheMoonLoader = (_loader) => {
        if (_loader) {
            if (loading3 || loading5) return;
            const randInt = Math.floor(Math.random() * (2 - 0)) + 0;
            if (randInt) {
                setLoading3(true)
                setLoading5(true)
            } else {
                setLoading5(true)
                setLoading3(true)

            }
        } else {
            setLoading3(false)
            setLoading5(false)
        }
    }




    const objContext = {
        loading,
        setLoading,
        currentRoute,
        setCurrentRoute,
        pagination,
        setPagination,
        cartItems,
        addItemToCart,
        removeItemFromCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        clearCart,
        activePriceList,
        setActivePriceList,
        getCartTotal,
        getCartTotals,
        activeCustomer,
        setActiveCustomer,
        setTaxRate,
        folio,
        setFolio,
        taxRate,
        breadcrumb,
        setBreadcrumb,
        setActiveBranch,
        activeBranch,
        currentProject,
        setProject,
        exportDb,
        importDb,
        fileUploadUniqueService,
        importingDb,
        exportingDb,
        setPreviewLoader,
        setToTheMoonLoader,
        loading1,
        loading2,
        loading3,
        loading4,
        loading5,
        loading_files,
        setLoadingFiles,
        total_files,
        setTotalFiles,
        current_files,
        setCurrentFiles,
        total_size,
        setTotalSize,
        current_size,
        setCurrentSize,
    }



    return <Context.Provider value={objContext}>{children}</Context.Provider>
}

Provider.propTypes = { children: PropTypes.any }
export const { Consumer } = Context