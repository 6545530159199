import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import YoutubeEmbed from '../../../components/Global/EmbedYoutube'
import image_box from '../../../assets/images/ImageBox.png'
import DataService from '../../../helpers/DataService'
import { segmentTrack } from "../../../helpers/Segment"


import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { faCloudArrowUp, faCheckCircle, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dateHourFormater, dateSeparatedFormater, dateFromFormat } from "../../../helpers/utils"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm, collection_id, collection } = props;
    const [description, setDescription] = useState('')
    const [logo, setLogo] = useState('')
    const [event_title, setEventTitle] = useState('')
    const [event_organizer, setEventOrganizer] = useState('')
    const [event_venue, setEventVenue] = useState('')
    const [event_venue_address, setEventVenueAddress] = useState('')
    const [event_start_date, setEventStartDate] = useState('')
    const [event_end_date, setEventEndDate] = useState('')
    const [event_description, setEventDescription] = useState('')
    const [event_start_date_minuter, setEventStartDateMinuter] = useState('')
    const [event_end_date_minuter, setEventEndDateMinuter] = useState('')
    const [subdomain, setSubdomain] = useState('')
    const [minting_site, setmintingSite] = useState('')
    const [bouncer, setBouncer] = useState('')
    const [enableSubdomain, setEnableSubdomain] = useState(true)

    const [image_tempo, setImageTempo] = useState(undefined)
    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }

    useEffect(() => {
        const selectedTicket = collection.event_info;
        if (!selectedTicket) return;

        const event_start_date = selectedTicket.event_start_date.replaceAll('/', '-')
        const event_end_date = selectedTicket.event_end_date.replaceAll('/', '-')
        const end_date = dateFromFormat(event_end_date)
        const start_date = dateFromFormat(event_start_date)
        const endHours = end_date.getHours()
        const endMinutes = end_date.getMinutes()
        const startHours = start_date.getHours()
        const startMinutes = start_date.getMinutes()
        const minute_end_date = `${(endHours + '').length > 1 ? endHours : '0' + endHours}:${(endMinutes + '').length > 1 ? endMinutes : '0' + endMinutes}`
        const minute_start_date = `${(startHours + '').length > 1 ? startHours : '0' + startHours}:${(startMinutes + '').length > 1 ? startMinutes : '0' + startMinutes}`

        setEventStartDateMinuter(minute_start_date)
        setEventEndDateMinuter(minute_end_date)
        setDescription(selectedTicket.description)
        setLogo(selectedTicket.logo)
        setEventTitle(selectedTicket.event_title)
        setEventOrganizer(selectedTicket.event_organizer)
        setEventVenue(selectedTicket.event_venue)
        setEventVenueAddress(selectedTicket.event_venue_address)
        setEventStartDate(dateSeparatedFormater(start_date))
        setEventEndDate(dateSeparatedFormater(end_date))
        setEventDescription(selectedTicket.event_description)
        setImageTempo(collection.logo)
        setSubdomain(selectedTicket.subdomain)

        setmintingSite(`${collection.subdomain}.neefter.com`)
        setBouncer(`https://ticket-puncher-front.vercel.app/pending?collection_id=${collection_id}&label=meh`)

    }, [collection])

    const _handleFileUpload = (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        fileUpload(files[0]).then(async (response) => {

            setLogo(response.data.id)

            const _file = await toBase64(files[0])
            setImageTempo(_file);
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }


    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            setLoading(true)
            const _event_starts = dateHourFormater(event_start_date, event_start_date_minuter)
            const _event_ends = dateHourFormater(event_end_date, event_end_date_minuter)
            const body = {
                description: description,
                logo: logo,
                event_title: event_title,
                event_organizer: event_organizer,
                event_venue: event_venue,
                event_venue_address: event_venue_address,
                event_start_date: _event_starts,
                event_end_date: _event_ends,
                event_description: event_description,
                image_tempo: image_tempo,
            }
            DataService.putCollectionTicketing(collection_id, body).then(() => {
                toastrSuccess(`Ticket ${event_title} updated`)
            })


        } catch (error) {
            toastrError(`Failed to add ${event_title}: ${error}`, 'Error');

        }
    }
    const _validateStep = () => {
        if (logo === '') {
            toastrError(props.t(`Don't forget to add the logo of the event`), 'Error')
            return false
        }
        if (event_title === '') {
            toastrError(props.t(`Don't forget to enter the event title`), 'Error')
            return false
        }
        if (event_organizer === '') {
            toastrError(props.t(`Don't forget to enter the event organizer`), 'Error')
            return false
        }
        if (event_venue === '') {
            toastrError(props.t(`Don't forget to enter the event venue`), 'Error')
            return false
        }
        if (event_venue_address === '') {
            toastrError(props.t(`Don't forget to enter the event venue address`), 'Error')
            return false
        }
        if (event_start_date === '') {
            toastrError(props.t(`Don't forget to enter the event start date`), 'Error')
            return false
        }
        if (event_end_date === '') {
            toastrError(props.t(`Don't forget to enter the event end date`), 'Error')
            return false
        }
        if (event_description === '') {
            toastrError(props.t(`Don't forget to enter the event description`), 'Error')
            return false
        }
        if (event_start_date_minuter === '') {
            toastrError(props.t(`Don't forget to enter the event time starts`), 'Error')
            return false
        }
        if (event_end_date_minuter === '') {
            toastrError(props.t(`Don't forget to enter the event time ends`), 'Error')
            return false
        }
        // if (subdomain === '') {
        //     toastrError(props.t(`Don't forget to add subdomain`), 'Error')
        //     document.getElementById('subdomain').classList.add('input-error')
        //     return false
        // }
        if (!enableSubdomain) {
            toastrError(props.t(`Don't forget to add a non repeated subdomain`), 'Error')
            document.getElementById('subdomain').classList.add('input-error')
            return false
        }
        return true
    }

    return (
        <React.Fragment>
            <Form className='collections-ticketing'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`Ticket settings`)}</CardTitle>
                            <CardTitle className='collections-settings-tab-title mini'>{props.t(`URLs`)}</CardTitle>
                            <div className='collections-settings-row'>
                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Minting site`)}
                                                </Label>
                                                <div className='collections-minting-control' >
                                                    <div className='collections-minting-control-input'>
                                                        <Input
                                                            required
                                                            value={minting_site}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Cryptolucha'
                                                            id="subdomain"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className='collections-minting-control-post'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(minting_site)
                                                            toastrSuccess('Copied')
                                                        }}
                                                    >
                                                        <span><FontAwesomeIcon icon={faCopy}></FontAwesomeIcon></span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Bouncer`)}
                                                </Label>
                                                <div className='collections-minting-control' >
                                                    <div className='collections-minting-control-input'>
                                                        <Input
                                                            required
                                                            value={bouncer}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Cryptolucha'
                                                            id="subdomain"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className='collections-minting-control-post'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(bouncer)
                                                            toastrSuccess('Copied')
                                                        }}>
                                                        <span><FontAwesomeIcon icon={faCopy}></FontAwesomeIcon></span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <CardTitle className='collections-settings-tab-title mini'>{props.t(`Basic information`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>Name your event and tell event-goers why they should come. Add details that highlight what makes it unique.</span>
                            <div className='collections-ticketing-settings-form'>
                                <div className='collections-ticketing-settings-form-column'>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event avatar`)}
                                                </Label>
                                                <div className='collections-minting-control' >
                                                    <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_img')?.click()}>
                                                        <span>Choose File</span>
                                                    </div>
                                                    <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_img')?.click()}>
                                                        <Input
                                                            required
                                                            value={image_tempo}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='No file choosen'
                                                            id="logotype"
                                                        />
                                                    </div>
                                                    {/* <div className='collections-minting-control-icon'>
                                                        {
                                                            image_tempo &&
                                                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        }
                                                    </div> */}
                                                </div>
                                                <span className='input-label-mini'>jpg or png.</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Event title`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_title}
                                                    type="text"
                                                    id='eventTitle'
                                                    className="input-white-input circle"
                                                    placeholder='collection'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_title').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_title').classList.remove('input-error')
                                                        }
                                                        setEventTitle(event.target.value)
                                                    }}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Organizer`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_organizer}
                                                    type="text"
                                                    id='eventTitle'
                                                    className="input-white-input circle"
                                                    placeholder='collection'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_organizer').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_organizer').classList.remove('input-error')
                                                        }
                                                        setEventOrganizer(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Venue`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_venue}
                                                    type="text"
                                                    id='event_venue'
                                                    className="input-white-input circle"
                                                    placeholder='Venue'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_venue').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_venue').classList.remove('input-error')
                                                        }
                                                        setEventVenue(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Venue address`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={event_venue_address}
                                                    type="text"
                                                    id='event_venue_address'
                                                    className="input-white-input circle"
                                                    placeholder='Venue address'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_venue_address').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_venue_address').classList.remove('input-error')
                                                        }
                                                        setEventVenueAddress(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='collections-ticketing-settings-form-column'>
                                    <div className='collections-create-form-row-img'>
                                        <p>Avatar preview</p>
                                        <div className='card-picture' style={img_style} >
                                            <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo ? image_tempo : image_box} />
                                            <input type="file"
                                                id="upload_img"
                                                hidden
                                                onChange={event => {
                                                    segmentTrack(`Upload project avatar`)
                                                    _handleFileUpload(event)
                                                }}
                                            />
                                        </div>
                                        <div className='collections-create-form-row-ico' onClick={() => document.getElementById('upload_img')?.click()}>
                                            <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CardTitle className='collections-settings-tab-title mini'>{props.t(`Date and time`)}</CardTitle>
                            <div className='collections-settings-row'>
                                <div className='collections-settings-item'>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event starts*`)}
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="event_start_date"
                                                    id="event_start_date"
                                                    value={event_start_date}
                                                    placeholder="Event starts*"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_start_date').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_start_date').classList.remove('input-error')
                                                        }
                                                        setEventStartDate(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Start time`)}
                                                </Label>
                                                <Input
                                                    type="time"
                                                    name="event_start_date_minuter"
                                                    id="event_start_date_minuter"
                                                    value={event_start_date_minuter}
                                                    placeholder="time placeholder"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_start_date_minuter').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_start_date_minuter').classList.remove('input-error')
                                                        }
                                                        setEventStartDateMinuter(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`Event ends*`)}
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="event_end_date"
                                                    id="event_end_date"
                                                    value={event_end_date}
                                                    placeholder="Event ends*"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('event_end_date').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_end_date').classList.remove('input-error')
                                                        }
                                                        setEventEndDate(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label className='input-label_monserrat'>
                                                    {props.t(`End time`)}
                                                </Label>
                                                <Input
                                                    type="time"
                                                    name="event_end_date_minuter"
                                                    id="event_end_date_minuter"
                                                    value={event_end_date_minuter}
                                                    placeholder="time placeholder"
                                                    className="input-white-input circle"
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('event_end_date_minuter').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('event_end_date_minuter').classList.remove('input-error')
                                                        }
                                                        setEventEndDateMinuter(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label className='input-label_monserrat'>
                                            {props.t(`Token description`)}
                                        </Label>

                                        <Input
                                            required
                                            style={{ padding: 10 }}
                                            type="textarea"
                                            className="input-white-input circle input-text-area"
                                            placeholder="The description will be included on the item's detail page underneath 
                    its image. Markdown syntax is supported."
                                            value={event_description}
                                            id="event_description"
                                            onChange={event => {
                                                const { value } = event.target
                                                if (!value) {
                                                    document.getElementById('event_description').classList.add('input-error')
                                                } else {
                                                    document.getElementById('event_description').classList.remove('input-error')
                                                }
                                                setEventDescription(event.target.value)
                                                setDescription(event.target.value)
                                            }}
                                        />

                                    </FormGroup>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        type="button"
                                        color="secundary"
                                        id='update_settings_ticket'
                                        className="theme-button-secundary mobile"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _handleSubmit()
                                        }}
                                    >
                                        {props.t(`Update`)}
                                    </Button>
                                </Col>
                            </Row>
                        </section>
                        <section className='container-settings-tab-side'>
                            <div className='container-video-all'>
                                <div className='container-video'>
                                    <p>How to create your assets</p>
                                    <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                                </div>
                                <div className='container-video'>
                                    <p>Cómo lanzar tu colección de NFT sin código</p>
                                    <YoutubeEmbed embedId="YhEdU-ypBkc"></YoutubeEmbed>
                                </div>
                            </div>
                        </section>

                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))