import React from 'react'
import { connect } from "react-redux"

const HeaderAdmin = (props) => {
    return (
        <div className='header-admin'>
            <p>
                New Software Release V4.0 340 Topics
                <span>
                    <a>
                        Read full story
                    </a>
                </span>
            </p>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { Auth } = state
    return { Auth }
}

export default connect(mapStatetoProps)(HeaderAdmin)