import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import {
    Card, CardBody,
    CardFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import { Link, withRouter } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { segmentTrack } from "../../../helpers/Segment";
import { setUserTicket } from '../../../store/actions'
import { connect } from "react-redux"
import { loadState } from "../../../store/localStorage"

const DataTableCard = (props) => {
    const { display, key, item, setActiveTab } = props
    let [status, setStatus] = useState('yellow')
    let [timeCalc, setTimeCalc] = useState()

    let [url, setUrl] = useState('')
    let [type_name, setTypeName] = useState('')
    const todayDate = new Date();
    const [showModalCard, setShowModalCard] = useState(false)
    let [selectedTicket, setSelectedTicket] = useState(undefined)


    useEffect(() => {

        if (loadState()?.Auth?.ticket) {
            selectedTicket = loadState()?.Auth?.ticket;
            setSelectedTicket(loadState()?.Auth?.ticket)
        }

    }, [])


    useEffect(() => {
        if (item) {
            const { id, is_locked, is_deployed, is_deploying, is_generative, modified_at, type_id
            } = item;
            if (!is_locked && !csv && ((type_id + '' !== '3') && (type_id + '' !== '4'))) {
                status = 'draft';
                setStatus('draft')
            } else {
                if (!is_deployed && !is_deploying) {
                    status = 'red';
                    setStatus('red')
                } else if (!is_deployed && is_deploying) {
                    status = 'yellow';
                    setStatus('yellow')
                } if ((is_deployed || csv) && !is_deploying) {
                    status = 'green';
                    setStatus('green')
                }
            }

            if (type_id === 3 || type_id === '3') {
                setUrl(`/collections/oneandone/view/${id}`)
                setTypeName('1/1')
            } else if (!!is_locked) {
                setUrl(`/collections/view/${id}`)
                setTypeName('1/1')
            } else if (is_generative) {
                setUrl(`/collections/generative/settings/${id}`)
                setTypeName('1/1')
            } else {
                setUrl(`/collections/settings/${id}`)
                setTypeName('1/1')
            }

            const n_date = new Date(modified_at)
            const minus_date = calcInputs(n_date, todayDate)
            setTimeCalc(minus_date)

        }
    }, [item])


    //use best practices by labeling your constants.
    let MS_PER_SEC = 1000
        , SEC_PER_HR = 60 * 60
        , HR_PER_DAY = 24
        , MS_PER_DAY = MS_PER_SEC * SEC_PER_HR * HR_PER_DAY
        ;

    //let's assume we get Date objects as arguments, otherwise return 0.
    function dateDiffInDays(date1Time, date2Time) {
        if (!date1Time || !date2Time) return 0;
        return Math.round((date2Time - date1Time) / MS_PER_DAY);
    }

    function getUTCTime(dateStr) {
        const date = new Date(dateStr);
        // If use 'Date.getTime()' it doesn't compute the right amount of days
        // if there is a 'day saving time' change between dates
        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    }


    function calcInputs(date1, date2) {
        if (date1 && date2) {
            //remove non-date characters

            let date1Time = getUTCTime(date1)
                , date2Time = getUTCTime(date2)
                , result = dateDiffInDays(date1Time, date2Time)
                ;
            return result;
        } else {
            return 'no date'
        }
    }
    const manageRoute = () => {
        console.log('select item');
        props.setUserTicket({
            ...selectedTicket,
            collection_id: item.id,
            total_tokens: item.total_tokens
        })
        setActiveTab('3')
    }

    return (
        <React.Fragment>
            {
                display === 'table' && (
                    <Card className='card-borderless' key={key}>
                        <Link to={'#'} onClick={manageRoute}>
                            <CardBody>
                                <div className='card-picture'>
                                    <img style={{ width: '100%' }} alt={item.name} src={item.image_id || '/assets/images/avatar-2.png'} />
                                    <div className='card-picture-float'>
                                        <div className={cn('rowcard-option-type', item.is_generative ? 'generative' : 'fixed')}>
                                            {
                                                (item.type_id + '') === '3' &&
                                                <span>1 / 1</span>
                                            }
                                            {
                                                (item.type_id + '') === '4' &&
                                                <span>Quick Mint</span>
                                            }
                                            {
                                                ((item.type_id + '') !== '3' && (item.type_id + '') !== '4') && item.is_generative &&
                                                <span>Generative</span>
                                            }
                                            {
                                                ((item.type_id + '') !== '3' && (item.type_id + '') !== '4') && !item.is_generative &&
                                                <span>Fixed art</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Link>

                        <CardFooter className="bg-transparent border-top">
                            <div className="d-flex">
                                <div className="flex-fill card-title rowcard-mtitle">
                                    <Link to={'#'} onClick={manageRoute}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className='subrow'>
                                    <div className='rowcard-body-sub' >
                                        {
                                            status === 'draft' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot yellow'>
                                                </div>
                                                Draft
                                            </div>
                                        }
                                        {
                                            status === 'yellow' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot yellow'>
                                                </div>
                                                Processing
                                            </div>
                                        }
                                        {
                                            status === 'red' &&
                                            <div className='rowcard-body-sub-banner'>

                                                <div className='rowcard-body-sub-banner-dot red'>
                                                </div>
                                                Error
                                            </div>
                                        }
                                        {
                                            status === 'green' &&
                                            <div className='rowcard-body-sub-banner'>
                                                <div className='rowcard-body-sub-banner-dot green'>
                                                </div>
                                                Processed
                                            </div>
                                        }
                                        <span title={timeCalc + ' days ago'}>
                                            {timeCalc} days ago
                                        </span>
                                    </div>
                                    <div className='rowcard-option-network'>
                                        {
                                            (item.type_id !== 6 && (item.network_id + '') === '1') &&
                                            <img src={`/assets/images/Solana.svg`} />
                                        }
                                        {
                                            (item.type_id !== 6 && (item.network_id + '') === '2') &&
                                            <img src={`/assets/images/Matic.svg`} />
                                        }
                                        {
                                            (item.type_id === 6) &&
                                            <img src={`/assets/images/bitcoind-sv-1.svg`} />
                                        }
                                    </div>
                                    <div style={{ textAlign: 'right' }}>

                                    </div>
                                </div>

                            </div>
                        </CardFooter>
                    </Card>
                )
            }
            {
                display === 'list' && (
                    <div className='rowcard'>
                        <Link className='rowcard-img' to={'#'} onClick={manageRoute}>
                            <img alt={item.name} src={item.image_id || '/assets/images/avatar-2.png'} />
                        </Link>
                        <div className='rowcard-body' id="second-app-title">
                            <Link title={item.name} to={'#'} onClick={manageRoute}>
                                {item.name}
                            </Link>
                            <Tooltip
                                anchorSelect="second-app-title"
                                anchorId="second-app-title"
                                place="bottom"
                                variant="info"
                                content="{item.name}"
                            />
                            <div className='rowcard-body-sub'>
                                {
                                    status === 'yellow' &&
                                    <div className='rowcard-body-sub-banner'>
                                        <div className='rowcard-body-sub-banner-dot yellow'>
                                        </div>
                                        Processing
                                    </div>
                                }
                                {
                                    status === 'red' &&
                                    <div className='rowcard-body-sub-banner'>

                                        <div className='rowcard-body-sub-banner-dot red'>
                                        </div>
                                        Error
                                    </div>
                                }
                                {
                                    status === 'green' &&
                                    <div className='rowcard-body-sub-banner'>
                                        <div className='rowcard-body-sub-banner-dot green'>
                                        </div>
                                        Processed
                                    </div>
                                }
                                <span>
                                    {timeCalc} days ago
                                </span>
                            </div>
                        </div>
                        <div className='rowcard-option'>
                            <div className={cn('rowcard-option-type', item.is_generative ? 'generative' : 'fixed')}>
                                {
                                    item.is_generative &&
                                    <span>Generative</span>
                                }
                                {
                                    !item.is_generative &&
                                    <span>Fixed art</span>
                                }
                            </div>
                            <div className='rowcard-option-network'>
                                {
                                    (item.network_id === 1 || item.network_id === '1') &&
                                    <img src={`/assets/images/Solana.svg`} />
                                }
                                {
                                    (item.network_id === 2 || item.network_id === '2') &&
                                    <img src={`/assets/images/Matic.svg`} />
                                }
                            </div>
                        </div>
                        <div className='rowcard-action'>

                        </div>
                    </div>

                )
            }
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ticket: state.Auth?.ticket

    }
}

export default (connect(mapStatetoProps, {
    setUserTicket
}))(withRouter(withNamespaces()(DataTableCard)))
