import React, { useEffect } from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';

const TabHeaderMenu = (props) => {
    const { step_number, active, collection_id } = props;
    useEffect(() => {
        
    }, [collection_id])
    useEffect(() => {
        
    }, [step_number])
    return (
        <section className='collections-settings-header-container'>
            <div className={cn('collections-settings-header-item', active === 1 ? 'active' : '')}>
                <span>1. Upload</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 2 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 1) {
                        props.history.push(`/collections/settings/${collection_id}`)
                    }
                }}>
                <span>2. Settings</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 3 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 2) {
                        props.history.push(`/collections/token/${collection_id}`)
                    }
                }}>
                <span>3. Preview</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 4 ? 'active' : '')} onClick={(e) => {
                e.preventDefault()
                if (step_number > 2) {
                    props.history.push(`/collections/minting/${collection_id}`)
                }
            }}>
                <span>4. Deployment</span>
            </div>
        </section >
    );
};

export default withRouter(withNamespaces()(TabHeaderMenu))