import React, { useContext, useEffect, useState } from 'react';
import {
    Button
} from "reactstrap";

import { GeneralContext } from '../../../../context';

import { faArrowRight, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ethers } from 'ethers';
import { withNamespaces } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Modal from "../../../../components/Global/modals";
import DataService from '../../../../helpers/DataService';

const { ethereum } = window;
const network = 'https://polygon.rpc.thirdweb.com' // use mumbai testnet
const ethers_provider = ethers.getDefaultProvider(network)

const Funds = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [showModal, setShowModal] = useState(false)
    const [showModalConnected, setShowModalConnected] = useState(false)
    const [showModalDone, setShowModalDone] = useState(false)

    const { collection_id, collection } = props
    const [payouts_table, setPayoutTable] = useState([])
    const [payout, setPayout] = useState({ total_revenue: 0, available_funds: 0 })

    const [solanaWallet, setSolanaWallet] = useState('')
    const [etherWallet, setEtherWallet] = useState('')

    const [network_id, setNetworkId] = useState('')
    let [network_config, setNetworkConfig] = useState('')
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);

    useEffect(() => {
        if (network_id) {
            console.log('network_id', network_id);
            connectWallet()
        }
    }, [network_id])
    useEffect(() => {
        if (collection) {
            console.log('setNetworkId', collection.network_id);
            setNetworkId(collection.network_id)
            console.log();
            network_config = collection.network_config;
            setNetworkConfig(collection.network_config)
            console.log('network_config', network_config);
        }
    }, [collection])

    /* Mumbai */
    const [currentAccount, setCurrentAccount] = useState(null);
    const [balance, setBalance] = useState("Loading...")



    function getNetworkConfig() {
        return DataService.getNetworkConfig().then(response => {
            console.log('getNetworkConfig', response.data);
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
        })
    }


    useEffect(() => {
        if (currentAccount) {
            console.log('currentAccount', currentAccount);
            if (showModal) {
                setShowModalConnected(true);
            }
            setShowModal(false);

        }
    }, [currentAccount])


    const checkWalletIsConnected = async () => {
        if (!ethereum) {
            console.log("Make sure you have Metamask installed!");
            return;
        } else {
            console.log("Wallet exists! We're ready to go!")
        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            setEtherWallet(account)
            console.log("Found an authorized account: ", account);
            setCurrentAccount(account);
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                setBalance(`${balanceInEth} ETH`)
            })

        } else {
            console.log("No authorized account found");
        }
    }

    const connectWalletButton = () => {
        return (
            <Button
                type="button"
                color="primary"
                className="theme-button-secundary circle cw"
                onClick={(e) => {
                    e.preventDefault()
                    connectWalletHandler()
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        )
    }


    /* Solana */
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);

    useEffect(() => {
        const provider = getProvider();

        if (provider) setProvider(provider);
        else setProvider(undefined);

        checkWalletIsConnected()
    }, []);


    useEffect(() => {
        if (walletKey) {
            console.log('walletKey', walletKey);
            if (showModal) {
                setShowModalConnected(true);
            }
            setShowModal(false);

        }
    }, [walletKey])


    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
    };



    /**
 * @description prompts user to connect wallet if it exists
 */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;

        if (solana) {
            try {
                const response = await solana.connect();

                setWalletKey(response.publicKey.toString());
                setSolanaWallet(response.publicKey.toString())
                console.log('setSolanaWallet', response.publicKey.toString());
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };


    const onConfirmRequest = () => {
        if ((network_id + '') === '1') {
            const body = {
                total: payout.available_funds,
                collection_id: collection_id,
                wallet: walletKey
            }
            DataService.postPayout(body).then(response => {
                console.log('response', response);
                setShowModalConnected(false)
                setShowModalDone(true)
            }).catch(err => {
                console.log('err', err);
            })

        } else if (((network_id + '') !== '1')) {
            const body = {
                total: payout.available_funds,
                collection_id: collection_id,
                wallet: currentAccount
            }
            DataService.postPayout(body).then(response => {
                console.log('response', response);
                setShowModalConnected(false)
                setShowModalDone(true)
            }).catch(err => {
                console.log('err', err);
            })

        }
    }
    useEffect(() => {
        if (collection_id) {
            callEndpoints();
        }
    }, [collection_id])

    const callEndpoints = () => {
        setLoading(true);
        DataService.getCollectionBalance(collection_id).then(response => {
            console.log('getCollectionBalance', response.data);
            setPayout(response.data)
        })
        DataService.getPayout({ id: collection_id }).then(response => {
            console.log('getPayout', response.data.data);
            setPayoutTable(response.data.data)
        })
        setLoading(false);
    }

    useEffect(() => {
        if (!!payouts_table && !!payout) {
            console.log('it works');
        }
    }, [payouts_table, payout])

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '782px'
        }
    }


    const modalBody = <section>
        <div className='collections-funds-modal'>
            <div className='collections-funds-card-top'>
                <span className='collections-funds-card-principal'>
                    Withdrawal Confirmed
                </span>
            </div>
            <div className='collections-funds-card-ico'>
                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
            </div>
            <div className='collections-funds-card-bot'>
                <span className='collections-funds-card-second'>
                    Your funds are on their way, please consider it’ll take about 24 hours to reach your wallet.
                </span>
            </div>
        </div>
    </section >


    const modalBody2 = <section>

        <div className='collections-funds-modal'>
            <div className='collections-funds-card-top'>
                <span className='collections-funds-card-principal'>
                    Confirm Request
                </span>
                {
                    network_config && (
                        <span className='collections-funds-card-second' style={{ marginTop: '28px' }}>
                            You are about to request the Withdrawal
                            of {payout.available_funds} {network_config.native_token} to the wallet:
                            {
                                !!network_id && ((network_id + '') === '1') && (
                                    solanaWallet
                                )
                            }
                            {
                                !!network_id && ((network_id + '') !== '1') && (
                                    etherWallet
                                )
                            }

                        </span>

                    )
                }
            </div>
            <div className='collections-funds-card-bot'>
                <span className='collections-funds-card-second'>
                    ****You will be prompted to connect your wallet, and then sign the transaction**** <br />
                    It usually takes about 24 hours for the funds to reach your wallet.
                </span>
                <Button
                    type="button"
                    color="primary"
                    className="theme-button-secundary circle"
                    onClick={(e) => {
                        e.preventDefault()
                        onConfirmRequest();
                    }}
                    style={{ marginTop: '28px', width: '36rem', height: '32px' }}
                >
                    {props.t(`Confirm request`)}
                </Button>

            </div>
        </div>
    </section>
    const modalBody3 = <section>
        <div className='collections-funds-modal'>
            <div className='collections-funds-card-top'>
                <span className='collections-funds-card-principal'>
                    Requesting Funds
                </span>
                <span className='collections-funds-card-second' style={{ marginTop: '28px' }}>
                    You are about to request the withdrawal of {payout.available_funds} {network_config.native_token}
                </span>
            </div>
            <div className='collections-funds-card-bot'>
                <span className='collections-funds-card-second'>
                    You will be prompted to connect your wallet
                </span>


                {
                    network_id && ((network_id + '') === '1') && (
                        <div>
                            {provider && !walletKey && (
                                <Button
                                    type="button"
                                    color="primary"
                                    className="theme-button-secundary circle cw"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        connectWallet()
                                    }}
                                >
                                    {props.t(`Connect wallet`)}
                                </Button>
                            )}
                            {!provider && (
                                <p>
                                    No provider found. Install{" "}
                                    <a href="https://phantom.app/">Phantom Browser extension</a>
                                </p>
                            )}
                        </div>
                    )
                }
                {
                    network_id && ((network_id + '') !== '1') && (
                        <div>
                            {connectWalletButton()}
                        </div>
                    )
                }

            </div>
        </div>
    </section>
    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            console.log("Found an account! Address: ", accounts[0]);
            setCurrentAccount(accounts[0]);
        } catch (err) {
            console.log(err)
        }
    }

    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody3}
            footer={<></>}
            styles={modalStyles}
        />
    )
    const __renderPOViewConnected = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModalConnected}
            onRequestClose={() => {
                setShowModalConnected(false)
            }}
            body={modalBody2}
            footer={<></>}
            styles={modalStyles}
        />
    )
    const __renderPOViewDone = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModalDone}
            onRequestClose={() => {
                setShowModalDone(false)
                callEndpoints();
            }}
            body={modalBody}
            footer={<></>}
            styles={modalStyles}
        />
    )

    /*
        blockchain 1: 
        wallet: https://solscan.io/account/<wallet>
        Transaccion: https://solscan.io/tx/<network_transaction>
        blockchain 2: 
        wallet: https://polygonscan.com/address/<wallet>
        Transaccion: https://polygonscan.com/tx/<network_transaction>
 */

    const wallet1 = 'https://solscan.io/account/'
    const transaccion1 = ' https://solscan.io/tx/'
    const wallet2 = 'https://polygonscan.com/address/'
    const transaccion2 = 'https://polygonscan.com/tx/'

    const toRouter = (source) => {
        if (!source) return;
        if (network_id === 1 || network_id === '1') {
            window.open(`${wallet1}${source}`, '_blank')
        } else {
            window.open(`${wallet2}${source}`, '_blank')
        }
    }
    const txnRouter = (source) => {
        if (!source) return;
        if (network_id === 1 || network_id === '1') {
            window.open(`${transaccion1}${source}`, '_blank')
        } else {
            window.open(`${transaccion2}${source}`, '_blank')
        }
    }
    return (
        <React.Fragment>
            {
                showModal && __renderPOView()
            }
            {
                showModalConnected && __renderPOViewConnected()
            }
            {
                showModalDone && __renderPOViewDone()
            }
            <section className='collections-funds' style={{ width: '100%' }}>
                <div className='collections-funds-top'>
                    <div className='collections-funds-card'>
                        <div className='collections-funds-card-top'>
                            <span className='collections-funds-card-title'>
                                ${payout.total_revenue_usdc} usd
                            </span>
                            <span className='collections-funds-card-cripto'>
                                {/* <FontAwesomeIcon icon={faHome}></FontAwesomeIcon> */}
                                {
                                    ((network_id + '') === '2') &&
                                    <img src={`/assets/images/Matic.svg`} />
                                }
                                {
                                    ((network_id + '') === '1') &&
                                    <img src={`/assets/images/Solana.svg`} />
                                }
                                {
                                    ((network_id + '') === '3') &&
                                    <img src={`/assets/images/Ether.svg`} />
                                }
                                {
                                    ((network_id + '') === '4') &&
                                    <img src={`/assets/images/BNB.svg`} />
                                }
                                {payout.total_revenue}
                            </span>
                        </div>
                        <div className='collections-funds-card-bot'>
                            <span className='collections-funds-card-bold'>
                                Total Revenue
                            </span>
                            <span className='collections-funds-card-subtitle'>
                                Collection's total revenue to date
                            </span>
                        </div>

                    </div>
                    <div className='collections-funds-card'>
                        <div className='collections-funds-card-top'>
                            <span className='collections-funds-card-title'>
                                ${payout.available_funds_usdc} usd
                            </span>
                            <span className='collections-funds-card-cripto'>
                                {/* <FontAwesomeIcon icon={faHome}></FontAwesomeIcon> */}
                                {
                                    ((network_id + '') === '1') &&
                                    <img src={`/assets/images/Solana.svg`} />
                                }
                                {
                                    ((network_id + '') !== '1') &&
                                    <img src={`/assets/images/Matic.svg`} />
                                }
                                {payout.available_funds}
                            </span>
                        </div>
                        <div className='collections-funds-card-bot'>
                            <span className='collections-funds-card-bold'>
                                Available Funds
                            </span>
                            <span className='collections-funds-card-subtitle'>
                                Total funds you can request for withdrawal
                            </span>
                        </div>
                    </div>
                    <div className='collections-funds-card'>
                        {
                            ((network_id + '') === '1') && (
                                <Button
                                    type="button"
                                    color="primary"
                                    className="theme-button-request"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!walletKey) {
                                            setShowModal(true)
                                        } else {
                                            setShowModalConnected(true)
                                        }
                                    }}
                                >
                                    {props.t(`Request funds`)}
                                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                </Button>
                            )
                        }
                        {
                            ((network_id + '') !== '1') && (
                                <Button
                                    type="button"
                                    color="primary"
                                    className="theme-button-request"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!currentAccount) {
                                            setShowModal(true)
                                        } else {
                                            setShowModalConnected(true)
                                        }
                                    }}
                                >
                                    {props.t(`Request funds`)}
                                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                </Button>
                            )}
                    </div>
                    {/* <div className='collections-funds-card'>
                        <div className='collections-funds-card-top'>
                            <span className='collections-funds-card-principal'>
                                Requesting Withdrawal
                            </span>
                            <span className='collections-funds-card-second'>
                                You are about to request the withdrawal of 3.73 ETH
                                to the wallet: 0x000111222333
                            </span>
                        </div>
                        <div className='collections-funds-card-bot'>
                            <span className='collections-funds-card-second'>
                                You will be prompted to connect your wallet, and then sign the transaction to request the withdrawal.
                                It usually takes about 24 hours for the funds to reach your wallet.
                            </span>
                            <Button
                                type="button"
                                color="primary"
                                className="theme-button-secundary circle"
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                {props.t(`Connect Wallet`)}
                            </Button>

                        </div>

                    </div>
                    <div className='collections-funds-card'>
                        <div className='collections-funds-card-top'>
                            <span className='collections-funds-card-principal'>
                                Withdrawal Confirmed
                            </span>
                        </div>
                        <div className='collections-funds-card-ico'>
                            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                        </div>
                        <div className='collections-funds-card-bot'>
                            <span className='collections-funds-card-second'>
                                Your funds are on their way, please consider it’ll take about 24 hours to reach your wallet.
                            </span>
                        </div>
                    </div> */}

                </div>
                <div className='collections-funds-payout'>
                    <div className='collections-funds-payout-top'>
                        <span className='collections-funds-payout-top-title'>Payouts</span>
                        <Button
                            type="button"
                            color="primary"
                            className="theme-button-view"
                            onClick={(e) => {
                                e.preventDefault()
                            }}
                        >
                            {props.t(`Download excel`)}
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                        </Button>
                    </div>
                    <div className='collections-funds-payout-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Status <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> </th>
                                    <th>Amount</th>
                                    <th>To</th>
                                    <th>Txn Hash</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payouts_table.length > 0 && (
                                    payouts_table.map((payout, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{payout.created_at}</td>
                                                <td className='status'>
                                                    {
                                                        payout.is_paid &&
                                                        <span>
                                                            Paid
                                                        </span>
                                                    }
                                                    {
                                                        payout.is_authorized && !payout.is_paid &&
                                                        <span>
                                                            Authorized
                                                        </span>
                                                    }
                                                    {
                                                        !payout.is_authorized && !payout.is_paid &&
                                                        <span>
                                                            Pending
                                                        </span>
                                                    }
                                                </td>
                                                <td>{payout.total}</td>
                                                <td className='to' onClick={() => toRouter(payout.wallet)}>{payout.wallet}</td>
                                                <td className='txn_hash' onClick={() => txnRouter(payout.network_transaction)}>{payout.network_transaction}</td>
                                            </tr>

                                        )
                                    })
                                )}
                                {(!payouts_table || payouts_table.length === 0) && (
                                    <tr>
                                        <td>No date</td>
                                        <td>No status</td>
                                        <td>No amount</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Funds))