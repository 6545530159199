import React, { useContext } from 'react'
import { GeneralContext } from '../context/'
import { Route, Redirect } from "react-router-dom"
import Loading from '../components/Loading'
import { loadState } from '../store/localStorage'
import Lottie from 'react-lottie';
import * as Loader01 from '../assets/lottie/Loader01.json'
import * as Loader02 from '../assets/lottie/Loader02.json'
import * as Loader03 from '../assets/lottie/Loader03.json'
import * as Loader04 from '../assets/lottie/Loader04.json'
import * as Loader05 from '../assets/lottie/Loader05.json'


const AppRoute = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
}) => {
    const generalContext = useContext(GeneralContext)
    const {
        loading,
        loading1,
        loading2,
        loading3,
        loading4,
        loading5,
        loading_files,
        total_files,
        current_files,
        total_size,
        current_size,
    } = generalContext

    const Loader01Options = {
        loop: true,
        autoplay: true,
        animationData: Loader01,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader02Options = {
        loop: true,
        autoplay: true,
        animationData: Loader02,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader03Options = {
        loop: true,
        autoplay: true,
        animationData: Loader03,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader04Options = {
        loop: true,
        autoplay: true,
        animationData: Loader04,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const Loader05Options = {
        loop: true,
        autoplay: true,
        animationData: Loader05,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }


    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthProtected && !loadState()?.Auth?.token) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }

                return (
                    <Layout>
                        <Component {...props} />
                        {/* {loading && <Loading />} */}

                        {loading1 &&
                            <div className="full-page-loader">
                                <Lottie options={Loader01Options}
                                    height={400}
                                    width={400}
                                />
                            </div>
                        }
                        {loading2 &&
                            <div className="full-page-loader">
                                <Lottie options={Loader02Options}
                                    height={400}
                                    width={400}
                                />
                            </div>
                        }
                        {loading3 &&
                            <div className="full-page-loader">
                                <Lottie options={Loader03Options}
                                    height={400}
                                    width={400}
                                />
                            </div>
                        }
                        {loading &&
                            <div className="full-page-loader">
                                <Lottie options={Loader04Options}
                                    height={200}
                                    width={200}
                                />
                            </div>
                        }
                        {loading5 &&
                            <div className="full-page-loader">
                                <Lottie options={Loader05Options}
                                    height={400}
                                    width={400}
                                />
                            </div>
                        }
                        {
                            loading_files &&
                            <div className="full-page-loader-file">
                                <Lottie options={Loader04Options}
                                    height={200}
                                    width={200}
                                />
                                <span> Uploaded archives: {current_files} of {total_files}</span>
                                <span> Uploaded data: {formatBytes(current_size)} of {formatBytes(total_size)}</span><br></br>
                            </div>
                        }





                    </Layout>
                )
            }}
        />
    )
}

export default AppRoute
