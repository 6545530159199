import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Button,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import DashboardTab from './view-tabs/dashboard'
import BouncerTab from './view-tabs/bouncers'
import SettingsTab from './view-tabs/settings-tab'

import classnames from 'classnames';
import { useParams } from 'react-router-dom'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import ProgressHeader from '../../components/Global/Progress-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const View = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, currentProject, setProject } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);
    let [project_id, setProjectId] = useState([]);

    const { collection_id } = useParams();
    let [collection, setCollection] = useState();
    useEffect(() => {

        if (!currentProject) return;
        setProjectId(currentProject.id)
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Review an event`)
        })
    }, [currentProject])

    useEffect(() => {
        console.log('collection_id', collection_id);
        getCollection()
    }, [collection_id])

    const getCollection = () => {
        if (collection_id) {
            DataService.getCollectionById(collection_id).then(response => {
                console.log('response.data', response.data);
                setCollection(response.data)

            })
        }
    }
    const setCollectionReload = () => {
        getCollection()
    }

    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }

    return (
        <React.Fragment>

            {/* <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={100} percentage={100}></ProgressHeader> */}

            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <section className='collections-settings-header-container'>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}  >
                                <span>Dashboard</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '2' })} onClick={() => activeTab !== '2' && setActiveTab('2')} >
                                <span>Ticket settings</span>
                            </div>
                            <div className={classnames('collections-settings-header-item ', { active: activeTab === '3' })} onClick={() => activeTab !== '3' && setActiveTab('3')}>
                                <span>Bouncers</span>
                            </div>
                        </section>
                        <div className='collections-settings-header-button'>
                            {
                                activeTab === '2' && (
                                    <Button
                                        type="button"
                                        color="secundary"
                                        className="theme-button-secundary desktop"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const btn = document.getElementById('update_settings_ticket')
                                            if (btn) {
                                                btn.click()
                                            }
                                        }}
                                    >
                                        {props.t(`Update`)}
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-header'>

                        </div>
                        <div className='collections-settings-body'>
                            {
                                (activeTab === '1' && !!collection) &&
                                <DashboardTab project_id={project_id} collection_id={collection_id} collection={collection} setActiveTab={setActiveTab} />
                            }
                            {
                                (activeTab === '2' && !!collection) &&
                                <SettingsTab project_id={project_id} collection_id={collection_id} collection={collection} setActiveTab={setActiveTab} />
                            }
                            {
                                (activeTab === '3' && !!collection) &&
                                <BouncerTab project_id={project_id} collection_id={collection_id} collection={collection} setActiveTab={setActiveTab} />
                            }


                        </div>
                    </div>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(View))
