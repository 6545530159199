import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Input,
    ButtonGroup
} from "reactstrap"
import { GeneralContext } from '../../../../context'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataService from '../../../../helpers/DataService'
import Table from '../airdrops-table'
import Modal from "../../../../components/Global/modals"
import cn from 'classnames';
import Dropzone from "./dropzone"
import { toastrError, toastrSuccess } from '../../../../helpers/Toastr'

const Airdrops = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [showModalCard, setShowModalCard] = useState()
    const [token_quantity, setTokenQuantity] = useState()
    const [wallet_address, setWalletAddress] = useState()
    const [display, setDisplay] = useState('form') // drop
    const { collection_id } = props;
    const [assets_drop, setAssetsDrop] = useState([])
    const [reload, setReload] = useState(1)



    let handleAssetFilled = (assets) => {
        console.log('handleAssetFilled', assets);
        if (assets.length > 0) {
            toastrSuccess('Files uploaded')
            setAssetsDrop(assets)
        }
    }
    const _newAirdrop = () => {
        console.log('_newAirdrop');
        setShowModalCard(true);
    }


    const _validateForm = () => {
        if (display === 'form') {
            if (!token_quantity) {
                toastrError('First you need to add a quantity')
                console.log('First you need to add a quantity')
                return false;
            }
            if (!wallet_address) {
                toastrError('First you need to add the wallet address')
                console.log('First you need to add the wallet address')
                return false;
            }
        } else {
            if (assets_drop.length === 0) {
                toastrError('First you need to add a csv file')
                console.log('First you need to add a csv file')
                return false;
            }
        }
        return true
    }

    const _cleanForm = () => {
        setDisplay('form')
        setAssetsDrop([])
        setWalletAddress('')
        setTokenQuantity('')
    }
    const _reloadList = () => {
        setReload(reload + 1);
    }
    const saveAirdrop = () => {
        console.log('saveAirdrop');
        if (!_validateForm()) return;
        let body = {
            collection_id: collection_id
        };
        if (display === 'form') {
            body['address_list'] = [
                {
                    address: wallet_address,
                    quantity: token_quantity
                }
            ]
        } else {
            body['address_list'] = assets_drop
        }
        console.log('saveAirdrop', body);

        DataService.postAirdrop(body).then(response => {
            if (response) {
                _cleanForm()
                _reloadList()
                setShowModalCard(false)
            }
        })

    }
    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
            minWidth: '900px',
        }
    }
    const __renderCardDetailView = (card) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={!!showModalCard}
            onRequestClose={() => {
                setShowModalCard(false)
            }}
            body={bodyNewCardDetail}
            footer={footerModal}
            styles={modalCardStyles}
        />
    )

    const bodyNewCardDetail = <div className='modalcard'>
        <div className='modalcard-legendary'>
            <div className='modalcard-legendary-title'>
                Airdrop
            </div>
            {/* <div className='modalcard-legendary-subtitle'>
            </div> */}
            <div className='modalcard-legendary-description'>
                Specify wich wallet to receive the airdrop & the total number of tokens to mint to that wallet
            </div>
            <div className='modalcard-legendary-switch'>
                <ButtonGroup className='input-white-buttongroup'>
                    <Button className={cn(display === 'form' ? 'selected' : '')} onClick={() => {
                        setDisplay('form')
                    }}>
                        {/* <FontAwesomeIcon icon={faBars}></FontAwesomeIcon> */}
                        <span>Single wallet</span>
                    </Button>
                    <Button className={cn(display === 'drop' ? 'selected' : '')}
                        onClick={() => {
                            setDisplay('drop')
                        }}>
                        {/* <FontAwesomeIcon icon={faTable}></FontAwesomeIcon> */}
                        <span>Multiple wallets</span>
                    </Button>
                </ButtonGroup>
            </div>
            <div className='modalcard-legendary-contain'>
                {
                    display === 'form' && (
                        <div className='modalcard-legendary-contain-body'>
                            <div className='modalcard-legendary-traits'>
                                <div className='modalcard-legendary-traits-title'>
                                    Token quantity
                                </div>
                                <div className='modalcard-legendary-traits-contain'>
                                    <div className='modalcard-legendary-traits-input'>
                                        <Input
                                            required
                                            value={token_quantity}
                                            type="text"
                                            className="input-white-input circle"
                                            placeholder='quantity'
                                            id='token_quantity'
                                            onChange={event => {
                                                const { value } = event.target
                                                const doc = document.getElementById('token_quantity')
                                                if (!value) {
                                                    if (doc) {
                                                        doc.classList.add('input-error')
                                                    }
                                                } else {
                                                    if (doc) {
                                                        doc.classList.remove('input-error')
                                                    }
                                                }
                                                setTokenQuantity(event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='modalcard-legendary-traits'>
                                <div className='modalcard-legendary-traits-title'>
                                    Wallet Address
                                </div>
                                <div className='modalcard-legendary-traits-contain'>
                                    <div className='modalcard-legendary-traits-input'>
                                        <Input
                                            required
                                            value={wallet_address}
                                            type="text"
                                            className="input-white-input circle"
                                            placeholder='Wallet Address'
                                            id='wallet_address'
                                            onChange={event => {
                                                const { value } = event.target
                                                const doc = document.getElementById('wallet_address')
                                                if (!value) {
                                                    if (doc) {
                                                        doc.classList.add('input-error')
                                                    }
                                                } else {
                                                    if (doc) {
                                                        doc.classList.remove('input-error')
                                                    }
                                                }
                                                setWalletAddress(event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    )
                }
                {
                    display === 'drop' && (
                        <div className='modalcard-legendary-contain-body'>
                            <Dropzone accept={'*.csv'} onAssetFull={handleAssetFilled} />
                        </div>
                    )
                }

            </div>

        </div>

    </div>

    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-white-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowModalCard(false)
            }}
        >
            {props.t(`Cancel`)}
        </Button>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                saveAirdrop()
            }}
        >
            {props.t(`Airdrop`)}
        </Button>

    </div>


    return (
        <React.Fragment>
            {
                showModalCard && __renderCardDetailView(showModalCard)
            }
            <section className='collections-minting' style={{ width: '100%' }}>
                <div className='collections-minting-top'>
                    <section className='container-deploy' style={{ marginBottom: '12px', marginTop: '12px' }}>
                        <div className='collections-tab-container'>
                            <div className='collections-tab-title-container'>
                                <div className='collections-tab-title-container-menu'>
                                    <div className='collections-tab-title'>
                                        Airdrops
                                    </div>
                                    <div className='collections-tab-subtitle'>
                                        Table of available airdrops
                                    </div>
                                </div>
                                <div className='collections-tab-title-button'>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="theme-button-view"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _newAirdrop()

                                        }}
                                    >
                                        {props.t(`New airdrop`)}
                                    </Button>
                                </div>

                            </div>
                            <Table collection_id={collection_id} reload={reload} setReload={setReload} />
                        </div>
                    </section>
                </div>
            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Airdrops))