import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Form,
    Col,
    Input,
    FormGroup,
    Label,
    CardTitle,

} from "reactstrap"

import { GeneralContext } from '../../../../context'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"





const Metadata = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [tokenDescription, setTokenDescription] = useState('')
    const [tokenExternal, setTokenExternal] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [collectionFamily, setCollectionFamily] = useState('')
    const [tokens, setTokens] = useState('')
    const [collectionSymbol, setCollectionSymbol] = useState('')
    const [blockchainNetwork, setBlockchainNetwork] = useState('')
    const { project_id } = props;



    useEffect(() => {
        (async () => {
            // await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <React.Fragment>
            <Form id="settings-tab-form">
                <div className='text-align-left'>
                    <CardTitle>{props.t(`General settings`)}</CardTitle>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Collection name`)}*
                                </Label>

                                <Input
                                    required
                                    value={collectionName}
                                    type="text"
                                    className="form-control"
                                    placeholder='Collection name'
                                    onChange={event => setCollectionName(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Collection Family`)}*
                                </Label>

                                <Input
                                    required
                                    value={collectionFamily}
                                    type="text"
                                    className="form-control"
                                    placeholder='Stark'
                                    onChange={event => setCollectionFamily(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`How many tokens`)}
                                </Label>

                                <Input
                                    required
                                    value={tokens}
                                    type="text"
                                    className="form-control"
                                    placeholder='Winterfell'
                                    onChange={event => setTokens(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Collection symbol`)}
                                </Label>

                                <Input
                                    required
                                    value={collectionSymbol}
                                    type="text"
                                    className="form-control"
                                    placeholder='123123123'
                                    onChange={event => setCollectionSymbol(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Blockchain network`)}
                                </Label>

                                <Input
                                    required
                                    value={blockchainNetwork}
                                    type="text"
                                    className="form-control"
                                    placeholder='123123123'
                                    onChange={event => setBlockchainNetwork(event.target.value)}
                                />
                            </FormGroup>
                        </Col>



                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Token external`)}*
                                </Label>

                                <Input
                                    required
                                    value={tokenExternal}
                                    type="text"
                                    className="form-control"
                                    placeholder='Collection name'
                                    onChange={event => setTokenExternal(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Token description`)}*
                                </Label>

                                <Input
                                    required
                                    style={{ padding: 10 }}
                                    type="text"
                                    className="form-control"
                                    placeholder='Stark'
                                    value={tokenDescription}
                                    onChange={event => setTokenDescription(event.target.value)} />

                            </FormGroup>
                        </Col>
                    </Row>
                </div>

            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Metadata))