import { faCheckCircle, faChevronDown, faCloudArrowUp, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLiveQuery } from "dexie-react-hooks";
import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from "react-i18next";
import { withRouter } from 'react-router-dom';
import {
    Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, FormGroup,
    Input, Label, Row
} from "reactstrap";
import image_box from '../../assets/images/ImageBox.png';
import YoutubeEmbed from '../../components/Global/EmbedYoutube';
import ProgressHeader from '../../components/Global/Progress-header';
import { GeneralContext } from '../../context/';
import DataService from '../../helpers/DataService';
import { segmentTrack } from "../../helpers/Segment"
import Dropzone from "./components/dropzone";
import TabHeaderMenu from './components/tab-header-menu';
import { toastClear, toastrError, toastrInfoPermanent } from "../../helpers/Toastr";
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';
import { fileReader } from '../../helpers/constants';


const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const [data, setData] = useState('fixed')
    const [collectionName, setCollectionName] = useState('')
    const [blockChain, setBlockChain] = useState(1)
    const [image_id, setImageId] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const [showDrop, setShowDropzone] = useState(true)
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);
    let [universeFiles, setUniverseFiles] = useState([]);
    let [pairFiles, setPairFiles] = useState([]);
    let [assetLoaded, onAssetLoaded] = useState([]);
    let { loading,
        setLoading,
        loading_files,
        setLoadingFiles,
        total_files,
        setTotalFiles,
        current_files,
        setCurrentFiles,
        total_size,
        setTotalSize,
        current_size,
        setCurrentSize,
        currentProject,
        setBreadcrumb,
    } = generalContext

    useEffect(() => {
        console.log('assetLoaded', assetLoaded);
    }, [assetLoaded])

    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);
    const [assets, setAssets] = useState(false);
    const [collection_saved, setCollectionSaved] = useState('')
    const bgcolor = '#A066FF'
    let [completed, setCompleted] = useState(10);
    let [activeTab, setActiveTab] = useState('1');

    // togle 
    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }
    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }
    // useEffect(() => {
    //     setBreadcrumb({
    //         icon: '',
    //         parent: props.t(`Collections`),
    //         parentLink: `/projects/${project_id}/collections`,
    //         item: props.t(`Create Collection`)
    //     })
    // }, [project_id])
    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Create Collection`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])

    useEffect(() => {
        DataService.getNetworkConfig().then(response => {
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            setSelectedBlockchain(arr[0])
        })
    }, [])


    let handleAssetFilled = (assets) => {

        setAssets(assets);
    }





    const _validateStep = () => {
        if (collectionName === '') {
            toastrError(props.t(`Don't forget to enter the collection name`), 'Error')
            return false
        }
        if (blockChain === '') {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        if (image_id === '') {
            toastrError(props.t(`Don't forget to enter the collection image`), 'Error')
            return false
        }
        if (!assetLoaded || assetLoaded.length === 0) {
            toastrError(props.t(`Don't forget to upload correctly yours assets`), 'Error')
            return false
        }
        return true
    }

    const _handleExport = () => {
        console.log('universeFiles', universeFiles);
        console.log('assetLoaded', assetLoaded);
        const properties = assetLoaded.map(asset => {
            const findFile = universeFiles.find(uf => uf.name.toLowerCase() === asset.file.toLowerCase())
            const findFilePreview = !!asset.preview ? universeFiles.find(uf => uf.name.toLowerCase() === asset.preview.toLowerCase()) : undefined
            return {
                image: findFile ? fileReader + findFile.file : undefined,
                name: asset.name,
                image_preview: findFilePreview ? fileReader + findFilePreview.file : undefined,
                attributes: asset.data.attributes.map(att => {
                    return {
                        trait_type: att.trait_type,
                        value: att.value
                    }
                }),
            }
        })

        console.log('properties', properties);


        DataService.postCollection({
            name: collectionName,
            network_id: blockChain,
            image_id: image_id || undefined,
            project_id: project_id,
            step_number: 1,
        }).then(async response => {
            console.log('properties preate to upload', properties);
            if (response.data && response.data.id) {
                // aqui hacer el put de archivos de todos los traits
                const body = {
                    data: properties
                }
                DataService.postCollectionNftBulkById(response.data.id, body).then(response_bulk => {
                    if (response_bulk) {
                        props.history.push(`/collections/settings/` + response.data.id)
                        setLoading(false)
                        
                    }
                })
            }
        })


        // DataService.postCollection({
        //     name: collectionName,
        //     image_id: image_id || undefined,
        //     project_id: project_id,
        //     network_id: blockChain,
        //     step_number: 1,
        // }).then(async response => {
        //     if (response.data && response.data.id) {

        //         setLoading(false)
        //         props.history.push(`/collections/settings/` + response.data.id)
        //     }
        // })

    }


    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        console.log('assetLoaded', assetLoaded);
        try {
            const totalFiles = universeFiles.length;
            const totalFilesSize = universeFiles.reduce((acc, file) => acc + (!!file.file ? file.file.size : 0), 0);
            setTotalFiles(totalFiles)
            setTotalSize(totalFilesSize)
            setLoadingFiles(true)
            for await (const file of universeFiles) {
                current_size = current_size + file.file.size
                file['file'] = await uploadFileToServer(file.file)
                current_files = current_files + 1;
                setCurrentFiles(current_files)
                console.log('current_files', current_files);
                setCurrentSize(current_size)
                console.log('current_size', current_size);
            }
            resetFileLoader()
            setLoadingFiles(false)
            setLoading(true)
            _handleExport()
        } catch (error) {
            toastrError(`Failed to add ${collectionName}: ${error}`, 'Error');
            console.log('error', error)
            setLoading(false)

        }
    }
    const resetFileLoader = () => {
        setLoadingFiles(false)
        setTotalFiles(0)
        setTotalSize(0)
        setCurrentFiles(0)
        setCurrentSize(0)
    }
    const _handleFileUpload = (event) => {
        let files = event?.target.files
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            setImageId(response.data.id)
            setImageTempo(URL.createObjectURL(files[0]));
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    function uploadFileToServer(file) {
        const promise = new Promise(function (resolve, reject) {
            console.log('file', file);
            const name = file.name;
            const name_split = name.split('.')
            let ext = name_split[name_split.length - 1]
            if (ext === 'jpg') {
                ext = 'jpeg'
            }
            DataService.getUploadkey(ext.toLowerCase()).then(async response => {
                console.log('Uploading to: ', response.data.uploadURL)
                console.log('ext.toLowerCase()', ext.toLowerCase());
                if (ext.toLowerCase() === 'glb') {
                    var reader = new FileReader();
                    console.log('reader');
                    reader.onload = function (evt) {
                        fetch(response.data.uploadURL, {
                            method: 'PUT',
                            body: evt.target.result,
                            headers: {
                                'Content-Type': 'application/octet-stream',
                            }
                        }).then(result => {
                            console.log('Result: ', result)
                            if (result.status === 403) {
                                reject(undefined)
                            }
                            resolve(response.data.Key)
                        }).catch(err => {
                            reject(undefined)
                        })
                    };
                    reader.readAsArrayBuffer(file);


                } else {
                    fetch(response.data.uploadURL, {
                        method: 'PUT',
                        body: file,

                    }).then(result => {
                        console.log('Result: ', result)
                        if (result.status === 403) {
                            reject(undefined)
                        }
                        resolve(response.data.Key)
                    }).catch(err => {
                        reject(undefined)
                    })
                }


            }).catch(err => {
                console.log('err', err);
                reject(undefined)
            })
        })
        return promise
    }


    const reloadDropzone = async () => {
        console.log('reloadDropzone');
        setShowDropzone(false);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        await ndelay(40)
        setShowDropzone(true);

    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={bgcolor} completed={completed} percentage={10}></ProgressHeader>
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={0} active={1}></TabHeaderMenu>
                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                disabled={loading}
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`Go to project general settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>

                    <section className='container-create-all'>
                        <section className='container-create'>
                            <section className='collections-create-form'>
                                <div className='collections-create-form-row'>
                                    <div className='collections-create-form-row-img'>
                                        <p>Collection image</p>
                                        <div className='card-picture' style={img_style} onClick={() => document.getElementById('upload_img')?.click()}>
                                            <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo ? image_tempo : image_box} />
                                            <input type="file"
                                                id="upload_img"
                                                hidden
                                                onChange={event => {
                                                    segmentTrack(`Upload project avatar`)
                                                    _handleFileUpload(event)
                                                }}
                                            />
                                        </div>
                                        <div className='collections-create-form-row-ico' onClick={() => document.getElementById('upload_img')?.click()}>
                                            <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                        </div>
                                    </div>
                                    <div className='collections-create-form-row-content text-align-left'>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Collection name`)}
                                                    </Label>

                                                    <Input
                                                        required
                                                        value={collectionName}
                                                        type="text"
                                                        id='collectionName'
                                                        className="input-white-input circle"
                                                        placeholder='collection'
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (!value) {
                                                                document.getElementById('collectionName').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('collectionName').classList.remove('input-error')
                                                            }
                                                            setCollectionName(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Blockchain network`)}
                                                    </Label>

                                                    <Dropdown
                                                        style={{ width: '100%' }}
                                                        isOpen={menu}
                                                        toggle={toggle}
                                                        className="d-inline-block"
                                                    >
                                                        <DropdownToggle
                                                            className="input-drop-white"
                                                            id="select-block-dropdown"
                                                            tag="button"
                                                        >
                                                            <span className="header-item-project-name">
                                                                {selectedBlockChain && (
                                                                    <span>{selectedBlockChain.name}</span>
                                                                )}
                                                            </span>
                                                            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                        </DropdownToggle>
                                                        <DropdownMenu right className='input-drop-white-item'>
                                                            {filteredBlockchains.map(
                                                                (item, key) => (
                                                                    <div key={key} className="dropdown-item " onClick={() => {
                                                                        setSelectedBlockchain(item)
                                                                        setBlockChain(item.neefter_id)
                                                                        segmentTrack(`Network selected: ${item.name}`)
                                                                        setMenu(false)
                                                                    }}>
                                                                        <span>{item.name}</span>
                                                                    </div>
                                                                ))}
                                                        </DropdownMenu>
                                                    </Dropdown>


                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`Collection avatar`)}
                                                        <span className='input-label-mini'>jpg or png. This will be the thumbnail of your collection.</span>
                                                    </Label>
                                                    <div className='collections-minting-control' >
                                                        <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_img')?.click()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_img')?.click()}>
                                                            <Input
                                                                required
                                                                value={image_tempo}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                disabled
                                                                id="logotype"
                                                            />
                                                        </div>
                                                        <div className='collections-minting-control-icon'>
                                                            {
                                                                image_tempo &&
                                                                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                            }
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                    </div>
                                </div>
                                <div className='collections-create-form-upload'>
                                    <p className='collections-create-form-upload-title'>Upload your assets</p>
                                    <div className='collections-create-form-upload-container'>
                                        <div className='collections-create-form-upload-container-dropzone'>
                                            {
                                                showDrop &&
                                                <Dropzone project_id={project_id} accept={'image/*,.json,.csv,.mp4,.gif,.glb'} onAssetFull={handleAssetFilled} multiple={true} reloadDropzone={reloadDropzone}
                                                    universeFiles={universeFiles} setUniverseFiles={setUniverseFiles}
                                                    pairFiles={pairFiles} setPairFiles={setPairFiles} onAssetLoaded={onAssetLoaded}
                                                />
                                            }
                                        </div>
                                        <div className='collections-create-form-upload-container-list'>
                                            <ul>
                                                <li>
                                                    Files must contain one .csv or .json file with metadata. - <a href='https://neefter.s3.us-west-1.amazonaws.com/downloads/neefter-csv-sample.csv' target="_blank">Download example.csv.</a>  <a href='https://neefter.s3.us-west-1.amazonaws.com/downloads/0.json' target="_blank">Download example.json.</a>
                                                </li>
                                                <li>
                                                    The csv must have a name column, which defines the name of the NFT.
                                                </li>
                                                <li>
                                                    Asset names must be sequential 0,1,2,3...n.[extension]. It doesn't matter at what number you begin. (Example: 131.png, 132.png).
                                                </li>
                                                <li>
                                                    Make sure to drag and drop the CSV/JSON and the images at the same time.
                                                </li>
                                                <li>
                                                    <b>IMPORTANT: </b>
                                                </li>
                                                <li>
                                                    <b>Image assets (.png, .jpeg) have a size limit of 2 mb.</b>
                                                </li>
                                                <li>
                                                    <b>Video/gif assets (.glb,.gif,.mp4) have a size limit of 10 mb.</b>
                                                </li>
                                            </ul>
                                            <Row>
                                                <Col sm={12}>
                                                    <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                        <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                        <span>How to create your assets</span>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                                <div className='collections-create-form-bottom'>
                                </div>
                            </section>
                        </section>
                        <div className='container-video-all'>
                            <div className='container-video'>
                                <p>How to create your assets</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>

                            </div>
                            <div className='container-video'>
                                <p>Cómo lanzar tu colección de NFT sin código</p>
                                <YoutubeEmbed embedId="YhEdU-ypBkc"></YoutubeEmbed>
                            </div>
                        </div>
                    </section>



                </Container>
                <div className='bottom_clear'>

                </div>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))
