import React, { useContext, useEffect, useState } from 'react'

import {
    Button,
    Card,
    CardTitle, Col, Form, FormGroup,
    Input, Label, Row
} from 'reactstrap'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { GeneralContext } from '../../context/'
import { withNamespaces } from "react-i18next"
import { toastrError } from "../../helpers/Toastr"
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { useRouter } from '../../helpers/useRouter'


import { apiError, loginSuccess, setToken } from "../../store/actions"

const Signup = (props) => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext
    const router = useRouter()
    const utmMedium = router.query.utm_medium || 'direct'
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [company_name, setCompanyName] = useState('')
    const [wallet_address, setWalletAddress] = useState('')
    const [countryCode, setCountryCode] = useState({
        label: '(+52) México',
        value: '+52',
        active: true
    },)
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [currentStep, setCurrentStep] = useState(1)

    useEffect(() => {
        (async () => {
            segmentTrack('User signup page viewed')
        })()
    }, []) // eslint-disable-line

    const _handleSubmit = async (e) => {
        try {
            setLoading(true)
            const body = {
                name: firstName,
                last_name: lastName,
                company_name: company_name,
                email: email,
                wallet_address: wallet_address ? wallet_address : undefined,
            }
            const response = await DataService.postUser(body)
            // const response = await ApiService.signup({
            //     firstName,
            //     lastName,
            //     email,
            //     countryCode: countryCode.value,
            //     phone,
            //     password,
            //     utmMedium
            // })
            console.log(response);

            // if (!response.data.success) {
            //     toastrError(`${response.data.message}`, 'Error')
            //     return
            // }

            // window.analytics.identify(response.data.userId, { email })
            // segmentTrack("User signed up", { userId: response.data.userId })
            // // window.gtag('event', 'conversion', { 'send_to': '' })

            // const user = response.data.user

            // props.setToken(response.data.token)
            // props.loginSuccess(user)
            if (response.data) {
                props.history.push('/login')
            }
        } catch (e) {
            if (e.response) {
                const { code, message } = e.response?.data

                if (message.includes('is not a mobile number')) {
                    toastrError(`Asegúrate de usar un número de Whatsapp válido`, '¡Hey!')
                }

                if (code === 'IS_TAKEN') {
                    toastrError(props.t(`You have an account, want to try resetting your password?`))
                }

                if (code === 'IS_BURNER_EMAIL') {
                    toastrError(props.t(`Wanna try a valid email?`))
                }
            } else {
                if (e.includes(`Network error`)) {
                    toastrError(props.t(`I am having trouble reaching the network`), props.t(`Network error`))
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const _validateStep1 = () => {
        if (email === '') { return false }

        return true
    }

    const _validateStep2 = () => {
        if (firstName === '') { return false }
        if (lastName === '') { return false }
        if (company_name === '') { return false }

        return true
    }

    const _validateStep3 = () => {
        if (password === '') { return false }
        if (password2 === '') { return false }
        if (password !== password2) {
            toastrError('Make sure to write down the same password on both fields', props.t(`Password mismatch`))

            return false
        }

        return true
    }

    const __renderSignupForm = () => {
        return (
            <div className="signup-form">
                <h1>{props.t(`Launch an NFT`)}</h1>
                <h2>{props.t(`As easy as it should be`)}</h2>

                {currentStep === 1 && (
                    <Card className='text-align-left'>
                        <CardTitle>{props.t(`Let's start with your email`)}</CardTitle>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`E-Mail`)}*  <small>({props.t(`You'll use it to signup`)})</small>
                                    </Label>

                                    <Input
                                        required
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!_validateStep1()) {
                                            toastrError(props.t(`Don't forget to enter a valid email`))
                                            return
                                        }

                                        setCurrentStep(2)
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Next`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                {currentStep === 2 && (
                    <Card className='text-align-left'>
                        <CardTitle>{props.t(`Now, tell us your story`)}</CardTitle>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`First name`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={firstName}
                                        type="text"
                                        className="form-control"
                                        placeholder='Sansa'
                                        onChange={event => setFirstName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Last name`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={lastName}
                                        type="text"
                                        className="form-control"
                                        placeholder='Stark'
                                        onChange={event => setLastName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Company name`)}
                                    </Label>

                                    <Input
                                        required
                                        value={company_name}
                                        type="text"
                                        className="form-control"
                                        placeholder='Winterfell'
                                        onChange={event => setCompanyName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Wallet address`)}
                                    </Label>

                                    <Input
                                        required
                                        value={wallet_address}
                                        type="text"
                                        className="form-control"
                                        placeholder='123123123'
                                        onChange={event => setWalletAddress(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>


                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!_validateStep2()) {
                                            toastrError(props.t(`Don't forget to enter your name`))
                                            return
                                        }

                                        _handleSubmit()
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Ir a mi cuenta`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                {currentStep === 3 && (
                    <Card className='text-align-left'>
                        {/* <Row>
                            <CardTitle>{props.t(`Lastly, set up your password`)}</CardTitle>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Password`)} *
                                    </Label>

                                    <Input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        required
                                        onChange={event => setPassword(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Confirm password`)} *
                                    </Label>

                                    <Input
                                        type="password"
                                        className="form-control"
                                        value={password2}
                                        required
                                        onChange={event => setPassword2(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row> */}

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={e => {
                                        e.preventDefault()
                                        // if (!_validateStep3()) {
                                        //     toastrError(props.t(`Don't forget to enter your password`))

                                        //     return
                                        // }

                                        _handleSubmit()
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Ir a mi cuenta`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="login signup">
                <div className="wrapper grid-layout">
                    <Form id="signup" type="POST" onSubmit={_handleSubmit}>
                        <div className="logo">
                            <img alt="Neefter" src="./assets/images/neefter.svg" />
                        </div>

                        {__renderSignupForm()}
                    </Form>

                    <Row>
                        <Col sm={12} className="login-copyright">
                            <p>Copyright © {new Date().getFullYear()}. {props.t(`All rights reserved`)}.</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error } = state.Auth

    return { error }
}

export default withRouter(
    connect(
        mapStatetoProps,
        { loginSuccess, apiError, setToken }
    )(withNamespaces()(Signup)
    ))