import React, { useContext, useEffect, useState } from 'react'

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { GeneralContext } from '../../../../context'

import { arrayMoveImmutable } from 'array-move'
import { toastrError, toastrInfo } from "../../../../helpers/Toastr"
import Preview from './Layers-components/preview'
import SortableList from './Layers-components/SortableList'
import DataService from '../../../../helpers/DataService'
import {
    Button, Col, Row, FormGroup, Label, CustomInput, Input
} from "reactstrap"
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from '../../../../components/Global/Dropdown'
import DropdownCheckBox from '../../../../components/Global/DropdownCheckbox'
import DropdownMix from './Layers-components/DropdownMix'
import { fileReader } from "../../../../helpers/constants"
import { id } from 'ethers/lib/utils'

const ComponentsTabs = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    let { project_id, activeForm, collection_id, tokens, setTokens, rules_array, setRulesArray,
        rules_updated, set_rules_updated, _handleExport } = props;
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    let [collectionsPropertiesSaved, setCollectionProperties] = useState([])
    let [options, setOptions] = useState([])
    let [universe_files, setUniverseFiles] = useState(undefined);

    let ruleError = 'It is not possible to create the rule you want, an opposite rule already exists'
    const typesRules = [
        {
            value: 1,
            name: 'Always pairs with'
        },
        {
            value: 2,
            name: `Doesn't mix with`
        }
    ]


    let [array_properties, setArrayProperties] = useState([])
    let [sorted, setSorted] = useState(false)

    useEffect(() => {
        if (!collection_id) return;
        toastrInfo('Loading properties', 'Info')
        setLoading(true)
        _checkCollectionProperties()
    }, [collection_id])

    const _checkCollectionProperties = () => {
        DataService.getCollectionAllTraitDataById(collection_id).then(responnse => {
            const { data } = responnse.data;
            const orderedData = data.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
            })
            array_properties = [...orderedData];
            setArrayProperties([...orderedData])
            setLoading(false)


            setOptions(orderedData.map(op => {
                return {
                    label: op.name,
                    options: op.variations.map(opt => {
                        return {
                            value: {
                                id: opt.id,
                                source_img: opt.image,
                                label: opt.name,
                                parent: op.name
                            },
                            label: opt.name,
                        }
                    }),
                }
            }))
            _checkCollectionRules()

        })
    }
    const _checkCollectionRules = () => {
        DataService.getCollectionAllRulesDataById(collection_id).then(responnse => {
            const { data } = responnse.data;
            console.log('getCollectionAllRulesDataById', data);
            let ordered_rules = [];
            console.log('array_properties', array_properties);
            data.forEach(rule => {
                const findRule = ordered_rules.findIndex(or => or.firstAttribute.id === rule.variation_1_id)
                const firstAttributeOption = array_properties.find(ap => !!ap.variations.find(apv => apv.id === rule.variation_1_id))
                console.log('firstAttributeOption', firstAttributeOption);
                const firstAttributeValue = firstAttributeOption.variations.find(apv => apv.id === rule.variation_1_id)
                console.log('firstAttributeValue', firstAttributeValue);
                const secondAttributeOption = array_properties.find(ap => !!ap.variations.find(apv => apv.id === rule.variation_2_id))
                const secondAttributeValue = secondAttributeOption.variations.find(apv => apv.id === rule.variation_2_id)
                console.log('secondAttributeValue', secondAttributeValue);
                console.log('findRule', findRule);
                if (findRule > -1) {
                    console.log('ordered_rules[findRule]', ordered_rules[findRule]);
                    ordered_rules[findRule].secondAttribute.push(
                        {
                            id: secondAttributeValue.id,
                            source_img: secondAttributeValue.image,
                            label: secondAttributeValue.name,
                            parent: secondAttributeOption.name
                        }
                    )
                } else {
                    ordered_rules.push({
                        firstAttribute: {
                            id: firstAttributeValue.id,
                            source_img: firstAttributeValue.image,
                            label: firstAttributeValue.name,
                            parent: firstAttributeOption.name
                        },
                        secondAttribute: [
                            {
                                id: secondAttributeValue.id,
                                source_img: secondAttributeValue.image,
                                label: secondAttributeValue.name,
                                parent: secondAttributeOption.name
                            }
                        ],
                        type: rule.rule_type,
                        error: false,
                    })
                }
            });
            console.log('ordered_rules', ordered_rules);
            setRulesArray(ordered_rules)
            /*
             {
                firstAttribute: undefined,
                secondAttribute: [],
                type: 1,
                error: false,
            }
            */
            // setRulesArray([...data])
            // setLoading(false)
        })
    }

    let setPropertyAfterChange = async (property) => {
        if (!property) return;
        setLoading(true)
        const index = array_properties.findIndex(ap => ap.id === property.id)
        if (index < 0) return;
        array_properties[index] = property;

        setLoading(false)
        setArrayProperties([...array_properties]);
        console.log('property', property);
        const variation_promises = [];
        property.variations.forEach(variation => {
            const body = {
                name: variation.name,
                image: variation.image,
                rarity: variation.rarity,
            }
            variation_promises.push(DataService.putCollectionTraitVariationById(collection_id, variation.id, body))
        });
        Promise.all(variation_promises).then(() => {
            const bodyTrait = {
                name: property.name,
                percentage: property.percentage,
                order: property.order,
            }
            DataService.putCollectionTraitById(collection_id, property.id, bodyTrait)
        })
    }

    const _handleSubmit = async (e) => {
        console.log('ar', array_properties);
        if (rules_updated) {
            setLoading(true)
            const findError = rules_array.find(rule => rule.error)
            if (findError) {
                toastrError('error setting rules')
                setLoading(false)
                return
            }
            findTraitsAndUpdateRules()
        } else {
            _handleExport(4)
        }


        // const properties = array_properties.map((ap, index) => {
        //     return {
        //         name: ap.name,
        //         order: index,
        //         percentage: ap.percentage,
        //         variations: ap.variations
        //     }
        // })
        // const body = {
        //     data: properties
        // }
        // DataService.putCollectionTraitBulkById(collection_id, body).then(response_bulk => {
        //     if (response_bulk) {
        //         console.log('rules_array', rules_array);


        //         findTraitsAndUpdateRules()
        //     }
        // })

    }

    const findTraitsAndUpdateRules = () => {
        DataService.getCollectionAllTraitDataById(collection_id).then(response => {
            const { data } = response.data;
            console.log('getCollectionAllTraitDataById', data);
            let separated_rules = [];
            const rules_array_filtered = rules_array.filter(ra => !!ra.firstAttribute && ra.secondAttribute.length > 0)
            console.log('rules_array_filtered', rules_array_filtered);
            rules_array_filtered.forEach(rule => {
                const firstAttributeParent = data.find(property => property.name === rule.firstAttribute.parent)
                console.log('firstAttributeParent', firstAttributeParent);
                const variation_1_id = firstAttributeParent.variations.find(_variation => _variation.name === rule.firstAttribute.label)
                console.log('variation_1_id', variation_1_id);
                console.log('rule.secondAttribute', rule.secondAttribute);
                rule.secondAttribute.forEach(att => {
                    const secondAttributeParent = data.find(property => property.name === att.parent)
                    console.log('secondAttributeParent', secondAttributeParent);
                    const variation_2_id = secondAttributeParent.variations.find(variation => variation.name === att.label)
                    console.log('variation_2_id', variation_2_id);
                    separated_rules.push({
                        rule_type: rule.type,
                        variation_1_id: variation_1_id.id,
                        variation_2_id: variation_2_id.id
                    })

                });
            });
            console.log('separated_rules', separated_rules);
            const rules_body = {
                data: separated_rules
            }
            DataService.putCollectionRulesById(collection_id, rules_body).then(response_rules => {
                if (response_rules) {
                    const body = {
                        step_number: 4,
                    }
                    DataService.putCollectionById(collection_id, body).then(response => {
                        if (response && response.data) {
                            props.history.push(`/collections/generative/legendary/` + collection_id)
                            setLoading(false)


                        }
                    })
                }
            })

        })
    }

    const swapPositions = async (array, to, from) => {
        // setLoading(true)
        console.log('to', to);
        console.log('from', from);

        // [array_properties[to], array_properties[from]] = [array_properties[from], array_properties[to]]
        // array_properties[to].order = to;
        // array_properties[from].order = from;

        // await db.properties.update(array_properties[to].id, {
        //     order: to
        // })
        // await db.properties.update(array_properties[from].id, {
        //     order: from
        // })
        // setArrayProperties([...array_properties])
        setLoading(false)


    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        console.log('oldIndex', oldIndex);
        console.log('newIndex', newIndex);
        const updateTrait = array_properties[oldIndex]
        const updateTraitChange = array_properties[newIndex]
        const updateTraitBody = {
            order: newIndex,
        }
        DataService.putCollectionTraitById(collection_id, updateTrait.id, updateTraitBody).then(response => {

        })
        const updateTraitChangeBody = {
            order: oldIndex,
        }
        DataService.putCollectionTraitById(collection_id, updateTraitChange.id, updateTraitChangeBody).then(response => {

        })

        const arr = arrayMoveImmutable(array_properties, oldIndex, newIndex)
        console.log('newIndexarr', arr);
        array_properties = [...arr]
        setArrayProperties(array_properties);
        console.log('newIndex', array_properties);

    };

    const addRule = () => {
        set_rules_updated(true)
        rules_array = [
            ...rules_array,
            {
                firstAttribute: undefined,
                secondAttribute: [],
                type: 1,
                error: false,
            }
        ]
        console.log('rules_array', rules_array);
        setRulesArray([...rules_array]);
    };
    const removeRuleInput = (index) => {
        setRulesArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
        set_rules_updated(true)

    }
    const handleChangeRuleFirstInput = (value, index) => {
        const val = 'first_input';
        if (checkRepeatedFirstAttribute(value, index)) {
            toastrError(ruleError)
            return;
        }
        set_rules_updated(true)
        setRulesArray(item => {
            const newArr = item.slice();
            newArr[index].firstAttribute = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    const handleChangeRuleSecondInput = (value, index) => {
        const val = 'second_input';
        if (checkRepeated(value, index)) {
            toastrError(ruleError)
            return;
        }
        set_rules_updated(true)
        const exist = rules_array[index].secondAttribute.findIndex(sa => JSON.stringify(sa) === JSON.stringify(value));
        if (exist > -1) {
            setRulesArray(item => {
                const newArr = item.slice();
                newArr[index].secondAttribute.splice(exist, 1);
                if (!value) {
                    newArr[index].error = true;
                    document.getElementById(val + index).classList.add('input-error')
                } else {
                    newArr[index].error = false;
                    document.getElementById(val + index).classList.remove('input-error')
                }
                return newArr;
            });
        } else {
            setRulesArray(item => {
                const newArr = item.slice();
                newArr[index].secondAttribute.push(value);
                if (!value) {
                    newArr[index].error = true;
                    document.getElementById(val + index).classList.add('input-error')
                } else {
                    newArr[index].error = false;
                    document.getElementById(val + index).classList.remove('input-error')
                }
                return newArr;
            });
        }

    };
    const handleChangeRuleMixInput = (value, index) => {

        const val = 'mix_input';
        if (checkRepeatedTypeAttribute(value, index)) {
            toastrError(ruleError)
            return;
        }
        set_rules_updated(true)
        setRulesArray(item => {
            const newArr = item.slice();
            newArr[index].type = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });

    };

    const checkRepeated = (value, index) => {
        console.log('value', value);
        console.log('rules_array', rules_array);
        console.log('index', index);

        if (!value) return false;
        if (!rules_array[index]) return false;
        const { firstAttribute, type } = rules_array[index]
        console.log('firstAttribute', firstAttribute);
        console.log('type', type);
        const ra = rules_array.find((rule, _index) => (
            _index !== index &&
            rule.type !== type &&
            (firstAttribute.child === rule.firstAttribute.child && firstAttribute.parent === rule.firstAttribute.parent) &&
            (rule.secondAttribute.find(rsa => (value.child === rsa.child && value.parent === rsa.parent)))))
        console.log('ra', ra);

        if (!!ra) {
            return true
        } else {
            return false;
        }
    }
    const checkRepeatedFirstAttribute = (value, index) => {
        console.log('value', value);
        console.log('rules_array', rules_array);
        if (!value) return false;
        if (!rules_array[index]) return false;
        const { secondAttribute, type, } = rules_array[index]
        const ra = rules_array.find((rule, _index) => (
            _index !== index &&
            rule.type !== type &&
            (value.child === rule.firstAttribute.child && value.parent === rule.firstAttribute.parent) &&
            (rule.secondAttribute.find(rsa => !!secondAttribute.find(sa => JSON.stringify(rsa) === JSON.stringify(sa))))))
        if (!!ra) {
            return true
        } else {
            return false;
        }
    }
    const checkRepeatedTypeAttribute = (value, index) => {
        console.log('value', value);
        console.log('rules_array', rules_array);
        if (!value) return false;
        if (!rules_array[index]) return false;
        const { secondAttribute, firstAttribute } = rules_array[index]
        const ra = rules_array.find((rule, _index) => (
            _index !== index &&
            rule.type !== value &&
            (firstAttribute.child === rule.firstAttribute.child && firstAttribute.parent === rule.firstAttribute.parent) &&
            (rule.secondAttribute.find(rsa => !!secondAttribute.find(sa => JSON.stringify(rsa) === JSON.stringify(sa))))))
        if (!!ra) {
            return true
        } else {
            return false;
        }
    }




    return (
        <React.Fragment>
            <section className='collections-layers container-tab-rarity'>
                <h1 className='collections-layers-title'>
                    Layers and rarity
                </h1>
                <Button
                    style={{ display: 'none' }}
                    type="button"
                    color="secundary"
                    id="_handleSubmitRarityTab"
                    className="theme-button-secundary"
                    onClick={(e) => {
                        e.preventDefault()
                        _handleSubmit()

                    }}
                >
                    {props.t(`Next step`)}
                </Button>
                <div className='collections-layers-container'>
                    <div className='collections-layers-ltable'>
                        <p className='collections-layers-subtitle'>
                            Attributes Order
                        </p>
                        <p className='collections-layers-label'>
                            Click on each attribute if you wish to edit rarity settings
                        </p>
                        <SortableList items={array_properties} onSortEnd={onSortEnd} swapPositions={swapPositions} array_properties={array_properties} setPropertyAfterChange={setPropertyAfterChange} />
                    </div>
                    <div className='collections-layers-preview'>
                        {
                            array_properties.length > 0 && (
                                <Preview collections_properties={array_properties}></Preview>
                            )
                        }
                    </div>
                </div>

                <div className='collections-layers-separator'>
                </div>
                <h1 className='collections-layers-title'>
                    Rules
                </h1>
                <div className='collections-layers-rules'>
                    <p className='collections-layers-subtitle'>
                        Create new rule
                    </p>
                    <p className='collections-layers-label'>
                        Your idea. Your collection. Your rules
                    </p>
                    <div className='collections-layers-rules-contain'>
                        {
                            rules_array.map((item, i) => {
                                return (
                                    <div className='collections-layers-rules-contain-row' key={i}>
                                        <div className='collections-layers-rules-contain-row-input'>
                                            <FormGroup>
                                                <Dropdown value={item.firstAttribute} parent={'first_input'} index={i}
                                                    setValue={handleChangeRuleFirstInput} options={options}></Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className='collections-layers-rules-contain-row-input'>
                                            <DropdownMix value={item.type}
                                                setValue={handleChangeRuleMixInput}
                                                options={typesRules}
                                                index={i}></DropdownMix>
                                            {/* <FormGroup>
                                                <Input
                                                    value={item.type}
                                                    className={cn('input-white-select circle', item.type + '' === '1' ? 'input-green-select' : 'input-red-select')}
                                                    type="select"
                                                    id={"rule_type" + i}
                                                    onChange={handleChangeRuleType}
                                                >
                                                    {
                                                        typesRules.map((item, i) => (
                                                            <option value={item.value} key={i}>{item.name}</option>
                                                        ))
                                                    }
                                                </Input>
                                            </FormGroup> */}

                                        </div>
                                        <div className='collections-layers-rules-contain-row-input'>
                                            <FormGroup>
                                                <DropdownCheckBox value={item.secondAttribute} parent={'second_input'} index={i}
                                                    setValue={handleChangeRuleSecondInput} options={options}></DropdownCheckBox>
                                            </FormGroup>
                                        </div>
                                        <div className='input-container_icon' onClick={() => { removeRuleInput(i) }} >
                                            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <Row>
                        <Col sm={6}>
                            <Button
                                type="button"
                                color="primary"
                                className="theme-button-collaborator"
                                onClick={addRule}
                            >
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                {props.t(`Create rule`)}
                            </Button>
                        </Col>

                    </Row>
                </div>
                <Button
                    type="button"
                    color="secundary"
                    className="theme-button-secundary mobile"
                    onClick={(e) => {
                        e.preventDefault()
                        segmentTrack(`go to project review`)
                        _handleSubmit(4)

                    }}
                >
                    {props.t(`Next step`)}
                </Button>


            </section>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(ComponentsTabs))