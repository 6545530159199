import React, { useContext, useEffect, useState } from 'react'
import {
    Card, CardBody,
    CardFooter, CardTitle, Col,
    Input, Row, Button
} from "reactstrap"

import { GeneralContext } from '../../../context'

import { withNamespaces } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import 'toastr/build/toastr.min.css'
import { toastrError, toastrSuccess } from "../../../helpers/Toastr"

import DataService from '../../../helpers/DataService'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DataTable = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const { collection_id, reload, setReload } = props;

    let [search, setSearch] = useState('');
    let [sort, setSort] = useState();
    let [display, setDisplay] = useState('table');



    useEffect(() => {
        if (!collection_id) return;

        DataService.getAirdrop(collection_id).then(
            response => {
                console.log(response);
                if (response && response.data) {
                    setData(response.data.data)
                    setFilteredData(response.data.data)
                }
            })
            .finally(() => {

            })



    }, [collection_id, reload])

    const fetchData = async () => {
        setLoading(true)

        try {
            let fdata = [];
            for (let index = 0; index < 10; index++) {
                fdata.push(
                    {
                        id: 1,
                        Quantity: 1,
                        Address: '1212312312312312312312312312',
                        Status: 'done'
                    },
                )
            }
            setFilteredData(fdata)
            setData(fdata)
        } catch (error) {
            toastrError(props.t(`I couldn't fetch your team members`))
        } finally {
            setLoading(false)
        }
    }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const Address = item.Address || ''
            const Status = item.Status || ''

            return Address.toLowerCase().includes(keystroke) ||
                Status.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)
        DataService.deleteAirdrop(id).then(response => {
            if (response) {
                setReload(reload + 1)
                setLoading(false)
            }
        })
    }

    return (
        <React.Fragment>
            <div className='collections-tab-table'>
                {
                    display === 'table' && (
                        <div className='collections-tab-table-contain'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Address</th>
                                        <th>Quantity</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(
                                        (item, key) => (
                                            <tr key={key}>
                                                <td>{item.address}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.is_sent ? 'Done' : 'Pending'}</td>
                                                <td>
                                                    <Button
                                                        outline
                                                        type="button"
                                                        color="secondary"
                                                        className="layers-button"
                                                        onClick={() => {
                                                            _deleteItem(item.id)
                                                        }}
                                                        disabled={false}
                                                    >
                                                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }

                {
                    display === 'list' && (
                        <Row>
                            {filteredData.map(
                                (item, key) => (
                                    <Col md={6} key={key}>
                                        <Card key={key}>
                                            <CardTitle>{item.firstName} {item.lastName}</CardTitle>
                                            <CardBody>
                                                <strong>{props.t(`Address`)}:</strong> {item.Address}<br />
                                                <strong>{props.t(`Quantity`)}:</strong> {item.Quantity}<br />
                                                <strong>{props.t(`Status`)}:</strong> {item.Status}<br />
                                            </CardBody>
                                            <CardFooter className="bg-transparent border-top">
                                                <div className="d-flex font-size-20">
                                                    <div className="flex-fill">
                                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                                            e.preventDefault()
                                                            _deleteItem(item.id)
                                                        }}>
                                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                )
                            )}
                        </Row>
                    )
                }

            </div>



        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(DataTable))