import React, { useContext, useEffect, useState } from 'react'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import {
    Button, Card,
    CardBody, Col, Form, FormGroup, Input, Label, Row
} from "reactstrap"
import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import { segmentTrack } from "../../../helpers/Segment"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [branchId, setBranchId] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [password, setPassword] = useState()

    useEffect(() => {
        if (props.data !== undefined) {
            const { data } = props

            setId(data.id)
            setBranchId(data.branchId)
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setEmail(data.email)
            setPhone(data.phone)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastrError('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)
        let data = {
            branchId,
            firstName,
            lastName,
            email,
            phone,
            password
        }

        if (props.data !== undefined) {
            _updateUser(id, data)
        } else {
            _createUser(data)
        }

        props.history.push('/users')
    }

    const _updateUser = async (id, data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateUser(id, data)

            if (!response.data.success) {
                toastrError(response.data.message, 'Error')

                return false
            }

            segmentTrack('User updated')
            toastrSuccess(props.t(`User Edited`), '¡Success!')
        } catch (error) {
            toastrError(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _createUser = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createUser(data)

            if (!response.data.success) {
                toastrError(response.data.message, 'Error')

                return false
            }

            segmentTrack('User Added')
            toastrSuccess(props.t(`User Added`), '¡Success!')
        } catch (error) {
            toastrError(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (firstName === '') { return false }
        if (email === '') { return false }
        if (password === '') { return false }

        return true
    }

    return (
        <React.Fragment>
            <Form id="lugares" method="POST" onSubmit={_handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <Card style={{ textAlign: 'right' }}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="firstName">
                                                {props.t(`First Name`)}
                                            </Label>

                                            <Input
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                value={firstName}
                                                onChange={event => setFirstName(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="lastName">
                                                {props.t(`Last Name`)}
                                            </Label>

                                            <Input
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                className="form-control"
                                                value={lastName}
                                                onChange={event => setLastName(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="email">
                                                {props.t(`Email`)}
                                            </Label>

                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="phone">
                                                {props.t(`Phone Number`)}
                                            </Label>

                                            <Input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                className="form-control"
                                                value={phone}
                                                onChange={event => setPhone(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="password">
                                                {props.t(`Password`)}
                                            </Label>

                                            <Input
                                                id="password"
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))