import React, { useContext, useEffect } from 'react'
import {
    Container,
    Row,
    Col,
} from "reactstrap"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import Dropzone from "./components/dropzone"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Collections`),
            parentLink: `/collections`,
            item: props.t(`Create Collection de prueba`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <section className='collections-create-form'>

                        <div className='collections-create-form-row'>
                            <div className='collections-create-form-row-content text-align-left'>
                                <Row>
                                    <Col sm={12}>

                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12}>

                                    </Col>


                                </Row>


                            </div>
                            <div className='collections-create-form-row-img'>
                                <div className='card-picture' style={{ cursor: 'pointer' }} onClick={() => document.getElementById('upload_img')?.click()}>

                                </div>
                            </div>
                        </div>
                        <div className='collections-create-form-upload'>
                            <p className='collections-create-form-upload-title'>Upload your assets</p>
                            <div className='collections-create-form-upload-container'>
                                <div className='collections-create-form-upload-container-dropzone'>
                                    <Dropzone accept={'image/*,.json,.csv'} />
                                </div>
                                <div className='collections-create-form-upload-container-list'>
                                    <ul>
                                        <li>
                                            Files must contain one .csv or .json file with metadata. - <span>Download example.csv.</span>  <span>Download example.json.</span>
                                        </li>
                                        <li>
                                            The csv must have a name column, which defines the name of the NFT.
                                        </li>
                                        <li>
                                            Asset names must be sequential 0,1,2,3...n.[extension]. It doesn't matter at what number you begin. (Example: 131.png, 132.png).
                                        </li>
                                        <li>
                                            Make sure to drag and drop the CSV/JSON and the images at the same time.
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className='collections-create-form-bottom'>
                            <div className='form-actions-row'>


                            </div>
                        </div>
                    </section>



                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
