import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    DropdownItem,
    UncontrolledCollapse,
} from "reactstrap"

import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import CheckboxContainer from './checkbox-container'
import TogglerFilter from './toggler-filter'


const FilterContainer = (props) => {
    const { array_filters, changeCheckedOption } = props;

    let onChangeCheckedOption = (key, item) => {
        changeCheckedOption(key, item)
    }

    return (
        <React.Fragment>
            {array_filters.map(
                (item, key) => (
                    <TogglerFilter item={item} index={key} key={key} changeCheckedOption={onChangeCheckedOption}></TogglerFilter>
                )
            )}

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(FilterContainer))