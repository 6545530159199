import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col, Button,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'


import { toastrError, toastrSuccess } from "../../../helpers/Toastr"
import DataService from '../../../helpers/DataService'
import { loadState } from "../../../store/localStorage"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm, project_id, userInfo } = props;
    const [company_name, setCompanyName] = useState('')
    const [billing_email, setBillingEmail] = useState('')
    const [twitter_user, setTwitterUser] = useState('')
    const [billing_address, setBillingAddress] = useState('')

    useEffect(() => {
        if (loadState()?.Auth) {
            console.log('loadState()?.Auth', loadState()?.Auth.profile);
        }
    }, [project_id])

    useEffect(() => {
        if (userInfo) {
            console.log('userInfo', userInfo);
            const { company_name, email, billing_address } = userInfo
            setCompanyName(company_name)
            setBillingEmail(email)
            setBillingAddress(billing_address)

        }
    }, [userInfo])



    useEffect(() => {
        if (activeForm > 0) {
            _handleSubmit();
        }
    }, [activeForm])



    const _validateStep = () => {
        if (company_name === '') {
            toastrError(props.t(`Don't forget to add the company name of the organization`), 'Error')
            return false
        }
        if (billing_email === '') {
            toastrError(props.t(`Don't forget to add the  billing email of the organization`), 'Error')
            return false
        }
        if (twitter_user === '') {
            toastrError(props.t(`Don't forget to add the twitter user of the organization`), 'Error')
            return false
        }
        if (billing_address === '') {
            toastrError(props.t(`Don't forget to add the billing addrress of the organization`), 'Error')
            return false
        }


        return true
    }

    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            setLoading(true)
            const body = {
                company_name: company_name,
                billing_email: billing_email,
                twitter_user: twitter_user,
                billing_address: billing_address
            }
            DataService.putOrganization(body).then(response => {
                toastrSuccess('Organization data updated successfully')
                setLoading(false)
            })
                .catch(err => {
                    console.log(err)
                    toastrError(`Failed to update organization data`, 'Error');
                })
        } catch (error) {
            toastrError(`Failed to update organization data`, 'Error');

        }
    }

    return (
        <React.Fragment>
            <Form className='collections-ticketing review'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <CardTitle className='collections-settings-tab-title'>{props.t(`General settings`)}</CardTitle>
                            <span className='collections-settings-tab-subtitle'>{props.t(`Text about general tab.`)}</span>
                            <div className='collections-ticketing-settings-form'>
                                <div className='collections-ticketing-settings-form-column'>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Company name`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={company_name}
                                                    type="text"
                                                    id='company_name'
                                                    className="input-white-input circle"
                                                    placeholder='Company name'
                                                    onChange={event => {
                                                        const { value } = event.target
                                                        if (!value) {
                                                            document.getElementById('company_name').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('company_name').classList.remove('input-error')
                                                        }
                                                        setCompanyName(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Twitter`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={twitter_user}
                                                    type="text"
                                                    id='twitter_user'
                                                    className="input-white-input circle"
                                                    placeholder='twitter user'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('twitter_user').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('twitter_user').classList.remove('input-error')
                                                        }
                                                        setTwitterUser(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Billing address`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={billing_address}
                                                    type="text"
                                                    id='billing_address'
                                                    className="input-white-input circle"
                                                    placeholder='Company name'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('billing_address').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('billing_address').classList.remove('input-error')
                                                        }
                                                        setBillingAddress(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Billing email`)}
                                                </Label>

                                                <Input
                                                    required
                                                    value={billing_email}
                                                    type="text"
                                                    id='billing_email'
                                                    className="input-white-input circle"
                                                    placeholder='twitter user'
                                                    onChange={event => {
                                                        const { value } = event.target

                                                        if (!value) {
                                                            document.getElementById('billing_email').classList.add('input-error')
                                                        } else {
                                                            document.getElementById('billing_email').classList.remove('input-error')
                                                        }
                                                        setBillingEmail(event.target.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
                <div className='bottom_clear'></div>

            </Form>

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))