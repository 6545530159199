import React, { useState, useEffect, useContext } from "react"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { setActiveProject, updateProject, setUserProject } from "../../store/actions"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap"
import toastr from 'toastr'
import { GeneralContext } from '../../context/'
import DataService from '../../helpers/DataService'
import { loadState } from "../../store/localStorage"
import { toastrError } from "../../helpers/Toastr"


const ProfileMenu = (props) => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext
    const { currentProject, setProject } = generalContext
    let [selectedProject, setSelectedProject] = useState(undefined)
    const [filteredData, setFilteredData] = useState([])

    const [menu, setMenu] = useState(false)

    const toggle = () => { setMenu(!menu) }

    useEffect(() => {

        if (loadState()?.Auth?.project) {
            selectedProject = loadState()?.Auth?.project;
            setSelectedProject(loadState()?.Auth?.project)
        }
        (async () => {
            await fetchData()
        })()
    }, [])


    useEffect(() => {

        if (!!selectedProject) {
            props.updateProject(selectedProject)
            props.setUserProject(selectedProject)
            setProject(selectedProject)
        }
    }, [selectedProject])
    useEffect(() => {
        if (!!currentProject) {

        }
    }, [currentProject])

    const fetchData = async () => {

        // setLoading(true)
        console.log('loadState()?.Auth', loadState()?.Auth);
        if (!loadState()?.Auth.profile) return;
        try {
            DataService.getProject().then(response => {


                setFilteredData(response.data.data)
                if (!!selectedProject) return;
                if (response.data.data.length > 0) {
                    setSelectedProject(response.data.data[0])
                    props.setActiveProject(response.data.data[0])
                    props.setUserProject(response.data.data[0])


                } else {
                    props.history.push('/projects/create')
                }
            })
        } catch (error) {
            toastrError(props.t(`I couldn't fetch your projects`))
        } finally {
            // setLoading(false)
        }
    }
    return (
        <React.Fragment>
            {/* <Dropdown
                isOpen={menu}
                toggle={toggle}
                className="d-inline-block"
                onClick={() => {
                    segmentTrack('Profile: Project selecter')
                }}
            >
                <DropdownToggle
                    className="btn header-item waves-effect input-secundary-dropdown circle"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <span className="header-item-project-name">
                        {selectedProject && (
                            <span>{selectedProject.name}</span>
                        )}
                    </span>
                    <i className="header-item-chevron mdi mdi-chevron-down" style={{ color: 'white' }}></i>
                </DropdownToggle>
                <DropdownMenu right>

                    {filteredData.map(
                        (item, key) => (
                            <Link to="#" key={key} className="dropdown-item" onClick={() => {
                                setSelectedProject(item)
                            }}>
                                <span>{item.name}</span>
                            </Link>
                        ))}

                    <Link to="/projects/create" className="dropdown-item" onClick={() => {

                    }}>
                        <span>New project</span>
                    </Link>
                </DropdownMenu>
            </Dropdown> */}
        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project
    }
}

export default withRouter(
    connect(mapStatetoProps, {
        setActiveProject,
        updateProject,
        setUserProject
    })(ProfileMenu)
)
