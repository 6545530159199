import {
    SET_ACTIVE_TICKET, UPDATE_TICKET
} from "./actionTypes"
import { loadState } from "../../localStorage"

const persistedState = loadState()
const initialState = persistedState ? persistedState?.ticket : {
    ticket: null
}

const TicketReducer = (state = initialState, action) => {
    const { data } = action

    switch (action.type) {
        case UPDATE_TICKET:
            return {
                ...state,
                ticket: data
            }
        case SET_ACTIVE_TICKET:
            return {
                ...state,
                ticket: data
            }
        default:
            return { ...state }
    }
    return state
}

export default TicketReducer
