import React from 'react'
import Content from "../Global/content"
import { withRouter } from "react-router-dom"

export default withRouter(({ children }) => {
    return (
        <React.Fragment>
            <Content body={children} />
        </React.Fragment>
    )
})