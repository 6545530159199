import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap"

import { withRouter, Link, useParams } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import { segmentTrack } from "../../helpers/Segment"

import Personal from './components/Personal'
import Global from './components/Global'

const Index = (props) => {
    const { route } = useParams() 
    const [navigation, setNavigation] = useState()
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Settings`),
            parentLink: '/settings',
            item: props.t(`Settings`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        switch(route) {
            case 'projects':
                segmentTrack('[Settings] Go to projects')
                break;
            case 'global':
                segmentTrack('[Settings] Go to global')
                setNavigation(<Global />)
                break;
            case 'personal':
            default:
                segmentTrack('[Settings] Go to personal')
                setNavigation(<Personal />)
                break;
        }
    }, [route])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <Row className="config">
                                <Col className="configuration-nav" md={12} xs={12}>
                                    <nav>
                                        <Link to="/settings/personal" className={route === 'personal' ? 'active' : ''}>{props.t(`Personal`)}</Link>
                                        <Link to="/settings/global" className={route === 'global' ? 'active' : ''}>{props.t(`Global`)}</Link>
                                        {/* <Link to="/settings/projects" className={route === 'projects' ? 'active' : ''}>{props.t(`Projects`)}</Link> */}
                                    </nav>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {navigation}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))
