import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Container, Button, Input
} from "reactstrap"
import { GeneralContext } from '../../context/'

import { withNamespaces } from "react-i18next"
import { useParams } from 'react-router-dom'
import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'
import Twtheader from "./components/header"
import image_source from '../../assets/images/Variant2.png'
import { segmentTrack } from "../../helpers/Segment"
import sha256Fallback from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

const Index = (props) => {
    const MAGIC_API_KEY = "pk_live_9E32243E04763494";
    const OAUTH_REDIRECT_METADATA_KEY = 'oauth_redirect_metadata';
    const HAS_CRYPTO = typeof window !== 'undefined' && !!(window.crypto);
    const HAS_SUBTLE_CRYPTO = HAS_CRYPTO && !!(window.crypto.subtle);
    const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

    const ext = new OAuthExtension()
    const magic = new Magic(MAGIC_API_KEY, {
        extensions: [ext]
    });


    useEffect(() => {


    }, [])

    /**
     * Starts the OAuth 2.0 login flow.
     */
    const handleLogin = async () => {
        const config = {
            provider: "twitter",
            redirectURI: `${window.location.origin}/magic_callback`,
        }
        try {
            const r = await createURI.call(this, config);
            const { provider, query } = r;
            // @ts-ignore - this.sdk.endpoint is marked protected but we need to access it.
            window.top.location.href = new URL(`/v1/oauth2/${provider}/start?${query}`, ext.sdk.endpoint).href;
            // window.location.href = new URL(`/v1/oauth2/${provider}/start?${query}`, ext.sdk.endpoint).href;
        }
        catch (error) {
            console.log('error', error);
        }
    };
    /**
     * Stringifies `bytes` using the OAuth 2.0 `code_verifier` character set.
     */
    function bytesToVerifierString(bytes) {
        return Array.from(bytes)
            .map((value) => CHARSET[value % CHARSET.length])
            .join('');
    }


    /**
 * Stringifies argument (as CryptoJS `WordArray` or EcmaScript `ArrayBuffer`)
 * and encodes to URL-safe Base64.
 */
    // function base64URLEncodeFromByteArray(wordArray)
    // function base64URLEncodeFromByteArray(arrayBuffer)
    function base64URLEncodeFromByteArray(arg) {
        const makeURLSafe = (base64) => {
            return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        };

        if (arg instanceof ArrayBuffer) {
            const bytes = new Uint8Array(arg);
            const utf8Binary = Array.from(bytes)
                .map((value) => String.fromCharCode(value))
                .join('');

            const base64 = btoa(utf8Binary);
            return makeURLSafe(base64);
        }

        return makeURLSafe(Base64.stringify(arg));
    }
    function base64URLEncodeFromByteArray(arg) {
        const makeURLSafe = (base64) => {
            return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        };

        if (arg instanceof ArrayBuffer) {
            const bytes = new Uint8Array(arg);
            const utf8Binary = Array.from(bytes)
                .map((value) => String.fromCharCode(value))
                .join('');

            const base64 = btoa(utf8Binary);
            return makeURLSafe(base64);
        }

        return makeURLSafe(Base64.stringify(arg));
    }


    /**
 * Produces a SHA-256 hash of the given `message`. This function first attempts
 * to use the browser's built-in `SubtleCrypto` API, falling back to
 * CryptoJS if required.
 */
    async function sha256(message) {
        if (HAS_SUBTLE_CRYPTO) {
            const bytes = new TextEncoder().encode(message);
            return crypto.subtle.digest('SHA-256', bytes).then(base64URLEncodeFromByteArray);
        }

        return base64URLEncodeFromByteArray(sha256Fallback(message));
    }

    /**
     * Creates a cryptographically random string using the browser's built-in
     * `Crypto` API, falling back to `Math.random` if required.
     */
    function createRandomString(size) {
        const bytes = new Uint8Array(size);

        if (HAS_CRYPTO) {
            window.crypto.getRandomValues(bytes);
        } else {
            for (let i = 0; i < size; i += 1) bytes[i] = Math.floor(Math.random() * Math.floor(255));
        }

        return bytesToVerifierString(bytes);
    }

    /**
 * Creates OAuth 2.0-compatible `code_verifier`, `code_challenge`, and `state`
 * parameters.
 */
    async function createCryptoChallenge() {
        const state = createRandomString(128);
        const verifier = createRandomString(128);
        const challenge = await sha256(verifier);
        return { verifier, challenge, state };
    }

    /*
    createURI(this: OAuthExtension, configuration: OAuthRedirectConfiguration)
     */
    async function createURI(configuration) {

        const magic = ext;
        // Bust any old, in-progress OAuth flows.

        await magic.utils.storage.removeItem(OAUTH_REDIRECT_METADATA_KEY);

        // Unpack configuration, generate crypto values, and persist to storage.
        const { provider, redirectURI, scope, loginHint } = configuration;
        const { verifier, challenge, state } = await createCryptoChallenge();

        /* Stringify for RN Async storage */
        const storedData = JSON.stringify({
            verifier,
            state,
        });

        await magic.utils.storage.setItem(OAUTH_REDIRECT_METADATA_KEY, storedData);

        // Formulate the initial redirect query to Magic's OAuth hub.
        // Required fields:
        //   - `magic_api_key`
        //   - `magic_challenge`
        //   - `state`
        //   - `redirect_uri`
        //   - `platform`

        const query = [
            `magic_api_key=${encodeURIComponent(magic.sdk.apiKey)}`,
            `magic_challenge=${encodeURIComponent(challenge)}`,
            `state=${encodeURIComponent(state)}`,
            `platform=${encodeURIComponent('web')}`,
            scope && `scope=${encodeURIComponent(scope.join(' '))}`,
            redirectURI && `redirect_uri=${encodeURIComponent(redirectURI)}`,
            loginHint && `login_hint=${encodeURIComponent(loginHint)}`,
        ].reduce((prev, next) => (next ? `${prev}&${next}` : prev));

        return {
            query,
            provider,
            redirectURI,
        };
    }



    return (
        <React.Fragment>
            <section className='twt'>
                <div className='twt-container'>
                    <Twtheader active={1}></Twtheader>
                    <div className='twt-container-body'>
                        <div className='twt-log'>
                            <div className='twt-log-title'>
                                Login with twitter, to verify your account
                            </div>
                            <div className='twt-log-subtitle'>
                                First of all, let’s connect your twitter account to verify your identity.
                            </div>
                            <div className='twt-log-button'>
                                <Button
                                    type="button"
                                    color="primary"
                                    id="btn-send"
                                    className=" theme-button-third"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`Connect twitter`)
                                        handleLogin()
                                    }}
                                >
                                    {props.t(`Connect twitter`)}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment >
    )

}
export default withRouter(withNamespaces()(Index))
