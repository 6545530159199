import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import {
    Button, Col, Container, FormGroup,
    Input, Label, Row
} from "reactstrap"
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { useParams } from 'react-router-dom'
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';
import TabHeaderMenu from './components/tab-header-menu'
import Modal from "../../components/Global/modals"

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { segmentTrack } from '../../helpers/Segment'
import image_tempo from '../../assets/images/ImageBox.png'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { toastrSuccess } from '../../helpers/Toastr'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext
    const { collection_id } = useParams();

    const disableAll = false;
    const [showModal, setShowModal] = useState(false)
    const minting_site = '35Kj6xQzF1YHaGongfz8bSbHBuwCusGfe'
    const [name, setName] = useState('bc1p4djjmu70t6p4a288t25q8evq7g303fal3tej2dz39hm78r7d60ys5f443f')
    const [loadPayment, setLoadPayment] = useState(false)
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }

    const connectWallet = () => {
        console.log('connectWallet');
    }


    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Payment on Chain</p>
            <p className='deploy-modal-subtitle'> You’re about to mint your twitter pfp on the Polygon blockchain,
                now, let’s confirm the transaction, but don’t worry, we’ll pay for the gas.</p>
            <div className='deploy-modal-token-container'>
                <img src="https://s3-alpha-sig.figma.com/img/e479/9aef/569fa786e50cf5ba3eb73d3c45d54fb3?Expires=1690761600&Signature=qsyYiJIZVj~DgeoOBp7qEbrNcE~arxmXwOxnlqvDefbZ~HRwzuX1wEw6BGdFq2IhSVSFNrXrft57RzlERpF1KNTvNmRS4n7MQ83yq5mv2EJiq6S2QzWSzS3NuvyO2-s7jBNSRS6o-ZiKnQtsqx67a4v1OXmiZFl55Pa9-AAqjgVY2mFrZvQYYLgE9ey2Abl2P-i9wgvSY7GsY~Avyk~IVE0RcATuwM7ByY2I5gkBTZYzXx-GfXuPY6EZF1~ZSkCloFKnDmR138uQXcFIgTsT4-kTJcFpYVQaKUQyi6pKZRj2IYaKaAk7KVw3amlD925G82DvAZTTbpOkkXHmRsywVQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" />
            </div>


            <p className='deploy-modal-subtitle'>Copy address below:.</p>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Col sm={6}>
                    <FormGroup>
                        {/* <Label className='input-label_monserrat'>
                            {props.t(`Minting site`)}
                        </Label> */}
                        <div className='collections-minting-control' >
                            <div className='collections-minting-control-input'>
                                <Input
                                    required
                                    value={minting_site}
                                    type="text"
                                    className="form-control"
                                    placeholder='Cryptolucha'
                                    id="subdomain"
                                    disabled
                                />
                            </div>
                            <div className='collections-minting-control-post'
                                onClick={() => {
                                    navigator.clipboard.writeText(minting_site)
                                    toastrSuccess('Copied')
                                }}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                                </span>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <span>
                Fee Rate: 29 sats/byte <br />
                Services Fee: 27194 sats<br />
                Total Amount: 0.00049139 BTC (49139 sats)
            </span>
        </div >
    </section >

    const footer = <div className='deploy-modal-footer'>
        <div>

        </div>
    </div>

    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={90} percentage={90}></ProgressHeader>
            {
                showModal && __renderPOView(12)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={4} active={3} ></TabHeaderMenu>
                        <div className='collections-settings-header-button '>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal(true)
                                }}
                            >
                                {props.t(`Submit and pay`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all ordinals'>
                        <section className='collections-ordinals'>

                            <div className='collections-ordinals-inscription'>
                                <p className='collections-ordinals-title'>
                                    Ordinal Recipient Address
                                </p>
                                <span className='collections-ordinals-subtitle'>
                                    Bitcoin address where the ordinal will be transferred. This should be an ordinals-compatible wallet. Learn more
                                </span>

                                <Row >
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Recipient Bitcoin Address`)}
                                            </Label>
                                            <Input
                                                required
                                                value={name}
                                                type="text"
                                                id='name'
                                                className="input-white-input circle"
                                                placeholder='Name'
                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (!value) {
                                                        document.getElementById('name').classList.add('input-error')
                                                    } else {
                                                        document.getElementById('name').classList.remove('input-error')
                                                    }
                                                    setName(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                        <span className='collections-ordinals-help-sub'>
                                            This is the address where we’ll send your ordinal. If we need to process a refund and no refund address is provided, we’ll send the funds to this address.                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Refund Address (Recommended)`)}
                                            </Label>
                                            <Input
                                                required
                                                value={name}
                                                type="text"
                                                id='name'
                                                className="input-white-input circle"
                                                placeholder='Name'
                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (!value) {
                                                        document.getElementById('name').classList.add('input-error')
                                                    } else {
                                                        document.getElementById('name').classList.remove('input-error')
                                                    }
                                                    setName(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                        <span className='collections-ordinals-help-sub'>
                                            Bitcoin address where a refund would be sent, if needed.
                                        </span>                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col sm={12}>
                                    <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                        <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                        <span>How to create a 1/1 (Single Edition)</span>
                                    </a>
                                </Col>
                            </Row>
                        </section>
                        <div className='container-video-all' style={{ flex: '0 1' }}>
                            <div className='container-video'>
                                <p>How to inscribe an ordinal</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                            </div>

                        </div>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))

