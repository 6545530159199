import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Container,
} from "reactstrap"

import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withNamespaces } from "react-i18next"
import { useParams, withRouter } from 'react-router-dom'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import { segmentTrack } from "../../helpers/Segment"
import MetadataTab from './components/Settings-tabs/Metadata'
import SettingsTab from './components/Settings-tabs/Settings'
import TabHeaderMenu from './components/tab-header-menu'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    let [activeTab, setActiveTab] = useState('1');
    let [activeForm, setActiveForm] = useState(0);
    let [step_number, setStepNumber] = useState(0);
    const { currentProject } = generalContext
    let [project_id, setProjectId] = useState([]);
    const { collection_id } = useParams();
    useEffect(() => {
        
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Settings`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])


    const _handleSubmit = (e) => {
        setActiveForm(activeForm + 1);
    }

    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={40} percentage={40}></ProgressHeader>

            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        {/* <section className='collections-settings-header-container'>
                            <div className='collections-settings-header-item' >
                                <span>1. Upload</span>
                            </div>
                            <div className='collections-settings-header-item active'>
                                <span>2. Settings</span>
                            </div>
                            <div className='collections-settings-header-item'>
                                <span>3. Preview</span>
                            </div>
                            <div className='collections-settings-header-item'>
                                <span>4. Deployment</span>
                            </div>
                        </section> */}
                        <TabHeaderMenu step_number={step_number} active={2} collection_id={collection_id}></TabHeaderMenu>

                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`Go to project review`)
                                    _handleSubmit()
                                }}

                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <div className='collections-settings'>
                        {/* <div className='collections-settings-header'>
                            <section className='collections-settings-header-container'>
                                <div className={classnames('collections-settings-header-item', { active: activeTab === '1' })} onClick={() => activeTab !== '1' && setActiveTab('1')}>
                                    <span>1. Settings</span>
                                </div>
                                <div className={classnames('collections-settings-header-item disabled', { active: activeTab === '2' })}>
                                    <span>2. Metadata</span>
                                </div>
                                {/* <div className={classnames('collections-settings-header-item', { active: activeTab === '3' })} onClick={() => activeTab !== '3' && setActiveTab('3')}>
                                <span>Minting site</span>
                            </div> 
                            </section>
                            <div className='collections-settings-header-button'>
                                <Button
                                    type="button"
                                    color="secundary"
                                    className="theme-button-secundary"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        // if (!_validateStep()) {
                                        //     toastr.error(props.t(`Don't forget to enter your name`))
                                        //     return
                                        // }
                                        _handleSubmit()
                                    }}
                                >
                                    {props.t(`Preview`)}
                                </Button>
                            </div>
                        </div> */}
                        <div className='collections-settings-body'>
                            {
                                activeTab === '1' &&
                                <SettingsTab project_id={project_id} collection_id={collection_id} activeForm={activeForm}
                                    step_number={step_number} setStepNumber={setStepNumber} />
                            }
                            {
                                activeTab === '2' &&
                                <MetadataTab project_id={project_id} collection_id={collection_id} activeForm={activeForm}
                                    step_number={step_number} setStepNumber={setStepNumber}
                                />
                            }
                            {/* {
                                activeTab === '3' &&
                                <MintingTab project_id={project_id} collection_id={collection_id} />
                            } */}
                        </div>
                    </div>
                    {/* <Nav tabs className='collections-settings-tab-nav'>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { setActiveTab('1'); }}
                            >
                                Settings
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                            >
                                Metadata
                            </NavLink>
                        </NavItem>
                        <div className='collections-settings-tab-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    // if (!_validateStep()) {
                                    //     toastr.error(props.t(`Don't forget to enter your name`))
                                    //     return
                                    // }
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Preview`)}
                            </Button>
                        </div>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <SettingsTab project_id={project_id} activeForm={activeForm} />
                        </TabPane>
                        <TabPane tabId="2">
                            <MetadataTab project_id={project_id} activeForm={activeForm} />
                        </TabPane>
                    </TabContent> */}
                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Create))
