import { faCheckCircle, faChevronDown, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import {
    Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, FormGroup,
    Input, Label, Row
} from "reactstrap"
import image_box from '../../assets/images/ImageBox.png'
import YoutubeEmbed from '../../components/Global/EmbedYoutube'
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"

import Dropzone from "./components/dropzone"
import TabHeaderMenu from './components/tab-header-menu'
import { toastClear, toastrError, toastrInfo, toastrInfoPermanent, toastrSuccess } from "../../helpers/Toastr"
import { ReactComponent as PlayCircle } from '../../assets/images/material-symbols_play-circle.svg';
import { fileReader } from "../../helpers/constants"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const [assets, setAssets] = useState(false);
    let [dimensionError, setDimensionError] = useState(false);

    const [collectionName, setCollectionName] = useState('')
    const [blockChain, setBlockChain] = useState(1)
    const [image_id, setImageId] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);
    let [properties, setProperties] = useState([]);
    var hiddenFileInput = React.useRef(null);


    let {
        loading,
        setLoading,
        loading_files,
        setLoadingFiles,
        total_files,
        setTotalFiles,
        current_files,
        setCurrentFiles,
        total_size,
        setTotalSize,
        current_size,
        setCurrentSize,
        currentProject,
        setBreadcrumb,
    } = generalContext
    let [project_id, setProjectId] = useState(undefined);
    // togle 
    const [menu, setMenu] = useState(false)
    const [uploadingFiles, setUploadingFiles] = useState(false)

    const toggle = () => { setMenu(!menu) }
    const img_style = {
        height: '250px',
        width: '250px',
        cursor: 'pointer'
    }

    useEffect(() => {
        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: props.t(`Collections`),
            parentLink: `/collections`,
            item: props.t(`Create generative collection`)
        })
        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // setLoadingFiles(true)
    }, [currentProject])

    useEffect(() => {
        DataService.getNetworkConfig().then(response => {
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            setSelectedBlockchain(arr[0])
        })
    }, [])
    let handleAssetFilled = (assets) => {

        setAssets(assets);
    }

    const _validateStep = () => {
        if (collectionName === "") {
            toastrError(props.t(`Don't forget to enter the collection name`), 'Error')
            return false
        }
        if (blockChain === '') {
            toastrError(props.t(`Don't forget to enter the blockchain network`), 'Error')
            return false
        }
        if (image_id === '') {
            toastrError(props.t(`Don't forget to enter the collection image`), 'Error')
            return false
        }
        if (!assets) {
            toastrError(props.t(`Don't forget to upload correctly yours assets`), 'Error')
            return false
        }
        if (dimensionError) {
            toastrError(props.t(`Don't forget, all the images need to be of the same size of width and height`), 'Error')
            return false
        }
        return true
    }

    const _handleExport = async () => {
        DataService.postCollection({
            name: collectionName,
            network_id: blockChain,
            image_id: image_id || undefined,
            project_id: project_id,
            is_generative: true,
            step_number: 1,
        }).then(async response => {
            console.log('properties preate to upload', properties);
            if (response.data && response.data.id) {
                // aqui hacer el put de archivos de todos los traits
                const body = {
                    data: properties
                }
                DataService.putCollectionTraitBulkById(response.data.id, body).then(response_bulk => {
                    if (response_bulk) {
                        props.history.push(`/collections/generative/settings/` + response.data.id)
                        setLoading(false)
                    }
                })
            }
        })
    }

    const countPropertyVariationsImage = (variations) => {
        const totalFiles = variations.reduce((acc, variation) => acc + (!!variation.image ? 1 : 0), 0);
        return totalFiles
    }
    const countPropertyVariationsImageSize = (variations) => {
        const totalFilesSize = variations.reduce((acc, variation) => acc + (!!variation.image.size ? variation.image.size : 0), 0);
        return totalFilesSize
    }

    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        try {
            console.log('properties before upload', properties);
            const totalFiles = properties.reduce((acc, property) => acc + countPropertyVariationsImage(property.variations), 0);
            const totalFilesSize = properties.reduce((acc, property) => acc + countPropertyVariationsImageSize(property.variations), 0);
            setTotalFiles(totalFiles)
            setTotalSize(totalFilesSize)
            setLoadingFiles(true)
            const promises = [];
            for (const property of properties) {
                for (const variation of property.variations) {
                    current_size = current_size + variation.image.size
                    const file_key = await uploadFileToServer(variation.image).then(response => {
                        const key = response;
                        variation['image'] = fileReader + key;
                    })
                    current_files = current_files + 1;
                    setCurrentFiles(current_files)
                    console.log('current_files', current_files);
                    setCurrentSize(current_size)
                    console.log('current_size', current_size);
                    promises.push(file_key)
                }
            }
            console.log('properties after upload', properties);
            console.log('I will wait')
            resetFileLoader()
            setLoading(true)
            // Export to Blob
            //
            _handleExport()


        } catch (error) {
            toastrError(`Failed to add ${collectionName}: ${error}`, 'Error');
        }
    }
    const resetFileLoader = () => {
        setLoadingFiles(false)
        setTotalFiles(0)
        setTotalSize(0)
        setCurrentFiles(0)
        setCurrentSize(0)
    }

    function uploadFileToServer(file) {
        const promise = new Promise(function (resolve, reject) {
            console.log('file', file);
            const name = file.name;
            const name_split = name.split('.')
            let ext = name_split[name_split.length - 1]
            if (ext === 'jpg') {
                ext = 'jpeg'
            }
            DataService.getUploadkey(ext.toLowerCase()).then(async response => {
                console.log('Uploading to: ', response.data.uploadURL)
                fetch(response.data.uploadURL, {
                    method: 'PUT',
                    body: file
                }).then(result => {
                    console.log('Result: ', result)
                    resolve(response.data.Key)

                }).catch(err => {
                    reject(err)
                })

            }).catch(err => {
                console.log('err', err);
                reject(err)
            })
        })
        return promise
    }



    const _handleFileUpload = (event) => {
        event.preventDefault();
        let files = event?.target.files
        setLoading(true)
        fileUpload(files[0]).then((response) => {

            setImageId(response.data.id)
            setImageTempo(URL.createObjectURL(files[0]));
            setLoading(false)

        }).catch(error => {

            setLoading(false)

        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)

        return DataService.uploadImage(formData)
    }

    const clickFileInput = () => {
        const upload_img = document.getElementById('upload_img');
        if (upload_img) {
            upload_img.click()
            // toastrInfo('clicking input upload_img')

        } else {
            toastrError('missing html input')
        }
    }

    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={10} percentage={10}></ProgressHeader>
            <label htmlFor="upload_img" style={{ cursor: 'pointer', display: 'none' }}>
                Click to upload an image
            </label>
            <input type="file"
                id="upload_img"
                style={{ position: 'absolute', left: '-9999px' }}
                onChange={event => {
                    segmentTrack(`Upload project avatar`)
                    _handleFileUpload(event)
                }}
            />
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={0} active={1} ></TabHeaderMenu>
                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                disabled={loading}
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`go to project general settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <section className='container-create-all'>
                        <section className='container-create'>
                            <section className='collections-create-form'>
                                <div className='collections-create-form-row'>
                                    <div className='collections-create-form-row-img'>
                                        <p>Collection image</p>
                                        <div className='card-picture' style={img_style} onClick={() => clickFileInput()}>
                                            <img className='card-picture-img' style={{ width: '100%', cursor: 'pointer' }} alt="item" src={image_tempo ? image_tempo : image_box} />

                                        </div>
                                        <div className='collections-create-form-row-ico' style={{ cursor: 'pointer' }} onClick={() => clickFileInput()}>
                                            <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>
                                        </div>
                                    </div>
                                    <div className='collections-create-form-row-content text-align-left'>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Collection name`)}*
                                                    </Label>

                                                    <Input
                                                        required
                                                        value={collectionName}
                                                        type="text"
                                                        id='collectionName'
                                                        className="input-white-input circle"
                                                        placeholder='collection'
                                                        onChange={event => {
                                                            const { value } = event.target

                                                            if (!value) {
                                                                document.getElementById('collectionName').classList.add('input-error')
                                                            } else {
                                                                document.getElementById('collectionName').classList.remove('input-error')
                                                            }
                                                            setCollectionName(event.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Blockchain network`)}
                                                    </Label>



                                                    <Dropdown
                                                        style={{ width: '100%' }}
                                                        isOpen={menu}
                                                        toggle={toggle}
                                                        className="d-inline-block"
                                                    >
                                                        <DropdownToggle
                                                            className="input-drop-white"
                                                            id="select-block-dropdown"
                                                            tag="button"
                                                        >
                                                            <span className="header-item-project-name">
                                                                {selectedBlockChain && (
                                                                    <span>{selectedBlockChain.name}</span>
                                                                )}
                                                            </span>
                                                            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                        </DropdownToggle>
                                                        <DropdownMenu right className='input-drop-white-item'>
                                                            {filteredBlockchains.map(
                                                                (item, key) => (
                                                                    <div key={key} className="dropdown-item " onClick={() => {
                                                                        setSelectedBlockchain(item)
                                                                        setBlockChain(item.neefter_id)
                                                                        segmentTrack(`network selected`)
                                                                        setMenu(false)
                                                                    }}>
                                                                        <span>{item.name}</span>
                                                                    </div>
                                                                ))}
                                                        </DropdownMenu>
                                                    </Dropdown>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label className='input-label_monserrat'>
                                                        {props.t(`Collection avatar`)}
                                                        <span className='input-label-mini'>jpg or png. This will be the thumbnail of your collection.</span>
                                                    </Label>
                                                    <div className='collections-minting-control' style={{ cursor: 'pointer' }} >
                                                        <div className='collections-minting-control-pre' style={{ cursor: 'pointer' }} onClick={() => clickFileInput()}>
                                                            <span>Choose File</span>
                                                        </div>
                                                        <div className='collections-minting-control-input' style={{ cursor: 'pointer' }} onClick={() => clickFileInput()}>
                                                            <Input
                                                                required
                                                                value={image_tempo}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='No file choosen'
                                                                disabled
                                                                id="logotype"
                                                            />
                                                        </div>
                                                        <div className='collections-minting-control-icon'>
                                                            {
                                                                image_tempo &&
                                                                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                            }
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                                <div className='collections-create-form-upload'>
                                    <p className='collections-create-form-upload-title'>Upload your assets</p>
                                    <div className='collections-create-form-upload-container'>
                                        <div className='collections-create-form-upload-container-dropzone'>
                                            <Dropzone accept={'image/*,.json,.csv'} onAssetFull={handleAssetFilled} webkitdirectory directory multiple="false"
                                                dimensionError={dimensionError} setDimensionError={setDimensionError}
                                                properties={properties} setProperties={setProperties}

                                            />
                                        </div>
                                        <div className='collections-create-form-upload-container-list'>
                                            <ul>
                                                <li>
                                                    Files must contain one .csv or .json file with metadata. - <span>Download example.csv.</span>  <span>Download example.json.</span>
                                                </li>
                                                <li>
                                                    The csv must have a name column, which defines the name of the NFT.
                                                </li>
                                                <li>
                                                    Asset names must be sequential 0,1,2,3...n.[extension]. It doesn't matter at what number you begin. (Example: 131.png, 132.png).
                                                </li>
                                                <li>
                                                    Make sure to drag and drop the CSV/JSON and the images at the same time.
                                                </li>
                                                <li>
                                                    <b>IMPORTANT:The assets for the layers of the collection have a size limit of 2 MB</b>
                                                </li>
                                            </ul>
                                            <Row>
                                                <Col sm={12}>
                                                    <a href='https://www.youtube.com/embed/fClzEWoX_UU' target="_blank" className='collections-ticketing-video'>
                                                        <PlayCircle width={'30px'} height={'30px'} className='collections-ticketing-video-icon' />
                                                        <span>How to create your assets</span>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>

                                <Row>
                                    <Col>
                                        <Button
                                            type="button"
                                            color="secundary"
                                            className="theme-button-secundary mobile"
                                            disabled={loading}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                segmentTrack(`go to project general settings`)
                                                _handleSubmit()
                                            }}
                                        >
                                            {props.t(`Next step`)}
                                        </Button>
                                    </Col>
                                </Row>

                            </section>
                        </section>
                        <div className='container-video-all'>
                            <div className='container-video'>
                                <p>How to create your assets</p>
                                <YoutubeEmbed embedId="fClzEWoX_UU"></YoutubeEmbed>
                            </div>
                            <div className='container-video'>
                                <p>Cómo lanzar tu colección de NFT sin código</p>
                                <YoutubeEmbed embedId="YhEdU-ypBkc"></YoutubeEmbed>
                            </div>
                        </div>
                    </section>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
