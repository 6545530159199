import React, { useEffect } from 'react'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { logoutUserSuccess } from "../../store/actions"

const Logout = (props) => {
    useEffect(() => {
        props.logoutUserSuccess()
        props.history.push('/login')

        // eslint-disable-next-line
    }, [])

    return (<React.Fragment></React.Fragment>)
}

const mapStatetoProps = () => {
    return {}
}

export default withRouter(
    connect(mapStatetoProps, { logoutUserSuccess })(Logout)
)