import React, { useContext, useEffect, useState } from 'react';

import { useLiveQuery } from "dexie-react-hooks";
import { withNamespaces } from "react-i18next";
import { withRouter } from 'react-router-dom';
import {
    Button
} from "reactstrap";
import { GeneralContext } from '../../../../../context/';
import { toastrError } from "../../../../../helpers/Toastr";
import { fileReader } from "../../../../../helpers/constants";

const Preview = (props) => {
    const generalContext = useContext(GeneralContext)

    let [item, setItem] = useState(undefined)
    let [firstLoad, setFirstLoad] = useState(false)
    const { setLoading } = generalContext
    const { collections_properties } = props
    // orm
    let [totalHash, setTotalHash] = useState([]);


    useEffect(() => {
        if (collections_properties) {
            _setShuffleItem()
            setLoading(false);
            setFirstLoad(true)
        }

    }, [collections_properties])


    const _setShuffleItem = () => {
        if (!collections_properties) return;
        if (collections_properties.length > 0) {

            const tokens = 1;
            let assets = [];
            let properties = collections_properties;
            const allPosibleCombinations = properties.reduce((acc, property) => acc + ((acc > 0 ? acc : 1) * property.variations.length) - acc, 0);

            if (allPosibleCombinations < tokens) {
                toastrError('cant get all posible combinations', 'Error')
            }
            let i = 0;
            while (assets.length < tokens && totalHash.length < allPosibleCombinations) {
                const asset = {
                    name: i,
                    layers: []
                }
                let minihash = '';
                properties.forEach((property, index) => {
                    const _trait = getRandomTrait(property.variations, property.name)
                    asset.layers.push({ layer: property.name, trait: _trait })
                    minihash = minihash + _trait.name
                });
                console.log('asset', asset);
                // Si existe el hash del asset, skip , --i
                if (totalHash.findIndex(hash => hash === totalHash) < 0) {
                    assets.push(asset);
                    i = i + 1;
                    totalHash.push(minihash)
                    setTotalHash([...totalHash])
                    setItem(asset)
                }

            }
        }
    }
    function getRandomTrait(variations, property_name) {
        let universe = [];
        variations.forEach((variation, index) => {
            for (let i = 0; i < variation.rarity; i++) {
                universe.push(variation);
            }
        });
        const randint = getRandomInt(universe.length)
        const file = (universe[randint]) ? universe[randint].image : undefined
        const trait = {
            ...universe[randint],
            avatar: file

        }
        return trait;
    }


    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }


    return (<React.Fragment>
        <div className='collections-layers-subtitle'>
            <p >
                Preview
            </p>
        </div>
        <div>
            {item &&
                <div>
                    <div className='card-example'>

                        {
                            item.layers.map(
                                (layer, key) => (
                                    layer.trait.avatar &&
                                    <img style={{ width: '100%', 'zIndex': key, 'position': 'absolute', height: '100%' }} alt={item.name} src={layer.trait.avatar} key={'avatar' + key} />
                                )
                            )
                        }

                    </div>
                </div>
            }

        </div>
        <div>
            <Button
                style={{ 'width': '200px' }}
                type="button"
                color="primary"
                className="theme-button-upload"
                onClick={(e) => {
                    e.preventDefault()
                    _setShuffleItem()
                }}
            >
                {props.t(`Shuffle Preview`)}

            </Button>
        </div>
    </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Preview))
