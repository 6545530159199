export const Paths = {
    authWhoami: '/auth/whoami',
    user: '/user',
    authReset: '/auth/reset',
    authLogin: '/auth/login',
    authRecover: '/auth/recover',
    project: '/project',
    collection: '/collection',
    publicCollection: '/public/collection',
    magicLogin: '/magic/login',
    collectionSubdomain: '/collection/subdomain',
    payout: '/payout',
    collection1_1: '/collection/1_1',
    claim: '/claim',
    claimTwitter: '/claim/twitter/',
    collectionRedeploy: '/collection/redeploy',
    whitelist: '/whitelist',
    airdrop: '/airdrop',
    collectionQuick: '/collection/quick',
    network_config: '/network_config',
    collectionTicketing: '/collection/ticketing',
    organization: '/organization',
    organizationInvite: '/organization/invite',
    organizationInviteAccept: 'organization/invite/accept',
    organizationDelete: '/organization/delete',
    collectionTransaction: '/collection/transaction_list',
    btcInscribeCost: '/btc/inscribe/cost',
    btcInscribeCostRates: '/btc/inscribe/rates',
    btcInscribeCostPayment: '/btc/inscribe/payment',
    btcInscribe: '/btc/inscribe',

}
