import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Button, Row, Col
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import image_box from '../../../assets/images/ImageBox.png'
import { GeneralContext } from '../../../context'
import { dateSeparatedFormater, dateHourFormater, dateFromFormat } from "../../../helpers/utils"

import DataService from '../../../helpers/DataService'
import { toastrError, toastrInfo, toastrSuccess } from '../../../helpers/Toastr'

import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js'
import { loadState } from "../../../store/localStorage"
import Modal from "../../../components/Global/modals"
import { segmentTrack } from "../../../helpers/Segment"
import { connect } from "react-redux"
import { setUserTicket } from '../../../store/actions'

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { activeForm } = props;

    let [selectedTicket, setSelectedTicket] = useState(undefined)
    const [cache_zip, setCacheZip] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const [sign, setSign] = useState('')
    const [description, setDescription] = useState('')
    const [logo, setLogo] = useState('')
    const [event_title, setEventTitle] = useState('')
    const [event_organizer, setEventOrganizer] = useState('')
    const [event_venue, setEventVenue] = useState('')
    const [event_venue_address, setEventVenueAddress] = useState('')
    const [event_start_date, setEventStartDate] = useState('')
    const [event_end_date, setEventEndDate] = useState('')
    const [event_description, setEventDescription] = useState('')
    const [event_start_date_minuter, setEventStartDateMinuter] = useState('')
    const [event_end_date_minuter, setEventEndDateMinuter] = useState('')
    const [collection_id, setCollectionId] = useState('')
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);
    const [event_price, setEventPrice] = useState(0)
    const [total_tokens, setTotalTokens] = useState(0)
    const [subdomain, setSubdomain] = useState('')


    // Payment 
    const [loadPayment, setLoadPayment] = useState(false)
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);
    const neefter_cash = 'C123NqmCoXtEUt3sbJrHtYw3853b6y5tXttTLGHGvB6a'
    const neefter_prov = 'FZSbRL64bBk42QHCea44pdvxAGv67ofk9FAh8ZgBKJSE';
    const lamport = 1_000_000_000
    const NEEFTER_SOLANA_NODE = 'https://orbital-fittest-bird.solana-mainnet.quiknode.pro/bc45b120094c7d49e064f515fafd083b0e248f70/'
    let [counter, setCounter] = useState(0);
    const [finalizedSignature, setFinalizedSignature] = useState(undefined)
    const [showModal, setShowModal] = useState(false)
    const [enableButton, setEnableButton] = useState(true)
    const [showErrorTransaction, setShowErrorTransaction] = useState(false)
    const [button_loader, setButtonLoader] = useState(false)
    const [blockchainNetwork, setBlockchainNetwork] = useState(1)

    useEffect(() => {
        const provider = getProvider();
        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, []);


    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            // @ts-ignore
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
        // if ('phantom' in window) {
        //     // @ts-ignore
        //     const provider = window.phantom?.solana as any;

        //     if (provider?.isPhantom) {
        //         return provider as PhantomProvider;
        //     }
        // }
    };



    /**
    * @description prompts user to connect wallet if it exists
    */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;

        if (solana) {
            try {
                const response = await solana.connect();

                setWalletKey(response.publicKey.toString());
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };


    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }

    useEffect(() => {
        if (loadState()?.Auth?.ticket) {
            selectedTicket = loadState()?.Auth?.ticket;
            setSelectedTicket(loadState()?.Auth?.ticket)
        }
    }, [])

    function getNetworkConfig() {
        return DataService.getNetworkConfig().then(response => {
            console.log('getNetworkConfig', response.data);
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            setSelectedBlockchain(arr[0])
            setBlockchainNetwork(arr[0].neefter_id)
            setEventPrice(arr[0].prices.ticket)
        })
    }

    useEffect(() => {
        if (!activeForm) return;
        setShowModal(true)
    }, [activeForm])

    useEffect(() => {
        if (!selectedTicket) return;
        console.log('selectedTicket', selectedTicket);
        const event_start_date = selectedTicket.event_start_date ? selectedTicket.event_start_date.replaceAll('/', '-') : undefined
        const event_end_date = selectedTicket.event_end_date ? selectedTicket.event_end_date.replaceAll('/', '-') : undefined
        const end_date = dateFromFormat(event_end_date)
        const start_date = dateFromFormat(event_start_date)
        const endHours = end_date.getHours()
        const endMinutes = end_date.getMinutes()
        const startHours = start_date.getHours()
        const startMinutes = start_date.getMinutes()
        const minute_end_date = `${endHours}:${endMinutes}`
        const minute_start_date = `${startHours}:${startMinutes}`

        setDescription(selectedTicket.description)
        setLogo(selectedTicket.logo)
        setEventTitle(selectedTicket.event_title)
        setEventOrganizer(selectedTicket.event_organizer)
        setEventVenue(selectedTicket.event_venue)
        setEventVenueAddress(selectedTicket.event_venue_address)
        console.log('start_date', start_date);
        setEventStartDate(dateSeparatedFormater(start_date))
        setEventEndDate(dateSeparatedFormater(end_date))
        setEventDescription(selectedTicket.event_description)
        setEventStartDateMinuter(minute_start_date)
        setEventEndDateMinuter(minute_end_date)
        setCollectionId(selectedTicket.collection_id)
        setTotalTokens(selectedTicket.total_tokens)
        setImageTempo(selectedTicket.image_tempo)
        setSubdomain(selectedTicket.subdomain)
        getNetworkConfig()

    }, [selectedTicket])

    useEffect(() => {
        if (!total_tokens) return;
        getNetworkConfig()
    }, [total_tokens])

    const img_style = {
        height: '265px',
        width: '250px',
        cursor: 'pointer'
    }


    const _handleBuyCollection = () => {
        buyCollection()
    }

    /**
    * Creates an arbitrary transfer transaction
    * @param   {String}      publicKey  a public key
    * @param   {Connection}  connection an RPC connection
    * @returns {Transaction}            a transaction
    */
    const createTransferTransaction = async (toPublicKey, fromPublicKey, connection, lamports) => {
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: fromPublicKey,
                toPubkey: toPublicKey,
                lamports: lamports,
            })
        );


        transaction.feePayer = fromPublicKey;

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;


        return transaction;
    };

    let buyCollection = async () => {

        const provider = getProvider();

        setEnableButton(true);
        setLoadPayment(true);
        setShowErrorTransaction(false);
        const network = NEEFTER_SOLANA_NODE;


        const connection = new Connection(network);

        // setLoading(true);
        try {
            let transaction = Transaction;
            const to_public_key = new PublicKey(neefter_cash);
            const from_public_key = new PublicKey(walletKey);
            const lamp = lamport * event_price;

            const txn = await createTransferTransaction(to_public_key, from_public_key, connection, lamp)


            const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
            try {
                return provider?.signAndSendTransaction(txn)
                    .then(async response => {
                        const signature = response?.signature
                        let getSignatureStatus;
                        let conditionExit = false;
                        while (!conditionExit && counter < 8) {
                            getSignatureStatus = await connection.getSignatureStatus(signature);
                            await ndelay(5000)
                            conditionExit = (!!getSignatureStatus.value && getSignatureStatus.value.confirmationStatus === 'finalized');
                            counter = counter + 1;
                        }
                        setFinalizedSignature(signature);
                        // const signature_status = await connection.getSignatureStatus(signature);

                        // openModal('MAIL_FORM')

                        // const data = {
                        //     signature: response?.signature,
                        //     email: '',
                        // }
                        // setpostBftMintByNftIPubKeyData({ id, wallet: walletState.data.Wallet?.wallet, data, clickNextStep, connection })
                        // setStepperView(true);
                        // return postBftMintByNftIPubKey(id, walletState.data.Wallet?.wallet, data, clickNextStep)

                    })
                    .catch(err => {
                        setShowErrorTransaction(true);
                        setLoading(false);
                        setEnableButton(false);
                        setLoadPayment(false);

                    })
            } catch (err) {
                setShowErrorTransaction(true);
                setLoading(false);
                setEnableButton(false);
                setLoadPayment(false);

            }
        } catch (err) {
            setShowErrorTransaction(true);
            setLoading(false);
            setEnableButton(false);

        }
    }

    useEffect(() => {
        if (finalizedSignature) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setSign(finalizedSignature)
            setLoading(false)

        }
    }, [finalizedSignature])
    useEffect(() => {
        if (sign) {
            _handleDeploy()
        }
    }, [sign])

    const _handleDeploy = (e) => {
        // if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService();
    }

    const sendFormToService = () => {
        const _event_starts = dateHourFormater(event_start_date, event_start_date_minuter)
        const _event_ends = dateHourFormater(event_end_date, event_end_date_minuter)
        const body = {
            description: description,
            logo: logo,
            event_title: event_title,
            event_organizer: event_organizer,
            event_venue: event_venue,
            event_venue_address: event_venue_address,
            event_start_date: _event_starts,
            event_end_date: _event_ends,
            event_description: event_description,
            sign: sign,
            collection_id: collection_id,
            subdomain: subdomain
        }
        return DataService.postCollectionTicketing(body).then(response => {
            setButtonLoader(false);
            props.setUserTicket(undefined)
            props.history.push(`/collections/ticket/to-the-moon?ticket=true`)
            setLoadPayment(false);
        })
    }


    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You’re about to publish an Event </p>
            <p className='deploy-modal-count'> {event_title} </p>
            <div className='deploy-modal-token-container'>
                <img src={image_tempo} />
            </div>

            {
                (blockchainNetwork === 1 || blockchainNetwork === '1') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {event_price} </b> SOL  </span> to deploy your collectible.</p>
            }

        </div>
    </section>

    const footer = <div className='deploy-modal-footer'>

        {
            (blockchainNetwork === 1 || blockchainNetwork === '1') && (
                <div>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className=" theme-button-secundary"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {provider && !!walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="custom-row theme-button-secundary"
                            disabled={loadPayment}
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`pay deploy`)
                                _handleBuyCollection()
                            }}
                        >
                            {props.t(`Pay ${event_price} SOL and deploy`)}
                            {loadPayment &&
                                <div className='min-loader'>
                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                        <div></div><div><div></div></div>
                                    </div></div>
                                </div>
                            }

                        </Button>
                    )}
                    {!provider && (
                        <p>
                            No provider found. Install{" "}
                            <a href="https://phantom.app/">Phantom Browser extension</a>
                        </p>
                    )}
                </div>
            )
        }

    </div>


    return (
        <React.Fragment>
            {
                showModal && __renderPOView(12)
            }
            <div className='collections-ticketing review'>
                <div className='collections-settings text-align-left'>
                    <section className='container-settings-tab'>
                        <section className='container-settings-tab-form'>
                            <div className='collections-ticketing-review'>
                                <div className='collections-ticketing-review-info'>
                                    <CardTitle className='collections-settings-tab-title'>{props.t(`Basic information`)}</CardTitle>
                                    <div className='collections-ticketing-review-info-body'>
                                        <div className='collections-ticketing-review-info-column'>
                                            <div className='collections-ticketing-review-info-img'>
                                                <p>Avatar preview</p>
                                                <div className='card-picture' style={img_style} onClick={() => document.getElementById('upload_img')?.click()}>
                                                    <img className='card-picture-img' style={{ width: '100%' }} alt="item" src={image_tempo || image_box} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='collections-ticketing-review-info-column'>
                                            <div className='collections-ticketing-review-info-item'>
                                                <p className='title'>Event title</p>
                                                <p className='subtitle'>{event_title}</p>
                                            </div>
                                            <div className='collections-ticketing-review-info-item'>
                                                <p className='title'>Organizer</p>
                                                <p className='subtitle'>{event_organizer}</p>
                                            </div>
                                            <div className='collections-ticketing-review-info-item'>
                                                <p className='title'>Venue</p>
                                                <p className='subtitle'>{event_venue}</p>
                                            </div>
                                            <div className='collections-ticketing-review-info-item'>
                                                <p className='title'>Venue's address</p>
                                                <p className='subtitle'>{event_venue_address}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CardTitle className='collections-settings-tab-title'>{props.t(`Date and time`)}</CardTitle>
                                <div className='collections-ticketing-review-row'>
                                    <div className='collections-ticketing-review-row-item'>
                                        <p className='title'>Event starts*</p>
                                        <p className='subtitle'>{event_start_date}</p>
                                    </div>
                                    <div className='collections-ticketing-review-row-item'>
                                        <p className='title'>Starts time</p>
                                        <p className='subtitle'>{event_start_date_minuter}</p>
                                    </div>
                                </div>
                                <div className='collections-ticketing-review-row'>
                                    <div className='collections-ticketing-review-row-item'>
                                        <p className='title'>Event Ends*</p>
                                        <p className='subtitle'>{event_end_date}</p>
                                    </div>
                                    <div className='collections-ticketing-review-row-item'>
                                        <p className='title'>End time</p>
                                        <p className='subtitle'>{event_end_date_minuter}</p>
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        <Button
                                            type="button"
                                            color="secundary"
                                            className="theme-button-update collections-ticketing-submit"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setShowModal(true)
                                            }}
                                        >
                                            {props.t(`Publish`)}
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        </section>
                    </section>
                </div>
            </div>

        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        project: state.Data?.project,
        ticket: state.Auth?.ticket

    }
}


export default (connect(mapStatetoProps, {
    setUserTicket
}))(withRouter(withNamespaces()(Settings)))