import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    DropdownItem,
    UncontrolledCollapse,
    Collapse
} from "reactstrap"

import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import CheckboxContainer from './checkbox-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


const TogglerFilter = (props) => {
    let [collapse, setCollapse] = useState(false)

    let toggle = () => {
        setCollapse(!collapse);
    }

    const { item, key, index, changeCheckedOption } = props;


    let index_toggler = index;
    const onChangeCheckedOption = (key, item) => {
        changeCheckedOption(key, item);
    }
    return (
        <React.Fragment>

            <div className="collections-token-form-border">
                <div onClick={toggle} className="collections-token-form-border-container">
                    <p className="collections-token-form-border-container-title">{item.trait_name}</p>
                    <p className="collections-token-form-border-container-icon">
                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </p>
                </div>
                <Collapse isOpen={collapse}>
                    <CheckboxContainer type={item.trait_name} options={item.variations} key={'container' + index}
                        changeCheckedOption={onChangeCheckedOption}></CheckboxContainer>
                </Collapse>
            </div>

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(TogglerFilter))