import React, { useContext, useEffect, useState } from 'react'
import {
    CardTitle, Col,
    Form, FormGroup, Input, Label, Row
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import Table from '../components/Datatable'


import { toastrError } from "../../../helpers/Toastr"
import { faCloudArrowUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadState } from "../../../store/localStorage"

const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { setActiveTab } = props;




    return (
        <React.Fragment>
            <div className='container-deploy' style={{ width: '100%' }}>
                <Table setActiveTab={setActiveTab} />
            </div>
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))