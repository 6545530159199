import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { connect } from "react-redux"
import Profile from './Profile'
import ProjectSelecter from './ProjectSelecter'

const Sidebar = (props) => {
    return (
        <div id="sidebar" className="sidebar">
            <div className="site-menubar centered flex">
                <div className="site-menubar-header">
                    <Link to='/'>
                        <img alt="Neefter" src={"/assets/svg/Neefter_SVG_2.svg"} />
                    </Link>
                    <div className="site-menu-project">
                        <ProjectSelecter profile={props.Auth.profile}></ProjectSelecter>
                    </div>
                </div>
                <div className="navbar-profile">
                    <div className='navbar-profile-actions'>
                        {
                            props.Auth.profile &&
                            <Profile profile={props.Auth.profile} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { Auth } = state
    return { Auth }
}

export default connect(mapStatetoProps)(withRouter(withNamespaces()(Sidebar)))