import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardFooter, Container
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { GeneralContext } from '../../context/'
import { segmentTrack } from "../../helpers/Segment"
import DataService from '../../helpers/DataService'
import FilterContainer from './components/token-filters/filter-container'

import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import Modal from "../../components/Global/modals"
import ProgressHeader from '../../components/Global/Progress-header'
import { toastrError, toastrInfo } from "../../helpers/Toastr"
import TabHeaderMenu from './components/tab-header-menu'
import { fileReader } from "../../helpers/constants"

const Token = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);
    const { setPreviewLoader, importDb, exportDb } = generalContext
    const setLoading = setPreviewLoader;
    const { currentProject, setProject } = generalContext
    const { collection_id } = useParams();
    const [setting_name, setSettingName] = useState('')
    let [universe_files, setUniverseFiles] = useState(undefined);

    let [assets, setAssets] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [totalHash, setTotalHash] = useState([]);
    const [showModalCard, setShowModalCard] = useState()
    let [filters, setFilters] = useState([]);
    let [rules, setRules] = useState([]);

    const [collection_saved, setCollectionSaved] = useState('')
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    const [array_filters, setArrayFilters] = useState([])
    let [filteredData, setFilteredData] = useState([])
    let [filteredMixedData, setFilteredMixedData] = useState([])
    const limit = 50;
    let [actualPage, setActualPage] = useState(1)
    let [tokensFromSettings, setTokensFromSettings] = useState(0)

    let [filteredMixedDataLength, setFilteredMixedDataLength] = useState(0)

    let [filteredDataLength, setFilteredDataLength] = useState(0)
    let [submitLoading, setSubmitLoading] = useState(false)

    let [step_number, setStepNumber] = useState(0);
    let [generateAllLoader, setGenerateAllLoader] = useState(false)
    let [cache_zip, setCacheZip] = useState()

    let [fetched, setFetched] = useState(false)

    let [legendary_assets, setLegendaryAssets] = useState([])
    let [array_properties, setArrayProperties] = useState([])
    let [trait_hash__contains, set_trait_hash__contains] = useState([])
    let [regenerate_needed, set_regenerate_needed] = useState(false)
    let [trait_hash__contains_param, set_trait_hash__contains_param] = useState('')




    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredDataLength < tokensFromSettings) {
                    setActualPage(++actualPage)
                    getCollectionNftListById()
                }
            }
        }

    }, [])
    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredDataLength < tokensFromSettings) {
                    setActualPage(++actualPage)
                    getCollectionNftListById()

                }
            } else {
            }
        }

    }, [filteredDataLength, filteredMixedDataLength])

    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Preview`)
        })
        setProjectId(currentProject.id)

        const doc = document.getElementById('content')
        if (doc) {
            doc.classList.add('no-max-width')
        }

    }, [currentProject])


    useEffect(() => {
        if (collection_id) {
            _getCollectionById()
        }
    }, [collection_id])

    const _getCollectionById = () => {
        setLoading(true)
        DataService.getCollectionById(collection_id).then(
            response => {
                if (response && response.data) {
                    const { step_number, name, total_tokens, is_regenerate_needed } = response.data;
                    setStepNumber(step_number)
                    setSettingName(name)
                    tokensFromSettings = total_tokens;
                    setTokensFromSettings(total_tokens)
                    regenerate_needed = is_regenerate_needed
                    set_regenerate_needed(is_regenerate_needed)
                    _checkCollectionProperties()
                }
            })
    }

    const _checkCollectionProperties = () => {
        DataService.getCollectionAllTraitDataById(collection_id).then(responnse => {
            const { data } = responnse.data;
            console.log('getCollectionAllTraitDataById', data);
            let mergedArray = []
            data.forEach(property => {
                mergedArray = [...mergedArray, ...property.variations]
            });
            console.log('mergedArray', mergedArray);
            array_properties = [...mergedArray];
            setArrayProperties([...mergedArray])
            checkNftList()
        })
    }




    const checkNftList = () => {
        const params = {
            offset: 0,
            limit: 50,
            order_by: 'is_generative.asc,name.asc'
        }
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                const { data, total_count } = response.data;
                console.log('tokensFromSettings', tokensFromSettings);
                console.log('regenerate_needed', regenerate_needed);
                if (total_count !== tokensFromSettings || regenerate_needed) {
                    _callGenerateTokens()
                } else {
                    checkFiltersNftList()
                    // set tokens
                    const filterData = data.map(item => {
                        if (item.is_generative) {
                            return {
                                ...item,
                                attributes_json: item.attributes_json.map(att => {
                                    const variation = array_properties.find(ap => ap.id === att.trait_variation_id);
                                    return {
                                        ...att,
                                        image: variation ? variation.image : undefined
                                    }
                                })
                            }
                        } else {
                            return {
                                ...item,
                            }
                        }

                    })
                    console.log('filterData', filterData);
                    filteredData = filterData;
                    setFilteredData(filteredData)

                }
            }
        })
    }

    const checkFiltersNftList = () => {
        DataService.getCollectionNftFiltersListById(collection_id).then(response => {
            console.log('getCollectionNftFiltersListById', response.data);
            const checkfilters = []
            response.data.forEach((filter, index) => {
                const findFilter = checkfilters.findIndex(fl => fl.trait_id === filter.trait_id)
                if (findFilter > -1) {
                    checkfilters[findFilter].variations.push({
                        ...filter,
                        checked: false
                    })
                } else {
                    checkfilters.push({
                        trait_id: filter.trait_id,
                        trait_name: filter.trait_name,
                        variations: [{
                            ...filter,
                            checked: false
                        }]
                    })
                }
            });
            console.log('checkfilters', checkfilters);
            setArrayFilters(checkfilters)
            setLoading(false)
        })
    }
    const getCollectionNftListById = (reset = false) => {
        setLoading(true)
        console.log('trait_hash__contains_param', trait_hash__contains);
        const params = {
            offset: (actualPage - 1) * limit,
            limit: 50,
            order_by: 'is_generative.asc,name.asc',
            trait_hash__contains: trait_hash__contains.length > 0 ? [...trait_hash__contains].toString() : undefined
        }
        console.log('getCollectionNftListById');
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                const { data, total_count } = response.data;
                console.log('response.tokensFromSettings', tokensFromSettings);
                // set tokens
                const filterData = data.map(item => {
                    if (item.is_generative) {
                        return {
                            ...item,
                            attributes_json: item.attributes_json.map(att => {
                                const variation = array_properties.find(ap => ap.id === att.trait_variation_id);
                                return {
                                    ...att,
                                    image: variation ? variation.image : undefined
                                }
                            })
                        }
                    } else {
                        return {
                            ...item,
                        }
                    }

                })
                console.log('filteredData', filteredData);
                if (reset) {
                    const ndata = [...filterData]
                    console.log('ndata', ndata);
                    filteredData = ndata;
                    setFilteredData([...filteredData])
                    setLoading(false)
                } else {
                    const ndata = [...filteredData, ...filterData]
                    console.log('ndata', ndata);
                    filteredData = ndata;
                    setFilteredData([...filteredData])
                    setLoading(false)
                }


            }

        })
    }

    const _callGenerateTokens = () => {
        setLoading(true)
        const body = {
            quantity: tokensFromSettings
        }
        DataService.putCollectionNftGenerateById(collection_id, body).then(response => {
            if (response && response.data) {
                console.log('response.data', response.data);
                if (response.data.message === 'success') {
                    _getCollectionById()
                }
            }
        })
    }

    // const _checkFilters = async () => {
    //     const filters = await get.filters.toArray();
    //     setArrayFilters(filters)
    // }





    const _handleSubmit = async (e) => {
        setLoading(true)
        setSubmitLoading(true);

        const body = {
            step_number: 5,
        }
        DataService.putCollectionById(collection_id, body).then(response => {
            if (response && response.data) {
                props.history.push(`/collections/generative/minting/${collection_id}`)
                setLoading(false)
            }
        })
    }


    const [show, setShow] = useState(false);

    const _startAgain = (e) => {
        if (generateAllLoader) return;
        _callGenerateTokens()
    }

    const onChangeCheckedOption = (key, item) => {
        console.log('key', key);
        const findParentFilterIndex = array_filters.findIndex(af => af.trait_id === item.trait_id)
        if (findParentFilterIndex === -1) return;
        const findFilterIndex = array_filters[findParentFilterIndex].variations.findIndex(variation => variation.trait_variation_id === item.trait_variation_id)
        if (findFilterIndex === -1) return;
        const value = !array_filters[findParentFilterIndex].variations[findFilterIndex].checked
        array_filters.forEach(af => {
            af.variations.forEach(variation => {
                variation.checked = false;
            })
        })
        array_filters[findParentFilterIndex].variations[findFilterIndex].checked = value;

        if (value) {
            addItemToFilterList(item)
        } else {
            removeItemToFilterList(item)
        }
    }

    const addItemToFilterList = (item) => {
        console.log('addItemToFilterList', item);
        // trait_hash__contains.push(item.trait_variation_id)
        trait_hash__contains = [item.trait_variation_id]
        set_trait_hash__contains([item.trait_variation_id])
        setActualPage(1)
        getCollectionNftListById(true)

    }
    const removeItemToFilterList = (item) => {
        console.log('removeItemToFilterList');
        setActualPage(1)
        let arr = trait_hash__contains;
        const find_arr = trait_hash__contains.findIndex(th => th === item.trait_variation_id)
        if (find_arr === -1) return;
        arr.splice(find_arr, 1)
        getCollectionNftListById(true)

    }

    useEffect(() => {
        console.log('trait_hash__contains effect', trait_hash__contains);
    }, [trait_hash__contains])




    const itemsPerPage = 20;
    const [hasMore, setHasMore] = useState(true);
    const [records, setrecords] = useState(itemsPerPage);
    const loadMore = () => {


        if (records === filteredData.length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                if (records >= filteredData.length) {
                    setrecords(filteredData.length);
                } else {
                    setrecords(records + itemsPerPage);
                }
            }, 3000);
        }
    };

    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
            minWidth: '900px',
        }
    }
    const __renderCardDetailView = (card) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={!!showModalCard}
            onRequestClose={() => {
                setShowModalCard(undefined)
            }}
            body={getModalContainerCard(card)}
            footer={footerModal}
            styles={modalCardStyles}
        />
    )

    const getModalContainerCard = (card) => {


        return <section className='modalcard'>
            <div className='modalcard-img'>
                <div className='modalcard-img-picture generative'>
                    {
                        card.is_generative && (
                            <div className='card-picture' >
                                {
                                    card.attributes_json.map(
                                        (layer, key) => (
                                            layer.image &&
                                            <img style={{ 'zIndex': key, 'position': 'absolute' }} alt={card.name} src={layer.image} key={'avatar' + key} />
                                        )
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        !card.is_generative && (
                            <div className='card-picture' >
                                <img style={{ 'position': 'absolute' }} alt={card.name} src={card.image} />
                            </div>
                        )
                    }

                </div>
            </div>
            <div className='modalcard-body'>
                <div className='modalcard-body-title'>
                    <p>{card.name}</p>
                </div>
                <div className='modalcard-body-cards'>
                    {
                        card.attributes_json.map((layer, key) => (
                            <div className='modalcard-body-cards-item' key={key}>
                                <div style={{ display: 'inline', maxWidth: '100px', padding: '0 12px' }}>
                                    <p className='modalcard-body-cards-item-top'>
                                        {layer.trait_type}
                                    </p>
                                    <p className='modalcard-body-cards-item-middle'>
                                        {layer.value}
                                    </p>
                                    {/* <p className='modalcard-body-cards-item-bot'>
                                        {key}% have this
                                    </p> */}
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    }
    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowModalCard(undefined)
            }}
        >
            {props.t(`Close`)}
        </Button>

    </div>



    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={85} percentage={85}></ProgressHeader>

            {
                showModalCard && __renderCardDetailView(showModalCard)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={step_number} active={6} collection_id={collection_id}></TabHeaderMenu>


                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`go to deploy settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <section className='collections-token'>
                        <div className='collections-token-form container-token-filter'>
                            <p className='collections-token-form-title'>Tokens</p>
                            <div>
                                <Button
                                    type="button"
                                    color="primary"
                                    className="theme-button-primary w-100"
                                    disabled={generateAllLoader}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        segmentTrack(`regenerate tokens`)
                                        _startAgain()
                                    }}
                                >

                                    {props.t(generateAllLoader ? 'Regenerating' : `Regenerate Preview`)}
                                    {generateAllLoader &&
                                        <div className='min-loader'>
                                            <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                                <div></div><div><div></div></div>
                                            </div></div>
                                        </div>
                                    }
                                </Button>
                            </div>

                            <p className='collections-token-form-title'>Attributes</p>
                            <FilterContainer array_filters={array_filters} changeCheckedOption={onChangeCheckedOption}></FilterContainer>


                        </div>
                        <div className='collections-token-resume'>
                            <div className='collections-token-resume-list container-token-table' >
                                {
                                    !generateAllLoader &&
                                    <div className='collections-token-resume-list-infinite'>
                                        {filteredData.map(
                                            (item, key) => (
                                                // <Col xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
                                                <Card className='card-borderless collections-token-resume-card theme-token-card' key={key} onClick={() => {
                                                    setShowModalCard(item)
                                                }}>
                                                    <div>
                                                        {
                                                            item.is_generative && (
                                                                <CardBody>
                                                                    <div className='card-picture generative' style={{ height: '200px' }} >
                                                                        {
                                                                            item.attributes_json.map(
                                                                                (layer, key) => (
                                                                                    layer.image &&
                                                                                    <img className='generative' style={{ width: '100%', 'zIndex': key, 'position': 'absolute' }} alt={item.name} src={layer.image} key={'avatar' + key} />
                                                                                )
                                                                            )
                                                                        }

                                                                    </div>
                                                                </CardBody>
                                                            )
                                                        }
                                                        {
                                                            !item.is_generative && (
                                                                <CardBody>
                                                                    <div className='card-picture generative' style={{ height: '200px' }} >

                                                                        <img className='generative' style={{ width: '100%', 'position': 'absolute' }} alt={item.name} src={item.image} key={'avatar' + key} />


                                                                    </div>
                                                                </CardBody>
                                                            )
                                                        }
                                                    </div>

                                                    <CardFooter className="bg-transparent border-top">
                                                        <div className="d-flex">
                                                            <div className="flex-fill container-token-nowrap">
                                                                <div className='container-token-nowrap'>
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardFooter>
                                                </Card>
                                            )
                                        )}
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>

                                    </div>
                                }


                            </div>
                            {
                                filteredMixedData.length !== filteredData.length &&
                                <div className='collections-token-clearfix'>

                                </div>
                            }
                        </div>
                    </section>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Token))
