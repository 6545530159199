/**
     <Modal
        title={`Hola`}
        isOpen={true}
        body={<React.Fragment>Content</React.Fragment>} 
        footer={<React.Fragment>Footer</React.Fragment>}
    />
 */
import React, { useState } from 'react'
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

// Modal.setAppElement(document.getElementById("root"))

export default ({ isOpen, onAfterOpen, onRequestClose, contentLabel, title, body, footer, styles, disableClose }) => {
    const [open, setOpen] = useState(isOpen)



    const _closeModal = () => {
        if (disableClose) return;
        setOpen(false)
    }

    return (
        <Modal
            isOpen={open}
            onAfterOpen={onAfterOpen || function noRefCheck() { }}
            contentLabel={contentLabel}
            style={styles || customStyles}
            className="jsmodal-dialog"
            shouldCloseOnOverlayClick={!disableClose}
            onRequestClose={onRequestClose || _closeModal}
        >
            <div className='jsmodal-content'>
                <div className="jsmodal-header">
                    <h4 className="jsmodal-title">{title}</h4>
                    {
                        !disableClose && (
                            <button type="button" className='btn-close' onClick={onRequestClose || _closeModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only"><i className='fa fa-close'></i></span>
                            </button>
                        )
                    }

                </div>

                <div className='jsmodal-body'>{body}</div>
                <div className='jsmodal-footer'>{footer}</div>
            </div>
        </Modal>
    )
}