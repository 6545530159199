import { faChevronDown, faLink, faHome, faArrowLeft, faPercentage, faPlus, faTrashAlt, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState, useEffect } from 'react'
import { withNamespaces } from "react-i18next"
import { withRouter, useLocation } from "react-router-dom"

import {
    Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, FormGroup,
    Input, Label, Row, Form,
    CardTitle,
} from "reactstrap"
import ProgressHeader from '../../components/Global/Progress-header'
import { GeneralContext } from '../../context/'
import TabHeaderMenu from './Components/tab-header-menu'
import bottomButton from '../../assets/images/bottom-button.png'
import { toastrError, toastrInfo, toastrSuccess } from '../../helpers/Toastr'
import { db } from "../../db"
import { useLiveQuery } from "dexie-react-hooks"
import Modal from "../../components/Global/modals"
import { ethers } from 'ethers'
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import cn from 'classnames'
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import { scrollTop } from "../../helpers/utils"
import { useParams } from 'react-router-dom'
import { loadState } from "../../store/localStorage"


const contractAddress = "0x6562A3b9E2Aa62c9106D6C88167Ba3AeBf180A06";
const network = 'https://polygon.rpc.thirdweb.com' // use mumbai testnet
const ethers_provider = ethers.getDefaultProvider(network)
const abi = [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "string", "name": "name", "type": "string" }], "name": "NameChanged", "type": "event" }, { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "string", "name": "_name", "type": "string" }], "name": "setName", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
const { ethereum } = window;




const Settings = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    const { currentProject, setProject } = generalContext
    const { loading, setLoading } = generalContext
    const { collection_id } = useParams();

    const location = useLocation();


    let [project_id, setProjectId] = useState(undefined);
    let [price, setPrice] = useState(undefined);
    const [launch_date, setLaunchDate] = useState('')
    const [end_date, setEndDate] = useState('')
    const [seller_fee_basis_points, setSellerFeesBasisPoints] = useState('')
    const [tokenSupply, setTokenSupply] = useState('')
    const [button_loader, setButtonLoader] = useState(false)
    const [image_tempo, setImageTempo] = useState(undefined)
    const [showImage, setShowImage] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [valid_price, setValidPrice] = useState(true)
    const [explorerApi, setExplorerApi] = useState('')
    let [selectedBlockChain, setSelectedBlockchain] = useState();
    let [filteredBlockchains, setFilteredBlockchains] = useState([]);

    const [image_updated, setImageUpdated] = useState(false)
    const [blockchainNetwork, setBlockchainNetwork] = useState(1)
    let [creator_array, setCreatorArray] = useState([]);
    let [show_neefter_creator, setShowNeefterCreator] = useState(true);

    const [image_id, setImageId] = useState('')
    const [tokenName, setTokenName] = useState('')
    const [tokenDescription, setTokenDescription] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [externalUrl, setExternalUrl] = useState('')
    const [properties, setProperties] = useState([])
    const [symbol, setSymbol] = useState('sol')
    const [file_type, setFileType] = useState('')
    let [disableAll, setDisableAll] = useState(false);

    const image_multiplicator = 0.001;
    let [is_deployed, setIsDeployed] = useState(false);
    let [full_subdomain, setFullSubdomain] = useState('');

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
        }
    }
    let [selectedTicket, setSelectedTicket] = useState(undefined)
    useEffect(() => {
        if (loadState()?.Auth?.ticket) {
            selectedTicket = loadState()?.Auth?.ticket;
            setSelectedTicket(loadState()?.Auth?.ticket)
        }
    }, [loadState()?.Auth])
    useEffect(() => {

        if (!currentProject) return;


        console.log('location', location);

        const provider_b = location.pathname;
        console.log('provider.split', provider_b.split('/'));
        if (provider_b.split('/').find(p => p === 'view')) {
            setEditMode(true)
            setDisableAll(true)
        }

        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: editMode ? props.t(`Edit Collection`) : props.t(`Create Collection`)
        })
        setProjectId(currentProject.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])


    // Payment 
    const [loadPayment, setLoadPayment] = useState(false)
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);
    const [sign, setSign] = useState(undefined);
    const neefter_cash = 'C123NqmCoXtEUt3sbJrHtYw3853b6y5tXttTLGHGvB6a'

    const neefter_prov = 'FZSbRL64bBk42QHCea44pdvxAGv67ofk9FAh8ZgBKJSE';
    const lamport = 1_000_000_000
    const NEEFTER_SOLANA_NODE = 'https://orbital-fittest-bird.solana-mainnet.quiknode.pro/bc45b120094c7d49e064f515fafd083b0e248f70/'
    let [counter, setCounter] = useState(0);
    const [enableButton, setEnableButton] = useState(true)
    const [showErrorTransaction, setShowErrorTransaction] = useState(false)
    const [finalizedSignature, setFinalizedSignature] = useState(undefined)

    useEffect(() => {
        getNetworkConfig()
        const provider = getProvider();
        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, []);

    function getNetworkConfig() {
        return DataService.getNetworkConfig().then(response => {
            console.log('getNetworkConfig', response.data);
            const arr = Object.values(response.data)
            setFilteredBlockchains(arr)
            if (!selectedBlockChain) {
                setSelectedBlockchain(arr[0])
                setSymbol(arr[0].native_token)
                setExplorerApi(arr[0].explorer_api)
            }

        })
    }
    useEffect(() => {
        if (selectedBlockChain) {
            setExplorerApi(selectedBlockChain.explorer_api)
        }
    }, [selectedBlockChain])
    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            // @ts-ignore
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
        // if ('phantom' in window) {
        //     // @ts-ignore
        //     const provider = window.phantom?.solana as any;

        //     if (provider?.isPhantom) {
        //         return provider as PhantomProvider;
        //     }
        // }
    };



    /**
    * @description prompts user to connect wallet if it exists
    */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;

        if (solana) {
            try {
                const response = await solana.connect();

                setWalletKey(response.publicKey.toString());
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };



    const _handleBuyCollection = () => {
        buyCollection()
    }

    let buyCollection = async () => {

        const provider = getProvider();

        setEnableButton(true);
        setLoadPayment(true);
        setShowErrorTransaction(false);
        const network = NEEFTER_SOLANA_NODE;


        const connection = new Connection(network);

        // setLoading(true);
        try {
            let transaction = Transaction;
            const to_public_key = new PublicKey(neefter_cash);
            const from_public_key = new PublicKey(walletKey);
            const lamp = lamport * image_multiplicator * tokenSupply;

            const txn = await createTransferTransaction(to_public_key, from_public_key, connection, lamp)


            const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
            try {
                return provider?.signAndSendTransaction(txn)
                    .then(async response => {
                        const signature = response?.signature
                        let getSignatureStatus;
                        let conditionExit = false;
                        while (!conditionExit && counter < 8) {
                            getSignatureStatus = await connection.getSignatureStatus(signature);
                            await ndelay(5000)
                            conditionExit = (!!getSignatureStatus.value && getSignatureStatus.value.confirmationStatus === 'finalized');
                            counter = counter + 1;
                        }
                        setFinalizedSignature(signature);
                        // const signature_status = await connection.getSignatureStatus(signature);

                        // openModal('MAIL_FORM')

                        // const data = {
                        //     signature: response?.signature,
                        //     email: '',
                        // }
                        // setpostBftMintByNftIPubKeyData({ id, wallet: walletState.data.Wallet?.wallet, data, clickNextStep, connection })
                        // setStepperView(true);
                        // return postBftMintByNftIPubKey(id, walletState.data.Wallet?.wallet, data, clickNextStep)

                    })
                    .catch(err => {
                        setShowErrorTransaction(true);
                        setLoading(false);
                        setEnableButton(false);
                        setLoadPayment(false);

                    })
            } catch (err) {
                setShowErrorTransaction(true);
                setLoading(false);
                setEnableButton(false);
                setLoadPayment(false);

            }
        } catch (err) {
            setShowErrorTransaction(true);
            setLoading(false);
            setEnableButton(false);

        }
    }

    useEffect(() => {
        if (finalizedSignature) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setSign(finalizedSignature)
            setLoading(false)

        }
    }, [finalizedSignature])

    useEffect(() => {
        if (sign) {
            _handleDeploy()
        }
    }, [sign])

    /**
* 
* 
* 
* 
* Purchase options
* 
* 
* 
* 
* 
*/

    /**
    * Creates an arbitrary transfer transaction
    * @param   {String}      publicKey  a public key
    * @param   {Connection}  connection an RPC connection
    * @returns {Transaction}            a transaction
    */
    const createTransferTransaction = async (toPublicKey, fromPublicKey, connection, lamports) => {
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: fromPublicKey,
                toPubkey: toPublicKey,
                lamports: lamports,
            })
        );


        transaction.feePayer = fromPublicKey;

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;


        return transaction;
    };

    const _handleDeploy = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService();
    }

    /**
     * Mumbai stuff
     * ____________________________________________________________________________________
     */

    const [balance, setBalance] = useState("Loading...")
    const [currentAccount, setCurrentAccount] = useState(null);
    const [transferBalance, setTransferBalance] = useState('0.001')
    const [transferAddress, setTransferAddress] = useState('0x11Ef8475B65C63161e2269eD5b5FF6fa6c2Bf9bD')
    const [name, setName] = useState("")
    const [nameInfo, setNameInfo] = useState("")
    const [blockhash, setBlockhash] = useState(undefined);
    const [loadMumbaiPayment, setLoadMumbaiPayment] = useState(false)
    const [finalizedSignatureMumbai, setFinalizedSignatureMumbai] = useState(undefined)

    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    const getName = async () => {
        let getName_provider = ethers.getDefaultProvider(network)
        const erc20 = new ethers.Contract(contractAddress, abi, getName_provider)
        const nameInf = await erc20.name()
        setNameInfo(nameInf)

    }


    const submitName = async () => {
        let submitName_provider = ethers.getDefaultProvider(network)
        const privateKey = "########################################";
        let wallet = new ethers.Wallet(privateKey, submitName_provider)
        const learnToken = new ethers.Contract(contractAddress, abi, wallet)


        learnToken.on("NameChanged", (newName) => {

        });

        const createReceipt = await learnToken.setName(name);
        await createReceipt.wait();



    }
    async function transferETH() {
        setLoadMumbaiPayment(true);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        try {
            let receiverAddress = transferAddress
            let amountInEther = (image_multiplicator * (tokenSupply || 1)) + '';
            console.log('amountInEther', amountInEther);
            let tx = {
                to: receiverAddress,
                value: ethers.utils.parseEther(amountInEther)
            }

            const _provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = _provider.getSigner();
            // Send the transaction and log the receipt
            const receipt = await signer.sendTransaction(tx);

            let wrongResult = false;
            let _transaction = await _provider.getTransactionReceipt(receipt.hash);


            // let conditionExit = false;
            // while (!conditionExit && counter < 10) {
            //     _transaction = await _provider.getTransactionReceipt(receipt.hash);
            //     await ndelay(5000)
            //     console.log('_transaction', _transaction.status);
            //     counter = counter + 1;
            //     if (_transaction) {
            //         conditionExit = (_transaction.status !== 2 && _transaction.status !== 3)
            //         wrongResult = _transaction.status === 1;
            //     }
            // }
            let counter_explorer_api = 0;
            let condition_exit_explorer = false;
            let loop;
            if (explorerApi) {
                loop = async value => {
                    while (!condition_exit_explorer && counter_explorer_api < 10) {
                        const validatePath = `${explorerApi}?module=transaction&action=gettxreceiptstatus&txhash=${receipt.hash}`;
                        const explorer_validation = await DataService.getPath(validatePath)
                        await ndelay(5000)
                        counter = counter + 1;
                        value = value + 1
                        if (explorer_validation && explorer_validation.data && explorer_validation.data.result) {
                            condition_exit_explorer = ((explorer_validation.data.result.status + '') === '1')
                        }
                    }
                }
            }
            if (loop) {
                await loop()
            }
            if (!condition_exit_explorer) {
                console.log('_transaction super errrrrorrrrr 1', _transaction);
                setLoadMumbaiPayment(false);
                setLoadPayment(false)
                toastrError('Something goes wrong')
            } else {
                setFinalizedSignatureMumbai(receipt.hash)
                // setLoadMumbaiPayment(false);
            }

        } catch (err) {
            toastrError(`Payment Error, check your wallet`)
            console.log('err', err);
            setLoadMumbaiPayment(false);

        }

    }

    const checkWalletIsConnected = async () => {
        if (!ethereum) {

            return;
        } else {

        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];

            setCurrentAccount(account);
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                setBalance(`${balanceInEth} ETH`)
            })

        } else {

        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

            setCurrentAccount(accounts[0]);
        } catch (err) {

        }
    }

    const connectWalletButton = () => {
        return (
            <Button
                type="button"
                color="primary"
                className=" theme-button-secundary"
                onClick={(e) => {
                    e.preventDefault()
                    segmentTrack(`Connect wallet to deploy`)
                    connectWalletHandler()
                }}
            >
                {props.t(`Connect wallet`)}
            </Button>
        )
    }

    useEffect(() => {
        if (finalizedSignatureMumbai) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setBlockhash(finalizedSignatureMumbai)
            setLoading(false)

        }
    }, [finalizedSignatureMumbai])

    useEffect(() => {
        if (blockhash) {
            _handleDeployMumbai()
        }
    }, [blockhash])

    const _handleDeployMumbai = (e) => {
        if (!_validateFullStep()) return;
        setButtonLoader(true);
        sendFormToService();
    }


    /**
     * ___________________________________________________________________________________________
     */

    const _handleSubmit = async (e) => {
        if (!_validateStep()) return;
        setShowModal(true)
    }


    const [menu, setMenu] = useState(false)
    const toggle = () => { setMenu(!menu) }

    const [menuBlockchain, setMenuBlockchain] = useState(false)
    const toggleBlockchain = () => { setMenuBlockchain(!menuBlockchain) }

    const [menuCurrency, setMenuCurrency] = useState(false)
    const toggleCurrency = () => { setMenuCurrency(!menuCurrency) }


    const [menuSymbol, setMenuSymbol] = useState(false)
    const toggleSymbol = () => { setMenuSymbol(!menuSymbol) }
    let [selectedSymbol, setSelectedSymbol] = useState();


    let [selectedTokenType, setSelectedTokenType] = useState({
        id: 1,
        name: 'Single edition'
    });

    const filteredTokenTypes = [
        {
            id: 1,
            name: 'Single edition'
        },
        {
            id: 2,
            name: 'Multiple editions'
        },
        {
            id: 3,
            name: 'Unlimited editions'
        }
    ]

    useEffect(() => {
        if (!selectedTokenType) return;
        if (selectedTokenType.id === 1) {
            setTokenSupply(1)
        }
        if (selectedTokenType.id === 3) {
            setTokenSupply(0)
        }

    }, [selectedTokenType])
    const _validateFullStep = () => {
        // if (!selectedTokenType) {
        //     toastrError(props.t(`Don't forget to select the token type`), 'Error')
        //     return false
        // }
        if (!tokenSupply) {
            toastrError(props.t(`Don't forget to select the token supply`), 'Error')
            return false
        }
        if (!price || isNaN(price) || !valid_price) {
            toastrError(props.t(`Don't forget to enter the token price`), 'Error')
            return false
        }
        if (!blockchainNetwork) {
            toastrError(props.t(`Don't forget to select the blockchain network`), 'Error')
            return false
        }
        if (!end_date) {
            toastrError(props.t(`Don't forget to select the end date`), 'Error')
            return false
        }
        if (!launch_date) {
            toastrError(props.t(`Don't forget to select the launch date`), 'Error')
            return false
        }
        if (seller_fee_basis_points === '') {
            toastrError(props.t(`Don't forget to enter the royalties`), 'Error')
            return false
        }
        if (!!seller_fee_basis_points) {
            const v = parseFloat(seller_fee_basis_points)
            if (!(v >= 0 && v < 100)) {
                toastrError(props.t(`The royalties can't be less than 0 and greater than 100`), 'Error')
                return false;
            }
        }
        if (!sign && !blockhash) {
            toastrError(props.t(`First you need paid for the service`), 'Error')
            return false
        }
        return true
    }
    const _validateStep = () => {
        if (!selectedTokenType) {
            toastrError(props.t(`Don't forget to select the token type`), 'Error')
            return false
        }
        if (!tokenSupply) {
            toastrError(props.t(`Don't forget to select the token supply`), 'Error')
            return false
        }
        if (!price || isNaN(price) || !valid_price) {
            toastrError(props.t(`Don't forget to enter the token price`), 'Error')
            return false
        }
        if (!blockchainNetwork) {
            toastrError(props.t(`Don't forget to select the blockchain network`), 'Error')
            return false
        }
        if (!end_date) {
            toastrError(props.t(`Don't forget to select the end date`), 'Error')
            return false
        }
        if (!launch_date) {
            toastrError(props.t(`Don't forget to select the launch date`), 'Error')
            return false
        }
        if (seller_fee_basis_points === '') {
            toastrError(props.t(`Don't forget to enter the royalties`), 'Error')
            return false
        }
        if (!!seller_fee_basis_points) {
            const v = parseFloat(seller_fee_basis_points)
            if (!(v >= 0 && v < 100)) {
                toastrError(props.t(`The royalties can't be less than 0 and greater than 100`), 'Error')
                return false;
            }
        }
        return true
    }

    useEffect(() => {
        if (!!collection_id) return;
        getCollectionDb()
    }, [])

    const getCollectionDb = async () => {
        try {
            const collectionSelected = await db.oneandone.toArray()
            console.log('collectionSelected', collectionSelected);
            if (collectionSelected && collectionSelected.length > 0) {
                const value = collectionSelected[0];
                console.log('collectionSelected', value);

                setImageId(value.image_id)
                const splt_img = value.image.name.split('.')
                if (value.image) {
                    const file_type = splt_img[splt_img.length - 1]

                    setFileType(file_type)
                    setImageTempo(URL.createObjectURL(value.image));
                    if (file_type.toLowerCase() === 'mp4') {
                        setShowImage(false)
                    } else {
                        setShowImage(true)
                    }
                }
                setTokenName(value.name)
                setExternalUrl(value.external_url)
                setTokenDescription(value.description)
                setProperties(value.properties)

            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const addCreator = () => {

        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) {
            document.getElementById('seller_fee_basis_points').classList.add('input-error')
            toastrError('Royalties missing', 'Error')
            return;
        }
        if (blockchainNetwork === 2 || blockchainNetwork === '2') {
            if (creator_array.length === 1) {
                return;
            }
        }

        creator_array = [
            ...creator_array,
            {
                address: '',
                share: '',
                error: false,
            }
        ]
        setCreatorArray([...creator_array]);


        checkAllCreators()
    };
    const removeCreatorInput = (index) => {
        setCreatorArray((item) =>
            item.filter((current, _ind) => _ind !== index)
        )
        checkAllCreators()
    }
    const handleChangeCreatorAddress = (event) => {
        event.preventDefault();
        const val = 'creator_address';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;


        setCreatorArray(item => {
            const newArr = item.slice();
            newArr[index].address = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    const handleChangeCreatorShare = (event) => {
        event.preventDefault();
        const val = 'creator_share';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;

        setCreatorArray(item => {
            const newArr = item.slice();

            if (value === null || value === undefined || value === '') {
                newArr[index].error = true;
            } else {
                newArr[index].error = false;
            }
            var regexp = new RegExp(/[`~!@$%^&*()+{}[\]\\|,.//?;':"]/g, 'g');
            const indexHashtagError = value.match(regexp);
            const indexSpaceError = value.match(/\s/);
            if ((value === null || value === undefined || value === '') || (indexHashtagError && indexHashtagError?.length > 0) || (indexSpaceError && indexSpaceError?.length > 0)) {
                newArr[index].error = true;
                newArr[index].share = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
                const doc = document.getElementById(val + '_p' + index)

                if (doc) {
                    doc.classList.add('input-error')
                }
            } else {
                newArr[index].error = false;
                newArr[index].share = (value === null || value === undefined || value === '') ? '' : parseFloat(value);
                const doc = document.getElementById(val + '_p' + index)
                if (doc) {
                    doc.classList.remove('input-error')
                }
            }
            return newArr;
        });
        // checkAllCreators()


    };
    useEffect(() => {
        if (creator_array.filter(ca => !ca.share).length > 0) return;
        if ((!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0')) return;
        const validate = setTimeout(() => {
            checkAllCreators()
        }, 5000);
        return () => clearTimeout(validate)
    }, [creator_array])

    const validateWebsiteUrl = websiteUrl => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

        return regex.test(String(websiteUrl).toLowerCase());
    };
    function checkAllCreators() {
        let total = 0;
        const checkError = creator_array.find(creator => creator.error)
        if (!checkError) {
            total = creator_array.reduce((acc, creator) => acc + parseFloat(creator.share || 0), 0);
            if (blockchainNetwork === 2 || blockchainNetwork === '2') {
                if ((total !== 100) && creator_array.length > 0) {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        if (document.getElementById(val + index)) {
                            document.getElementById(val + index).classList.add('input-error')
                        }
                        return {
                            ...creator,
                            error: true
                        }
                    })
                    setCreatorArray([...n_array])
                    toastrInfo('The total of the sum of the shares between all creators must to be 100%')

                } else {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        document.getElementById(val + index).classList.remove('input-error')
                        return {
                            ...creator,
                            error: false
                        }
                    })
                    setCreatorArray([...n_array])
                }
            } else {
                if (((show_neefter_creator && total !== 90) || (!show_neefter_creator && total !== 100)) && creator_array.length > 0) {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        if (document.getElementById(val + index)) {
                            document.getElementById(val + index).classList.add('input-error')
                        }
                        return {
                            ...creator,
                            error: true
                        }
                    })
                    setCreatorArray([...n_array])
                    toastrInfo('The total of the sum of the shares between all creators must to be 100%')

                } else {
                    const val = 'creator_share';
                    const n_array = creator_array.map((creator, index) => {
                        document.getElementById(val + index).classList.remove('input-error')
                        return {
                            ...creator,
                            error: false
                        }
                    })
                    setCreatorArray([...n_array])
                }
            }


        }

    }

    const sendFormToService = () => {
        const body = {
            image_id: image_id,
            name: tokenName,
            external_url: externalUrl,
            project_id: project_id,
            description: tokenDescription,
            properties: properties,
            total_tokens: tokenSupply,
            price: price,
            symbol: symbol,
            network_id: blockchainNetwork,
            end_date: end_date,
            launch_date: launch_date,
            file_type: file_type,
            seller_fee_basis_points: parseFloat((seller_fee_basis_points * 100) || 0),
            creators: (!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') ? [] : creator_array.map(creator => {
                return {
                    address: creator.address,
                    share: creator.share,
                }
            }),
            sign: (blockchainNetwork === 1 || blockchainNetwork === '1') ? sign : blockhash,
        }
        return DataService.postCollection1_1(body).then(response => {
            try {
                db.oneandone.clear();
            } catch (error) {
                console.log('error');
            }
            setButtonLoader(false);
            if (selectedTicket) {
                props.history.push(`/collections/ticketing?step=3`)
                // scrollTop()
            } else {
                props.history.push(`/collections/to-the-moon`)
                // scrollTop()
            }
            setLoadPayment(false);
            setLoadMumbaiPayment(false);
        })
    }
    function naiveRound(num, decimalPlaces = 5) {
        if (!num) return 0;
        var p = Math.pow(10, decimalPlaces);
        return Math.round(num * p) / p;
    }

    useEffect(() => {
        console.log('collection_id', collection_id);
        if (!!collection_id) {
            DataService.getCollectionById(collection_id).then(
                response => {
                    if (response && response.data) {
                        console.log('response', response.data);
                        const { id, image_id, name, external_url, description, total_tokens, price, is_deployed, network_config,
                            project_id, symbol, network_id, launch_date, end_date, seller_fee_basis_points, creators
                        } = response.data;


                        setImageId(image_id)
                        setImageTempo(image_id)
                        const splt_img = image_id.split('.')

                        const file_type = splt_img[splt_img.length - 1]
                        console.log('file_type', file_type);

                        if (file_type.toLowerCase() === 'mp4') {
                            setShowImage(false)
                        } else {
                            setShowImage(true)
                        }

                        setIsDeployed(is_deployed)
                        setTokenName(name)
                        setExternalUrl(external_url)
                        setTokenDescription(description)
                        setTokenSupply(total_tokens)
                        setPrice(price)
                        // setSymbol(symbol)
                        // setBlockchainNetwork(network_id)
                        setSellerFeesBasisPoints(seller_fee_basis_points !== undefined ? seller_fee_basis_points / 100 : '')

                        const selected_network = filteredBlockchains.find(fb => fb.id === network_id)
                        if (selected_network) {
                            setSelectedBlockchain(selected_network)
                            setBlockchainNetwork(selected_network.neefter_id)
                            setSymbol(selected_network.native_token)
                        } else {
                            setSelectedBlockchain(network_config)
                            setBlockchainNetwork(network_config.neefter_id)
                            setSymbol(network_config.native_token)
                        }

                        const hostname = window && window.location && window.location.hostname
                        switch (hostname) {
                            case 'localhost':
                                setFullSubdomain(`https://dev.neefter.com/claim/${collection_id}`)
                                break
                            case 'neefter-front-git-develop-metafans.vercel.app':
                                setFullSubdomain(`https://dev.neefter.com/claim/${collection_id}`)
                                break
                            case 'app.neefter.com':
                                setFullSubdomain(`https://app.neefter.com/claim/${collection_id}`)
                                break
                            default:
                                setFullSubdomain(`https://dev.neefter.com/claim/${collection_id}`)
                                break
                        }




                        if (total_tokens === 0) {
                            setSelectedTokenType({
                                id: 3,
                                name: 'Unlimited editions'
                            })
                        } else if (total_tokens === 1) {
                            setSelectedTokenType({
                                id: 1,
                                name: 'Single edition'
                            })
                        } else {
                            setSelectedTokenType({
                                id: 2,
                                name: 'Multiple editions'
                            })
                        }
                        const launch = launch_date ? new Date(launch_date) : '';
                        if (launch) {
                            const year = launch.getFullYear()
                            let month = parseInt(launch.getMonth() + 1)
                            month = (month + '').length > 1 ? month : '0' + month
                            const day = ((launch.getDate() + '').length > 1 ? launch.getDate() : '0' + launch.getDate());
                            let date = year + "-" + month + "-" + day;
                            setLaunchDate(date)
                        }
                        const end = end_date ? new Date(end_date) : '';
                        if (end) {
                            const year = launch.getFullYear()
                            let month = parseInt(launch.getMonth() + 1)
                            month = (month + '').length > 1 ? month : '0' + month
                            const day = ((launch.getDate() + '').length > 1 ? launch.getDate() : '0' + launch.getDate());
                            let date = year + "-" + month + "-" + day;
                            setEndDate(date)
                        }
                        if (creators && creator_array.length === 0) {
                            let resumeCreators = creators.map(creator => {
                                return {
                                    address: creator.address == neefter_prov ? 'Neefter creator' : creator.address,
                                    share: creator.share,
                                    error: false,
                                }
                            })
                            if((network_id + '') !== '1') {
                                resumeCreators = resumeCreators.filter(creator => creator.address !== 'Neefter creator')
                            }
                            creator_array = resumeCreators
                            setCreatorArray([...resumeCreators])
                        }
                        if (creators.length > 1) {
                            show_neefter_creator = false;
                            setShowNeefterCreator(show_neefter_creator)

                        }

                    }
                })
        }
    }, [collection_id])

    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={footer}
            disableClose={loadPayment}
            styles={modalStyles}
        />
    )

    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Let's go!</p>
            <p className='deploy-modal-subtitle'> You’re about to publish a Digital Collectible of </p>
            <p className='deploy-modal-count'> {tokenName} </p>
            <div className='deploy-modal-token-container'>
                {
                    showImage &&
                    <img src={image_tempo} />
                }
                {
                    !showImage &&
                    <video id='video' src={image_tempo} className='collections-token-resume-card-video' style={{
                        width: '100%',
                        height: '100%'
                    }}
                        muted playsInline autoPlay={true}>
                        {/* <source type="video/mp4" /> */}
                        Your browser does not support the video tag.
                    </video>
                }
            </div>

            {
                (blockchainNetwork === 1 || blockchainNetwork === '1') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {image_multiplicator * (tokenSupply || 1)} </b> SOL  </span> to deploy your collection.</p>
            }
            {
                (blockchainNetwork === 2 || blockchainNetwork === '2') &&
                <p className='deploy-modal-subtitle'>You will be prompted to connect your wallet, and then pay  <span style={{ marginRight: '4px' }}> <b> {image_multiplicator * (tokenSupply || 1)} </b>  MATIC  </span> to deploy your collection.</p>
            }
        </div>
    </section>

    const footer = <div className='deploy-modal-footer'>

        {
            (blockchainNetwork === 1 || blockchainNetwork === '1') && (
                <div>
                    {provider && !walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className=" theme-button-secundary"
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`connect wallet to deploy`)
                                connectWallet()
                            }}
                        >
                            {props.t(`Connect wallet`)}
                        </Button>
                    )}
                    {provider && !!walletKey && (
                        <Button
                            type="button"
                            color="primary"
                            className="custom-row theme-button-secundary"
                            disabled={loadPayment}
                            onClick={(e) => {
                                e.preventDefault()
                                segmentTrack(`pay deploy`)
                                _handleBuyCollection()
                            }}
                        >
                            {props.t(`Pay ${image_multiplicator * (tokenSupply || 1)} SOL and deploy`)}
                            {loadPayment &&
                                <div className='min-loader'>
                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                        <div></div><div><div></div></div>
                                    </div></div>
                                </div>
                            }

                        </Button>
                    )}
                    {!provider && (
                        <p>
                            No provider found. Install{" "}
                            <a href="https://phantom.app/">Phantom Browser extension</a>
                        </p>
                    )}
                </div>
            )
        }
        {
            (blockchainNetwork === 2 || blockchainNetwork === '2') && (
                <div>
                    {currentAccount ?
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className=" theme-button-secundary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`pay deploy`)
                                    transferETH()
                                }}
                            >
                                {props.t(`Pay ${image_multiplicator * (tokenSupply || 1)} MATIC and deploy`)}
                                {loadMumbaiPayment &&
                                    <div className='min-loader'>
                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                            <div></div><div><div></div></div>
                                        </div></div>
                                    </div>
                                }
                            </Button>
                        </div>
                        : connectWalletButton()
                    }
                </div>
            )
        }
    </div>


    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={50} percentage={50}></ProgressHeader>
            {
                showModal && __renderPOView(12)
            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={1} active={2} is_deployed={is_deployed}></TabHeaderMenu>
                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-update desktop"
                                disabled={loading || disableAll}
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`Pay and publish`)
                                    _handleSubmit()
                                }}
                            >
                                {
                                    button_loader &&
                                    <div className='min-loader'>
                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                            <div></div><div><div></div></div>
                                        </div></div>
                                    </div>

                                }
                                {!button_loader &&
                                    props.t(`Pay and publish`)
                                }
                            </Button>
                        </div>
                    </div>
                    <div className='collections-settings'>
                        <div className='collections-settings-body'>
                            <Form id="settings-tab-form" >
                                <div className='collections-settings text-align-left'>

                                    <section className='container-settings-tab oneandone'>
                                        <section className='container-settings-tab-form'>
                                            <CardTitle className='collections-settings-tab-title'>{props.t(`General settings`)}</CardTitle>

                                            {
                                                is_deployed && (
                                                    <div className='container-settings-tab-form-view-subdomain'>
                                                        <a href={full_subdomain} target="_blank">
                                                            {full_subdomain}
                                                        </a>
                                                        <div className='container-settings-tab-form-view-subdomain-ico' onClick={() => {
                                                            navigator.clipboard.writeText(full_subdomain)
                                                            toastrSuccess('Copied')
                                                        }}>
                                                            <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                                                        </div>

                                                    </div>
                                                )
                                            }

                                            <div className='collections-settings-row'>

                                                <div className='collections-settings-item'>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label>
                                                                    {props.t(`Token type`)}
                                                                </Label>
                                                                <Dropdown
                                                                    style={{ width: '100%' }}
                                                                    isOpen={menu}
                                                                    toggle={toggle}
                                                                    disabled={disableAll}
                                                                    className="d-inline-block"
                                                                >
                                                                    <DropdownToggle
                                                                        className="input-drop-white"
                                                                        id="select-block-dropdown"
                                                                        tag="button"
                                                                    >
                                                                        <span className="header-item-project-name">
                                                                            {selectedTokenType && (
                                                                                <span>{selectedTokenType.name}</span>
                                                                            )}
                                                                        </span>
                                                                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right className='input-drop-white-item'>
                                                                        {filteredTokenTypes.map(
                                                                            (item, key) => (
                                                                                <div key={key} className="dropdown-item " onClick={() => {
                                                                                    setSelectedTokenType(item)
                                                                                    setMenu(false)
                                                                                }}>
                                                                                    <span>{item.name}</span>
                                                                                </div>
                                                                            ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>

                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`Token supply`)}
                                                                    {/* total_tokens */}
                                                                </Label>
                                                                <Input
                                                                    value={tokenSupply}
                                                                    type="text"
                                                                    id='tokenSupply'
                                                                    className="input-white-input circle"
                                                                    placeholder=''
                                                                    step="0.01"
                                                                    disabled={(selectedTokenType === 1) || (selectedTokenType === 3) || disableAll}
                                                                    pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                    onChange={event => {
                                                                        const { value } = event.target
                                                                        if (!value) {
                                                                            document.getElementById('tokenSupply').classList.add('input-error')
                                                                        } else {
                                                                            document.getElementById('tokenSupply').classList.remove('input-error')
                                                                        }
                                                                        setTokenSupply(event.target.value)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`Price`)}
                                                                </Label>

                                                                <div className='theme-form-control visible' id='price' style={{ overflow: 'visible' }}>
                                                                    <div className='theme-form-control-input'>
                                                                        <Input
                                                                            value={price}
                                                                            type="text"
                                                                            className="input"
                                                                            placeholder=''
                                                                            id="sub_price"
                                                                            step="0.01"
                                                                            pattern="[-+]?[0-9]*[.]?[0-9]+"
                                                                            onChange={event => {
                                                                                const { value } = event.target
                                                                                const nvalue = parseFloat(value)
                                                                                if (!value || isNaN(nvalue) || !event.target.validity.valid) {
                                                                                    document.getElementById('price').classList.add('input-error')
                                                                                    setValidPrice(false)
                                                                                } else {
                                                                                    document.getElementById('price').classList.remove('input-error')
                                                                                    setValidPrice(true)
                                                                                }
                                                                                setPrice(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='theme-form-control-post transparent'>
                                                                        <span>
                                                                            {selectedBlockChain ? selectedBlockChain.symbol : ''}
                                                                        </span>
                                                                        {/* <Dropdown
                                                                            style={{ width: '100%' }}
                                                                            isOpen={menuSymbol}
                                                                            toggle={toggleSymbol}
                                                                            className="d-inline-block"
                                                                            disabled={disableAll}
                                                                        >
                                                                            <DropdownToggle
                                                                                className="input-drop-white post"
                                                                                id="select-block-dropdown"
                                                                            >
                                                                                <span className="header-item-project-name">
                                                                                    {selectedSymbol && (
                                                                                        <span style={{ color: '#6b7280' }}>{selectedSymbol.name}</span>
                                                                                    )}
                                                                                </span>
                                                                                <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu right className='input-drop-white-item'>
                                                                                {filteredSymbol.map(
                                                                                    (item, key) => (
                                                                                        <div key={key} className="dropdown-item " onClick={() => {
                                                                                            setSelectedSymbol(item)
                                                                                            setSymbol(item.id)
                                                                                            setMenuSymbol(false)
                                                                                        }}>
                                                                                            <span style={{ color: '#6b7280' }}>{item.name}</span>
                                                                                        </div>
                                                                                    ))}
                                                                            </DropdownMenu>
                                                                        </Dropdown> */}
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`Blockchain network`)}
                                                                </Label>
                                                                <Dropdown
                                                                    style={{ width: '100%' }}
                                                                    isOpen={menuBlockchain}
                                                                    toggle={toggleBlockchain}
                                                                    className="d-inline-block"
                                                                    disabled={disableAll}
                                                                >
                                                                    <DropdownToggle
                                                                        className="input-drop-white"
                                                                        id="select-block-dropdown"
                                                                    >
                                                                        <span className="header-item-project-name">
                                                                            {selectedBlockChain && (
                                                                                <span>{selectedBlockChain.name}</span>
                                                                            )}
                                                                        </span>
                                                                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right className='input-drop-white-item'>
                                                                        {filteredBlockchains.map(
                                                                            (item, key) => (
                                                                                <div key={key} className="dropdown-item " onClick={() => {
                                                                                    setSelectedBlockchain(item)
                                                                                    setBlockchainNetwork(item.neefter_id)
                                                                                    setSymbol(item.native_token)
                                                                                    setMenuBlockchain(false)
                                                                                }}>
                                                                                    <span>{item.name}</span>
                                                                                </div>
                                                                            ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <CardTitle className='collections-settings-tab-title'>{props.t(`Go live settings`)}</CardTitle>
                                            <div className='collections-settings-row'>
                                                <div className='collections-settings-item'>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`Go-live Date`)}
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    name="launch_date"
                                                                    id="launch_date"
                                                                    value={launch_date}
                                                                    placeholder="Launch date"
                                                                    className="input-white-input circle"
                                                                    onChange={event => {
                                                                        const { value } = event.target
                                                                        if (!value) {
                                                                            document.getElementById('launch_date').classList.add('input-error')
                                                                        } else {
                                                                            document.getElementById('launch_date').classList.remove('input-error')
                                                                        }
                                                                        setLaunchDate(event.target.value)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`End Date`)}
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    name="end_date"
                                                                    id="end_date"
                                                                    value={end_date}
                                                                    placeholder="End date"
                                                                    className="input-white-input circle"
                                                                    disabled={disableAll}
                                                                    onChange={event => {
                                                                        const { value } = event.target
                                                                        if (!value) {
                                                                            document.getElementById('end_date').classList.add('input-error')
                                                                        } else {
                                                                            document.getElementById('end_date').classList.remove('input-error')
                                                                        }
                                                                        setEndDate(event.target.value)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <CardTitle className='collections-settings-tab-title'>{props.t(`Royalties & comissions`)}</CardTitle>
                                            <div className='collections-settings-row'>
                                                <div className='collections-settings-item'>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`Royalties %`)}
                                                                </Label>

                                                                <div className='theme-form-control' id='seller_fee_basis_points'>
                                                                    <div className='theme-form-control-input'>
                                                                        <Input
                                                                            required
                                                                            value={seller_fee_basis_points}
                                                                            type="text"
                                                                            className="input"

                                                                            placeholder=''
                                                                            step="0.01"
                                                                            pattern="[0-9]*"
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            disabled={disableAll}
                                                                            onChange={event => {
                                                                                const { value } = event.target
                                                                                if (!value || !event.target.validity.valid) {
                                                                                    document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                                } else {
                                                                                    const v = parseFloat(value)
                                                                                    if (v >= 0 && v < 100) {
                                                                                        const strAfterDot = value.split('.', 2)[1]
                                                                                        if (!strAfterDot || strAfterDot.length <= 2) {
                                                                                            document.getElementById('seller_fee_basis_points').classList.remove('input-error')
                                                                                        } else {
                                                                                            document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                                        }
                                                                                    } else {
                                                                                        document.getElementById('seller_fee_basis_points').classList.add('input-error')
                                                                                    }
                                                                                }
                                                                                setSellerFeesBasisPoints(event.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='theme-form-control-post white'>
                                                                        <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                                    </div>
                                                                </div>

                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label className='input-label_monserrat'>
                                                                    {props.t(`creators %`)}
                                                                </Label>
                                                                <p className='title-mini'>
                                                                    Payments will be split between the wallet address you list here, according to their share.
                                                                </p>

                                                                {
                                                                    (show_neefter_creator && seller_fee_basis_points > 0 && (blockchainNetwork === 1 || blockchainNetwork === '1')) && (
                                                                        <div className='input-container' key={'cont0'} >
                                                                            <div className='input-container_lg'>
                                                                                <Input
                                                                                    required
                                                                                    value="Neefter creator"
                                                                                    type="text"
                                                                                    className="input-white-input circle"
                                                                                    disabled={true}
                                                                                    placeholder=''
                                                                                    id={"creator_address"}
                                                                                />
                                                                            </div>

                                                                            <div className='input-container_sm'>


                                                                                <div className='theme-form-control'>
                                                                                    <div className='theme-form-control-input'>
                                                                                        <Input
                                                                                            required
                                                                                            value="10"
                                                                                            type="text"
                                                                                            disabled={true}
                                                                                            className="input"
                                                                                            placeholder=''
                                                                                            id={"creator_shar0"}
                                                                                            pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                                        />
                                                                                    </div>
                                                                                    <div className='theme-form-control-post white'>
                                                                                        <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    creator_array.map((item, i) => {
                                                                        return (
                                                                            <div className='input-container' key={'cont' + i} >
                                                                                <div className='input-container_lg'>
                                                                                    <Input
                                                                                        required
                                                                                        value={item.address}
                                                                                        type="text"
                                                                                        className="input-white-input circle"
                                                                                        placeholder=''
                                                                                        id={"creator_address" + i}
                                                                                        disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') || disableAll}
                                                                                        onChange={handleChangeCreatorAddress}
                                                                                    />
                                                                                </div>
                                                                                <div className='input-container_sm'>
                                                                                    <div className='theme-form-control' id={"creator_share_p" + i}>
                                                                                        <div className='theme-form-control-input'>
                                                                                            <Input
                                                                                                required
                                                                                                value={item.share}
                                                                                                type="text"
                                                                                                className="input"
                                                                                                placeholder=''
                                                                                                id={"creator_share" + i}
                                                                                                pattern="[-+]?[0-9]*[.,]?[0-9]+"
                                                                                                disabled={(!seller_fee_basis_points || seller_fee_basis_points === 0 || seller_fee_basis_points === '0') || disableAll}
                                                                                                onChange={handleChangeCreatorShare}
                                                                                            />
                                                                                        </div>
                                                                                        <div className='theme-form-control-post white'>
                                                                                            <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    !disableAll &&
                                                                                    <div className='input-container_icon' onClick={() => { removeCreatorInput(i) }}>
                                                                                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        )

                                                                    })
                                                                }
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>


                                                    <Row>
                                                        <Col sm={6}>
                                                            {
                                                                !disableAll &&
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="theme-button-collaborator"
                                                                    onClick={addCreator}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                                    {props.t(`Add creator`)}
                                                                </Button>
                                                            }

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Button
                                                                type="button"
                                                                color="secundary"
                                                                className="theme-button-update mobile"
                                                                disabled={loading || disableAll}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    segmentTrack(`Pay and publish`)
                                                                    _handleSubmit()
                                                                }}
                                                            >
                                                                {
                                                                    button_loader &&
                                                                    <div className='min-loader'>
                                                                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                                                                            <div></div><div><div></div></div>
                                                                        </div></div>
                                                                    </div>

                                                                }
                                                                {!button_loader &&
                                                                    props.t(`Pay and publish`)
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='container-settings-tab-side'>
                                            <section className='container-settings-tab-example' style={{ height: '55rem' }}>
                                                <p>Token MetaData</p>
                                                <div className='container-settings-tab-example-custom'>
                                                    <div className='container-settings-tab-example-titles'>
                                                        <span className='container-settings-tab-example-titles token-name'>
                                                            Token Name
                                                        </span>
                                                        <span className='container-settings-tab-example-titles token-media'>
                                                            Token Media
                                                        </span>
                                                        <span className='container-settings-tab-example-titles token-description'>
                                                            Token <br /> Description
                                                        </span>
                                                        <span className='container-settings-tab-example-titles external-url'>
                                                            External URL
                                                        </span>
                                                        <div className='container-settings-tab-example-stroke stoken-name'>
                                                        </div>
                                                        <div className='container-settings-tab-example-stroke stoken-media'>
                                                        </div>
                                                        <div className='container-settings-tab-example-stroke stoken-description'>
                                                        </div>
                                                        <div className='container-settings-tab-example-stroke sexternal-url'>
                                                        </div>
                                                    </div>
                                                    <div className='container-settings-tab-example-card'>
                                                        <div className='container-settings-tab-example-card-title'>
                                                            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                                                            <span> {tokenName}</span>
                                                            <FontAwesomeIcon icon={faEllipsisVertical}></FontAwesomeIcon>
                                                        </div>
                                                        <div className='container-settings-tab-example-card-img'>
                                                            {
                                                                showImage &&
                                                                <img src={image_tempo} />
                                                            }
                                                            {
                                                                !showImage &&
                                                                <video id='video' src={image_tempo} className='collections-token-resume-card-video' style={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }}
                                                                    muted playsInline autoPlay={true}>
                                                                    {/* <source type="video/mp4" /> */}
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            }
                                                        </div>
                                                        <div className='container-settings-tab-example-card-buttons'>
                                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                                Send
                                                            </div>
                                                            <div className='container-settings-tab-example-card-buttons-card'>
                                                                List
                                                            </div>
                                                        </div>
                                                        <div className='container-settings-tab-example-card-description'>
                                                            <span className='container-settings-tab-example-card-description-title'>
                                                                Description
                                                            </span>
                                                            <span>
                                                                {tokenDescription}
                                                            </span>
                                                            <span className='container-settings-tab-example-card-description-url'>
                                                                {externalUrl}
                                                            </span>
                                                        </div>
                                                        <div className='container-settings-tab-example-card-bottom'>
                                                            <img src={bottomButton} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        </section>
                                    </section>
                                </div>
                                <div className='bottom_clear'></div>

                            </Form>
                        </div>
                    </div>
                </Container>
                <div className='bottom_clear'>

                </div>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Settings))
