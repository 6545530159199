import React, { useEffect, useState } from 'react'

import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"

import { isEmpty } from 'lodash'
import { connect } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import { toastrError } from "../../helpers/Toastr"
import DataService from '../../helpers/DataService'
import { apiError } from "../../store/actions"

import { loginSuccess, setToken } from '../../store/actions'
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}




const ResetPassword = (props) => {
    const [nextPage] = useState(props.location?.state?.from?.pathname || '/collections')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [profile, setProfile] = useState()
    let query = useQuery();
    const token = query.get('token')

    useEffect(() => {
        const { error } = props

        if (error) {
            toastrError('Utilizaste un usuario o password incorrecto', `Oop's`)
            props.apiError('')
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.error])

    useEffect(() => {
        if (!isEmpty(props.profile)) {
            setProfile(props.profile)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.profile])

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateNull()) {
            toastrError('No olvides ingresar tu contraseña correctamente')

            return false
        }
        const body = {
            token: token,
            password: password,
        }
        DataService.postAuthReset(body).then(response => {
            console.log(response)
            if (response && response.data.token) {
                props.setToken(response.data.token)
                DataService.getAuthWhoami().then(response => {
                    console.log('getAuthWhoami', response);
                    props.loginSuccess(response.data)
                    props.history.push('/collections')

                })
            }
        })
    }

    const _validateNull = () => {
        console.log('query', query);
        console.log('token', token);
        if (passwordRepeat === '') { return false }
        if (password === '') { return false }
        if (!token) { return false }
        if (password !== passwordRepeat) { return false }

        return true
    }



    function _passwordFormat(value) {
        const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/;
        if (!PASSWORD_REGEXP.test(value)) {
            return false;
        }
        return true;
    }



    const __resetForm = () => {
        return (
            <div className="form">
                <h1>Reset password</h1>

                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                New password
                            </Label>
                            <Input
                                type="password"
                                className="form-control"
                                id="password"
                                value={password}
                                onChange={event => {
                                    const { value } = event.target
                                    if (value === '' || !_passwordFormat(value)) {
                                        document.getElementById('password').classList.add('mt-required-error')
                                    } else {
                                        document.getElementById('password').classList.remove('mt-required-error')
                                    }
                                    setPassword(value)
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                Repeat Password
                            </Label>
                            <Input
                                type="password"
                                className="form-control"
                                id="password-repeat"
                                value={passwordRepeat}
                                onChange={event => {
                                    const { value } = event.target
                                    if (value === '' || !_passwordFormat(value)) {
                                        document.getElementById('password-repeat').classList.add('mt-required-error')
                                    } else {
                                        document.getElementById('password-repeat').classList.remove('mt-required-error')
                                    }
                                    setPasswordRepeat(value)
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={_handleSubmit}>
                        <div className="logo">
                            <img alt="Neefter" src="./assets/images/neefter.svg" />
                        </div>

                        {isEmpty(profile) && __resetForm()}
                    </Form>

                    <Row>
                        <Col sm={12} className="login-copyright">
                            <p>Copyright © {new Date().getFullYear()}. All rights reserved.</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error, profile } = state.Auth

    return { error, profile }
}

export default withRouter(
    connect(mapStatetoProps, {
        ResetPassword, apiError, loginSuccess,
        setToken
    })(ResetPassword)
)