import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
    Container,
} from 'reactstrap'

import Form from './components/Form'
import ApiService from '../../data/services/ApiService'
import { GeneralContext } from '../../context/'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const Edit = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext

    const { id } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Users`),
            parentLink: '/users',
            item: props.t(`Edit User`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            setLoading(true)

            try {
                const response = await ApiService.getUserById(id)
                setData(response.data.user)
            } catch (error) {
                
            } finally {
                setLoading(false)
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Form data={data} {...props} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Edit))