import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    Container, Button, Input
} from "reactstrap"
import { GeneralContext } from '../../context/'

import { withNamespaces } from "react-i18next"
import { useParams } from 'react-router-dom'
import DetailComponent from "./Components/Data"
import ImageComponent from "./Components/Image"
import DataService from '../../helpers/DataService'
import { segmentTrack } from "../../helpers/Segment"
import image_source from '../../assets/images/Variant2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { toastrError, toastrInfo, toastrSuccess } from '../../helpers/Toastr'
import { ethers } from 'ethers'
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import Modal from "../../components/Global/modals"


const contractAddress = "0x11Ef8475B65C63161e2269eD5b5FF6fa6c2Bf9bD";
const network = 'https://polygon.rpc.thirdweb.com' // use mumbai testnet
const ethers_provider = ethers.getDefaultProvider(network)
const abi = [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "string", "name": "name", "type": "string" }], "name": "NameChanged", "type": "event" }, { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "string", "name": "_name", "type": "string" }], "name": "setName", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
const { ethereum } = window;


const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb, setLoading } = generalContext
    const { collection_id } = useParams();
    const { connectOnLoad } = useParams();

    const [tokenDescription, setTokenDescription] = useState('')
    const [tokenExternal, setTokenExternal] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [collectionFamily, setCollectionFamily] = useState('')
    const [collectionSymbol, setCollectionSymbol] = useState('')
    const [network_id, setBlockchainNetwork] = useState(1)
    const [launch_date, setLaunchDate] = useState('')
    const [price, setPrice] = useState('')
    const [image_id, setImageId] = useState('')
    let [collection, setCollection] = useState(undefined)
    const [showDetails, setShowDetails] = useState(true)
    const [showConnect, setShowConnect] = useState(true)
    const [showBuyNow, setShowBuyNow] = useState(false)

    const [item_sold, setItemSold] = useState(0)
    const [item_total, setItemTotal] = useState(0)
    const [counter_tokens, setCounterTokens] = useState(0)
    const [showImage, setShowImage] = useState(true)
    const [explorerApi, setExplorerApi] = useState('')



    const image_multiplicator = 0.001;
    const [button_loader, setButtonLoader] = useState(false)
    const [touchScreenDevice, setTouchScreenDevice] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    let [agreement, setAgreement] = useState(false);
    let [mailForm, setMail] = useState('');
    let [mailSent, setMailSent] = useState(false);

    // Payment 
    const [loadPayment, setLoadPayment] = useState(false)
    const [provider, setProvider] = useState(undefined);
    const [walletKey, setWalletKey] = useState(undefined);
    const [sign, setSign] = useState(undefined);
    const neefter_cash = 'C123NqmCoXtEUt3sbJrHtYw3853b6y5tXttTLGHGvB6a'
    const lamport = 1_000_000_000
    const NEEFTER_SOLANA_NODE = 'https://orbital-fittest-bird.solana-mainnet.quiknode.pro/bc45b120094c7d49e064f515fafd083b0e248f70/'
    let [counter, setCounter] = useState(0);
    const [enableButton, setEnableButton] = useState(true)
    const [showErrorTransaction, setShowErrorTransaction] = useState(false)
    const [finalizedSignature, setFinalizedSignature] = useState(undefined)

    const [provitionalSignature, setProvitionalSignature] = useState(undefined)
    const [nofifyLoader, setNotifyLoader] = useState(false)
    const [provitionalReceipt, setProvitionalReceipt] = useState(undefined)



    const gas_fee = 0.012;
    const gas_fee_note = 'A small fee of 0.012 SOL will be charged to cover the network gas.'


    useEffect(() => {
        setTouchScreenDevice(isTouchScreenDevice())

        const provider = getProvider();
        if (provider) setProvider(provider);
        else setProvider(undefined);
    }, []);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
* @description gets Phantom provider, if it exists
*/
    const getProvider = () => {
        if ("solana" in window) {
            // @ts-ignore
            const provider = window.solana
            if (provider.isPhantom) {
                return provider
            }
        }
    };

    /**
    * @description prompts user to connect wallet if it exists
    */
    const connectWallet = async () => {
        // @ts-ignore
        const { solana } = window;
        const location = window.location.href
        if (solana) {
            try {
                const response = await solana.connect();

                setWalletKey(response.publicKey.toString());
                setShowConnect(false)
                setShowBuyNow(true)
                const solbalance = await getSolBalance(response.publicKey)

                if (!isNaN(solbalance)) {
                    setSolBalance(solbalance)
                }
                // dispatch(setActiveWallet(response.publicKey.toString()))
            } catch (err) {
                // { code: 4001, message: 'User rejected the request.' }
            }
        }

    };

    const getSolBalance = async (publicKey) => {
        let balance = 0;
        try {
            const network = NEEFTER_SOLANA_NODE;
            const connection = new Connection(network);
            balance = await connection.getBalance(
                publicKey,
                'confirmed'
            );
            balance = balance / lamport;
            return balance
        } catch (e) {
            console.log(`error getting balance: `, e);
        }

    }



    const _handleBuyCollection = () => {
        buyCollection()
    }
    let buyCollectionMobile = () => {
        const location = window.location.href
        document.location = `https://phantom.app/ul/browse/${location}`

    }

    let buyCollection = async () => {

        const provider = getProvider();

        setEnableButton(true);
        setLoadPayment(true);
        setShowErrorTransaction(false);
        const network = NEEFTER_SOLANA_NODE;
        const connection = new Connection(network);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        // setLoading(true);
        try {
            let transaction = Transaction;
            const to_public_key = new PublicKey(neefter_cash);
            const from_public_key = new PublicKey(walletKey);
            const price_w_gas = (price + '' === '0') ? gas_fee : price
            const lamp = lamport * price_w_gas;
            const txn = await createTransferTransaction(to_public_key, from_public_key, connection, lamp)

            const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
            try {
                return provider?.signAndSendTransaction(txn, { skipPreflight: true })
                    .then(async response => {
                        setProvitionalSignature(response?.signature)
                        setShowModal(true)
                        toastrInfo('Confirming your transaction.')
                    })
                    .catch(err => {
                        setShowErrorTransaction(true);
                        setLoading(false);
                        setEnableButton(false);
                        setLoadPayment(false);
                        console.log('buyCollection-err-------------------', err);
                    })
            } catch (err) {
                setShowErrorTransaction(true);
                setLoading(false);
                setEnableButton(false);
                setLoadPayment(false);
                console.log('buyCollection-err-------------------', err);
                // toastrError('Not Enough Funds: Please ensure your connected wallet has a sufficient balance')

            }
        } catch (err) {
            setShowErrorTransaction(true);
            setLoading(false);
            setEnableButton(false);
            console.log('buyCollection-err-------------------', err);
            // toastrError('Not Enough Funds: Please ensure your connected wallet has a sufficient balance')

        }
    }

    useEffect(() => {
        if (finalizedSignature) {

            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setSign(finalizedSignature)
            setLoading(false)

        }
    }, [finalizedSignature])

    useEffect(() => {
        if (sign) {
            _handleDeploy()
        }
    }, [sign])

    /**
* 
* 
* 
* 
* Purchase options
* 
* 
* 
* 
* 
*/

    /**
    * Creates an arbitrary transfer transaction
    * @param   {String}      publicKey  a public key
    * @param   {Connection}  connection an RPC connection
    * @returns {Transaction}            a transaction
    */
    const createTransferTransaction = async (toPublicKey, fromPublicKey, connection, lamports) => {
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: fromPublicKey,
                toPubkey: toPublicKey,
                lamports: lamports,
            })
        );


        transaction.feePayer = fromPublicKey;

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;


        return transaction;
    };

    const _handleDeploy = (e) => {
        setButtonLoader(true);
        setShowModal(true)
        //    Try to pay, open mail dialog
    }

    /**
     * Mumbai stuff
     * ____________________________________________________________________________________
     */

    const [balance, setBalance] = useState("Loading...")
    const [currentAccount, setCurrentAccount] = useState(null);
    const [maticBalance, setMaticBalance] = useState()
    const [solBalance, setSolBalance] = useState()
    const [transferBalance, setTransferBalance] = useState('0.001')
    const [transferAddress, setTransferAddress] = useState('0x11Ef8475B65C63161e2269eD5b5FF6fa6c2Bf9bD')
    const [name, setName] = useState("")
    const [nameInfo, setNameInfo] = useState("")
    const [blockhash, setBlockhash] = useState(undefined);
    const [loadMumbaiPayment, setLoadMumbaiPayment] = useState(false)
    const [finalizedSignatureMumbai, setFinalizedSignatureMumbai] = useState(undefined)

    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    const getName = async () => {
        let getName_provider = ethers.getDefaultProvider(network)
        const erc20 = new ethers.Contract(contractAddress, abi, getName_provider)
        const nameInf = await erc20.name()
        setNameInfo(nameInf)
    }


    const submitName = async () => {
        let submitName_provider = ethers.getDefaultProvider(network)
        const privateKey = "########################################";
        let wallet = new ethers.Wallet(privateKey, submitName_provider)
        const learnToken = new ethers.Contract(contractAddress, abi, wallet)

        learnToken.on("NameChanged", (newName) => {
        });

        const createReceipt = await learnToken.setName(name);
        await createReceipt.wait();
    }
    async function transferETH() {
        if (loadPayment) return;
        setLoadMumbaiPayment(true);
        setLoadPayment(true)
        try {
            let receiverAddress = transferAddress
            let amountInEther = transferBalance
            let tx = {
                to: receiverAddress,
                value: ethers.utils.parseEther(amountInEther)
            }

            const _provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = _provider.getSigner();
            // Send the transaction and log the receipt
            const receipt = await signer.sendTransaction(tx);
            setProvitionalReceipt(receipt)
            setShowModal(true)
            toastrInfo('Confirming your transaction.')

        } catch (err) {
            console.log('transferETH-err-------------------', err);
            // toastrError('Not Enough Funds: Please ensure your connected wallet has a sufficient balance')
            setLoadMumbaiPayment(false);
            setLoadPayment(false)

        }

    }

    const _finishEthValidation = async () => {
        toastrInfo('Minting your token.')
        setNotifyLoader(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = _provider.getSigner();
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        let wrongResult = false;
        let _transaction;
        let conditionExit = false;
        const receipt = provitionalReceipt;
        _transaction = await _provider.getTransactionReceipt(receipt.hash);
        // while (!conditionExit && counter < 10) {
        //     console.log('receipt.hash', receipt.hash);
        //     _transaction = await _provider.getTransactionReceipt(receipt.hash);
        //     console.log('_transaction_________________________' + counter, _transaction);
        //     await ndelay(5000)
        //     counter = counter + 1;
        //     if (_transaction) {
        //         conditionExit = (_transaction.status !== 2 && _transaction.status !== 3)
        //         wrongResult = _transaction.status === 1;
        //     }
        // }
        let counter_explorer_api = 0;
        let condition_exit_explorer = false;
        let loop;
        if (explorerApi) {
            loop = async value => {
                while (!condition_exit_explorer && counter_explorer_api < 10) {
                    const validatePath = `${explorerApi}?module=transaction&action=gettxreceiptstatus&txhash=${receipt.hash}`;
                    const explorer_validation = await DataService.getPath(validatePath)
                    await ndelay(5000)
                    counter = counter + 1;
                    value = value + 1
                    if (explorer_validation && explorer_validation.data && explorer_validation.data.result) {
                        condition_exit_explorer = ((explorer_validation.data.result.status + '') === '1')
                    }
                }
            }
        }
        if (loop) {
            await loop()
        }
        if (!condition_exit_explorer) {
            console.log('_transaction super errrrrorrrrr 1', _transaction);
            setLoadMumbaiPayment(false);
            setLoadPayment(false)
            toastrError('Something goes wrong')
        } else {
            const body = {
                signature: receipt.hash,
                email: mailForm || ''
            }
            DataService.postClaimByCollection(collection_id, body).then(response => {
                toastrSuccess('Mint success, check your wallet.')
                setNotifyLoader(false)
                setShowModal(false)
                setShowSuccessModal(true)
            }).catch(err => {
                toastrError('Something goes wrong')
            })
            // setFinalizedSignatureMumbai(receipt.hash)
            // setLoadMumbaiPayment(false);
        }

    }

    const checkWalletIsConnected = async () => {
        if (!ethereum) {
            console.log("Make sure you have Metamask installed!");
            return;
        } else {
            console.log("Wallet exists! We're ready to go!")
        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            setShowConnect(false)
            setShowBuyNow(true)
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                setMaticBalance(balanceInEth)
                setShowConnect(false)
                setShowBuyNow(true)
                console.log('setMaticBalance', balanceInEth);
            })

        } else {
            console.log("No authorized account found");
        }
    }

    // useEffect(() => {
    //     if (connectOnLoad) {
    //         connectWalletHandler()
    //     }
    // }, connectOnLoad)

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request(
                { method: 'eth_requestAccounts' }
            );
            setCurrentAccount(accounts[0]);
            setShowConnect(false)
            setShowBuyNow(true)
            if (!ethers_provider) return;
            ethers_provider.getBalance(account).then((balance) => {
                // convert a currency unit from wei to ether
                const balanceInEth = ethers.utils.formatEther(balance)
                setMaticBalance(balanceInEth)
                setShowConnect(false)
                setShowBuyNow(true)
                console.log('setMaticBalance', balanceInEth);
            })
        } catch (err) {
            const message = err.message || "";
            if (message.includes("wallet_requestPermissions") || message.includes("Already processing eth_requestAccounts")) {
                console.error(message)
                toastrError('A connection attempt has been detected. Check your wallet for pending actions.')

            }
            setShowConnect(true)
            setShowBuyNow(false)
        }
    }


    useEffect(() => {
        if (finalizedSignatureMumbai) {
            toastrInfo('transaccion approved', 'Info')
            setEnableButton(false)
            setBlockhash(finalizedSignatureMumbai)
            setLoading(false)
            setShowModal(true);
        }
    }, [finalizedSignatureMumbai])

    useEffect(() => {
        if (blockhash) {
            _handleDeployMumbai()
        }
    }, [blockhash])

    const _handleDeployMumbai = (e) => {
        setButtonLoader(true);
        setShowModal(true)

    }


    /**
     * ___________________________________________________________________________________________
     */



    useEffect(() => {
        if (!collection_id) return;
        setLoading(true)
        DataService.getPublicCollectionById(collection_id).then(
            response => {
                if (response && response.data) {
                    collection = response.data
                    setCollection(response.data)
                    console.log('collection', collection);
                    const { brackground, candy_machine_address, csv, description, external_url, network_config,
                        family, favicon, high_fidelity_icon, id, image_id, is_candy_machine, is_deployed,
                        is_deploying, launch_date, logo, name, network_id, organization_id, price, project_id,
                        slug, subdomain, symbol, seller_fee_basis_points,
                        creators, blockchain, step_number } = response.data;
                    const launch = launch_date ? new Date(launch_date) : '';

                    if (launch) {
                        const year = launch.getFullYear()
                        let month = parseInt(launch.getMonth() + 1)
                        month = (month + '').length > 1 ? month : '0' + month
                        const day = ((launch.getDate() + '').length > 1 ? launch.getDate() : '0' + launch.getDate());
                        let date = year + "-" + month + "-" + day;
                        setLaunchDate(date)
                    }
                    setExplorerApi(network_config.explorer_api)
                    setCollectionName(name || '')
                    setBlockchainNetwork(network_id || 1)
                    setCollectionFamily(family || '')
                    setImageId(response.data.image_id)
                    setCollectionSymbol(symbol || '')
                    setTokenExternal(external_url || '')
                    setTokenDescription(description || '')
                    setPrice(price || '0')
                    setTransferBalance(price + '' === 0 ? gas_fee + '' : price + '')

                    setLoading(false)

                }
            }
        )
        DataService.getPublicCollectionBalance(collection_id).then(response => {
            if (response && response.data) {
                setItemSold(parseFloat(response.data.item_sold ? response.data.item_sold + '' : '0'))
                setItemTotal(parseFloat(response.data.item_total ? response.data.item_total + '' : '0'))
            }

        })

    }, [collection_id])


    useEffect(() => {
        if (image_id) {
            const split = image_id.split('.')
            const extension = split[split.length - 1]
            if (extension.toLowerCase() === 'mp4') {
                setShowImage(false)
            } else {
                setShowImage(true)
            }
        }
    }, [image_id])

    const setThumbnail = async (image_id) => {
        try {
            getVideoCover(image_id);
            // print out the result image blob
        } catch (ex) {
            console.log("ERROR: ", ex);
        }
    }

    function getVideoCover(image_id) {
        var src = image_id; ///video url not youtube or vimeo,just video on server
        var video = document.createElement('video');

        video.src = src;

        video.width = 360;
        video.height = 240;

        var canvas = document.createElement('canvas');
        canvas.width = 360;
        canvas.height = 240;
        var context = canvas.getContext('2d');

        video.addEventListener('loadeddata', function () {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            var dataURI = canvas.toDataURL('image/jpeg');
        });
    }

    const sendTerms = () => {
        setShowModal(false);
        props.history.push('/terms-of-service');
    }
    const sendPrivacy = () => {
        setShowModal(false);
        props.history.push('/privacy-policy');
    }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
        }
    }
    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                _clearForm()
                setShowModal(false)
            }}
            body={modalBody}
            footer={footerModal}
            disableClose={true}
            styles={modalStyles}
        />
    )
    const __renderSuccesView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showSuccessModal}
            onRequestClose={() => {
                setShowSuccessModal(false)
            }}
            body={modalBodySuccess}
            footer={footerModalSuccess}
            disableClose={true}
            styles={modalStyles}
        />
    )



    const modalBody = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>We are processing your order</p>
            <p className='deploy-modal-subtitle'>
                You can add an email to receive notifications about your order progress. After confirming your payment,
                you can safely leave this page and we will send an email to let you know when your NFT is ready.
            </p>
            <div className='deploy-modal-input'>
                <Input
                    required
                    value={mailForm}
                    type="text"
                    className="input-white-input circle"
                    placeholder='Enter your mail'
                    id="modal_mail"
                    onChange={event => {
                        const { value } = event.target
                        if (value === '' || !isValidEmail(value)) {
                            document.getElementById('modal_mail').classList.add('input-error')
                        } else {
                            document.getElementById('modal_mail').classList.remove('input-error')
                        }
                        setMail(value)
                    }}
                />
            </div>

            <p className='deploy-modal-subtitle' style={{ flexWrap: 'wrap' }}>
                <input type="checkbox" id="cbox1"
                    onClick={() => setAgreement(!agreement)}
                    onChange={event => {
                    }} />
                <span style={{ paddingLeft: '8px' }}>By checking this box, you agree to the </span>
                <span className='mt-cursor-pointer' style={{ paddingLeft: '8px' }} onClick={sendPrivacy}>Privacy Policy </span>
                <span style={{ paddingLeft: '4px' }}>and </span>
                <span className='mt-cursor-pointer ' onClick={sendTerms} style={{ paddingLeft: '4px' }} >Terms of Service</span> .
            </p>
            <div>

            </div>
        </div>
    </section>
    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary no-hov"
            onClick={(e) => {
                e.preventDefault()
                sendMail()
            }}
        >
            {props.t(`CONTINUE MINT`)}
            {
                nofifyLoader && (
                    <div className='min-loader'>
                        <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw">
                            <div></div><div><div></div></div>
                        </div></div>
                    </div>
                )
            }
        </Button>

    </div>

    const modalBodySuccess = <section>
        <div className='deploy-modal'>
            <p className='deploy-modal-title'>Success</p>
            <p className='deploy-modal-success' >
                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
            </p>
            <p className='deploy-modal-subtitle'>
                Mint success, check your wallet.
            </p>
            <div>

            </div>
        </div>
    </section>
    const footerModalSuccess = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                _mintedSuccess()
            }}
        >
            {props.t(`OK`)}
        </Button>

    </div>


    const sendMail = () => {
        if (!agreement) {
            toastrError('First you need to agree our terms')
            return;
        }
        if (!_validateForm()) {
            toastrError('Email is required')
            return;
        }
        const body = {
            signature: (network_id + '' === '1') ? sign : blockhash,
            email: mailForm
        }
        if (network_id + '' === '1') {
            _finishValidation()
        } else {
            _finishEthValidation()
        }

    }

    const _finishValidation = async () => {
        toastrInfo('Minting your token.')
        const network = NEEFTER_SOLANA_NODE;
        setNotifyLoader(true)

        const connection = new Connection(network);
        const ndelay = (ms) => new Promise(res => setTimeout(res, ms));
        let getSignatureStatus;
        let conditionExit = false;
        while (!conditionExit && counter < 8) {
            getSignatureStatus = await connection.getSignatureStatus(provitionalSignature);
            await ndelay(5000)
            conditionExit = (!!getSignatureStatus.value && getSignatureStatus.value.confirmationStatus === 'finalized');
            counter = counter + 1;
        }

        const body = {
            signature: provitionalSignature,
            email: mailForm || ''
        }
        DataService.postClaimByCollection(collection_id, body).then(response => {
            toastrSuccess('Mint success, check your wallet.')
            setNotifyLoader(false)
            setShowModal(false)
            setShowSuccessModal(true)
            // ndelay(1000)
            // window.location.reload();
        }).catch(err => {
            toastrError('Something goes wrong')
        })
    }
    const _mintedSuccess = () => {
        window.location.reload();
    }


    const _validateForm = () => {
        if (mailForm === '' || !isValidEmail(mailForm) || !agreement) { return false }
        return true
    }
    const _clearForm = () => {
        setMail('')
        setMailSent(false)
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }


    return (
        <React.Fragment>
            {
                showModal && __renderPOView()
            }
            {
                showSuccessModal && __renderSuccesView()
            }
            <div className='collections-reclaim-rebackground'>

            </div>
            {
                (showImage && !!image_id && image_id.length > 0) &&
                <div style={{ backgroundImage: `url(${image_id})` }} className='collections-reclaim-background'>

                </div>
            }
            {
                (!showImage && !!image_id) &&
                <div style={{ backgroundImage: `url(${image_id})` }} className='collections-reclaim-background'>
                    <video id='video' src={image_id} style={{
                        width: '100%',
                        height: '100%'
                    }}
                        muted playsInline autoPlay={false}>
                        {/* <source type="video/mp4" /> */}
                        Your browser does not support the video tag.
                    </video>
                </div>
            }
            <div className='collections-reclaim-logo'>
                <img alt="Neefter" src={"/assets/images/Logotype1.png"} />
            </div>
            <div className='collections-reclaim'>
                {
                    image_id &&
                    <ImageComponent collection={collection} image_id={image_id} is_desktop={true}></ImageComponent>
                }
                {
                    !!collection &&
                    <DetailComponent collection={collection} item_sold={item_sold}
                        item_total={item_total} counter={counter_tokens} setCounter={setCounterTokens}
                        network_id={network_id}
                        touchScreenDevice={touchScreenDevice}
                        provider={provider}
                        walletKey={walletKey}
                        connectWallet={connectWallet}
                        buyCollectionMobile={buyCollectionMobile}
                        connectWalletHandler={connectWalletHandler}
                        showConnect={showConnect}
                        showBuyNow={showBuyNow}
                        _handleBuyCollection={_handleBuyCollection}
                        loadPayment={loadPayment}
                        transferETH={transferETH}
                        maticBalance={maticBalance}
                        solBalance={solBalance}
                    ></DetailComponent>
                }
            </div>
            {/* 
            <div className='page-content' >
                <Container fluid>

                    {
                        !showDetails && showConnect && (
                            <section className='collections-claim-connect'>
                                <div className='collections-claim-connect-logo'>
                                    {
                                        image_source &&
                                        <img src={image_source}></img>
                                    }
                                </div>
                                <div className='collections-claim-connect-card'>
                                    <div className='collections-claim-connect-card-ico'>
                                        <FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon>
                                    </div>
                                    <div className='collections-claim-connect-card-title'>
                                        Connect your wallet
                                    </div>
                                    <div className='collections-claim-connect-card-subtitle'>
                                        Please connect your wallet to continue with the transaction.
                                    </div>
                                    {
                                        ((network_id === 1 || network_id === '1') && !touchScreenDevice) && (
                                            <div>
                                                {provider && !walletKey && (
                                                    <div className='collections-claim-connect-card-button'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            segmentTrack(`connect wallet to deploy`)
                                                            connectWallet()
                                                        }}>
                                                        CONNECT WALLET
                                                    </div>
                                                )}
                                                {!provider && (
                                                    <p>
                                                        No provider found. Install{" "}
                                                        <a href="https://phantom.app/">Phantom Browser extension</a>
                                                    </p>
                                                )}
                                            </div>
                                        )
                                    }
                                    {
                                        ((network_id === 1 || network_id === '1') && touchScreenDevice) && (
                                            <div>
                                                {provider && !walletKey && (
                                                    <div className='collections-claim-connect-card-button'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            segmentTrack(`connect wallet to deploy`)
                                                            connectWallet()
                                                        }}>
                                                        CONNECT WALLET
                                                    </div>
                                                )}
                                                {!provider && (
                                                    <div className='collections-claim-connect-card-button'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            segmentTrack(`connect wallet to deploy`)
                                                            buyCollectionMobile()
                                                        }}>
                                                        CONNECT WALLET
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                    {
                                        (network_id === 2 || network_id === '2') &&
                                        <div className='collections-claim-connect-card-button'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                segmentTrack(`Connect wallet to deploy`)
                                                connectWalletHandler()
                                            }}>
                                            CONNECT WALLET
                                        </div>
                                    }
                                </div>
                            </section>
                        )
                    }
                    {
                        !showDetails && showBuyNow && (
                            <section className='collections-claim-buy'>
                                <div className='collections-claim-connect-logo'>
                                    <img src={image_source}></img>
                                </div>
                                <div className='collections-claim-connect-card'>
                                    <div className='collections-claim-connect-card-title'>
                                        Buy now
                                    </div>
                                    <div className='collections-claim-connect-card-subtitle'>
                                        Once the transaction is confirmed, the NFT will be sent to your wallet instantly.
                                    </div>
                                    {
                                        (price + '' === '0') && (
                                            <div className='collections-claim-connect-card-subtitle'>
                                                {gas_fee_note}
                                            </div>
                                        )
                                    }
                                    <div className='collections-claim-connect-card-input'>
                                        <div className='collections-claim-connect-card-input-price'>
                                            {price + '' === '0' ? gas_fee : price}
                                        </div>
                                        <div className='collections-claim-connect-card-input-type'>
                                            {collectionSymbol}
                                        </div>
                                    </div>

                                    {
                                        (network_id === 1 || network_id === '1') &&
                                        <div className='collections-claim-connect-card-button' onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`pay deploy`)
                                            _handleBuyCollection()
                                        }}>
                                            {props.t(`CONFIRM`)}
                                            {loadPayment &&
                                                <div className='min-loader'>
                                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw black">
                                                        <div></div><div><div></div></div>
                                                    </div></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        (network_id === 2 || network_id === '2') &&
                                        <div className='collections-claim-connect-card-button' onClick={(e) => {
                                            e.preventDefault()
                                            segmentTrack(`pay deploy`)
                                            transferETH()
                                        }}>
                                            {props.t(`CONFIRM`)}
                                            {loadPayment &&
                                                <div className='min-loader'>
                                                    <div className='loadingio-spinner-dual-ring-e'><div className="ldio-tw black">
                                                        <div></div><div><div></div></div>
                                                    </div></div>
                                                </div>
                                            }
                                        </div>
                                    }





                                </div>

                            </section>
                        )
                    }
                </Container>
            </div> */}
        </React.Fragment >
    )
}


export default withRouter(withNamespaces()(Index))

