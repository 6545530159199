import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Container,
    UncontrolledButtonDropdown,
    DropdownMenu,
    FormGroup,
    Input,
    DropdownToggle,
    DropdownItem,
    Row,
    Col
} from "reactstrap"

import { withNamespaces } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { GeneralContext } from '../../context/'
import { segmentTrack } from "../../helpers/Segment"
import DataService from '../../helpers/DataService'

import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import Modal from "../../components/Global/modals"
import ProgressHeader from '../../components/Global/Progress-header'
import { toastrError, toastrInfo } from "../../helpers/Toastr"
import TabHeaderMenu from './components/tab-header-menu'
import ToggleSwitch from "../../components/Global/toggleSwitch"
import backgroundImage from '../../assets/images/avatar-1.png'
import { toBase64 } from "../../helpers/utils"
import { fileReader } from '../../helpers/constants'

const Legendary = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext
    // const { project_id } = useParams();
    let [project_id, setProjectId] = useState([]);
    const { setLoading, importDb, exportDb } = generalContext
    const { currentProject, setProject } = generalContext
    const { collection_id } = useParams();
    const [setting_name, setSettingName] = useState('')

    let [assets, setAssets] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [totalHash, setTotalHash] = useState([]);
    const [showModalCard, setShowModalCard] = useState()
    let [array_properties, setArrayProperties] = useState([])

    const [collection_saved, setCollectionSaved] = useState('')
    const [collection_setting_saved, setCollectionSettingSaved] = useState('')
    const [array_filters, setArrayFilters] = useState([])
    let [filteredData, setFilteredData] = useState([])
    let [filteredMixedData, setFilteredMixedData] = useState([])
    const limit = 50;
    let [actualPage, setActualPage] = useState(0)

    let [filteredMixedDataLength, setFilteredMixedDataLength] = useState(0)

    let [filteredDataLength, setFilteredDataLength] = useState(0)
    let [submitLoading, setSubmitLoading] = useState(false)

    let [step_number, setStepNumber] = useState(0);
    let [generateAllLoader, setGenerateAllLoader] = useState(false)
    let [cache_zip, setCacheZip] = useState()

    const [showNewModalCard, setShowNewModalCard] = useState(false)

    let [fetched, setFetched] = useState(false)



    /************************+
     * Dialog form options********************+
     * 
     * 
     * 
     * 
     */
    let [dialogProperties, setDialogProperties] = useState([])
    /*
    example of dialog property
    {
        name:
        trait:
        active:
        deletable
    }
    */
    let [collectionName, setCollectionName] = useState('')
    const [background, setBackground] = useState('')
    const [image_tempo, setImageTempo] = useState(undefined)
    const [file, setFile] = useState(undefined)

    let [newAttributeName, setNewAttributeName] = useState('')
    let [newTraitName, setNewTraitName] = useState('')

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('upload_file', file)
        return DataService.uploadImage(formData)
    }

    const _handleFileBackground = async (event) => {
        let files = event?.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']


        if (types.every(type => files[0] && files[0].type !== type)) {
            return
        }
        setLoading(true)
        const _file = await uploadFileToServer(files[0])
        console.log('_file', _file);
        setBackground(_file)
        setFile(_file)
        const file_name = files[0].name.split('.')[0]
        setCollectionName(file_name)
        setImageTempo(fileReader + _file)
        setLoading(false)

    }

    function uploadFileToServer(file) {
        const promise = new Promise(function (resolve, reject) {
            console.log('file', file);
            const name = file.name;
            const name_split = name.split('.')
            let ext = name_split[name_split.length - 1]
            if (ext === 'jpg') {
                ext = 'jpeg'
            }
            DataService.getUploadkey(ext.toLowerCase()).then(async response => {
                console.log('Uploading to: ', response.data.uploadURL)
                fetch(response.data.uploadURL, {
                    method: 'PUT',
                    body: file
                }).then(result => {
                    console.log('Result: ', result)
                    resolve(response.data.Key)
                }).catch(err => {
                    reject(err)
                })

            }).catch(err => {
                console.log('err', err);
                reject(err)
            })
        })
        return promise
    }

    const handleChangePropertyTrait = (event) => {
        event.preventDefault();
        const val = 'property_trait';
        const index = event.target.id.slice(val.length);

        const value = event.target.value;


        setDialogProperties(item => {
            const newArr = item.slice();
            newArr[index].value = value;
            if (!value) {
                newArr[index].error = true;
                document.getElementById(val + index).classList.add('input-error')
            } else {
                newArr[index].error = false;
                document.getElementById(val + index).classList.remove('input-error')
            }
            return newArr;
        });
    };
    const handleChangePropertyActive = (event, index) => {

        const value = event

        setDialogProperties(item => {
            const newArr = item.slice();
            newArr[index].active = value;
            return newArr;
        });
    };

    const addProperty = () => {
        if (!newAttributeName) {
            toastrError('The attribute name of the new trait is required')
            return;
        }
        if (!newTraitName) {
            toastrError('The trait name of the new trait is required')
            return;
        }
        dialogProperties = [
            ...dialogProperties,
            {
                trait_type: newAttributeName,
                value: newTraitName,
                active: true,
                deletable: true,
            }
        ]
        setDialogProperties([...dialogProperties]);
        setNewAttributeName('')
        setNewTraitName('')

    };

    const saveLegendary = async () => {
        if (!collectionName) {
            toastrError('The collection name is required to create a legendary token')
            return;
        }
        if (!background) {
            toastrError('The file image is required to create a legendary token')
            return;
        }
        console.log('dialogProperties', dialogProperties);
        const availableTraits = dialogProperties.filter(dp => dp.active && !!dp.value);
        // if (availableTraits.length < 1) {
        //     toastrError('At least one trait is required to create a legendary token')
        //     return;
        // }
        setLoading(true)
        let postArray = [];
        if (!!newAttributeName && !!newTraitName) {
            postArray = [
                {
                    trait_type: newAttributeName,
                    value: newTraitName
                }
            ]
        }
        const avtraits = availableTraits.map(at => {
            return {
                trait_type: at.trait_type,
                value: at.value,
                trait_id: !!at.trait_id ? at.trait_id : undefined
            }
        })
        console.log('[...avtraits, ...postArray]', [...avtraits, ...postArray]);
        // const _file = await toBase64(file)
        const body = {
            name: collectionName,
            image: fileReader + background,
            attributes: [...avtraits, ...postArray]
        }
        DataService.postCollectionNftGenerateById(collection_id, body).then(response => {
            setLoading(false)
            setFilteredData([])
            setFilteredMixedData([])
            setShowNewModalCard(false)
            resetLegendaryForm()
            _checkLegendaryCollections()
        })


    }

    /************************ */

    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredMixedDataLength < filteredDataLength) {
                    setActualPage(++actualPage)
                }
            }
        }

    }, [])
    useEffect(() => {
        window.onscroll = function () {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
                if (filteredMixedDataLength < filteredDataLength) {
                    setActualPage(++actualPage)
                }
            }
        }

    }, [filteredDataLength, filteredMixedDataLength])

    useEffect(() => {

        if (!currentProject) return;
        setBreadcrumb({
            icon: '',
            parent: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
            parentLink: `/collections`,
            item: props.t(`Legendary Nft`)
        })
        setProjectId(currentProject.id)

        const doc = document.getElementById('content')
        if (doc) {
            doc.classList.add('no-max-width')
        }

    }, [currentProject])


    useEffect(() => {
        setLoading(false)
        if (collection_id) {
            setLoading(true)
            DataService.getCollectionById(collection_id).then(
                response => {
                    if (response && response.data) {
                        const { step_number } = response.data;
                        setStepNumber(step_number)
                    }
                    _checkLegendaryCollections();
                })
            _checkCollectionProperties()



        }
    }, [collection_id])

    const _checkCollectionProperties = () => {
        DataService.getCollectionAllTraitDataById(collection_id).then(responnse => {
            const { data } = responnse.data;
            const orderedData = data.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
            })

            setArrayProperties([...orderedData])

            const preDialogProperties = orderedData.map(ap => {
                return {
                    trait_type: ap.name,
                    value: '',
                    active: true,
                    deletable: false,
                    trait_id: ap.id
                }
            })
            setDialogProperties([...preDialogProperties])
            console.log('dialog properties', dialogProperties);
            setLoading(false)
        })
    }

    const _checkLegendaryCollections = () => {
        console.log('_checkLegendaryCollections');
        const params = {
            is_generative: false
        }
        DataService.getCollectionNftListById(collection_id, params).then(response => {
            console.log(response.data);
            const { data } = response.data
            setFilteredData(data)
        })
    }
    useEffect(() => {
        console.log('assets', assets);
        if (assets.length === 0) {
            filteredData = assets;
            setFilteredData([...filteredData])
            setFilteredMixedData([])
        } else {
            filteredData = assets;
            setFilteredData([...filteredData])
        }

    }, [assets])

    const deleteCollection = (item_id) => {
        return DataService.delCollectionNftById(collection_id, item_id).then(response => {
            if (response) {
                toastrInfo('Legendary asset deleted succesfully')
                return _checkLegendaryCollections()
            }
        })
    }


    useEffect(() => {
        console.log('filteredData', filteredData);
        console.log('filteredMixedData', filteredMixedData);
        if (actualPage !== 1) {
            setActualPage(1);
        } else {
            filteredMixedData = [];
            setFilteredMixedData([])
        }
        filteredDataLength = filteredData.length;
        setFilteredDataLength(filteredDataLength)

        if (actualPage > 0) {
            if (JSON.stringify(filteredMixedData) === JSON.stringify(filteredData)) return;
            const set_value = [...filteredMixedData, ...filteredData.slice((actualPage - 1) * limit, (actualPage * limit))]
            setFilteredMixedData([...set_value])
            filteredMixedDataLength = set_value.length;
            setFilteredMixedDataLength(filteredMixedDataLength)
        }
    }, [filteredData])

    useEffect(() => {
        console.log('actualPage', actualPage);
        if (actualPage > 1) {
            const actual = (actualPage - 1) * limit;
            const next = (actualPage * limit);
            setLoading(true);
            if (actual > filteredData.length) {
                setLoading(false);
                return;
            }
            toastrInfo('Loading more...', 'Info')

            setTimeout(() => {
                if (next >= filteredData.length - 1) {
                    setFilteredMixedData([...filteredMixedData, ...filteredData.slice(actual, filteredData.length)])
                    setLoading(false);
                } else {
                    setFilteredMixedData([...filteredMixedData, ...filteredData.slice(actual, next)])
                    setLoading(false);
                }
            }, 1500);

        }
    }, [actualPage])


    function exist(layers, fill) {

        let find_value;
        const find_att = layers.find(_layer => _layer.layer === fill.type)

        if (!!find_att) {
            const value = find_att.trait.name.split('.')[0];
            const find_filter = fill.options.find(opt => opt.value === value)

            if (find_filter) {
                find_value = find_filter;
                return true;
            }
        }
        return !!find_value;
    }


    let delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    const _handleSubmit = async (e) => {
        // props.history.push(`/projects/${project_id}/collections/minting`)
        setLoading(true)
        setSubmitLoading(true);
        const body = {
            step_number: 5,
        }
        DataService.putCollectionById(collection_id, body).then(response => {
            if (response && response.data) {
                setLoading(false)
                setSubmitLoading(false);
                props.history.push(`/collections/generative/token/${collection_id}`)
            }
        })

    }


    const [show, setShow] = useState(false);







    const itemsPerPage = 20;
    const [hasMore, setHasMore] = useState(true);
    const [records, setrecords] = useState(itemsPerPage);
    const loadMore = () => {


        if (records === filteredData.length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                if (records >= filteredData.length) {
                    setrecords(filteredData.length);
                } else {
                    setrecords(records + itemsPerPage);
                }
            }, 3000);
        }
    };

    const modalCardStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '86%',
            maxWidth: '1120px',
            minWidth: '900px',
        }
    }
    const __renderCardDetailView = (card) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={!!showModalCard}
            onRequestClose={() => {
                setShowModalCard(undefined)
            }}
            body={getModalContainerCard(card)}
            footer={footerModal}
            styles={modalCardStyles}
        />
    )

    const getModalContainerCard = (card) => {


        return <section className='modalcard'>
            <div className='modalcard-img'>
                <div className='modalcard-img-picture generative'>
                    <div className='card-picture' >

                        <img style={{ 'position': 'absolute' }} alt={card.name} src={card.image} />

                    </div>
                </div>
            </div>
            <div className='modalcard-body'>
                <div className='modalcard-body-title'>
                    <p>{card.name}</p>
                </div>
                <div className='modalcard-body-cards'>
                    {
                        card.attributes_json.map((layer, key) => (
                            <div className='modalcard-body-cards-item' key={key}>
                                <div style={{ display: 'inline', maxWidth: '100px', padding: '0 12px' }}>
                                    <p className='modalcard-body-cards-item-top'>
                                        {layer.trait_type}
                                    </p>
                                    <p className='modalcard-body-cards-item-middle'>
                                        {layer.value}
                                    </p>
                                    <p className='modalcard-body-cards-item-bot'>
                                        0% have this
                                    </p>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    }
    const footerModal = <div className='modalcard-footer'>
        <Button
            type="button"
            className="theme-button-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowModalCard(undefined)
            }}
        >
            {props.t(`Close`)}
        </Button>

    </div>
    const __renderNewCardDetailView = (card) => (
        <Modal
            className="xxl global-dialog"
            title={props.t(``)}
            isOpen={showNewModalCard}
            onRequestClose={() => {
                setShowNewModalCard(false)
            }}
            body={bodyNewCardDetail}
            footer={footerModalNew}
            styles={modalCardStyles}
        />
    )
    const resetLegendaryForm = async () => {
        setCollectionName('')
        setBackground('')
        setNewAttributeName('')
        setNewTraitName('')
        setImageTempo(undefined)
        setFile(undefined)
        console.log('array_properties', array_properties);
        const preDialogProperties = array_properties.map(ap => {
            return {
                trait_type: ap.name,
                value: '',
                active: true,
                deletable: false
            }
        })
        setDialogProperties([...preDialogProperties])

    }

    const bodyNewCardDetail = <div className='modalcard'>
        <div className='modalcard-legendary'>
            <div className='modalcard-legendary-title'>
                Create a Legendary Token
            </div>
            <div className='modalcard-legendary-subtitle'>
                Legendary tokens are those unique, special ones
            </div>
            <div className='modalcard-legendary-description'>
                By creating a legendary token, you make sure Neefter generates it with the traits you define below.
            </div>
            <div className='modalcard-legendary-contain'>
                <div className='modalcard-legendary-contain-body'>
                    <div className='modalcard-legendary-input-title'>
                        Legendary Name
                    </div>
                    <div className='modalcard-legendary-input-row'>

                        <Input
                            required
                            value={collectionName}
                            type="text"
                            className="input-white-input circle"
                            placeholder='Collection name'
                            id='collectionName'
                            style={{ flex: 1, margin: '16px 0' }}
                            onChange={event => {
                                const { value } = event.target
                                if (!value) {
                                    document.getElementById('collectionName').classList.add('input-error')
                                } else {
                                    document.getElementById('collectionName').classList.remove('input-error')
                                }
                                setCollectionName(event.target.value)
                            }}
                        />

                        <FormGroup>
                            <div className='collections-minting-control' >
                                <div className='collections-minting-control-pre' onClick={() => document.getElementById('upload_background')?.click()}>
                                    <span>Choose File</span>
                                </div>
                                <div className='collections-minting-control-input' onClick={() => document.getElementById('upload_background')?.click()}>
                                    <Input
                                        required
                                        value={background}
                                        type="text"
                                        className="form-control"
                                        placeholder='No file choosen'
                                        disabled
                                        id="background"
                                    />
                                    <input type="file"
                                        id="upload_background"
                                        hidden
                                        onChange={event => {
                                            _handleFileBackground(event)
                                        }}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                    <div className='modalcard-legendary-input-title space-top'>
                        Traits
                    </div>
                    {
                        dialogProperties.map((item, i) => {
                            return (
                                <div className='modalcard-legendary-traits' key={'cont' + i}>
                                    <div className='modalcard-legendary-traits-title'>
                                        {item.trait_type}
                                    </div>
                                    <div className='modalcard-legendary-traits-contain'>
                                        <div className='modalcard-legendary-traits-input'>
                                            <Input
                                                required
                                                value={item.value}
                                                type="text"
                                                className="input-white-input circle"
                                                placeholder=''
                                                id={"property_trait" + i}
                                                disabled={!item.active}
                                                onChange={handleChangePropertyTrait}
                                            />
                                        </div>
                                        <div className='modalcard-legendary-traits-switch'>
                                            <ToggleSwitch isToggled={item.active} setIsToggled={(event) => {

                                                handleChangePropertyActive(event, i)
                                            }} size={'medium'} />
                                            {
                                                item.active &&
                                                <span className='modalcard-legendary-traits-switch-text'>On</span>
                                            }
                                            {
                                                !item.active &&
                                                <span className='modalcard-legendary-traits-switch-text'>Off</span>
                                            }
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }

                    <div className='modalcard-legendary-new'>
                        <div className='modalcard-legendary-new-input'>
                            <Input
                                required
                                value={newAttributeName}
                                type="text"
                                className="input-white-input circle"
                                placeholder='Attribute name'
                                id='newAttributeName'
                                onChange={event => {
                                    const { value } = event.target
                                    if (!value) {
                                        document.getElementById('newAttributeName').classList.add('input-error')
                                    } else {
                                        document.getElementById('newAttributeName').classList.remove('input-error')
                                    }
                                    setNewAttributeName(event.target.value)
                                }}
                            />
                        </div>
                        <div className='modalcard-legendary-new-input'>
                            <Input
                                required
                                value={newTraitName}
                                type="text"
                                className="input-white-input circle"
                                placeholder='Trait name'
                                id='newTraitName'
                                onChange={event => {
                                    const { value } = event.target
                                    if (!value) {
                                        document.getElementById('newTraitName').classList.add('input-error')
                                    } else {
                                        document.getElementById('newTraitName').classList.remove('input-error')
                                    }
                                    setNewTraitName(event.target.value)
                                }}
                            />
                        </div>
                        <div className='modalcard-legendary-new-button' onClick={addProperty}>
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
                <div className='modalcard-legendary-contain-img' >
                    <img alt="item" src={image_tempo ? image_tempo : backgroundImage} />
                </div>
            </div>

        </div>

    </div>


    const footerModalNew = <div className='modalcard-footer end'>
        <Button
            type="button"
            className="theme-button-white-primary"
            onClick={(e) => {
                e.preventDefault()
                setShowNewModalCard(undefined)
            }}
        >
            {props.t(`Cancel`)}
        </Button>
        <Button
            type="button"
            className="theme-button-primary "
            onClick={(e) => {
                e.preventDefault()
                saveLegendary()
            }}
        >
            {props.t(`Save legendary`)}
        </Button>

    </div>

    const showModalNew = () => {
        resetLegendaryForm()
        setShowNewModalCard(true)
    }

    return (
        <React.Fragment>
            <ProgressHeader key={'progress-1'} bgcolor={'#A066FF'} completed={80} percentage={80}></ProgressHeader>

            {
                showModalCard && __renderCardDetailView(showModalCard)
            }
            {
                showNewModalCard && __renderNewCardDetailView()

            }
            <div className="page-content">
                <Container fluid>
                    <div className='collections-settings-header'>
                        <TabHeaderMenu step_number={step_number} active={5} collection_id={collection_id}></TabHeaderMenu>


                        <div className='collections-settings-header-button'>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary desktop"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`go to deploy settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button>
                        </div>
                    </div>
                    <section className='collections-token'>
                        <div className='collections-token-resume'>
                            <div className='collections-token-resume-list container-token-table' >
                                {
                                    !generateAllLoader &&
                                    <div className='collections-token-resume-list-infinite'>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card' onClick={() => {
                                            showModalNew()
                                        }}>
                                            <div>
                                                <CardBody>
                                                    <div className='card-picture generative' style={{ height: '200px' }} >
                                                        <div className='card-picture-empty'>
                                                            <FontAwesomeIcon icon={faPlus} className="card-picture-empty-icon"></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </div>

                                            <CardFooter className="bg-transparent border-top">
                                                <div className="d-flex">
                                                    <div className="flex-fill container-token-nowrap">
                                                        <div className='container-token-nowrap'>
                                                            Nuevo
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardFooter>
                                        </Card>

                                        {filteredData.map(
                                            (item, key) => (
                                                // <Col xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
                                                <Card className='card-borderless collections-token-resume-card theme-token-card' key={key} >
                                                    <div>
                                                        <CardBody onClick={() => {
                                                            setShowModalCard(item)
                                                        }}>
                                                            <div className='card-picture generative' style={{ height: '200px' }} >
                                                                <img className='generative' style={{ width: '100%', 'zIndex': key, 'position': 'absolute' }} alt={item.name} src={item.image} key={'avatar' + key} />
                                                            </div>
                                                        </CardBody>
                                                    </div>

                                                    <CardFooter className="bg-transparent border-top">
                                                        <div className="d-flex mrow">
                                                            <div className="flex-fill container-token-nowrap">
                                                                <div className='container-token-nowrap'>
                                                                    {item.name}
                                                                </div>

                                                            </div>
                                                            <div className='rowcard-action'>
                                                                <UncontrolledButtonDropdown
                                                                    className="d-inline-block">
                                                                    <DropdownToggle
                                                                        className="btn header-item waves-effect"
                                                                        id="page-header-user-dropdown"
                                                                        tag="button"
                                                                    >
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <DropdownItem tag="a" href="/#" onClick={(e) => {
                                                                            e.preventDefault()
                                                                            deleteCollection(item.id)
                                                                            segmentTrack('Delete collection')
                                                                        }}>
                                                                            <i className="bx bx-trash font-size-17 align-middle mr-1"></i>
                                                                            {props.t(`Delete`)}
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </div>
                                                        </div>
                                                    </CardFooter>
                                                </Card>
                                            )
                                        )}
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>
                                        <Card className='card-borderless collections-token-resume-card theme-token-card fill'></Card>

                                    </div>
                                }


                            </div>
                            {
                                filteredMixedData.length !== filteredData.length &&
                                <div style={{ height: '300px' }}>

                                </div>
                            }
                        </div>
                    </section>
                    <Row>
                        <Col>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-secundary mobile"
                                onClick={(e) => {
                                    e.preventDefault()
                                    segmentTrack(`go to deploy settings`)
                                    _handleSubmit()
                                }}
                            >
                                {props.t(`Next step`)}
                            </Button></Col>
                    </Row>

                </Container>
            </div >
        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(Legendary))
