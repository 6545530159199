import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Card,
    CardBody,
    CardFooter,
    Button
} from "reactstrap"

import { GeneralContext } from '../../../context'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import DataService from '../../../helpers/DataService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import CollectionsFilter from './collections-filter'
import Modal from "../../../components/Global/modals"
import { segmentTrack } from "../../../helpers/Segment"
import layer from "../../../assets/images/layers-color.png"
import DataTableCard from "./Datatable-card"

import cn from 'classnames';
import { toastrError, toastrSuccess } from "../../../helpers/Toastr"

const DataTable = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const { setActiveTab } = props;
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    let [project_id, setProjectId] = useState('');
    let [search, setSearch] = useState('');
    let [sort, setSort] = useState();
    let [display, setDisplay] = useState('table');
    const [showModal, setShowModal] = useState(false)
    let [queryMap, setQueryMap] = useState({
        network_id: 1, is_candy_machine: true, is_deployed: true
    });

    const { currentProject, setProject } = generalContext
    const ticket_id = 1;

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            minHeight: ' 549px',
            minWidth: '75rem',
        }
    }
    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project_id])

    useEffect(() => {

        if (!currentProject) return;

        setProjectId(currentProject.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])


    useEffect(() => {
        const _search = setTimeout(() => {
            //Your search query and it will run the function after 3secs from user stops typing

            if (!!search) {
                setQueryMap({ ...queryMap, 'name__icontains': search })
            } else {
                delete queryMap['name__icontains']
                setQueryMap({ ...queryMap })
            }
        }, 500);
        return () => clearTimeout(_search)

    }, [search])

    useEffect(() => {
        fetchData()
    }, [queryMap])

    useEffect(() => {

        if (sort && sort !== 'No sort') {
            setQueryMap({ ...queryMap, order_by: sort })
        } else {
            delete queryMap.order_by
            setQueryMap(queryMap)
        }
    }, [sort])


    const fetchData = async () => {

        setLoading(true)

        try {
            if (!project_id) return;
            const params = { project_id: project_id, ...queryMap }

            DataService.getCollection(params).then(response => {

                setData(response.data.data)
                setFilteredData(response.data.data)
            })
        } catch (error) {
            toastrError(props.t(`I couldn't fetch your collections`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            await DataService.deleteCollectionById(id)
            const filtered = await filteredData.filter((v) => { return v.id !== id })
            setData(filtered)
            setFilteredData(filtered)

            segmentTrack('collection deleted')
            toastrSuccess(props.t(`collection deleted`), '¡Success!')
        } catch (error) {
            toastrError(props.t(`I couldn't delete the collection`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const __renderPOView = (params = null) => (
        <Modal
            className="xxl"
            title={props.t(``)}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            body={modalBody}
            footer={<div></div>}
            styles={modalStyles}
        />
    )
    const filteredDataView = [{
        id: 'generative',
        name: 'Generative Art',
        description: <span>Bring in your layers, we’ll <br />generate the art for you</span>,
        avatar: layer,
        // class: 'gray-scale',
        route: '/collections/generative',
        disabled: false,
    }, {
        id: 'fixed',
        name: 'Fixed Art',
        description: <span>Art ready? Let’s prepare <br />them to deploy</span>,
        avatar: '/assets/images/avatar-2.png',
        route: '/collections/create',
        disabled: false,
    },
    {
        id: 'oneandone',
        name: '1 / 1',
        description: <span>Art ready? Let’s prepare <br />them to deploy</span>,
        avatar: '/assets/images/avatar-2.png',
        route: '/collections/oneandone',
        disabled: false,
    },
    {
        id: 'quickmint',
        name: 'Quick Mint',
        description: <span>Art ready? Let’s prepare <br />them to deploy</span>,
        avatar: '/assets/images/avatar-2.png',
        route: '/collections/quickmint',
        disabled: false,
    },
    ]



    const modalBody = <section>
        <p className='label-modal-choose-title'>What do you want to create?</p>
        <Row className='collections-choose-type-row-center'>
            {filteredDataView.map(
                (item, key) => (
                    <a className='modal-card' key={'a' + key} href={item.disabled ? '#' : item.route} onClick={() => {
                        segmentTrack(`Project type selected`)
                    }}>
                        <div className={cn('modal-card-picture', item.class)}>
                            <img style={{ width: '100%' }} alt={item.name} src={item.avatar} />
                        </div>
                        <p className='modal-card-title'> {item.name}</p>
                        <p className='modal-card-subtitle'> {item.description}</p>
                    </a>
                )
            )}

        </Row >

    </section >


    return (
        <React.Fragment>
            {
                showModal && (
                    <div>
                        {__renderPOView(12)}
                    </div>
                )
            }
            <CollectionsFilter search={search}
                setSearch={setSearch}
                sort={sort}
                setSort={setSort}
                display={display}
                setDisplay={setDisplay}>
            </CollectionsFilter>
            {
                display === 'table' && (
                    <Row>
                        <Card className='card-borderless'>
                            {/* <Link to={`/collections/choose-type`} > */}
                            <div onClick={() => {
                                segmentTrack('Initiate create new project')
                                setShowModal(true)
                            }}>
                                <CardBody style={{ height: '260px' }}>
                                    <div className='card-picture'>
                                        <div className='card-picture-empty'>
                                            <FontAwesomeIcon icon={faPlus} className="card-picture-empty-icon"></FontAwesomeIcon>
                                        </div>
                                        {/* <img style={{ width: '100%' }} src="/assets/images/btn-new-item.png" alt={``} /> */}
                                    </div>
                                </CardBody>
                                <CardFooter className="bg-transparent border-top" style={{ height: '50px' }}>
                                    <div className="d-flex">
                                        <div className="flex-fill card-title">{props.t(`Create new collection`)}</div>
                                    </div>
                                </CardFooter>
                            </div>
                        </Card>
                        {
                            filteredData.map(
                                (item, key) => (
                                    <DataTableCard key={key} item={item} display={display} setActiveTab={setActiveTab}></DataTableCard>
                                )
                            )
                        }
                    </Row >
                )
            }
            {
                display === 'list' && (
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Button
                                type="button"
                                color="secundary"
                                className="theme-button-new"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon>{props.t(`Create new collection`)}
                            </Button>
                        </Row>
                        {
                            filteredData.map(
                                (item, key) => (
                                    <DataTableCard key={key} item={item} display={display} setActiveTab={setActiveTab}></DataTableCard>
                                )
                            )
                        }
                    </div>


                )
            }

        </React.Fragment >
    )
}

export default withRouter(withNamespaces()(DataTable))