export const filteredBlockchains = [
    {
        id: 1,
        name: 'Solana'
    },
    {
        id: 2,
        name: 'Polygon'
    },
    {
        id: 3,
        name: 'Ethereum'
    }
]

export const fileReader = 'https://s3uploader-s3uploadbucket-6rrfmrpskpub.s3.us-west-1.amazonaws.com/'