import React from "react"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import cn from 'classnames';

const TabHeaderMenu = (props) => {
    const { step_number, active, collection_id } = props;

    return (
        <section className='collections-settings-header-container'>
            <div className={cn('collections-settings-header-item', active === 1 ? 'active' : '')}>
                <span>1. Upload</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 2 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 1) {
                        props.history.push(`/collections/generative/settings/${collection_id}`)
                    }
                }}>
                <span>2. Settings</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 3 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 2) {

                        props.history.push(`/collections/generative/settings/${collection_id}?step=3`)
                    }
                }}>
                <span>3. Components</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 4 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 3) {
                        props.history.push(`/collections/generative/settings/${collection_id}?step=4`)
                    }
                }}>
                <span>4. Layers & rarity</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 5 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 4) {
                        props.history.push(`/collections/generative/legendary/${collection_id}`)
                    }
                }}>
                <span>5. Legendary Nft</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 6 ? 'active' : '')}
                onClick={(e) => {
                    e.preventDefault()
                    if (step_number > 4) {
                        props.history.push(`/collections/generative/token/${collection_id}`)
                    }
                }}>
                <span>6. Preview</span>
            </div>
            <div className={cn('collections-settings-header-item', active === 7 ? 'active' : '')} onClick={(e) => {
                e.preventDefault()
                if (step_number > 5) {
                    props.history.push(`/collections/generative/minting/${collection_id}`)
                }
            }}>
                <span>7. Deployment</span>
            </div>
        </section >
    );
};

export default withRouter(withNamespaces()(TabHeaderMenu))