import { useDropzone } from "react-dropzone";
import React, { useEffect, useState, useContext } from "react"

function Dropzone({ open, onAssetFull }) {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
        useDropzone({

        });

    let [globalImage, setGlobalImg] = useState('');


    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    useEffect(() => {
        
    }, [globalImage])

    useEffect(() => {
        if (!acceptedFiles) return;
        if (acceptedFiles.length < 1) return;
        
        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d')
        ctx.globalAlpha = 1;
        acceptedFiles.forEach(async (file, index) => {
            let img = await createImageBitmap(file)
            
            ctx.drawImage(img, 0, 0);
            setGlobalImg(canvas.toDataURL('image/png'))
        });




    }, [acceptedFiles])

    return (
        <div style={{ width: '100%' }}>
            <div {...getRootProps({ className: "collections-create-form-upload-drop" })}>
                <input className="input-zone" {...getInputProps()} />

                <div className='collections-create-form-upload-drop-ico'>
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </div>
                {
                    isDragActive ? (

                        <p className='collections-create-form-upload-drop-title'>
                            Release to drop the files here
                        </p>
                    ) :
                        <p className='collections-create-form-upload-drop-title'>
                            Drop files here or click to upload your assets
                        </p>
                }
                <p className='collections-create-form-upload-drop-subtitle'>
                    Not sure what to do? Head to our&nbsp;&#32; <b>&nbsp;&#32;help section</b>
                </p>
            </div>
            <div>
                <p>image</p>
                {
                    globalImage &&
                    <img className="merged-image" src={globalImage} alt="merged image" />
                }

                <p>canvas</p>
                <canvas width="1000" height="600" id="canvas" />

            </div>


        </div>


    );
}
export default Dropzone;
