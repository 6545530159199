import React, { useState, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap'

import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { GeneralContext } from '../../../context'
import Sidemodal from '../../../components/Sidemodal'
import Users from './_Users'

const Global = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [teamMembersModalOpen, setTeamMembersModalOpen] = useState(false)

    const __renderUsersModal = () => (
        <Sidemodal
            className="lg"
            title={props.t(`Team Members`)}
            isOpen={teamMembersModalOpen}
            onRequestClose={() => {
                setTeamMembersModalOpen(false)
            }}
            body={<Users 
                t={props.t} 
                history={props.history} 
                setLoading={setLoading}
                closeModal={setTeamMembersModalOpen}
                />} />
    )

    return (
        <React.Fragment>
            <Row className="settings_company">
                <Col md={12}>
                    <Row className="settings_company_options">
                        <Col md={4}>
                            <Card>
                                <Link to={`/users`} onClick={(e) => {
                                    e.preventDefault()
                                    setTeamMembersModalOpen(true)
                                }}>
                                    <CardTitle>{props.t(`Team Members`)}</CardTitle>

                                    <CardBody>{props.t(`Invite team members to help you control your company.`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {teamMembersModalOpen && __renderUsersModal()}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Global))